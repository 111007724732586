import React from "react";
import PrimaryButton from "../../../../PrimaryButton/PrimaryButton";
import CustomModal from "../../../../CustomModal/CustomModal";
import { CloseDarkOutlined, U25VerifiedUser } from "../../../../Icons/Icons";
import { DisconnectModalProps } from "../../../../../type/types";

const DisconnectModal: React.FC<DisconnectModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  handleBtnClick,
  image,
  userTitle,
  userName,
  isVerified,
}) => {
  const userFirstName = userTitle && userTitle.split(" ")[0];
  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={handleCancel}
        title="Cancel"
        theme="light"
        fontClass="text-[17px] text-primary font-normal"
      />
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleCancel();
          handleBtnClick();
        }}
        title="Disconnect"
        theme="warning"
        fontClass="text-[17px] text-white font-normal"
      />
    </div>
  );

  return (
    <CustomModal
      className="house-modal"
      title={
        <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
          Disconnect
        </h3>
      }
      closeIcon={<CloseDarkOutlined />}
      width={615}
      isModalOpen={isModalOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
      footer={modalFooter}
    >
      <div className="my-6 px-6 py-6">
        <div className="mb-4 rounded-xl">
          <img
            className="mx-auto w-[80px] h-[80px] rounded-xl object-cover"
            src={image}
            alt="Logo"
          />
        </div>
        <div className="mb-4">
          <div className="mb-2 flex gap-1 items-center justify-center">
            <h6 className="text-primary text-[17px] font-medium leading-[100%]">
              {userTitle}
            </h6>
            {isVerified && (
              <span className="min-w-[16px] max-w-[16px] min-h-[16px] max-h-[16px] flex justify-center items-center">
                <U25VerifiedUser />
              </span>
            )}
          </div>
          <p className="text-center text-gray_6 text-[15px] font-normal leading-[120%]">
            {userName}
          </p>
        </div>
        <p className="max-w-[430px] mx-auto text-gray_1 text-center text-[17px] not-italic font-normal leading-[120%]">
          Are you sure you want to disconnect with {userFirstName}, you’ll have
          to request to connect again laters
        </p>
      </div>
    </CustomModal>
  );
};

export default DisconnectModal;
