import React, { useEffect, useState } from "react";
import { Button, Modal, Upload } from "antd";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import "./PhotoUpload.scss";
import { ImageUploadOutlined } from "../Icons/Icons";
import { PhotoUploadProps } from "../../type/types";
import useIsMac from "../../hooks/useIsMac";
import ImgCrop from "antd-img-crop";

const PhotoUpload: React.FC<PhotoUploadProps> = ({
  title = "Profile Photo",
  description = "Min size 2 MB",
  className,
  threadUpload,
  closeIcon = false,
  noBorder = false,
  handleClose,
  carouselUpload,
  setCarosalActiveImage,
  crop = false,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [carouselLists, setCarouselLists] = useState<any>([]);

  const isMac = useIsMac();

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));

    setPreviewOpen(true);

    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];
    newFileList = newFileList.slice(-1);
    newFileList = newFileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    });
    setFileList(newFileList);
    carouselUpload && setCarosalActiveImage(newFileList);
    setCarouselLists(info.fileList);
  };

  const uploadButton = (
    <div className={`${noBorder ? "" : "py-1 absolute left-28 top-4"}`}>
      <div
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col items-start mb-2 cursor-text"
      >
        <span className="text-dark_80 text-[15px] text-start not-italic font-normal leading-[120%] mb-[2px]">
          {title}
        </span>
        <span className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
          {description}
        </span>
      </div>
      <Button
        style={{
          fontFamily: "OskariG2Regular",
        }}
        className={`${isMac ? "pt-2.5 py-1.5" : "py-2"}
        } flex justify-center items-center h-auto border rounded-lg border-solid border-gray_2 text-U25_orange text-sm not-italic font-normal leading-[120%] py-1 px-3 hover:!text-U25_orange hover:!bg-gray_30 hover:!border-gray_2`}
      >
        {fileList.length === 0 ? "Upload" : "Change"}
      </Button>
    </div>
  );

  return (
    <>
      <div
        className={`flex relative border rounded-xl ${
          noBorder ? "border-none !h-auto" : "border-dashed p-4"
        }  border-gray_2 h-[112px] ${className} ${
          threadUpload ? "!h-auto" : ""
        }`}
      >
        {fileList.length > 0 ? null : (
          <div className="flex justify-center items-center w-20 h-[80px] p-7 bg-gray_4 border rounded-xl border-solid border-gray_2 mr-4">
            <ImageUploadOutlined />
          </div>
        )}

        {crop ? (
          <ImgCrop rotationSlider>
            <Upload
              accept="image/*"
              className={`photo-upload ${threadUpload ? "thread-upload" : ""}`}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture-card"
              fileList={fileList}
              onPreview={handlePreview}
              onChange={handleChange}
            >
              {fileList.length > 0 && threadUpload ? null : uploadButton}
            </Upload>
          </ImgCrop>
        ) : (
          <Upload
            accept="image/*"
            className={`photo-upload ${threadUpload ? "thread-upload" : ""}`}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length > 0 && threadUpload ? null : uploadButton}
          </Upload>
        )}

        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" src={previewImage} />
        </Modal>
        {closeIcon && (
          <div
            style={{
              boxShadow: "0px 1px 11px 0px rgba(0, 0, 0, 0.08)",
            }}
            onClick={handleClose}
            className={`absolute flex justify-center items-center ${
              fileList.length !== 0
                ? "right-[24px] top-[24px]"
                : "right-4 top-4"
            } cursor-pointer w-5 h-5 bg-white shadow-3xl rounded-xl hover:bg-gray_2 [&_svg]:stroke-slate-500 transition-[background] duration-200 ease-[ease]`}
          >
            {closeIcon}
          </div>
        )}
      </div>
    </>
  );
};

export default PhotoUpload;
