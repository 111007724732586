import React, { useEffect, useRef, useState } from "react";
import {
  RestartOutline,
  Under25Logo,
  HashTag,
} from "../components/Icons/Icons";
import OnboardingCarousel from "../components/OnboardingCarousel/OnboardingCarousel";
import ImageCheckbox from "../components/ImageCheckbox/ImageCheckbox";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import HouseLogo from "../assets/png/checkbox-img1.png";
import Avatar from "../assets/png/avatar/club/tl_avatar_black.original.png";
import AvatarGreen from "../assets/png/avatar/club/tl_avatar_green.original.png";
import AvatarLight_Blue from "../assets/png/avatar/club/tl_avatar_blue.original.png";
import AvatarOrange from "../assets/png/avatar/club/tl_avatar_orange.original.png";
import AvatarWhite from "../assets/png/avatar/club/tl_avatar_white.original.png";
import AvatarRed from "../assets/png/avatar/club/tl_avatar_red.png";
import AvatarYellow from "../assets/png/avatar/club/tl_avatar_yellow.original.png";
import { Button } from "antd";
import TutorialModal from "../components/Tutorial/TutorialModal";
import CustomModal from "../components/CustomModal/CustomModal";
import { Link } from "react-router-dom";
import HouseModal from "../components/HouseModal/HouseModal";

const SelectHouse = () => {
  const avatarRef = useRef(null);
  const [isTutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [houseTitle, setHouseTitle] = useState("Yellow House");
  const [houseColor, setHouseColor] = useState("#F8EA22");

  const [hashTag, setHashTag] = useState("#standforlearning");
  const [selectedHouseAvatar, setSelectedHouseAvatar] = useState(AvatarYellow);

  const saveToLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  useEffect(() => {
    saveToLocalStorage("selectedHouseAvatar", selectedHouseAvatar);
  }, [selectedHouseAvatar]);

  const getRandomColor = () => {
    const hexValue = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += hexValue[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const [randomColor, setRandomColor] = useState({
    color1: getRandomColor(),
    color2: getRandomColor(),
  });

  useEffect(() => {
    if (avatarRef.current) {
      const { color1, color2 } = randomColor;
      (
        avatarRef.current as HTMLDivElement
      ).style.background = `linear-gradient(135deg, ${color1} 0%, ${color2} 100%)`;
    }
  }, [randomColor]);

  const handleRandomColor = () => {
    const randomColor1 = getRandomColor();
    const randomColor2 = getRandomColor();
    setRandomColor({
      color1: randomColor1,
      color2: randomColor2,
    });
  };

  const imageCheckboxData = [
    {
      id: "1",
      isChecked: true,
      src: "#F8EA22",
      avatar: AvatarYellow,
      hashtag: "#standforlearning",
      title: "Yellow House",
    },

    {
      id: "2",
      isChecked: false,
      src: "#178040",
      avatar: AvatarGreen,
      hashtag: "#standforenvironment",
      title: "Green House",
    },
    {
      id: "3",
      isChecked: false,
      src: "#001EFF",
      avatar: AvatarLight_Blue,
      hashtag: "#standforexpression",
      title: "Blue House",
    },
    {
      id: "4",
      isChecked: false,
      src: "#FF7A00",
      avatar: AvatarOrange,
      hashtag: "#standforequality",
      title: "Orange House",
    },
    {
      id: "5",
      isChecked: false,
      src: "#fff",
      avatar: AvatarWhite,
      hashtag: "#standforwellbeing",
      title: "White House",
    },
    {
      id: "6",
      isChecked: false,
      src: "#1F1F1F",
      avatar: Avatar,
      hashtag: "#standforcollaboration",
      title: "Black House",
    },
    {
      id: "7",
      isChecked: false,
      src: "#FF3838",
      avatar: AvatarRed,
      hashtag: "#standforlove",
      title: "Red House",
    },
  ];

  const modalFooterConfirm = (
    <div className="flex justify-end items-center gap-4 rounded-[0_0_12px_12px] bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <Link to={"/community"}>
        <PrimaryButton title="Continue to community" width="fit-content" />
      </Link>
    </div>
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  return (
    <div className="flex justify-center items-center bg-white h-screen">
      <div className="flex shadow-[0px_4px_12px_4px_rgba(31,31,31,0.12)] h-[588px] rounded-3xl">
        <div className="pt-8 pb-16 pl-16 pr-20 max-w-[544px] overflow-y-auto theme-scrollbar">
          <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] mb-12">
            <Under25Logo />
          </div>
          <h1 className="text-primary text-[35px] not-italic font-medium leading-[100%] mb-2">
            Select House
          </h1>
          <p className="text-gray_1 text-xl not-italic font-normal leading-[120%] opacity-80 mb-6">
            Click and select a house, scroll for options.
          </p>
          <div className="pb-4">
            <div className="inline-flex bg-purple_bg rounded-lg p-2 mb-4 h-8 w-fit">
              <span className="text-purple_1 text-[13px] not-italic font-normal leading-[120%] uppercase">
                {hashTag}
              </span>
            </div>
            <h2 className="text-primary text-xl not-italic font-medium leading-[100%] mb-2">
              {houseTitle}
            </h2>
            <p className="text-gray_3_hover text-[17px] not-italic font-normal leading-[120%]">
              Lorem ipsum dolor sit amet consectetur. Sapien iaculis varius odio
              nisl.
            </p>
          </div>
          <div className="mb-6 pt-5 pb-6">
            <ImageCheckbox
              setHashTag={setHashTag}
              setHouseTitle={setHouseTitle}
              setHouseColor={setHouseColor}
              data={imageCheckboxData}
              setSelectedHouseAvatar={setSelectedHouseAvatar}
            />
          </div>
          <div className="mb-10">
            <div className="flex gap-4 pt-5 mb-4">
              <div
                ref={avatarRef}
                className="relative rounded-[10.684px] max-w-[90px] min-w-[90px] max-h-[90px] min-h-[90px] object-cover"
              >
                <img
                  className="absolute w-[85px] h-auto bottom-0 right-0 left-[4px]"
                  src={selectedHouseAvatar}
                  alt="under25 avatar"
                />
              </div>
              <div>
                <div className="mb-4">
                  <h3 className="text-primary text-xl not-italic font-medium leading-[100%] mb-1">
                    Your Avatar
                  </h3>
                  <p className="text-gray_1 text-sm not-italic font-normal leading-[120%]">
                    choose colors, setup your avatar.
                  </p>
                </div>
                <div className="flex gap-4">
                  <div className="inline-flex border p-1 rounded-lg border-solid border-gray_4">
                    <div
                      style={{
                        backgroundColor: randomColor.color1,
                      }}
                      className={`w-[18px] h-[18px] rounded `}
                    ></div>
                  </div>
                  <div className="inline-flex border p-1 rounded-lg border-solid border-gray_4">
                    <div
                      style={{
                        backgroundColor: randomColor.color2,
                      }}
                      className={`w-[18px] h-[18px] rounded`}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Button
                className="flex items-center justify-center gap-2 w-full h-11 border bg-white p-3 rounded-lg border-solid border-gray_2 hover:!border-primary transition-all duration-300 ease-[ease]"
                onClick={handleRandomColor}
                style={{
                  fontFamily: "OskariG2Regular",
                }}
              >
                <span>
                  <RestartOutline />
                </span>
                <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                  Randomize
                </span>
              </Button>
            </div>
          </div>
          <PrimaryButton
            onClick={() => setOpenModal(true)}
            title="Explore the Club"
            width="full"
          />
          {/* <TutorialModal
            isModalOpen={isTutorialModalOpen}
            handleOk={() => {
              setOpenConfirmModal(true);
              setTutorialModalOpen(false);
            }}
            handleCancel={() => {
              setTutorialModalOpen(false);
            }}
          /> */}
          <HouseModal
            isModalOpen={openModal}
            houseTitle={houseTitle}
            houseColor={houseColor}
            hashTag={hashTag}
            handleOk={() => {
              setOpenModal(false);
              // setTutorialModalOpen(true);
            }}
            handleCancel={() => {
              setOpenModal(false);
            }}
          />
          <CustomModal
            isModalOpen={openConfirmModal}
            handleOk={() => {
              setOpenConfirmModal(false);
            }}
            handleCancel={() => {
              setOpenConfirmModal(false);
            }}
            footer={modalFooterConfirm}
            title={
              <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
                Tutorial
              </h3>
            }
            width={615}
          >
            <div className="flex flex-col gap-4 mt-4 mb-3 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full justify-center items-center ">
              <span className=" p-[4.8px] w-12 h-12 bg-U25_orange rounded-[240px] flex justify-center items-center">
                <svg
                  width="28"
                  height="21"
                  viewBox="0 0 28 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_448_20998)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                      fill="white"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                      stroke="white"
                      stroke-width="1.8"
                      stroke-linecap="square"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_448_20998"
                      x="0.347656"
                      y="0.370972"
                      width="27.5047"
                      height="20.4579"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dx="1.2" dy="1.2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_448_20998"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_448_20998"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </span>
              <h4 className="text-2xl not-italic font-medium leading-[100%]">
                You’re all set!
              </h4>
              <p className="text-[17px] w-2/3 text-center not-italic text-gray_6   font-normal leading-[120%] capitaliz">
                Congratulations for completing the Tutorial, You’re all set to
                dive in.
              </p>
            </div>
          </CustomModal>
        </div>

        <OnboardingCarousel />
      </div>
    </div>
  );
};

export default SelectHouse;
