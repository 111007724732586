import { ActiveMissionListProps } from "../type/types";
import U25Dark from "../assets/svg/under25-dark.svg";
import U25PurpleFilled from "../assets/svg/under25-purple-filled.svg";
import SnapChat from "../assets/svg/snapchat.svg";
import JobsIntrestBox from '../components/Missions/JobsIntrestBox.tsx/JobsIntrestBox';

const activeMissionData: ActiveMissionListProps[] = [
    {
      id: "1",
      title: "Mission 01 - Complete your KYC",
      image: U25Dark,
      reward: "12",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "2",
      title: "Mission 01 - Complete your KYC",
      image: U25PurpleFilled,
      reward: "12",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "3",
      title: "Mission 01 - Complete your KYC",
      image: SnapChat,
      reward: "Giveaway",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "4",
      title: "Mission 01 - Complete your KYC",
      image: U25PurpleFilled,
      reward: "12",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
  ];

  export default activeMissionData;




