import React from "react";
import FeedModal from "../FeedModal/FeedModal";
import { HouseModalProps } from "../../../type/types";
import FeedLayout from "../FeedLayout";
import SnapUser from "../../../assets/png/snap.png";

const DetailView: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  return (
    <>
      <FeedModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleOk}
        width={1440}
      >
        <>
          <FeedLayout
            userAvatar={SnapUser}
            name={"snachatopinionleader"}
            subName={"e/anushaudupi"}
            closeIcon={true}
            handleClose={handleCancel}
            containerClass="pb-5 pl-0 pt-5 pr-[30px] mr-[22px] lg:max-w-[550px] md:max-w-auto theme-scrollbar-sm max-h-[calc(100vh-20px)] overflow-auto"
          >
            <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
              Are you a desi Munda or a hollywood geek?
            </h2>

            <p className="text-[17px] not-italic font-normal leading-[120%] mt-4 items-center  ">
              Lorem ipsum dolor sit amet consectetur. Facilisis sed id dolor
              purus turpis pulvinar.
            </p>
          </FeedLayout>
        </>
      </FeedModal>
    </>
  );
};

export default DetailView;
