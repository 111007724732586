import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import SelectTags from "../../../themes/SelectTags/SelectTags";
import SuggestComp from "../../SuggestComp/SuggestComp";
interface InterestModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectData: React.Dispatch<React.SetStateAction<string[]>>;
  title: string;
}

const InterestModal: React.FC<InterestModalProps> = ({
  popover,
  setPopover,
  setSelectData,
  title,
}) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const InterestSuggestions3 = ["dummy a", "dummy b", "dummy c", "dummy d "];

  const InterestSuggestions1 = [
    "Deep chats",
    "Mindfulness",
    "Nutrition",
    "Therapy",
    "Sleeping well",
    "dancing",
    "Time offline",
  ];
  const InterestSuggestions2 = [
    "dummy 1",
    "dummy 2",
    "dummy 3",
    "dummy 4 ",
    "dummy 5",
    "dummy 6",
    "dummy 7",
  ];

  const handleClosePopover = () => {
    setPopover(false);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };

  const SelectData = [
    {
      value: "Deep chats",
      label: "Deep chats",
    },
    {
      value: "Mindfulness",
      label: "Mindfulness",
    },
    {
      value: "Nutrition",
      label: "Nutrition",
    },
    {
      value: "Therapy",
      label: "Therapy",
    },
  ];

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Save"
      />
    </div>
  );

  const addValueToSelection = (value: string) => {
    if (selectedValues.length !== 5) {
      setSelectedValues([...selectedValues, value]);
      setSelectData([...selectedValues, value]);
    }
  };

  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={600}
    >
      <div className="mx-6 mt-[24px]">
        <SelectTags
          optionData={SelectData}
          selectValue={selectedValues}
          setSelectedValue={setSelectedValues}
        />
        <SuggestComp
          onChipClick={addValueToSelection}
          title="Interest 1"
          data={InterestSuggestions1}
        />
        <SuggestComp
          onChipClick={addValueToSelection}
          title="Interest 2"
          data={InterestSuggestions2}
        />
        <SuggestComp
          onChipClick={addValueToSelection}
          title="Interest 3"
          data={InterestSuggestions3}
        />
      </div>
    </CustomModal>
  );
};

export default InterestModal;
