import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import CommunityCard from "../../../../assets/png/community-card-1.png";
import "./CommunityCarousel.scss";

const CommunityCarousel = () => {
  const dummyData = [
    {
      author: "Kanishka Reddy",
      image: CommunityCard,
    },
    {
      author: "John Doe",
      image: CommunityCard,
    },
    {
      author: "Jane Smith",
      image: CommunityCard,
    },
  ];

  return (
    <div className="grid">
      <div className="w-full overflow-hidden">
        <OwlCarousel
          className="owl-theme community-carousel"
          itemElement="div"
          items={1}
          margin={16}
          dots
          loop
          autoplay
          controlsClass="mt-0"
          dotsClass="flex items-center justify-center w-fit mt-4 mx-auto bg-gray_4 p-2.5 rounded-[131px]"
          dotClass="white-dark-dot"
        >
          {dummyData.map((item, index) => (
            <div className="rounded-[24px] bg-dull_white p-2" key={index}>
              <div className="rounded-[16px]">
                <img
                  className="rounded-[16px]"
                  src={item.image}
                  alt="Community card"
                />
              </div>
              <div className="pt-4 px-2 pb-0">
                <h3 className="text-primary text-2xl not-italic font-normal leading-[120%]">
                  by {item.author}
                </h3>
              </div>
            </div>
          ))}
        </OwlCarousel>
      </div>
    </div>
  );
};

export default CommunityCarousel;
