import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { XCloseMd } from "../../Icons/Icons";
import ChatListItem from "../../Chat/ChatList/ChatListItem";
import { archiveDataLists } from "../../../data/archieveDataLists";

const ChatArchived: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Archived
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton
        onClick={() => {
          handleCancel();
        }}
        theme={"light"}
        title="Back"
        className=""
        width="fit-content"
      />
    </div>
  );

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
      title={CardHeader}
      footer={modalFooter}
      width={615}
      closeIcon={<XCloseMd />}
    >
      <div className="py-6 mx-6">
        {archiveDataLists?.map((item, index) => (
          <ChatListItem key={index} item={item} archiveMenu={true} />
        ))}
      </div>
    </CustomModal>
  );
};

export default ChatArchived;
