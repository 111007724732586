import React, { ReactNode, useState } from "react";
import { Checkbox } from "antd";
import "./CustomCheckbox.scss";
import { CheckboxChangeEvent } from "antd/es/checkbox";

interface CustomCheckboxProps {
  onChange?: (e: CheckboxChangeEvent) => void;
  className?: string;
  children: ReactNode;
  name?: string;
  size?: "large" | "default";
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  onChange,
  className,
  children,
  name,
  size,
}) => {
  return (
    <>
      <Checkbox
        className={`custom-checkbox text-gray_1 text-[17px] not-italic flex  items-center font-normal leading-[120%] select-none ${className} ${
          size === "large" ? "checkbox-lg" : "default"
        }`}
        onChange={onChange}
        name={name}
      >
        {children}
      </Checkbox>
    </>
  );
};

export default CustomCheckbox;
