import { Button, Input } from "antd";
import { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import SelectData from "../../../themes/SelectData/SelectData";
import U25DatePicker from "../../U25DatePicker/U25DatePicker";
import TextArea from "antd/es/input/TextArea";
import { EditOutlined } from "../../Icons/Icons";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import EditPicture from "./EditPicture";
import useIsMac from "../../../hooks/useIsMac";

interface PersonalInfoTabProps {
  handleClosePopover: () => void;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PersonalInfoTab: React.FC<PersonalInfoTabProps> = ({
  handleClosePopover,
  setPopover,
}) => {
  const [inputValue, setInputValue] = useState("Mohit Kapkoti");
  const [username, setUsername] = useState("Mohitt09");
  const maxValueCount = 30;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setInputValue(inputValue);
    }
  };
  const GenderData = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
    {
      value: "Other",
      label: "Other",
    },
  ];
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);
  const [about, setAbout] = useState("");

  const isMac = useIsMac();

  return (
    <div className="mt-2">
      {/* <div className="flex justify-center items-center flex-col gap-6">
                <div className="flex items-center gap-4">
                    <div className="border border-[#FC741E] rounded-[16px] p-1.5 min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px]">
                        <img src={profilePic} alt="profile pic" />
                    </div>
                    <div className="min-w-[112px] max-w-[112px] min-h-[112px] max-h-[112px]">
                        <img src={Avatarpic} alt="avatar" />
                    </div>
                </div>
                <Button className="personal-info-btn">
                    <div className="flex items-center gap-2">
                        <span className="max-w-[12px] min-w-[12px] min-h-[12px] max-h-[12px]"><EditOutlined width={12} height={12} /></span>
                        <h6 className="text-[#1F1F1F] text-[16px] font-normal leading-[normal]">Edit photo</h6>
                    </div>
                </Button>
            </div> */}
      <EditPicture
        handleClosePopover={handleClosePopover}
        setPopover={setPopover}
      />

      <div className="flex flex-col gap-5 mt-[32px]">
        <div className="flex flex-col gap-1">
          <label
            htmlFor="name"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            Full Name
          </label>
          <div className="relative">
            <CustomInput
              id="name"
              value={inputValue}
              onChange={handleInputChange}
              maxLength={maxValueCount}
            />
            <div
              className={` ${
                isMac && "top-[18px]"
              } absolute top-[16px] right-[20px]`}
            >
              <p className=" text-[#666666CC] text-[13px] not-italic font-normal leading-[120%]">
                {inputValue.length}/{maxValueCount}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="username"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            Username
          </label>
          <div className="relative">
            <CustomInput
              className="personal-tab-input"
              id="username"
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
            <div
              className={` ${
                isMac && "top-[14px]"
              } absolute top-[12px] left-[20px]`}
            >
              <p className=" text-[#66666699] text-[17px] not-italic font-normal leading-[120%]">
                e/
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
            Gender
          </label>
          <SelectData
            popupClassName={"social-link-dropdown-popup personal-info-popup"}
            defaultValue="Male"
            optionsData={GenderData}
            className="personal-info-dropdown"
          />
        </div>
        <div className="">
          <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
            Date of Birth
          </label>
          <U25DatePicker
            showSecondDatePicker={showSecondDatePicker}
            setShowSecondDatePicker={setShowSecondDatePicker}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="about"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            Bio
          </label>
          <div className="relative">
            <TextArea
              style={{ fontFamily: "OskariG2Regular" }}
              value={about}
              onChange={(e) => {
                const input = e.target.value;
                if (input.length <= 100) {
                  setAbout(input);
                }
              }}
              id="about"
              className="personal-info-textarea"
              placeholder="Tell us about yourself..."
            />
            <span className="absolute bottom-[12px] right-[16px]">
              <p className="text-[#666666CC] text-[13px] font-normal leading-[normal]">{`${about.length}/100`}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
