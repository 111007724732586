import { Select } from "antd";
import { DropdownArrow } from "../../components/Icons/Icons";
import { CSSProperties } from "react";
import useIsMac from "../../hooks/useIsMac";
import "./SelectData.scss";
interface SelectOption {
  value: string;
  label: string;
}

interface SelectDataProps {
  defaultValue: string;
  className?: string;
  optionsData: SelectOption[];
  popupClassName?: string;
  onChange?: (value: string) => void;
  popupMatchSelectWidth?: boolean | number;
  dropdownStyle?: CSSProperties;
  placement?: "topLeft" | "topRight" | "bottomLeft" | "bottomRight";
  suffixIcon?: any;
}
const SelectData: React.FC<SelectDataProps> = ({
  defaultValue,
  className = "",
  optionsData,
  popupClassName,
  onChange,
  popupMatchSelectWidth,
  dropdownStyle,
  placement,
  suffixIcon = <DropdownArrow />,
}) => {
  const isMac = useIsMac();

  return (
    <Select
      defaultValue={defaultValue}
      className={`${className} ${isMac && "is-mac"}`}
      onChange={onChange}
      options={optionsData}
      suffixIcon={suffixIcon}
      placement={placement}
      popupClassName={popupClassName}
      popupMatchSelectWidth={popupMatchSelectWidth}
      dropdownStyle={dropdownStyle}
    />
  );
};

export default SelectData;
