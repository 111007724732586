import React, { useState } from "react";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import { CloseLightOutlined } from "../Icons/Icons";
import { LinkUploadProps } from "../../type/types";

const LinkUpload: React.FC<LinkUploadProps> = ({ handleClose }) => {
  const [link, setLink] = useState<string>("");

  return (
    <div className="relative border px-4 py-5 rounded-[10px] border-solid border-[rgba(236,236,236,0.60)] my-5">
      <CustomTextArea
        className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize mb-5"
        placeholder="Title"
      />
      <CustomTextArea
        value={link}
        onChange={(e) => setLink(e.target.value)}
        className="placeholder:text-gray_10 capitalize overflow-hidden text-blue_1 text-ellipsis text-[17px] not-italic font-normal leading-[120%]"
        placeholder="Paste link here"
      />
      <div
        style={{
          boxShadow: "0px 1px 11px 0px rgba(0, 0, 0, 0.08)",
        }}
        onClick={handleClose}
        className={`absolute flex justify-center items-center right-4 top-4 cursor-pointer w-5 h-5 bg-white shadow-3xl rounded-xl hover:bg-gray_2 [&_svg]:stroke-slate-500 transition-[background] duration-200 ease-[ease]`}
      >
        <CloseLightOutlined />
      </div>
    </div>
  );
};

export default LinkUpload;
