import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

interface ChatLogoutModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const ChatLogoutModal: React.FC<ChatLogoutModalProps> = ({
  modal,
  setModal,
}) => {
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        Delete Chat
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleClosePopover();
        }}
        title="Cancel"
      />
      <PrimaryButton
        theme="warning"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Delete"
      />
    </div>
  );
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };
  return (
    <CustomModal
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6 py-4 pb-2">
        <p className="text-[#666666] text-[17px] font-normal leading-[120%] ">
          Messages will only be removed from this device.
        </p>
        {/* <CustomCheckbox
          onChange={() => {}}
          className="text-[15px] flex not-italic font-normal leading-[120%] items-center px-2 py-3"
        >
          Also Delete media received in this chat from the device gallery
        </CustomCheckbox> */}
      </div>
    </CustomModal>
  );
};

export default ChatLogoutModal;
