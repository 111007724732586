import { Progress } from 'antd';
import './progress.scss'

interface ProgressBarProps {
    label?: string | null;
    percentage?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ label = null, percentage = 30 }) => {
    return (
        <div>
            <h6 className="text-[#666666] font-normal text-[14px] leading-[120%]">{label}</h6>
            <Progress percent={percentage} className='progress-bar' />
        </div>
    )
}

export default ProgressBar