/* eslint-disable react-hooks/exhaustive-deps */
import { Steps, UploadFile } from "antd";
import { useEffect, useRef, useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import SelectData from "../../../themes/SelectData/SelectData";
import U25DatePicker from "../../U25DatePicker/U25DatePicker";
import TextArea from "antd/es/input/TextArea";
import { CheckURL, MissionCreatIcon } from "../../Icons/Icons";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import SubmissionTypes from "../SubmissionTypes/SubmissionTypes";
import useIsMac from "../../../hooks/useIsMac";
interface PersonalInfoTabProps {
  handleClosePopover: () => void;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  current?: number;
  setCurrent?: any;
}
export const CreateOpportunity: React.FC<PersonalInfoTabProps> = ({
  current = 0,
}) => {
  const [brandInputValue, setBrandInputValue] = useState("Snapchat");
  const [moneyInputValue, setMoneyInputValue] = useState("12");
  const [inputValue, setInputValue] = useState("");
  const [limitedInputValue, setLimitedInputValue] = useState("50");
  // const [inputValue, setInputValue] = useState("");

  const [linkCount, SetLinkCount] = useState(1);
  const [stepsCount, setStepsCount] = useState(2);
  const [guidelinesCount, setGuidelinesCount] = useState(2);
  const [unlocks, setUnlocks] = useState("Money");
  const [inputData, setInputData] = useState({
    giveaway: "",
  });
  const maxValueCount = 30;
  const [typeOfOpening, setTypeOfOpening] = useState("");
  const [carosalActiveImage, setCarosalActiveImage] = useState<UploadFile[]>(
    []
  );
  const [carosalActiveImageBlob, setCarosalActiveImageBlob] = useState("");
  const [link, setLink] = useState({
    link1: "",
    link2: "",
    link3: "",
  });
  const TimeData = [];
  const [images, setImages] = useState<any[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);
  const [showStartingOnDate, setShowStartingOnDate] = useState(false);
  const [showEndingOnDate, setShowEndingOnDate] = useState(false);
  const [about, setAbout] = useState("Snapchat Filter Mission");
  const isMac = useIsMac();
  const [stepsInput, setStepsInput] = useState({
    ...createStepsInputData(stepsCount),
  });
  const [guidelinesInput, setGuidelinesInput] = useState({
    ...createGuidelinesInputData(guidelinesCount),
  });

  const handleBrandInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setBrandInputValue(inputValue);
    }
  };

  const handleMoneyInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setMoneyInputValue(inputValue);
    }
  };

  const handleLimitedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setLimitedInputValue(inputValue);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setInputValue(inputValue);
    }
  };

  function validURL(str: string) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" +
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
        "((\\d{1,3}\\.){3}\\d{1,3}))" +
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
        "(\\?[;&a-z\\d%_.~+=-]*)?" +
        "(\\#[-a-z\\d_]*)?$",
      "i"
    );
    return !!pattern.test(str);
  }

  const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setLink((prevLink) => ({
      ...prevLink,
      [name]: value,
    }));
  };

  const handleStepsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setStepsInput((prevStepsInput) => ({
      ...prevStepsInput,
      [name]: value,
    }));
  };

  const handleGuidelinesInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setGuidelinesInput((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  function createStepsInputData(count: number) {
    const inputData = [];
    for (let i = 0; i < count; i++) {
      inputData.push({
        [`stepsInput${i + 1}`]: "",
      });
    }
    return inputData;
  }

  function createGuidelinesInputData(count: number) {
    const inputData = [];
    for (let i = 0; i < count; i++) {
      inputData.push({
        [`guidelinesInput${i + 1}`]: "",
      });
    }
    return inputData;
  }

  const UnLockData = [
    {
      value: "Money",
      label: "Money",
    },
    {
      value: "Giveaway",
      label: "Giveaway",
    },
  ];

  for (let hour = 0; hour < 24; hour++) {
    const time = hour.toString().padStart(2, "0") + ":00";
    TimeData.push({
      value: time,
      label: time,
    });
  }

  const giveaway = [
    { value: "600", label: "600" },
    { value: "1200", label: "1200" },
    { value: "1800", label: "1800" },
    { value: "2400", label: "2400" },
    { value: "3000", label: "3000" },
  ];

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      const newImage: any = {
        id: Date.now(),
        file: selectedFile,
      };

      setImages([...images, newImage]);
    }
  };

  useEffect(() => {
    if (carosalActiveImage[0]?.originFileObj instanceof Blob) {
      const imageUrl: any = URL.createObjectURL(
        carosalActiveImage[0].originFileObj
      );
      const imageUrlBlob: any = {
        id: Date.now(),
        file: carosalActiveImage[0].originFileObj,
      };

      setCarosalActiveImageBlob(imageUrl);
      setImages([...images, imageUrlBlob]);
    } else {
      console.error("The 'originFileObj' is not a valid Blob or is undefined.");
    }
  }, [carosalActiveImage]);

  return (
    <div className="mb-3 mt-[22px]">
      <div className="flex flex-col opportunity w-full">
        <div className="h-10">
          <Steps
            progressDot
            current={current}
            items={[
              {
                title: "Primary Info",
              },
              {
                title: "Mission Details",
              },
              {
                title: "Publish",
              },
              {
                title: "Submissions",
              },
            ]}
          ></Steps>
        </div>
        {current === 0 && (
          <>
            <div className="flex flex-col pt-5">
              <h6 className=" text-xl font-medium leading-[100%] mb-1">
                Primary info
              </h6>
              <p className=" text-gray_6 text-[17px] mb-5">
                Basic info about the mission
              </p>
            </div>
            <PhotoUpload
              title="Brand Logo"
              description="max file size 500kb "
              closeIcon={false}
            />
            <div className="flex flex-col gap-1 my-5">
              <label
                htmlFor="name"
                className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              >
                Brand Name
              </label>
              <div className="relative">
                <CustomInput
                  id="name"
                  value={brandInputValue}
                  onChange={handleBrandInputChange}
                  maxLength={maxValueCount}
                  placeholder="Brand Name"
                  defaultValue="Snapchat"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 ">
              <label
                htmlFor="about"
                className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              >
                Mission Name
              </label>
              <div className="relative">
                <TextArea
                  rows={2}
                  value={about}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length <= 100) {
                      setAbout(input);
                    }
                  }}
                  id="about"
                  className="misssion-create-textarea  theme-scrollbar-sm font-sans"
                  placeholder="Mission Name"
                />
                <span className="absolute bottom-[12px] right-[16px]">
                  <p className="text-[#666666CC] text-[13px] font-normal leading-[normal]">{`${about.length}/100`}</p>
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1 my-5">
              <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                Starting On
              </label>
              <div className="flex w-full justify-between">
                <div className=" w-3/4 mr-4">
                  <U25DatePicker
                    showSecondDatePicker={showStartingOnDate}
                    setShowSecondDatePicker={setShowStartingOnDate}
                    type={"mission"}
                  />
                </div>
                <SelectData
                  popupClassName={
                    "social-link-dropdown-popup personal-info-popup"
                  }
                  defaultValue="12:00"
                  optionsData={TimeData}
                  placement="bottomRight"
                  className="personal-info-dropdown w-1/4"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1 mb-5">
              <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                Ending On
              </label>
              <div className="flex w-full justify-between">
                <div className=" w-3/4 mr-4">
                  <U25DatePicker
                    showSecondDatePicker={showEndingOnDate}
                    setShowSecondDatePicker={setShowEndingOnDate}
                    type={"mission"}
                  />
                </div>
                <SelectData
                  popupClassName={
                    "social-link-dropdown-popup personal-info-popup"
                  }
                  defaultValue="12:00"
                  optionsData={TimeData}
                  placement="bottomRight"
                  className="personal-info-dropdown w-1/4"
                />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                Evaluation Deadline
              </label>
              <div className="flex w-full justify-between">
                <div className=" w-3/4 mr-4">
                  <U25DatePicker
                    showSecondDatePicker={showSecondDatePicker}
                    setShowSecondDatePicker={setShowSecondDatePicker}
                    type={"mission"}
                  />
                </div>
                <SelectData
                  popupClassName={
                    "social-link-dropdown-popup personal-info-popup"
                  }
                  defaultValue="12:00"
                  optionsData={TimeData}
                  placement="bottomRight"
                  className="personal-info-dropdown w-1/4"
                />
              </div>
            </div>
            <div className=" flex w-full gap-4 my-5">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                  Unlocks
                </label>
                <div className="flex w-full justify-between">
                  <SelectData
                    popupClassName={
                      "social-link-dropdown-popup personal-info-popup"
                    }
                    onChange={(value) => setUnlocks(value)}
                    defaultValue={`Money`}
                    optionsData={UnLockData}
                    className="personal-info-dropdown w-full"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                  {unlocks === "Money" ? "Money Amount" : "Worth of Giveaway"}
                </label>
                <div className="flex w-full justify-between">
                  {unlocks === "Money" ? (
                    <CustomInput
                      containerStyle="w-full"
                      id="name"
                      value={moneyInputValue}
                      onChange={handleMoneyInputChange}
                      className="w-full"
                      type="number"
                      maxLength={maxValueCount}
                    />
                  ) : (
                    <SelectData
                      popupClassName={
                        "social-link-dropdown-popup personal-info-popup"
                      }
                      defaultValue="1200"
                      optionsData={giveaway}
                      className="personal-info-dropdown w-full"
                    />
                  )}
                </div>
              </div>
            </div>
            {unlocks === "iveaway" && (
              <div className=" flex flex-col w-full gap-1 mb-5">
                <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                  Giveaway Title
                </label>
                <CustomInput
                  containerStyle="w-full"
                  id="name"
                  value={inputData.giveaway}
                  onChange={(e) =>
                    setInputData({ ...inputData, giveaway: e.target.value })
                  }
                  placeholder="eg. Amazon voucher worth ₹200"
                  className="w-full"
                  maxLength={maxValueCount}
                />
              </div>
            )}
            <div className=" flex w-full gap-4">
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]">
                  Type of Opening{" "}
                </label>
                <div className="flex w-full justify-between">
                  <SelectData
                    popupClassName={
                      "social-link-dropdown-popup personal-info-popup"
                    }
                    defaultValue="Limited"
                    optionsData={[
                      {
                        value: "Limited",
                        label: "Limited",
                      },
                      {
                        value: "Unlimited",
                        label: "Unlimited",
                      },
                    ]}
                    onChange={(value) => setTypeOfOpening(value)}
                    className="personal-info-dropdown  w-full "
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                <label className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                  No of Openings
                </label>
                <div className="flex w-full justify-between">
                  <CustomInput
                    id="name"
                    containerStyle="w-full"
                    value={
                      typeOfOpening.toLowerCase() === "unlimited"
                        ? "♾️"
                        : limitedInputValue
                    }
                    type={
                      typeOfOpening.toLowerCase() === "unlimited"
                        ? "text"
                        : "number"
                    }
                    readOnly={typeOfOpening.toLowerCase() === "unlimited"}
                    onChange={handleLimitedInputChange}
                    className="w-full"
                    maxLength={maxValueCount}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {current === 1 && (
          <>
            <div className="flex flex-col">
              <h6 className=" text-xl font-medium leading-[100%] mt-5">
                Mission Details
              </h6>
              <p className=" text-gray_6 text-[17px] mt-1">
                Images, Brief and Links
              </p>
              <p className=" text-gray_6 text-[17px] mt-5 mb-1">
                Carousel Pictures - {images.length}/6 (optional){" "}
              </p>
            </div>
            {carosalActiveImageBlob ? (
              <div className=" rounded-xl max-w-[316px] min-w-[316px]">
                <div className="relative">
                  <img
                    className="rounded-xl max-h-[177px] min-h-[177px] "
                    src={URL.createObjectURL(images[images.length - 1].file)}
                    alt=""
                  />
                  <span className=" w-5 bg-white rounded-[10px] h-5 p-1 flex justify-center items-center absolute top-2 right-2 cursor-pointer ">
                    <svg
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
                        stroke="#A4A4A4"
                        stroke-width="0.802784"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
                <div className="mt-2 flex">
                  <div className="flex gap-2">
                    {images.map((image) => (
                      <div
                        key={image.id}
                        className="last:border-2 last:border-U25_orange  rounded-lg "
                      >
                        <img
                          src={URL.createObjectURL(image.file)}
                          alt="Uploaded"
                          className="max-w-[46px] rounded-lg min-w-[46px] max-h-[46px] min-h-[46px] cursor-pointer"
                        />
                      </div>
                    ))}
                  </div>
                  {images.length < 6 && (
                    <>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileUpload}
                        style={{ display: "none" }}
                        ref={fileInputRef}
                      />
                      <button
                        className="p-3 justify-center items-center flex border-[1.5px] border-gray_2 rounded-lg ml-2"
                        onClick={() => fileInputRef.current?.click()}
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.0042 4.68359V16.8169M4.9375 10.7503H17.0708"
                            stroke="#1F1F1F"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <PhotoUpload
                title="Carousel Pictures"
                description="max file size 500kb "
                closeIcon={false}
                carouselUpload={true}
                setCarosalActiveImage={setCarosalActiveImage}
              />
            )}

            <div className="flex flex-col gap-1 mt-5">
              <label
                htmlFor="about"
                className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              >
                Mission Brief
              </label>
              <div className="relative">
                <TextArea
                  rows={2}
                  value={about}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length <= 100) {
                      setAbout(input);
                    }
                  }}
                  id="about"
                  className="misssion-create-textarea theme-scrollbar-sm font-sans"
                  placeholder="What is the event about?"
                />
                <span className="absolute bottom-[12px] right-[16px]">
                  <p className="text-[#666666CC]  text-[13px] font-normal leading-[normal]">{`${about.length}/100`}</p>
                </span>
              </div>
              <div className="flex flex-col gap-2 mt-5">
                <label
                  htmlFor="name"
                  className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
                >
                  Important Links - {linkCount}/3
                </label>
                {linkCount > 0 && (
                  <div className="relative">
                    <CustomInput
                      id="name"
                      name="link1"
                      value={link.link1}
                      onChange={handleLinkChange}
                    />
                    <div className="absolute top-[16px] right-[20px]">
                      {validURL(link.link1) && <CheckURL />}
                    </div>
                  </div>
                )}
                {linkCount > 1 && (
                  <div className="relative">
                    <CustomInput
                      id="name"
                      name="link2"
                      value={link.link2}
                      onChange={handleLinkChange}
                    />
                    <div className="absolute top-[16px] right-[20px]">
                      {validURL(link.link2) && <CheckURL />}
                    </div>
                  </div>
                )}
                {linkCount > 2 && (
                  <div className="relative">
                    <CustomInput
                      id="name"
                      name="link3"
                      value={link.link3}
                      onChange={handleLinkChange}
                    />
                    <div className="absolute top-[16px] right-[20px]">
                      {validURL(link.link3) && <CheckURL />}
                    </div>
                  </div>
                )}
              </div>
              {linkCount < 3 && (
                <div className="w-full flex justify-center items-center">
                  <button
                    onClick={() => {
                      SetLinkCount(linkCount + 1);
                    }}
                    className=" p-3 w-fit  flex h-10 justify-center items-center border rounded-lg border-gray_2  mr-4 cursor-pointer "
                  >
                    <span className=" w-5  flex h-5 justify-center items-center cursor-pointer mr-2 ">
                      <MissionCreatIcon />
                    </span>
                    Add Link
                  </button>
                </div>
              )}
            </div>
          </>
        )}
        {current === 2 && (
          <>
            <div className="flex flex-col my-6 ">
              <h6 className=" text-xl font-medium ">Task at Hand</h6>
            </div>
            {Array(stepsCount)
              .fill(stepsCount)
              ?.map((_, index: number) => (
                <div className="flex flex-col gap-1 mb-5">
                  <label
                    htmlFor="name"
                    className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Step{index + 1}
                  </label>
                  <div className="relative">
                    <CustomInput
                      id={`stepsInput${index + 1}`}
                      name={`stepsInput${index + 1}`}
                      value={stepsInput[index + 1]?.[`stepsInput${index + 1}`]}
                      onChange={handleStepsInputChange}
                      maxLength={maxValueCount}
                      placeholder="Enter Step"
                    />
                  </div>
                </div>
              ))}
            <div className="w-full flex justify-center items-center">
              <button
                onClick={() => {
                  setStepsCount((prev) => {
                    if (stepsCount < 4) {
                      return prev + 1;
                    } else {
                      return prev;
                    }
                  });
                }}
                className=" p-3 w-fit  flex h-10 justify-center items-center border rounded-lg border-gray_2  mr-4 cursor-pointer "
              >
                <span className=" w-5  flex h-5 justify-center items-center cursor-pointer mr-2 ">
                  <MissionCreatIcon />
                </span>
                Add Step
              </button>
            </div>
            <div className="flex flex-col my-5">
              <h6 className=" text-xl font-medium">Guidelines</h6>
            </div>
            {Array(guidelinesCount)
              .fill(guidelinesCount)
              ?.map((_, index) => (
                <div className="flex flex-col gap-1 [&:not(:last-child)]:mb-5">
                  <label
                    htmlFor="name"
                    className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Line {index + 1}
                  </label>
                  <div className="relative">
                    <CustomInput
                      id={`guidelinesInput${index + 1}`}
                      name={`guidelinesInput${index + 1}`}
                      value={
                        guidelinesInput[index + 1]?.[
                          `guidelinesInput${index + 1}`
                        ]
                      }
                      onChange={handleGuidelinesInputChange}
                      maxLength={maxValueCount}
                      placeholder="Enter Guideline"
                    />
                  </div>
                </div>
              ))}
            <div className="w-full flex justify-center items-center">
              <button
                onClick={() => {
                  setGuidelinesCount((prev) => {
                    if (guidelinesCount < 4) {
                      return prev + 1;
                    } else {
                      return prev;
                    }
                  });
                }}
                className=" p-3 w-fit  flex h-10 justify-center items-center border rounded-lg border-gray_2  mr-4 cursor-pointer "
              >
                <span className=" w-5  flex h-5 justify-center items-center cursor-pointer mr-2 ">
                  <MissionCreatIcon />
                </span>
                Add More
              </button>
            </div>
            <div className="flex flex-col my-5">
              <h6 className=" text-xl font-medium">Contact</h6>
            </div>
            <div className="flex flex-col gap-1">
              <label
                htmlFor="name"
                className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              >
                Email ID
              </label>
              <div className="relative">
                <CustomInput
                  id="name"
                  value={inputValue}
                  onChange={handleInputChange}
                  maxLength={maxValueCount}
                  placeholder="Enter Email ID"
                />
              </div>
            </div>
            <span className="flex mt-5">
              Note:{" "}
              <p className=" text-gray_6 ml-1">
                {" "}
                This Email ID will be visible to students
              </p>
            </span>
          </>
        )}
        {current === 3 && (
          <>
            <div className="flex flex-col gap-5 mt-5">
              <SubmissionTypes
                selectValue="option1"
                placeholder=""
                value="instagram reel video"
              />
              <SubmissionTypes
                selectValue="option2"
                placeholder=""
                value="instagram reel video"
              />
              <SubmissionTypes
                selectValue="option3"
                placeholder=""
                value="instagram reel video"
              />
              <SubmissionTypes selectValue="option4" placeholder="" value="" />
              <SubmissionTypes
                selectValue="option5"
                placeholder=""
                value="Which food do you like most?"
              />

              <div className="flex justify-center mb-6">
                <button className="p-3 w-fit flex h-10 justify-center items-center border rounded-lg border-gray_2 mr-4 cursor-pointer">
                  <span className="w-5 flex h-5 justify-center items-center cursor-pointer mr-2">
                    <MissionCreatIcon className={` ${isMac && "mb-[4px] "}`} />
                  </span>
                  <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                    Add Field
                  </span>
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
