import { Avatar } from "antd";
import React, { useState } from "react";
import { SpaceMemberData } from "../../../type/types";

interface SpaceMembersProps {
  data: SpaceMemberData[];
  showLists?: number;
}

const SpaceMembers: React.FC<SpaceMembersProps> = ({
  data,
  showLists = 10,
}) => {
  const [sliceData, setSliceData] = useState(showLists);

  const handleViewMoreData = () => {
    setSliceData(data.length);
  };
  const handleViewLessData = () => {
    setSliceData(showLists);
  };

  return (
    <div className="">
      {data?.slice(0, sliceData).map((item) => (
        <div
          key={item.id}
          className="flex items-center justify-between [&:not(:last-child)]:mb-4"
        >
          <div className="flex items-center gap-2">
            <Avatar
              src={item.picture}
              size={56}
              shape="square"
              alt="user"
              className="rounded-md min-w-[56px] max-w-[56px] min-h-[56px] max-h-[56px]"
            />
            <div>
              <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-1">
                {item.name}
              </p>
              <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                {item.userName}
              </p>
            </div>
          </div>
          {item.isAdmin && (
            <div className="bg-[rgba(0,30,255,0.04)] p-2 rounded-lg">
              <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                Admin
              </p>
            </div>
          )}
        </div>
      ))}
      {sliceData === data.length ? (
        <button
          onClick={handleViewLessData}
          className="text-U25_orange text-[15px] not-italic font-normal leading-[120%]"
        >
          View less
        </button>
      ) : (
        <button
          onClick={handleViewMoreData}
          className="text-U25_orange text-[15px] not-italic font-normal leading-[120%]"
        >
          View All({data?.length - showLists} more)
        </button>
      )}
    </div>
  );
};

export default SpaceMembers;
