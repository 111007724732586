import React, { useEffect, useRef, useState } from "react";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { PrimaryTabsProps } from "../../type/types";
import "./PrimaryTabs.scss";

const PrimaryTabs: React.FC<PrimaryTabsProps> = ({
  tabsData,
  onChange = () => {},
  defaultIndex = 1,
  containerClass = "",
  tabsChildrenClass = "",
  innerClass = "",
  setSelectedTab = () => {},
  selectedTab,
  isCount,
}) => {
  const actualIndex = defaultIndex > tabsData.length ? 0 : defaultIndex - 1;
  const [activeTab, setActiveTab] = useState<number>(actualIndex);
  const draggableContentRef = useRef<HTMLDivElement | null>(null);
  const [isDraggable, setIsDraggable] = useState<boolean>(false);
  const [startX, setStartX] = useState(0);

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };

  const handleTabLabelChange = (label: string) => {
    setSelectedTab(label);
    onChange(label);
  };

  useEffect(() => {
    if (selectedTab === "Outerspace") {
      handleTabChange(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const handleMouseDown = (event: any) => {
    if (draggableContentRef.current) {
      setIsDraggable(true);
      setStartX(event.clientX - draggableContentRef.current.scrollLeft);
    }
  };

  const handleMouseUp = () => {
    setIsDraggable(false);
  };

  const handleMouseMove = (e: any) => {
    if (!isDraggable) return;
    if (draggableContentRef.current) {
      const deltaX = e.clientX - startX;
      draggableContentRef.current.scrollLeft =
        draggableContentRef.current.scrollLeft - deltaX;
    }
  };

  return (
    <div className={`mb-5 ${containerClass}`}>
      <div
        ref={draggableContentRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        onMouseMove={handleMouseMove}
        className={`flex items-center gap-3 mt-5 mb-[18px] draggable-scroll-content ${innerClass}`}
      >
        {tabsData.map((tab, index) => (
          <>
            <PrimaryButton
              key={tab.id}
              className={`!h-6 ${
                activeTab !== index
                  ? "!border !border-solid !border-[#F2F2F2] "
                  : "!border !border-solid !border-primary"
              } `}
              fontClass="text-[13px]"
              onClick={() => {
                handleTabChange(index);
                handleTabLabelChange(tab.label);
              }}
              title={
                <>
                  {tab.label}
                  {isCount && tab.count && (
                    <span className="px-1.5 py-0.5 text-[13px] not-italic font-normal leading-[120%]  bg-U25_orange text-[#FC741E] bg-opacity-10 rounded-lg ">
                      {tab.count}
                    </span>
                  )}
                </>
              }
              size="small"
              theme={`${activeTab === index ? "dark" : "light"}`}
              tabs
            />
          </>
        ))}
      </div>
      <div className={`${tabsChildrenClass}`}>
        {tabsData.map((tab, index) => (
          <div
            key={tab.id}
            className={`${activeTab === index ? "block" : "hidden"} `}
          >
            {tab.children}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrimaryTabs;
