import { SearchSelect } from "../../../themes/SearchSelect/SearchSelect";
import CustomInput from "../../CustomInput/CustomInput";

interface SoulBoundTokenProps {
  onSearchAddBtn?: () => void;
}

export const SoulBoundToken: React.FC<SoulBoundTokenProps> = ({
  onSearchAddBtn = () => {},
}) => {
  const CollegeData = [
    {
      value: "Royal College1",
      label: "Royal College1",
    },
    {
      value: "Royal College2",
      label: "Royal College2",
    },
    {
      value: "Royal College3",
      label: "Royal College3",
    },
    {
      value: "Royal College4",
      label: "Royal College4",
    },
    {
      value: "Royal College5",
      label: "Royal College5",
    },
    {
      value: "Royal College6",
      label: "Royal College6",
    },
  ];

  const SchoolData = [
    {
      value: "Royal School",
      label: "Royal School",
    },
    {
      value: "Royal School2",
      label: "Royal School2",
    },
    {
      value: "Royal School3",
      label: "Royal School3",
    },
    {
      value: "Royal School4",
      label: "Royal School4",
    },
    {
      value: "Royal School5",
      label: "Royal School5",
    },
    {
      value: "Royal School6",
      label: "Royal School6",
    },
  ];

  return (
    <div className="flex flex-col gap-5 ">
      <div className="flex flex-col gap-1">
        <label
          htmlFor="cityOfBirth"
          className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
        >
          City of birth
        </label>
        <CustomInput id="cityOfBirth" onChange={(e) => {}} />
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="motherTongue"
          className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
        >
          Mother Tongue
        </label>
        <CustomInput id="motherTongue" onChange={(e) => {}} />
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="school"
          className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
        >
          School
        </label>
        <SearchSelect
          onSearchAddBtn={onSearchAddBtn}
          optionsData={SchoolData}
          placeholder="Find Your School"
        />
      </div>
      <div className="flex flex-col gap-1">
        <label
          htmlFor="college"
          className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
        >
          College
        </label>
        <SearchSelect
          onSearchAddBtn={onSearchAddBtn}
          optionsData={CollegeData}
          placeholder="Find Your College"
        />
      </div>
    </div>
  );
};
