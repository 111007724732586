import { Empty } from "antd"
import './empty_state.scss'

interface EmptyStateProps {
    image: string,
    title: string,
    desc: string
}
const EmptyState: React.FC<EmptyStateProps> = ({ image, title, desc }) => {
    return (
        <Empty
            className="empty-state"
            image={image}
            description={
                <div className="">
                    <h4 className="text-[#1F1F1F] text-[20px] font-medium leading-[1]">{title}</h4>
                    <p className="text-[#666666] text-[17px] font-normal leading-[1.2] mt-2">{desc}</p>
                </div>
            }
        />
    )
}

export default EmptyState