import React from "react";
import { DiscussionBtnTypes } from "../../type/types";
import {
  GlassesRd,
  HashTag,
  HorizontalBarChar,
  Microphone,
  VolumeMin,
} from "../Icons/Icons";
import { Button } from "antd";
import useIsMac from "../../hooks/useIsMac";
interface StartDiscussionTabProps {
  onClick?: () => void;
  activeTab?: number;
  setActiveTab?: (index: number) => void;
}

const StartDiscussionTab: React.FC<StartDiscussionTabProps> = ({
  onClick = () => {},
  activeTab,
  setActiveTab = () => {},
}) => {
  const isMac = useIsMac();
  const discussionBtnData: DiscussionBtnTypes[] = [
    {
      id: "1",
      title: "Threads",
      btnIcon: <HashTag />,
      onClick: () => {
        onClick();
      },
    },
    {
      id: "2",
      title: "Poll",
      btnIcon: <HorizontalBarChar />,
      onClick: () => {
        onClick();
      },
    },
    {
      id: "3",
      title: "Pods",
      btnIcon: <Microphone />,
      onClick: () => {
        onClick();
      },
    },
    {
      id: "4",
      title: "Dictionary",
      btnIcon: <VolumeMin />,
      onClick: () => {
        onClick();
      },
    },
    {
      id: "5",
      title: "Confession",
      btnIcon: <GlassesRd />,
      onClick: () => {
        onClick();
      },
    },
  ];

  return (
    <div className="flex items-center gap-2 overflow-y-auto theme-scrollbar-sm">
      {discussionBtnData?.map((btn, index) => (
        <Button
          key={btn.id}
          onClick={() => {
            setActiveTab(index);
            btn.onClick();
          }}
          style={{
            fontFamily: "OskariG2Regular",
          }}
          className={`flex items-center justify-center gap-2 bg-white h-7 px-4 py-2 rounded-3xl hover:!border-U25_orange hover:!bg-U25_orange [&_span]:hover:text-white [&_path]:hover:stroke-white ${
            activeTab === index
              ? "!bg-U25_orange !border-U25_orange [&_span]:!text-white [&_path]:!stroke-white"
              : ""
          }`}
        >
          {btn.btnIcon}
          <span
            className={`text-gray_1 text-[15px] not-italic font-normal leading-[normal] ${
              isMac && "mt-[3px]"
            }`}
          >
            {btn.title}
          </span>
        </Button>
      ))}
    </div>
  );
};

export default StartDiscussionTab;
