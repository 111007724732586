import React from "react";
import SpacesBox from "../SpacesBox/SpacesBox";
import allTabData from "../../../../data/allTabData";

const AllTab = () => {
  return (
    <div>
      <SpacesBox data={allTabData} />
    </div>
  );
};

export default AllTab;
