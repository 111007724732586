import React, { useEffect, useState } from "react";
import { UserPlusConnectedOutlined, UserPlusOutlined } from "../Icons/Icons";
import { UserContactListProps } from "../../type/types";
import "./UserContactList.scss";
import { Avatar, Checkbox } from "antd";

const UserContactList: React.FC<UserContactListProps> = ({
  data,
  containerClassName,
  noFooter,
  type,
  checkedLists,
  profile,
  checkbox = true,
}) => {
  const [checkedState, setCheckedState] = useState(
    new Array(data.length).fill(false)
  );

  const [voteIconConnectedMap, setVoteIconConnectedMap] = useState<any>({});

  const handleCheckboxChange = (position: number, id: any) => {
    const newCheckedState = checkedState.map((item, index) =>
      position === index ? !item : item
    );
    setCheckedState(newCheckedState);
    setVoteIconConnectedMap({
      ...voteIconConnectedMap,
      [id]: newCheckedState[position],
    });
  };

  useEffect(() => {
    checkedLists && checkedLists(checkedState);
  }, [checkedState]);

  return (
    <div
      className={`relative pr-8 user-list theme-scrollbar-sm ${containerClassName}`}
    >
      {data.map((user, index) => (
        <div
          className="flex justify-between items-center [&:not(:last-child)]:mb-4"
          key={user.id}
        >
          <div className="flex items-center gap-3 cursor-pointer">
            <div className="rounded-md">
              <Avatar
                src={user.profileImage}
                size={40}
                shape="square"
                alt="user"
                className="rounded-md"
              />
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                {user.fullName}
              </p>
              <span className="text-gray_1 text-[17px] not-italic font-normal leading-[120%]">
                {user.userName}
              </span>
            </div>
          </div>
          {!profile && checkbox && (
            <>
              {type === "checkbox" ? (
                <Checkbox
                  className={`custom-checkbox text-gray_1 text-[17px] not-italic font-normal leading-[120%]`}
                  checked={checkedState[index]}
                  onChange={() => handleCheckboxChange(index, user.id)}
                />
              ) : (
                <div
                  onClick={() => {
                    setVoteIconConnectedMap({
                      ...voteIconConnectedMap,
                      [user.id]: !voteIconConnectedMap[user.id],
                    });
                  }}
                  className={`inline-flex border p-2 rounded-lg border-solid cursor-pointer border-gray_2 ${
                    voteIconConnectedMap[user.id]
                      ? "bg-U25_orange hover:bg--U25_orange  "
                      : " hover-bg-gray_20"
                  }
                  `}
                >
                  {voteIconConnectedMap[user.id] ? (
                    <UserPlusConnectedOutlined />
                  ) : (
                    <UserPlusOutlined />
                  )}{" "}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default UserContactList;
