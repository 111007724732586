import { Modal } from "antd";
import React from "react";
import { ICustomModalProps } from "../../../../type/types";
import { CloseIconLight } from "../../../Icons/Icons";
import "./StoryViewerModal.scss";

const StoryViewerModal: React.FC<ICustomModalProps> = ({
  title,
  className,
  closeIcon = <CloseIconLight />,
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  footer = false,
}) => {
  return (
    <>
      <Modal
        className={`story-viewer-modal h-[110vh] ${className}`}
        closeIcon={closeIcon}
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer}
        centered
        width="100%"
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div>{children}</div>
      </Modal>
    </>
  );
};

export default StoryViewerModal;
