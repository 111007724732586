import React, { useState } from "react";

interface PollProps {
  options: string[];
}

const Poll: React.FC<PollProps> = ({ options }) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [votes, setVotes] = useState<number[]>(options.map(() => 0));

  const handleOptionClick = (index: number) => {
    if (selectedOption === null) {
      setSelectedOption(index);
      const newVotes = [...votes];
      newVotes[index]++;
      setVotes(newVotes);
    }
  };

  const getTotalVotes = () => {
    return votes.reduce((total, vote) => total + vote);
  };

  return (
    <div className="pt-3 w-full">
      <ul>
        {options.map((option, index) => (
          <li
            key={index}
            className={`${
              selectedOption !== null ? "cursor-not-allowed" : "cursor-pointer"
            } bg-poll_bg w-full h-[46px] flex items-center  rounded-lg  p-[6px]  mb-2`}
            onClick={() => handleOptionClick(index)}
          >
            <div className="flex justify-between w-full items-center">
              <div className="flex w-full justify-start items-center">
                <div
                  className={`text-primary font-semibold  p-3  py-1.5 rounded-[4px] mr-[-3px]  z-10 ${
                    index === selectedOption && "bg-primary text-white  "
                  }`}
                >
                  {index === 0 ? (
                    <span>A</span>
                  ) : index === 1 ? (
                    <span>B</span>
                  ) : index === 2 ? (
                    <span>C</span>
                  ) : (
                    <span>D</span>
                  )}
                </div>

                <div
                  className={`${
                    selectedOption === null
                      ? "bg-gray-100"
                      : index === 0
                      ? "bg-[#D8DCFF] text-[#0D24CF]"
                      : index === 1
                      ? "bg-[#FFDFCB] text-[#AD4503]"
                      : index === 2
                      ? "bg-[#BDECED] text-[#357A7F]"
                      : index === 3
                      ? "bg-[#F6D5FE] text-[#984372]"
                      : "bg-[#FFDFCB] text-[#AD4503]"
                  } rounded  w-full  py-[6px] px-3 `}
                  style={{
                    width: `${Math.round(
                      (votes[index] / getTotalVotes()) * 100
                    )}%`,
                  }}
                >
                  <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                    {option}
                  </span>
                </div>
              </div>
              {selectedOption !== null && (
                <div className="flex justify-between items-center ">
                  <span className="text-[15px] not-italic font-normal leading-[120%] ml-1 text-gray_6 ">
                    {Math.round((votes[index] / getTotalVotes()) * 100)}%
                  </span>
                </div>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Poll;
