import { SpaceMemberData } from "../type/types";
import member1 from "../assets/png/requests-2.png";
import member2 from "../assets/png/member-2.png";
import member3 from "../assets/png/member-3.png";
import member4 from "../assets/png/member-4.png";
import member5 from "../assets/png/member-5.png";
import member6 from "../assets/png/member-6.png";
import member7 from "../assets/png/member-7.png";
import member8 from "../assets/png/member-8.png";
import member9 from "../assets/png/member-9.png";
import member10 from "../assets/png/member-10.png";

export const spaceMembersData: SpaceMemberData[] = [
  {
    id: 1,
    name: "Kanishka Reddy",
    userName: "e/kani",
    picture: member1,
    isAdmin: true,
  },
  {
    id: 2,
    name: "John Doe",
    userName: "e/johndoe",
    picture: member2,
    isAdmin: false,
  },
  {
    id: 3,
    name: "Jane Smith",
    userName: "e/janesmith",
    picture: member3,
    isAdmin: false,
  },
  {
    id: 4,
    name: "Michael Johnson",
    userName: "e/michaelj",
    picture: member4,
    isAdmin: false,
  },
  {
    id: 5,
    name: "Emily Davis",
    userName: "e/emilyd",
    picture: member5,
    isAdmin: false,
  },
  {
    id: 6,
    name: "William Brown",
    userName: "e/williamb",
    picture: member6,
    isAdmin: false,
  },
  {
    id: 7,
    name: "Olivia Wilson",
    userName: "e/oliviaw",
    picture: member7,
    isAdmin: false,
  },
  {
    id: 8,
    name: "Daniel Lee",
    userName: "e/daniell",
    picture: member8,
    isAdmin: false,
  },
  {
    id: 9,
    name: "Sophia Johnson",
    userName: "e/sophiaj",
    picture: member9,
    isAdmin: false,
  },
  {
    id: 10,
    name: "Liam Martin",
    userName: "e/liamm",
    picture: member10,
    isAdmin: false,
  },
  {
    id: 11,
    name: "Ava Anderson",
    userName: "e/avaa",
    picture: member2,
    isAdmin: false,
  },
  {
    id: 12,
    name: "Noah Clark",
    userName: "e/noahc",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 13,
    name: "Isabella Rodriguez",
    userName: "e/isabellar",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 14,
    name: "Mason Garcia",
    userName: "e/masong",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 15,
    name: "Sophia Lewis",
    userName: "e/sophial",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 16,
    name: "Ethan Hernandez",
    userName: "e/ethanh",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 17,
    name: "Olivia Walker",
    userName: "e/oliviawalker",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 18,
    name: "Liam Green",
    userName: "e/liamgreen",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 19,
    name: "Charlotte Perez",
    userName: "e/charlottep",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 20,
    name: "Lucas King",
    userName: "e/lucask",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 21,
    name: "Amelia Hall",
    userName: "e/ameliah",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 22,
    name: "Mason Turner",
    userName: "e/masont",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 23,
    name: "Harper White",
    userName: "e/harperw",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 24,
    name: "Evelyn Cook",
    userName: "e/evelync",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 25,
    name: "Elijah Hill",
    userName: "e/elijahh",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 26,
    name: "Ella Scott",
    userName: "e/ellascott",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 27,
    name: "Aiden Young",
    userName: "e/aideny",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 28,
    name: "Aria Mitchell",
    userName: "e/ariam",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 29,
    name: "Carter Baker",
    userName: "e/carterb",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 30,
    name: "Grayson Adams",
    userName: "e/graysona",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 31,
    name: "Sofia Evans",
    userName: "e/sofiae",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 32,
    name: "Lucas Mitchell",
    userName: "e/lucasm",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 33,
    name: "Zoe Campbell",
    userName: "e/zoec",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 34,
    name: "Liam Parker",
    userName: "e/liamp",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 35,
    name: "Emma James",
    userName: "e/emmaj",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 36,
    name: "Mia Wright",
    userName: "e/miaw",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 37,
    name: "Benjamin Turner",
    userName: "e/benjamint",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 38,
    name: "Evelyn Young",
    userName: "e/evelyny",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 39,
    name: "Daniel White",
    userName: "e/danielw",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 40,
    name: "Harper Green",
    userName: "e/harperg",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 41,
    name: "Emily Collins",
    userName: "e/emilyc",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 42,
    name: "Aiden Adams",
    userName: "e/aidena",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 43,
    name: "Aria Martinez",
    userName: "e/ariamartinez",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 44,
    name: "Carter Hall",
    userName: "e/carterhall",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 45,
    name: "Grayson Scott",
    userName: "e/graysonscott",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 46,
    name: "Sofia Turner",
    userName: "e/sofiaturner",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 47,
    name: "Lucas Walker",
    userName: "e/lucaswalker",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 48,
    name: "Zoe Perez",
    userName: "e/zoeperez",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 49,
    name: "Liam Turner",
    userName: "e/liamturner",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 50,
    name: "Emma White",
    userName: "e/emmawhite",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 51,
    name: "Mia King",
    userName: "e/miaking",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 52,
    name: "Benjamin Harris",
    userName: "e/benjaminharris",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 53,
    name: "Evelyn Turner",
    userName: "e/evelynturner",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 54,
    name: "Daniel Scott",
    userName: "e/danielscott",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 55,
    name: "Harper Martinez",
    userName: "e/harpermartinez",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 56,
    name: "Emily Adams",
    userName: "e/emilyadams",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 57,
    name: "Aiden Hall",
    userName: "e/aidenhall",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 58,
    name: "Aria Scott",
    userName: "e/ariascott",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 59,
    name: "Carter Turner",
    userName: "e/carterturner",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
  {
    id: 60,
    name: "Grayson Walker",
    userName: "e/graysonwalker",
    picture:
      "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    isAdmin: false,
  },
];
