import { Avatar } from "antd";
import React from "react";
import U25VioletBg from "../../../assets/svg/snapchat.svg";
import {
  ChatTextSquare,
  GiveAwayIcon,
  MissionsClock,
} from "../../../components/Icons/Icons";
import { SpaceInfoCardProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import useIsMac from "../../../hooks/useIsMac";

const MissionInfoCard: React.FC<SpaceInfoCardProps> = ({
  title,
  containerClass = "",
  description,
}) => {
  const isMac = useIsMac();
  return (
    <>
      <div className={`bg-white rounded-xl py-5 px-4 h-fit ${containerClass}`}>
        <div className="flex">
          <Avatar
            src={U25VioletBg}
            size={100}
            shape="square"
            alt="user"
            className="rounded-md mr-4 max-h-[100px] min-h-[100px] max-w-[100px] min-w-[100px]"
          />
          <div className="mr-auto">
            <div className="flex gap-1 mb-2 mr-1">
              <h1 className="text-primary text-2xl not-italic font-medium leading-[100%]">
                {title}
              </h1>
            </div>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-2">
              {description}
            </p>
            <div className="flex items-center  text-[15px]">
              <span className="flex items-center">
                <span className="">
                  <GiveAwayIcon className={` ${isMac && "mb-1"} `} />
                </span>
                <p className="ml-1 text-link_hover ">Giveaway</p>
              </span>
              <span className=" flex items-center text-link_hover text-[15px]">
                <span className="px-2">
                  <svg
                    width="2"
                    height="16"
                    viewBox="0 0 2 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M1 0V16" stroke="#DBD9D9" />
                  </svg>
                </span>
                <span>
                  <MissionsClock className={` ${isMac && "mb-1"} `} />
                </span>
                <span className="ml-1">12 PM, Dec 31st</span>
              </span>
            </div>
          </div>
          <div className="flex items-end justify-end gap-2">
            <PrimaryButton
              title={
                <div className="flex items-center gap-1">
                  <p>I’m Interested</p>
                </div>
              }
              theme="orange"
              size="small"
              className="!rounded-lg"
              fontClass="text-gray_6 text-[13px]"
              onClick={() => {}}
            />

            <PrimaryButton
              title={
                <div
                  className={`flex items-center gap-1 ${isMac ? "-mt-1" : ""}`}
                >
                  <ChatTextSquare />
                  <p
                    className={`text-primary text-[13px] ${
                      isMac ? "mt-1" : ""
                    }`}
                  >
                    Chat
                  </p>
                </div>
              }
              size="small"
              className="!rounded-lg"
              theme="light"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionInfoCard;
