import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import LeftSection from "../components/Home/Community/LeftSection/LeftSection";
import RightSection from "../components/Home/Community/RightSection/RightSection";
import Main from "../components/Home/Community/Main/Main";
import TutorialModal from "../components/Tutorial/TutorialModal";
import CustomModal from "../components/CustomModal/CustomModal";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { useLocation } from "react-router-dom";
import { createBrowserHistory } from "history";
import MediaPreviewModal from "../components/Chat/MediaPreviewModal/MediaPreviewModal";
import SampleVideo2 from "../assets/video/sample-beach.mp4";
import useStickyScroll from "../hooks/useStickyScroll";

const Home: React.FC = () => {
  const [isTutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");

  const modalFooterConfirm = (
    <div className="flex justify-end items-center gap-4 rounded-[0_0_12px_12px] bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <PrimaryButton
        onClick={() => {
          setTutorialModalOpen(false);
          setOpenConfirmModal(false);
        }}
        title="Continue to community"
        width="fit-content"
      />
    </div>
  );

  const location = useLocation();
  const history = createBrowserHistory();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const userParam = params.get("user");

    if (userParam === "new") {
      setTutorialModalOpen(true);
      params.delete("user");
      history.replace({ search: params.toString() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useStickyScroll(".right-section");
  useStickyScroll(".left-section");

  const [openMediaPreviewModal, setOpenMediaPreviewModal] = useState(false);

  return (
    <Layout>
      <div className="grid homepage gap-[30px] lg:gap-[20px] py-6 px-8 lg:px-6 xl:grid-cols-[280px_3fr_280px] lg:grid-cols-[210px_3fr_250px] sm:grid-cols-[1fr] ">
        <div className="lg:top-0 xl:sticky lg:block sm:hidden  xl:block h-fit overscroll-y-auto no-scrollbar mb-6 left-section">
          <LeftSection />
        </div>
        <div className="no-scrollbar overflow-y-hidden main-section ">
          <Main selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        </div>
        <div className="xl:top-0 xl:sticky lg:block h-fit  sm:hidden xl:block overflow-y-auto right-section ">
          <RightSection
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
      </div>
      <div className="Tutorial">
        <TutorialModal
          isModalOpen={isTutorialModalOpen}
          setOpenConfirmModal={setOpenConfirmModal}
          handleOk={() => {
            setTutorialModalOpen(false);
            setOpenMediaPreviewModal(true);
          }}
          handleCancel={() => {
            setTutorialModalOpen(false);
            setOpenConfirmModal(false);
          }}
        />
      </div>

      <CustomModal
        isModalOpen={openConfirmModal}
        handleOk={() => {
          setOpenConfirmModal(false);
        }}
        handleCancel={() => {
          setOpenConfirmModal(false);
        }}
        footer={modalFooterConfirm}
        title={
          <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
            Tutorial
          </h3>
        }
        width={615}
      >
        <div className="flex flex-col gap-4 mt-4 mb-3 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full justify-center items-center ">
          <span className=" p-[4.8px] w-12 h-12 bg-U25_orange rounded-[240px] flex justify-center items-center">
            <svg
              width="28"
              height="21"
              viewBox="0 0 28 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_448_20998)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                  stroke="white"
                  stroke-width="1.8"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_448_20998"
                  x="0.347656"
                  y="0.370972"
                  width="27.5047"
                  height="20.4579"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1.2" dy="1.2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_448_20998"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_448_20998"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <h4 className="text-2xl not-italic font-medium leading-[100%]">
            You’re all set!
          </h4>
          <p className="text-[17px] w-2/3 text-center not-italic text-gray_6 font-normal leading-[120%] capitaliz">
            Congratulations for completing the Tutorial, You’re all set to dive
            in.
          </p>
        </div>
      </CustomModal>

      <MediaPreviewModal
        isModalOpen={openMediaPreviewModal}
        handleOk={() => setOpenMediaPreviewModal(false)}
        handleCancel={() => {
          setOpenMediaPreviewModal(false);
          setOpenConfirmModal(true);
        }}
        isTutorial={true}
      >
        <div className="flex justify-center ">
          <video
            controls
            src={SampleVideo2}
            className="object-contain h-[calc(80vh_-_229px)]"
            onEnded={() => {
              setOpenMediaPreviewModal(false);
              setOpenConfirmModal(true);
            }}
            autoPlay
          />
        </div>
      </MediaPreviewModal>
    </Layout>
  );
};

export default Home;
