import img1 from "../assets/png/space-1.png";
import img2 from "../assets/png/space-8.png";
import img3 from "../assets/png/space-5.png";
import img4 from "../assets/png/space-7.png";
import { SpaceDataType } from "../type/types";

const data: SpaceDataType[] = [
    {
        id: "1",
        title: "Under 25 Hardcore",
        image: img1,
        locked: false,
        cards: 12,
        events: 1,
        explorers: "13.5k"
    },
    {
        id: "2",
        title: "Guru Nanak Institutions",
        image: img2,
        locked: true,
        cards: 12,
        events: 2,
        explorers: "13.5k"
    },
    {
        id: "3",
        title: "U25 Summit",
        image: img2,
        locked: true,
        cards: 12,
        events: 0,
        explorers: "13.5k"
    },
    {
        id: "4",
        title: "Developer’s Background",
        image: img2,
        locked: true,
        cards: 12,
        events: 1,
        explorers: "13.5k"
    },
    {
        id: "5",
        title: "Under 25 Self care",
        image: img3,
        locked: false,
        cards: 12,
        events: 2,
        explorers: "13.5k"
    },
    {
        id: "6",
        title: "Under 25 College Stories",
        image: img4,
        locked: false,
        cards: 12,
        events: 2,
        explorers: "13.5k"
    },
]

export default data;