import React from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";
import { Input } from "antd";
import ProgressBar from "../../../themes/ProgressBar/ProgressBar";
import SliderLine from "../../../themes/SliderLine/SliderLine";
import { useState } from "react";

interface AddSkillsModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onBtnClick: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}
const AddSkillsModal: React.FC<AddSkillsModalProps> = ({
  popover,
  setPopover,
  title,
  onBtnClick,
}) => {
  const handleClosePopover = () => {
    setPopover(false);
  };
  const [count, setCount] = useState(20);
  const handleOpenPopover = () => {
    setPopover(true);
  };
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          onBtnClick(true);
        }}
        title="Add"
      />
    </div>
  );

  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="mt-4 mx-5 flex flex-col gap-5">
        <div className="">
          <label
            className="text-[#808080] text-[17px] not-italic font-normal leading-[120%] mb-1"
            htmlFor="my-modal"
          >
            Skill Name
          </label>
          <Input
            placeholder="Type skill Name"
            className="add-skill-input font-sans"
          />
        </div>
        <div className="">
          <div className="flex justify-between items-center">
            <label
              className="text-[#808080] text-[17px] not-italic font-normal leading-[120%] mb-1"
              htmlFor="my-modal"
            >
              Brand/organization
            </label>
            <span className="text-[#FC741E] text-[17px] font-normal leading-[120%]">
              {`${count}%`}
            </span>
          </div>
          <SliderLine
            onChange={setCount}
            className="skill-slider"
            defaultValue={20}
          />
        </div>
      </div>
    </CustomModal>
  );
};

export default AddSkillsModal;
