import React, { useState } from "react";
import PeopleYouMayKnow from "../PeopleYouMayKnow/PeopleYouMayKnow";
import ThemeCardLayout from "../ThemeCardLayout/ThemeCardLayout";
import { CardDeckProps } from "../../type/types";
import CardImg1 from "../../assets/png/card-img1.png";
import CardImg2 from "../../assets/png/card-img2.png";
import CardImg3 from "../../assets/png/card-img3.png";
import CardImg4 from "../../assets/png/card-img4.png";
import CardImg5 from "../../assets/png/card-img5.png";
import CardImg6 from "../../assets/png/card-img6.png";
import { BookmarkOutlined } from "../Icons/Icons";
import { Link } from "react-router-dom";

function UserRightSection() {
  const [cardsDeckData, setCardsDeckData] = useState<CardDeckProps[]>([
    {
      id: 1,
      title: "My Saved",
      image: CardImg2,
    },
    {
      id: 2,
      title: "Fashion",
      image: CardImg1,
    },
    {
      id: 3,
      title: "Music",
      image: CardImg3,
    },
    {
      id: 4,
      title: "Art",
      image: CardImg4,
    },
    {
      id: 5,
      title: "Cooking",
      image: CardImg5,
    },
    {
      id: 6,
      title: "Technology",
      image: CardImg6,
    },
  ]);

  const [activeCard, setActiveCard] = useState(
    Array(cardsDeckData.length).fill(false)
  );

  const handleActiveCard = (index: number) => {
    const updatedActiveCard = activeCard.map((item, i) =>
      index === i ? !item : item
    );
    setActiveCard(updatedActiveCard);
  };

  return (
    <section className="flex flex-col gap-5">
      <ThemeCardLayout
        containerClass="!bg-white"
        title="My Decks"
        titleClass="mb-4"
        btnTitle="View All"
      >
        <div className="grid grid-cols-[repeat(2,1fr)] gap-2">
          {cardsDeckData.map((item, index) => (
            <Link to={"/user-account/decks/details"}>
              <div
                key={item.id}
                onClick={() => handleActiveCard(index)}
                className={`bg-white rounded-2xl border-[1.3px] p-[6px]  hover:border-[1.3px] hover:border-solid hover:border-gray_2 transition-all duration-200 ease-[ease] cursor-pointer ${
                  activeCard[index]
                    ? "border-[1.3px] border-solid  hover:!border-U25_orange"
                    : ""
                }`}
              >
                {item.image ? (
                  <div className="flex items-center justify-center rounded-[7.8px] border-[1.3px] border-solid border-white">
                    <img className="object-cover" src={item.image} alt="Card" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-[126px] h-[104px] bg-gray_2 rounded-[7.8px] border-[1.3px] border-solid border-white">
                    <BookmarkOutlined />
                  </div>
                )}
                <div className="p-[6px]">
                  <p className="text-primary text-lg not-italic font-normal leading-[normal] text-center">
                    {item.title}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </ThemeCardLayout>
      <PeopleYouMayKnow />
    </section>
  );
}

export default UserRightSection;
