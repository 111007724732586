import React, { useState } from "react";
import {
  ArrowNarrowRight,
  EyeWhiteOutlined,
  XCloseSm,
} from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import Media1 from "../../../assets/png/space-media-1.png";
import Media2 from "../../../assets/png/space-media-2.png";
import Media3 from "../../../assets/png/space-media-3.png";
import Media4 from "../../../assets/png/space-media-4.png";
import SpaceMembers from "../SpaceMembers/SpaceMembers";
import { spaceMembersData } from "../../../data/spaceMembersData";
import SpaceAttachments from "../SpaceAttachments/SpaceAttachments";
import { Avatar } from "antd";
import LeaveGroupModal from "../LeaveGroupModal/LeaveGroupModal";
import { useGlobal } from "../../../context/GlobalContext";
import { useNavigate } from "react-router";
import useIsMac from "../../../hooks/useIsMac";

interface SpaceInfoProps {
  showSpaceInfo: boolean;
  setShowSpaceInfo: React.Dispatch<React.SetStateAction<boolean>>;
  spaceInfo?:
    | boolean
    | {
        picture: string;
        title: string;
        members: number;
        membersOnline: number;
      };
  groupInfo?:
    | boolean
    | {
        picture: string;
        title: string;
        members: number;
        membersOnline: number;
      };
  connectionInfo?:
    | boolean
    | { picture: string; fullName: string; isOnline: boolean };
}

const SpaceInfo: React.FC<SpaceInfoProps> = ({
  setShowSpaceInfo,
  spaceInfo,
  groupInfo,
  connectionInfo,
}) => {
  const [mediaView, setMediaView] = useState(false);
  const [openLeaveGroupModal, setOpenLeaveGroupModal] = useState(false);
  const { refreshCount, setRefreshCount } = useGlobal();

  const navigate = useNavigate();
  const isMac = useIsMac();

  return (
    <>
      {!mediaView ? (
        <div className="bg-white h-[calc(100vh_-_104px)] theme-scrollbar overflow-y-auto">
          <div className="flex items-center gap-3 pl-4 pr-5 py-5 border-b border-gray_2">
            <button
              className="inline-flex items-center justify-center max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] rounded-md hover:bg-gray_20 transition-all duration-200 ease-[ease] cursor-pointer"
              onClick={() => {
                setShowSpaceInfo(false);
              }}
            >
              <XCloseSm />
            </button>
            <h3 className="text-primary text-[15px] not-italic font-medium leading-[120%]">
              {typeof spaceInfo === "object"
                ? "Space"
                : typeof connectionInfo === "object"
                ? "Profile"
                : typeof groupInfo === "object"
                ? "Group"
                : ""}
              info
            </h3>
          </div>
          <div className="pt-6">
            <div className="max-w-[192px] mx-auto flex flex-col gap-4 items-center mb-6">
              <Avatar
                src={
                  typeof spaceInfo === "object"
                    ? spaceInfo?.picture
                    : typeof groupInfo === "object"
                    ? groupInfo?.picture
                    : typeof connectionInfo === "object"
                    ? connectionInfo?.picture
                    : ""
                }
                size={80}
                shape="square"
                alt="user"
                className="rounded-md min-w-[80px] max-w-[80px] min-h-[80px] max-h-[80px]"
              />
              <div>
                <h4 className="text-primary text-xl not-italic font-medium leading-[100%] text-center mb-1">
                  {typeof spaceInfo === "object"
                    ? spaceInfo?.title
                    : typeof groupInfo === "object"
                    ? groupInfo?.title
                    : typeof connectionInfo === "object"
                    ? connectionInfo?.fullName
                    : ""}
                </h4>
                {typeof connectionInfo !== "object" ? (
                  <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%] text-center">
                    <span className="text-U25_orange text-[15px] not-italic font-medium leading-[120%] mr-1">
                      {typeof spaceInfo === "object"
                        ? spaceInfo?.members
                        : typeof groupInfo === "object"
                        ? groupInfo?.members
                        : ""}
                    </span>
                    members
                    <span className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                      ,
                    </span>
                    <span className="text-blue_1 text-[15px] not-italic font-medium leading-[120%] mx-1">
                      {typeof spaceInfo === "object"
                        ? spaceInfo?.membersOnline
                        : typeof groupInfo === "object"
                        ? groupInfo?.membersOnline
                        : ""}
                    </span>
                    online
                  </p>
                ) : (
                  <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%] text-center">
                    {connectionInfo?.isOnline ? "Online" : ""}
                  </p>
                )}
              </div>
              <PrimaryButton
                onClick={() => {
                  if (typeof spaceInfo === "object") {
                    navigate("/spaces");
                  } else if (typeof connectionInfo === "object") {
                    navigate("/profile");
                  }
                }}
                size="regular"
                btnClass="!px-2 !py-3 !w-[160px]"
                width="full"
                title={
                  <div className="flex items-center gap-2">
                    <EyeWhiteOutlined className={`${isMac && "mb-1"}`} />
                    <span>
                      View
                      {typeof spaceInfo === "object"
                        ? "Space"
                        : typeof connectionInfo === "object"
                        ? "Profile"
                        : typeof groupInfo === "object"
                        ? "Group"
                        : ""}
                    </span>
                  </div>
                }
              />
            </div>
          </div>
          <div className="px-8 py-5 border-y-gray_2 border-t border-solid border-b">
            <div className="flex items-center justify-between mb-4">
              <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                Attachments
                <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] ml-1">
                  (50 attachments)
                </span>
              </p>
              <button
                className="inline-flex items-center justify-center max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] rounded-md hover:bg-gray_20 transition-all duration-200 ease-[ease] cursor-pointer"
                onClick={() => {
                  setMediaView(true);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <ArrowNarrowRight fill="black" />
              </button>
            </div>
            <div className="flex items-center gap-2 flex-wrap">
              <div className="rounded">
                <img
                  src={Media1}
                  alt="media files"
                  className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
                />
              </div>
              <div className="rounded">
                <img
                  src={Media2}
                  alt="media files"
                  className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
                />
              </div>
              <div className="rounded">
                <img
                  src={Media3}
                  alt="media files"
                  className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
                />
              </div>
              <div className="rounded">
                <img
                  src={Media4}
                  alt="media files"
                  className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
                />
              </div>
            </div>
          </div>
          <div className="px-8 py-5">
            <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-4">
              {refreshCount} Members
            </p>
            <SpaceMembers data={spaceMembersData} showLists={10} />
          </div>
          {typeof spaceInfo === "object" && (
            <button
              onClick={() => {
                setRefreshCount(refreshCount + 5);
              }}
              className="px-8 py-5 mb-8 border-y-gray_2 border-y border-solid w-full"
            >
              <p className="text-red_10 text-[17px] not-italic font-normal leading-[120%] text-left">
                Refresh
              </p>
            </button>
          )}
          {typeof groupInfo === "object" && (
            <button
              onClick={() => setOpenLeaveGroupModal(true)}
              className="px-8 py-5 mb-8 border-y-gray_2 border-y border-solid w-full"
            >
              <p className="text-red_10 text-[17px] not-italic font-normal leading-[120%] text-left">
                Leave Group
              </p>
            </button>
          )}
        </div>
      ) : (
        <SpaceAttachments setMediaView={setMediaView} />
      )}

      <LeaveGroupModal
        modal={openLeaveGroupModal}
        setModal={setOpenLeaveGroupModal}
      />
    </>
  );
};

export default SpaceInfo;
