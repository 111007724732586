import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./QuillEditor.scss";

const QuillEditor: React.FC = () => {
  const [isTextSelected, setIsTextSelected] = useState(false);
  const quillRef = useRef<ReactQuill | null>(null);

  useEffect(() => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      quill.on("selection-change", (range: any) => {
        setIsTextSelected(range && range.length > 0);
        const quillContainer = document.querySelector(".quill");
        if (quillContainer) {
          if (range && range.length > 0) {
            quillContainer.classList.add("text-selected");
          } else {
            quillContainer.classList.remove("text-selected");
          }
        }
      });
    }
  }, []);

  const formats = ["bold", "italic", "strike", "list", "bullet"];

  const modules = {
    toolbar: {
      container: [
        ["bold", "italic", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
    },
  };

  return (
    <div className="quill">
      <ReactQuill
        placeholder="Write more about it"
        ref={quillRef}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default QuillEditor;
