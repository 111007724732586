import { UserType } from "../type/types"
import UserInContacts from "../assets/png/user-1.png";

const userListData: UserType[] = [
    {
        id: "1",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "2",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "3",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "4",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "5",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "6",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "7",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "8",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "9",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "10",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "11",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
    {
        id: "12",
        profileImage: UserInContacts,
        fullName: "Kanishka Reddy",
        userName: "e/kani"
    },
]

export default userListData