import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  Archived,
  ChatListHeaderIcon,
  U25black,
} from "../components/Icons/Icons";
import SearchBar from "../components/SearchBar/SearchBar";
import { tabsDataProps } from "../type/types";
import PrimaryTabs from "../components/PrimaryTabs/PrimaryTabs";
import ConnectionUserImage from "../assets/png/explorer-4.png";
import ConnectionUserImage2 from "../assets/png/explorer-3.png";
import ChatLogoutModal from "../components/Chat/ChatLogoutModal/ChatLogoutModal";
import MuteNotificationModal from "../components/Chat/MuteNotificationModal/MuteNotificationModal";
import AddGroupMembersModal from "../components/Chat/AddGroupMembers/AddGroupMembersModal";
import AddGroupDetailsModal from "../components/Chat/AddGroupMembers/AddGroupDetailsModal";
import ChatListItem from "../components/Chat/ChatList/ChatListItem";
import ChatListConnectionData from "../components/Chat/ChatList/ChatListConnectionData";
import GroupImage from "../assets/png/GroupImage.png";
import ChatListGroup from "../components/Chat/ChatList/ChatListGroup";
import Receiver from "../components/Chat/Conversations/Messages/Receiver";
import Sender from "../components/Chat/Conversations/Messages/Sender";
import Messenger1 from "../assets/png/chat-per-1.png";
import Messenger2 from "../assets/png/chat-per-2.png";
import ShareProfile from "../assets/png/requests-2.png";
import { Badge } from "antd";
import ChatArchived from "../components/Feeds/Modal/ChatArchived";
import ChatBox from "../components/Chat/ChatBox/ChatBox";
import U25LogoViolet from "../assets/png/space-8.png";
import SenderPicture from "../assets/png/sender-picture.png";
import SampleVideo2 from "../assets/video/sample-beach.mp4";
import SnapLogo from "../assets/png/snap.png";
import Roposo from "../assets/png/roposo.png";
import Ella from "../assets/png/ella.png";

const Chat: React.FC = () => {
  const chatListSpaceData = [
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: true,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: <U25black />,
    },
  ];

  const chatListGroupData = [
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: true,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
    {
      name: "Roposo Opinion Leader Programme",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: GroupImage,
    },
  ];

  const chatListConnectionData = [
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: true,
      img: ConnectionUserImage2,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: true,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
    {
      name: "Guru Nanak Institutions",
      user: "e/kani",
      action: "shared a profile",
      timeAgo: "2m ago",
      isPinned: false,
      isActive: false,
      img: ConnectionUserImage,
    },
  ];

  const [isDeletePopOpen, setIsDeleteOpen] = useState(false);
  const [isMutePopOpen, setIsMuteOpen] = useState(false);
  const [isMute, setIsMute] = useState(false);

  const [isAddGroupPopOpen, setIsAddGroupOpen] = useState(false);
  const [isGroupDetailModalOpen, setIsGroupDetailModalOpen] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [showSpaceInfo, setShowSpaceInfo] = useState(false);
  const [openArchiveModal, setOpenArchiveModal] = useState(false);

  const handleOpenArchiveModal = () => {
    setOpenArchiveModal(true);
  };

  const handleCancelArchiveModal = () => {
    setOpenArchiveModal(false);
  };

  const menuContent = (
    <ul>
      <li className=" py-2 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span
          onClick={() => {
            setIsPopOverOpen(!isPopOverOpen);
          }}
          className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
        >
          Archive
        </span>
      </li>
      <li className=" py-2 p-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span
          onClick={() => {
            setIsPopOverOpen(!isPopOverOpen);
          }}
          className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
        >
          Mark as read
        </span>
      </li>
      <li className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span
          onClick={() => {
            setIsPopOverOpen(!isPopOverOpen);
          }}
          className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
        >
          Pin
        </span>
      </li>
      <li className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span
          onClick={() => {
            setIsMuteOpen(true);
            setIsPopOverOpen(!isPopOverOpen);
          }}
          className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
        >
          Mute notifications
        </span>
      </li>
      <li
        onClick={() => {
          setIsDeleteOpen(true);
          setIsPopOverOpen(!isPopOverOpen);
        }}
        className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          Delete
        </span>
      </li>
    </ul>
  );

  const homeTabs: tabsDataProps[] = [
    {
      id: "1",
      label: "Spaces",
      children: (
        <>
          {chatListSpaceData.map((item, index) => (
            <ChatListItem
              key={index}
              item={item}
              // menuContent={menuContent}
              setIsDeleteOpen={setIsDeleteOpen}
              setIsMuteOpen={setIsMuteOpen}
            />
          ))}
        </>
      ),
    },
    {
      id: "2",
      label: "Connections",
      count: 2,
      children: (
        <>
          {chatListConnectionData.map((item, index) => (
            <ChatListConnectionData
              key={index}
              item={item}
              menuContent={menuContent}
              isPopOverOpen={isPopOverOpen}
              setIsPopOverOpen={setIsPopOverOpen}
            />
          ))}
        </>
      ),
    },
    {
      id: "3",
      label: "Groups",
      children: (
        <div className="space-y-2">
          {chatListGroupData.map((item, index) => (
            <ChatListGroup
              key={index}
              item={item}
              menuContent={menuContent}
              isPopOverOpen={isPopOverOpen}
              setIsPopOverOpen={setIsPopOverOpen}
            />
          ))}
        </div>
      ),
    },
    // {
    //   id: "4",
    //   label: "Mission",
    //   children: <></>,
    // },
  ];

  type Item = {
    id: number;
    isMemberAdded: boolean;
    name: string;
    username: string;
    imageSrc: any;
  };

  const [selectedMembersData, setSelectedMembersData] = useState<Item[]>([]);
  const [tabsChange, setTabsChange] = useState("spaces");

  return (
    <Layout className="h-[calc(100vh)] lg:overflow-hidden md:overflow-auto">
      <div className="grid bg-gray_4  lg:grid-cols-[1fr_3fr] md:flex  pt-1">
        <div
          className="  
          bg-white border-r xl:w-1/4 lg:w-2/4 border-gray_2 md:w-2/5"
        >
          <div className="flex justify-between px-8  pt-5 pb-4">
            <h3 className="text-2xl not-italic font-medium leading-[100%]">
              Chats
            </h3>
            <div className="flex items-center gap-4">
              <div onClick={handleOpenArchiveModal}>
                <Badge
                  count={4}
                  className="cursor-pointer hover:bg-gray_3 max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px] flex justify-center items-center p-2 border rounded-lg border-gray_2 [&_sup]:!max-w-[16px] [&_sup]:!min-w-[16px] [&_sup]:!max-h-[16px] [&_sup]:!min-h-[16px] [&_sup]:!bg-U25_orange [&_sup]:!flex [&_sup]:!justify-center [&_sup]:!items-center [&_sup]:!text-xs [&_sup]:!text-white [&_sup]:!align-middle [&_sup]:!p-[6px] [&_sup]:!pt-1 [&_sup_bdi_span_span]:!align-sub"
                >
                  <span className="hover:bg-gray_3">
                    <Archived />
                  </span>
                </Badge>
              </div>
              <span
                onClick={() => setIsAddGroupOpen(true)}
                className=" cursor-pointer max-w-[40px] hover:bg-gray_3 min-w-[40px] max-h-[40px] min-h-[40px] flex justify-center items-center p-2 border rounded-lg border-gray_2  "
              >
                <ChatListHeaderIcon />
              </span>
            </div>
          </div>
          <div className=" h-full ">
            <div className="flex w-full lg:px-8  md:px-2">
              {/* {(tabsChange === "spaces" || tabsChange === "Spaces") && (
                <span className=" w-10  flex h-10 justify-center items-center p-2 border rounded-lg border-gray_2 hover:bg-gray_3  mr-4 cursor-pointer ">
                  <ChatFilterIcon />
                </span>
              )} */}
              <SearchBar
                containerStyle={"w-full"}
                className="h-10 cursor-pointer  "
                placeholder="Search"
              />
            </div>
            <PrimaryTabs
              tabsData={homeTabs}
              isCount={true}
              tabsChildrenClass="h-[calc(100vh_-_284px)] overflow-auto theme-scrollbar-sm pl-3 lg:pl-6 pr-[10px] pr-8 py-0 "
              innerClass=" h-10 overflow-x-auto theme-scrollbar-sm  lg:px-8  md:px-2 "
              onChange={(label) => {
                setTabsChange(label);
                setShowSpaceInfo(false);
              }}
            />
          </div>
        </div>
        <div className="md:w-2/3 lg:w-3/4">
          {tabsChange.toLowerCase() === "spaces" ? (
            <ChatBox
              showSpaceInfo={showSpaceInfo}
              setShowSpaceInfo={setShowSpaceInfo}
              isMute={isMute}
              setIsMuteOpen={setIsMuteOpen}
              setIsPopOverOpen={setIsPopOverOpen}
              picture={U25LogoViolet}
              spaces={{
                picture: U25LogoViolet,
                title: "Guru Nanak Institutions",
                members: 604,
                membersOnline: 3,
              }}
              title="Guru Nanak Institutions"
              pinnedInfo={{
                message:
                  "To gain creator access on Roposo and enhance your creative journey, you can follow these steps and start sharing your unique content with the world.",
              }}
            >
              <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                Yesterday
              </p>
              <Receiver
                userName="e/scott"
                message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc."
                time="07:00 PM"
                picture={Messenger1}
              />
              <Sender message="Awesome! Thanks." time="06:30 PM" />
              <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-6 mb-3 ">
                Today
              </p>
              <Receiver
                userName="e/ethan"
                deleted={true}
                time="09:00 AM"
                picture={Messenger2}
              />
              <Sender message="&#128522;" time="09:10 AM" />
              <Receiver
                userName="e/ethan"
                message="Spot on Adam, definitely love the energy of this group"
                time="10:12 AM  "
                picture={Messenger2}
                thread={true}
              />
              <Receiver
                userName="e/scott"
                time="05:10 PM"
                message="I wanna go here so bad."
                media={SenderPicture}
                picture={Messenger2}
              />
              <Receiver
                userName="e/scott"
                time="08:00 AM"
                message="I wanna go here so bad."
                media={SampleVideo2}
                picture={Messenger2}
              />
              <Receiver
                userName="e/scott"
                time="08:00 AM"
                message="I wanna go here so bad."
                sharedProfileData={{
                  id: 1,
                  userName: "e/kani",
                  fullName: "Kanishka Reddy",
                  profileImage: ShareProfile,
                }}
                picture={Messenger2}
              />
              <Receiver
                userName="e/scott"
                time="08:00 AM"
                message="I wanna go here so bad."
                sharedSpaceData={{
                  id: 1,
                  title: "Snapchat opinion leader programme",
                  picture: SnapLogo,
                  link: "",
                }}
                picture={Messenger2}
              />
              <Receiver
                userName="e/ethan"
                time="07:30 AM"
                message="Start joining guyss, its design nightt."
                link="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/video"
                picture={Messenger2}
              />
              <Sender
                repliedInfo={{
                  fullName: "Ethan",
                  message: "Office Tommorrow?",
                }}
                message="No Bro, working from HOME."
                time="05:30 PM"
              />
            </ChatBox>
          ) : tabsChange.toLowerCase() === "connections" ? (
            <ChatBox
              showSpaceInfo={showSpaceInfo}
              setShowSpaceInfo={setShowSpaceInfo}
              setIsMuteOpen={setIsMuteOpen}
              isMute={isMute}
              setIsPopOverOpen={setIsPopOverOpen}
              picture={Ella}
              connections={{
                picture: Ella,
                fullName: "William Ella",
                isOnline: true,
              }}
            >
              <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                Yesterday
              </p>
              <Receiver
                userName="e/kani"
                message="Spot on Adam, definitely love the energy of this group"
                time="05:00 PM"
                picture={Messenger1}
              />
              <Sender message="😊" time="26 h" />
              <p className="bg-[rgba(0,30,255,0.04)] rounded-lg p-2 w-max text-center mx-auto text-gray_6 text-sm not-italic font-normal leading-[120%]">
                <span className="text-U25_orange text-sm not-italic font-normal leading-[120%] me-1">
                  12
                </span>
                New Messages
              </p>
              <Receiver
                userName="e/kani"
                time="05:10 PM"
                message="Hey, I’ve finished with the requirements doc! I made some notes in the gdoc."
                picture={Messenger2}
              />
              <Sender message="" media={SenderPicture} time="09:10 AM" />
            </ChatBox>
          ) : tabsChange.toLowerCase() === "groups" ? (
            <ChatBox
              showSpaceInfo={showSpaceInfo}
              setShowSpaceInfo={setShowSpaceInfo}
              setIsMuteOpen={setIsMuteOpen}
              isMute={isMute}
              setIsPopOverOpen={setIsPopOverOpen}
              picture={Roposo}
              groups={{
                picture: Roposo,
                title: "Roposo Opinion Leader Programme",
                members: 604,
                membersOnline: 3,
              }}
            >
              <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                Yesterday
              </p>
              <Receiver
                userName="e/scott"
                message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc."
                time="05:00 PM"
                picture={Messenger1}
              />
              <Sender message="Awesome! Thanks." time="05:30 PM" />
              <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                Today
              </p>
              <Sender message="😊" time="06:00 PM" />
            </ChatBox>
          ) : null}
        </div>
      </div>
      <ChatLogoutModal modal={isDeletePopOpen} setModal={setIsDeleteOpen} />
      <MuteNotificationModal
        modal={isMutePopOpen}
        setModal={setIsMuteOpen}
        setMute={setIsMute}
      />
      <AddGroupDetailsModal
        modal={isGroupDetailModalOpen}
        setModal={setIsGroupDetailModalOpen}
        setIsAddGroupOpen={setIsAddGroupOpen}
        selectedMembersData={selectedMembersData}
      />
      <AddGroupMembersModal
        modal={isAddGroupPopOpen}
        setModal={setIsAddGroupOpen}
        setSelectedMembersData={setSelectedMembersData}
        setIsGroupDetailModalOpen={setIsGroupDetailModalOpen}
      />
      <ChatArchived
        isModalOpen={openArchiveModal}
        handleOk={handleOpenArchiveModal}
        handleCancel={handleCancelArchiveModal}
      />
    </Layout>
  );
};

export default Chat;
