import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import VerifyOtp from "./pages/VerifyOtp";
import Auth from "./pages/Auth";
import CreateProfile from "./pages/CreateProfile";
import SelectHouse from "./pages/SelectHouse";
import Home from "./pages/Home";
import Profile from "./pages/Profile";
import UserAccount from "./pages/UserAccount";
import Spaces from "./pages/Spaces";
import Explorer from "./pages/Explorer";
import Settings from "./pages/Settings";
import EventDetail from "./pages/EventDetail";
import Notification from "./pages/Notification";
import Requests from "./pages/Requests";
import Birthday from "./pages/Birthday";
import Refer from "./pages/Refer";
import Chat from "./pages/Chat";
import Missions from "./pages/Missions";
import MissionsDetails from "./pages/MissionsDetails";
import MyDecks from "./pages/MyDecks";
import DeckDetails from "./pages/DeckDetails";

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/referral-sign-up" element={<Auth />} />
        <Route path="/verify-otp" element={<VerifyOtp />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/select-house" element={<SelectHouse />} />
        <Route path="/community" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/user-account" element={<UserAccount />} />
        <Route path="/user-account/decks" element={<MyDecks />} />
        <Route path="/user-account/decks/details" element={<DeckDetails />} />

        <Route path="/spaces" element={<Spaces />} />
        <Route path="/spaces/explorer" element={<Explorer />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/spaces/event-detail" element={<EventDetail />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/requests" element={<Requests />} />
        <Route path="/birthday" element={<Birthday />} />
        <Route path="/invite" element={<Refer />} />
        <Route path="/chats" element={<Chat />} />
        <Route path="/missions" element={<Missions />} />
        <Route path="/missions/details" element={<MissionsDetails />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
