import CustomModal from "../CustomModal/CustomModal";
import { useState } from "react";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { TabComp } from "../../themes/TabComp/TabComp";
import "./edit_profile.scss";
import { PersonalInfoTab } from "./components/PersonalInfoTab";
import { SoulBoundToken } from "./components/SoulBoundToken";
import { AddCollegeModal } from "./components/AddCollegeModal";
interface EditProfileProps {
  popover: boolean;
  isBasics?: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
export const EditProfile: React.FC<EditProfileProps> = ({
  popover,
  setPopover,
  isBasics,
}) => {
  const [addCollege, setAddCollege] = useState(false);

  const handleClosePopover = () => {
    setPopover(false);
  };
  const handleOpenPopover = () => {
    setPopover(true);
  };

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#1F1F1F] text-[24px] font-medium leading-[100%] capitalize">
        Edit Profile
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Save Changes"
      />
    </div>
  );

  // add college
  const tabData = [
    {
      key: "1",
      label: "Personal Info",
      children: (
        <PersonalInfoTab
          handleClosePopover={handleClosePopover}
          setPopover={setPopover}
        />
      ),
    },
    {
      key: "2",
      label: "Soul Bound Token",
      children: (
        <SoulBoundToken
          onSearchAddBtn={() => {
            handleClosePopover();
            setAddCollege(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <CustomModal
        isModalOpen={popover}
        handleCancel={handleClosePopover}
        handleOk={handleOpenPopover}
        title={Header}
        footer={Footer}
        width={615}
      >
        <TabComp
          isBasics={isBasics}
          className="edit-profile-tab"
          tabItem={tabData}
        />
      </CustomModal>
      <AddCollegeModal
        onBackClick={() => {
          setPopover(true);
          setAddCollege(false);
        }}
        popover={addCollege}
        setPopover={setAddCollege}
      />
    </>
  );
};
