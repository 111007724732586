import React, { useState } from "react";
import { HouseModalProps } from "../../../type/types";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import CustomInput from "../../CustomInput/CustomInput";
import U25DatePicker from "../../U25DatePicker/U25DatePicker";
import { ClockOutlinedGray } from "../../Icons/Icons";
import CustomTextArea from "../../CustomTextArea/CustomTextArea";
import { Radio } from "antd";
import SelectData from "../../../themes/SelectData/SelectData";

const CreateEvent: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [description, setDescription] = useState("");

  const TimeData = [];

  for (let hour = 0; hour < 24; hour++) {
    const time = hour.toString().padStart(2, "0") + ":00";
    TimeData.push({
      value: time,
      label: time,
    });
  }

  const giveaway = [
    { value: "600", label: "600" },
    { value: "1200", label: "1200" },
    { value: "1800", label: "1800" },
    { value: "2400", label: "2400" },
    { value: "3000", label: "3000" },
  ];

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(e.target.value.slice(0, 100));
  };

  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Create Event
      </h4>
    </div>
  );

  const modalFooter = (
    <div
      onClick={() => handleCancel()}
      className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] "
    >
      <PrimaryButton title="Save" width="fit-content" />
    </div>
  );

  return (
    <div>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={modalFooter}
        width={615}
      >
        <div className="mx-0 my-6 px-6 py-0">
          <form
            action="
            "
          >
            <PhotoUpload
              title="Upload Event Thumbnail"
              description="max file size 500kb"
              className="mb-5"
            />
            <div className="flex flex-col gap-5">
              <div className="flex flex-col name-input">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Event Name
                </label>
                <CustomInput
                  name="fullName"
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="Give it a Name"
                />
              </div>
              <div className="flex flex-col dob">
                <label
                  htmlFor="dob"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Date
                </label>
                <U25DatePicker
                  showSecondDatePicker={showSecondDatePicker}
                  setShowSecondDatePicker={setShowSecondDatePicker}
                  containerClass="mt-1"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="dob"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Start Time
                </label>
                <div className="flex gap-4">
                  <SelectData
                    popupClassName={
                      "social-link-dropdown-popup personal-info-popup"
                    }
                    defaultValue="12:00"
                    optionsData={TimeData}
                    placement="bottomRight"
                    className="personal-info-dropdown w-1/2"
                    suffixIcon={
                      <>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_503_27826)">
                            <path
                              d="M8.00065 3.99967V7.99967L10.6673 9.33301M14.6673 7.99967C14.6673 11.6816 11.6825 14.6663 8.00065 14.6663C4.31875 14.6663 1.33398 11.6816 1.33398 7.99967C1.33398 4.31778 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31778 14.6673 7.99967Z"
                              stroke="#666666"
                              stroke-opacity="0.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_503_27826">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    }
                  />
                  <SelectData
                    popupClassName={
                      "social-link-dropdown-popup personal-info-popup"
                    }
                    defaultValue="12:00"
                    optionsData={TimeData}
                    placement="bottomRight"
                    className="personal-info-dropdown w-1/2"
                    suffixIcon={
                      <>
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_503_27826)">
                            <path
                              d="M8.00065 3.99967V7.99967L10.6673 9.33301M14.6673 7.99967C14.6673 11.6816 11.6825 14.6663 8.00065 14.6663C4.31875 14.6663 1.33398 11.6816 1.33398 7.99967C1.33398 4.31778 4.31875 1.33301 8.00065 1.33301C11.6825 1.33301 14.6673 4.31778 14.6673 7.99967Z"
                              stroke="#666666"
                              stroke-opacity="0.6"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_503_27826">
                              <rect width="16" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </>
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="dob"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Event Description
                </label>
                <div className="relative">
                  <CustomTextArea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="What is the event about?"
                    noBorder={false}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    className="text-primary placeholder:text-gray_3_hover text-[17px] not-italic font-normal leading-[120%] pt-4 pb-2 px-4 mt-1"
                  />
                  <div className="absolute bottom-2 right-4">
                    {description.length}/100
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Is it an online event?
                </label>
                <Radio.Group>
                  <Radio className="custom-radio" value={1}>
                    <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                      Yes
                    </span>
                  </Radio>
                  <Radio className="custom-radio" value={2}>
                    <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                      No
                    </span>
                  </Radio>
                </Radio.Group>
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Event Venue
                </label>
                <CustomInput
                  name="fullName"
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="Where"
                />
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Link to Location
                </label>
                <CustomInput
                  name="fullName"
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="gmaps.124ad135"
                />
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateEvent;
