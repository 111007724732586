import React from "react";
import { Avatar, Popover } from "antd";
import { RequestApprovalTypes } from "../../type/types";
import { KebabMenu } from "../Icons/Icons";

interface RequestApprovalProps {
  data: RequestApprovalTypes[];
  containerClass?: string;
  menu?: boolean;
}

const RequestApproval: React.FC<RequestApprovalProps> = ({
  data,
  containerClass,
  menu = false,
}) => {
  const menuContent = (
    <ul>
      <li className=" pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span className=" text-primary text-[17px] not-italic font-normal leading-[120%]">
          Mark all as read
        </span>
      </li>
      <li className=" pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
        <span className=" text-primary text-[17px] not-italic font-normal leading-[120%]">
          Remove this notification
        </span>
      </li>
    </ul>
  );

  return (
    <div className={`${containerClass}`}>
      {data?.map((item) => (
        <div key={item.id} className="flex gap-3 [&:not(:last-child)]:mb-4">
          <Avatar
            src={item.image}
            size={45}
            shape="square"
            alt="user"
            className="rounded-md mb-[2px] max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px]"
          />
          <div>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-1">
              Your
              <span className="text-primary text-[17px] not-italic font-medium leading-[120%] mx-1">
                Request for creating a space
              </span>
              and has been approved.
            </p>
            <p className="text-gray_6 text-[13px] not-italic font-normal leading-[normal]">
              {item.timeAgo} ago
            </p>
          </div>
          {menu && (
            <Popover
              overlayClassName="w-[190px]"
              content={menuContent}
              trigger="click"
              placement="bottomRight"
              arrow={false}
              overlayInnerStyle={{
                padding: 8,
                borderRadius: 12,
              }}
            >
              <button className="flex self-start ml-auto rounded-[50%] hover:bg-gray_4_hover transition-all duration-200 ease-[ease]">
                <KebabMenu />
              </button>
            </Popover>
          )}
        </div>
      ))}
    </div>
  );
};

export default RequestApproval;
