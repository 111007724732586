import React, { useState } from "react";
import userProfile from "../../../../../assets/png/community-user-picture.png";
import {
  BlockLined,
  ClockOutlined,
  DeleteLined,
  DropdownArrow,
  EditOutlined,
  FeedKababCopyIcon,
  FeedKababIcon,
  FeedKababReportIcon,
  FeedKababShareIcon,
  LogoutLined,
  MessageOutlined,
  U25VerifiedUser,
  UserPlusOutlined,
} from "../../../../Icons/Icons";
import { Avatar, Popover } from "antd";
import PrimaryButton from "../../../../PrimaryButton/PrimaryButton";
import "./banner_card.scss";
import AvatarComp from "../../../../../themes/AvatarComp/AvatarComp";
import DisconnectModal from "../DisconnectModal/DisconnectModal";
import CustomModal from "../../../../CustomModal/CustomModal";
import profilePic from "../../../../../assets/png/aboutuser.png";
import ReportModal from "../../../../Feeds/Modal/ReportModal";
import { Link } from "react-router-dom";
import useIsMac from "../../../../../hooks/useIsMac";
interface ImageInfo {
  src: string;
  alt: string;
}
interface BannerCardProps {
  Edit?: boolean;
  image?: ImageInfo;
  name?: string;
  userId?: string;
  onEditClick?: () => void;
}
const BannerCard: React.FC<BannerCardProps> = ({
  Edit = false,
  image = userProfile,
  name = "Carsol Dsliva",
  userId = "e/carsoldsliva",
  onEditClick = () => {},
}) => {
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [connectPopover, setConnectedPopover] = useState(false);
  const connectedPopoverHandler = () => {
    setConnectedPopover((prev) => !prev);
  };
  const isMac = useIsMac();

  const BtnData1 = [
    {
      key: 1,
      icon: (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.3346 6.66667V5.33333C11.3346 3.49238 9.84225 2 8.0013 2C6.16035 2 4.66797 3.49238 4.66797 5.33333V6.66667M8.0013 9.66667V11M5.86797 14H10.1346C11.2547 14 11.8148 14 12.2426 13.782C12.6189 13.5903 12.9249 13.2843 13.1166 12.908C13.3346 12.4802 13.3346 11.9201 13.3346 10.8V9.86667C13.3346 8.74656 13.3346 8.18651 13.1166 7.75869C12.9249 7.38236 12.6189 7.0764 12.2426 6.88465C11.8148 6.66667 11.2547 6.66667 10.1346 6.66667H5.86797C4.74786 6.66667 4.18781 6.66667 3.75999 6.88465C3.38366 7.0764 3.0777 7.38236 2.88596 7.75869C2.66797 8.18651 2.66797 8.74656 2.66797 9.86667V10.8C2.66797 11.9201 2.66797 12.4802 2.88596 12.908C3.0777 13.2843 3.38366 13.5903 3.75999 13.782C4.18781 14 4.74786 14 5.86797 14Z"
            stroke="#1F1F1F"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      name: "Privacy Terms",
    },
    {
      key: 2,
      icon: <LogoutLined />,
      name: "Logout",
    },
    {
      key: 3,
      icon: <DeleteLined />,
      name: "Delete Account",
    },
  ];
  const BtnData2 = [
    {
      key: 1,
      icon: <FeedKababShareIcon width={16} height={16} />,
      name: "Share",
    },
    {
      key: 2,
      icon: <BlockLined />,
      name: "Block",
    },
    {
      key: 3,
      icon: <FeedKababReportIcon />,
      name: "Report",
    },
    {
      key: 4,
      icon: <FeedKababCopyIcon />,
      name: "Copy",
    },
  ];

  const dataItems = (
    <>
      {Edit ? (
        <div>
          {BtnData1.map((btn) => {
            return (
              <button
                key={btn.key}
                className="flex items-center gap-3 w-full  pt-1.5 pb-1.5 px-2 rounded-lg hover:!bg-gray_4"
              >
                <span className="">{btn.icon}</span>
                <h6
                  className={`${
                    isMac && "pt-1"
                  } text-primary text-[17px] not-italic font-normal leading-[120%]`}
                >
                  {btn.name}
                </h6>
              </button>
            );
          })}
        </div>
      ) : (
        <div>
          {BtnData2.map((btn) => {
            return (
              <button
                onClick={() => {
                  if (btn.name === "Report") setReportModalOpen(true);
                  if (btn.name === "Block") setProfileBlock(true);
                  setIsPopOverOpen(false);
                }}
                key={btn.key}
                className="flex items-center gap-3 w-full  pt-1.5 pb-1.5 px-2 rounded-lg hover:!bg-gray_4"
              >
                <span className="">{btn.icon}</span>
                <h6 className="text-primary text-[17px] not-italic font-normal leading-[120%]">
                  {btn.name}
                </h6>
              </button>
            );
          })}
        </div>
      )}
    </>
  );

  const ConnectedBtnItem = (
    <div>
      <button
        onClick={() => {
          setConnectedPopover(false);
          setOpenModal(true);
        }}
        className="w-full text-left text-primary text-[15px] not-italic font-normal leading-[120%] py-1.5 px-2 rounded-lg hover:!bg-gray_4"
      >
        Disconnect
      </button>
    </div>
  );

  const [status, setStatus] = useState("notConnected");
  const PrimaryBtnDefault = (
    <div className="flex items-center gap-1">
      <span className="min-w-[10px] max-w-[10px] min-h-[10px] max-h-[10px] flex justify-center items-center banner-card-btn-icon">
        <UserPlusOutlined className={`${isMac && "mb-1"}`} />
      </span>
      <h6 className="text-white text-[13px] font-normal leading-[normal]">
        Connect
      </h6>
    </div>
  );
  const PrimaryBtnRequested = (
    <div className="flex items-center gap-1">
      <span className="min-w-[10px] max-w-[10px] min-h-[10px] max-h-[10px] flex justify-center items-center">
        <ClockOutlined className={`${isMac && "mb-1"}`} />
      </span>
      <h6 className="text-gray_6 text-[13px] font-normal leading-[normal]">
        Requested
      </h6>
    </div>
  );
  const PrimaryBtnConnected = (
    <div className="flex items-center gap-1">
      <h6 className="text-gray_6 text-[13px] font-normal leading-[normal]">
        Connected
      </h6>
      <span className="min-w-[10px] max-w-[10px] min-h-[10px] max-h-[10px] flex justify-center items-end">
        <DropdownArrow width={10} height={10} />
      </span>
    </div>
  );
  const SecondaryBtnTitle = (
    <div className="flex items-center gap-1">
      <span className="min-w-[10px] max-w-[10px] min-h-[10px] max-h-[10px] flex justify-center items-center">
        <MessageOutlined />
      </span>
      <h6 className="text-black text-[13px] font-normal leading-[normal]">
        Message
      </h6>
    </div>
  );

  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const handleModalClose = () => setOpenModal(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [backToConnect, setBackToConnect] = useState(false);
  const [profileBlock, setProfileBlock] = useState(false);

  const handleCancelSaveToDrafts = () => {
    setBackToConnect(false);
  };

  const handleOkSaveToDrafts = () => {
    setBackToConnect(true);
  };

  const backToConnectHeader = <div className="pt-6 pb-4 px-6"></div>;
  const backToConnectFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleCancelSaveToDrafts();
          setStatus("connected");
        }}
        title="No"
        theme="light"
      />
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleCancelSaveToDrafts();
          setStatus("notConnected");
        }}
        theme="warning"
        title="Yes"
      />
    </div>
  );
  return (
    <>
      <ReportModal
        isModalOpen={isReportModalOpen}
        setReportModalOpen={setReportModalOpen}
        handleOk={() => setReportModalOpen(false)}
        handleCancel={() => setReportModalOpen(false)}
        profile={true}
      />

      <div className="w-full bg-white py-5 px-4 rounded-[12px] flex gap-4">
        <span className="lg:min-w-[100px] lg:max-w-[100px] lg:min-h-[100px] lg:max-h-[100px] flex justify-center items-center ">
          <img src={image} alt="user" className="rounded-[12px]" />
        </span>
        <div className="w-full">
          <div className="flex justify-between items-start w-full">
            <div className="">
              <div className="flex gap-1 items-center">
                <h6 className="text-[#1F1F1F] text-2xl font-medium leading-[100%]">
                  {name}
                </h6>
                <span className="min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px] flex justify-center items-center">
                  <U25VerifiedUser />
                </span>
              </div>
              <p className="mt-1 text-[#666] text-[17px] font-normal leading-[120%]">
                {userId}
              </p>
            </div>
            <div className="flex items-center">
              {Edit && (
                <button
                  onClick={onEditClick}
                  className="flex justify-center items-center  w-[35px] h-[35px] hover:bg-[#F4F4F4] transition-colors rounded-[8px]"
                >
                  <EditOutlined />
                </button>
              )}
              <Popover
                placement="bottomLeft"
                arrow={false}
                content={dataItems}
                trigger={["click"]}
                open={isPopOverOpen}
                onOpenChange={() => {
                  setIsPopOverOpen((prev) => !prev);
                }}
                overlayClassName="header-dropdown pt-2 pb-4 px-2 min-w-[170px]"
              >
                <button
                  onClick={(e) => e.preventDefault()}
                  className="flex justify-center items-center  w-[35px] h-[35px] hover:bg-[#F4F4F4] transition-colors rounded-[8px]"
                >
                  <FeedKababIcon />
                </button>
              </Popover>
            </div>
          </div>
          <div className="flex gap-2.5 mt-1 flex-wrap">
            <div className="flex gap-1 items-center">
              <h4 className="text-[#FC741E] text-[15px] font-medium leading-[120%]">
                1.86K
              </h4>
              <h5 className="text-[#666] text-[15px] font-normal leading-[120%]">
                Connections
              </h5>
            </div>
            <div className="flex gap-1 items-center">
              <h4 className="text-[#001EFF] text-[15px] font-medium leading-[120%]">
                630
              </h4>
              <h5 className="text-[#666] text-[15px] font-normal leading-[120%]">
                Cards
              </h5>
            </div>
          </div>
          <div className="flex justify-between w-full mt-1">
            {!Edit && (
              <div className="flex gap-2 items-center">
                <AvatarComp />
                <h5 className="text-[#1F1F1F] text-[15px] font-medium leading-[120%]">
                  12
                  <span className="text-[#666666] text-[15px] font-normal leading-[120%] ml-1">
                    Mutuals
                  </span>
                </h5>
              </div>
            )}
            <div className="flex gap-2 items-center">
              {!Edit && status == "notConnected" ? (
                <PrimaryButton
                  onClick={() => {
                    setStatus("requested");
                  }}
                  title={PrimaryBtnDefault}
                  width="full"
                  size="regular"
                  className="!px-2 !h-[33px]"
                  fontClass="text-[15px]"
                />
              ) : status === "requested" ? (
                <PrimaryButton
                  onClick={() => {
                    setBackToConnect(true);
                  }}
                  title={PrimaryBtnRequested}
                  width="full"
                  size="regular"
                  className="!px-2 !h-[33px]"
                  fontClass="text-[15px]"
                  theme="light"
                />
              ) : status == "connected" ? (
                <Popover
                  open={connectPopover}
                  onOpenChange={connectedPopoverHandler}
                  placement="bottomLeft"
                  arrow={false}
                  content={ConnectedBtnItem}
                  trigger={["click"]}
                  overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
                >
                  <PrimaryButton
                    onClick={() => {
                      setStatus("connected");
                    }}
                    title={PrimaryBtnConnected}
                    width="full"
                    size="regular"
                    className="!px-2 !h-[33px]"
                    fontClass="text-[15px]"
                    theme="light"
                  />
                </Popover>
              ) : null}
              {!Edit && (
                <PrimaryButton
                  title={SecondaryBtnTitle}
                  width="full"
                  size="regular"
                  className="!px-2 !h-[33px]"
                  fontClass="text-[15px]"
                  theme="light"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <DisconnectModal
        isModalOpen={openModal}
        handleOk={handleModalOpen}
        handleCancel={handleModalClose}
        handleBtnClick={() => {
          setStatus("notConnected");
        }}
        image={profilePic}
        userTitle="Carsol Dsliva"
        userName="e/carsoldsliva"
        isVerified={true}
      />
      <CustomModal
        isModalOpen={backToConnect}
        handleCancel={handleCancelSaveToDrafts}
        handleOk={handleOkSaveToDrafts}
        title={backToConnectHeader}
        footer={backToConnectFooter}
        width={600}
      >
        <div className="mx-6">
          <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
            Are you sure you want to cancel the request?
          </p>
        </div>
      </CustomModal>
      <CustomModal
        isModalOpen={profileBlock}
        handleCancel={() => setProfileBlock(false)}
        handleOk={() => setProfileBlock(false)}
        title={
          <div className="flex items-center justify-start  text-primary text-2xl not-italic font-medium !leading-[100%] pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
            <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              Would you like to Block?
            </h4>
          </div>
        }
        footer={
          <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px]">
            <Link to={""}>
              <PrimaryButton
                onClick={() => {
                  setProfileBlock(false);
                }}
                title="No"
                theme={"light"}
                width="fit-content"
              />
            </Link>
            <Link to={""}>
              <PrimaryButton
                onClick={() => {
                  setProfileBlock(false);
                }}
                title="Yes"
                width="fit-content"
              />
            </Link>
          </div>
        }
        width={600}
      >
        <div className="mx-6 gap-6">
          <div className=" flex my-6 border-b-gray_4 border p-4  rounded-lg border-solid">
            <div className="relative w-fit ">
              <Avatar
                shape="square"
                size={70}
                src={userProfile}
                className=" rounded-xl "
              />
              <span className=" absolute right-[-5px] bottom-[-6px] p-[4.8px] rounded-[14.4px] bg-[#FFEAEA]">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.758 2.758L11.242 11.242M13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7Z"
                    stroke="#FF5050"
                    stroke-width="1.44"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="ml-4">
              <h3 className="text-2xl not-italic font-medium leading-[100%]">
                Kanishka Reddy
              </h3>
              <p className="text-[17px] not-italic font-normal leading-[120%] text-gray_6">
                e/kani
              </p>
            </div>
          </div>
          <div>
            <ul className="spCE px-2 mb-6  space-y-[10px] ">
              <li className=" flex text-[17px] not-italic font-normal leading-[120%] capitalize ">
                <span className="flex ">
                  &#8226;
                  <p className="ml-2">
                    They won’t be able to find, connect, or message you.
                  </p>
                </span>
              </li>
              <li>
                <span className="flex text-[17px] not-italic font-normal leading-[120%] capitalize">
                  &#8226;
                  <p className="ml-2">
                    They won’t be able to engage with your content.
                  </p>
                </span>
              </li>
              <li>
                <span className="flex text-[17px] not-italic font-normal leading-[120%] capitalize">
                  &#8226;
                  <p className="ml-2">
                    They won’t be notified that you blocked them.
                  </p>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default BannerCard;
