import Bannercard from "./components/BannerCard/BannerCard";
import FeedComp from "./components/FeedComp/FeedComp";
import ProfilePic from "../../../assets/png/aboutuser.png";
function Main() {
  const userId = "e/carsoldslivai";
  return (
    <section className="flex flex-col gap-6 no-scrollbar   overflow-y-scroll">
      <Bannercard image={ProfilePic} />
      <FeedComp FeedUser={ProfilePic} userId={userId} />
    </section>
  );
}

export default Main;
