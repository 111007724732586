import data from "../../../../data/mySpaceData";
import { SpaceDataType } from "../../../../type/types";
import Lock from "../../../../assets/svg/lock-02.svg";
import useIsMac from "../../../../hooks/useIsMac";
import { useNavigate } from "react-router-dom";

const SpaceBox = () => {
  const isMac = useIsMac();
  const navigate = useNavigate();

  return (
    <>
      {data &&
        data.map((item: SpaceDataType) => {
          return (
            <div
              onClick={() => navigate("/spaces/explorer")}
              key={item.id}
              className="flex items-center cursor-pointer justify-between [&:not(:last-child)]:pb-5 [&:not(:last-child)]:mb-5 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-gray_2"
            >
              <div className="flex items-center gap-4">
                <div>
                  <img
                    className="max-w-[64px] min-w-[64px] max-h-[64px] min-h-[64px] rounded-md object-cover"
                    src={item.image}
                    alt="space group"
                  />
                </div>
                <div>
                  <div className="flex items-center gap-2">
                    <h4 className="text-primary text-xl not-italic font-medium leading-[100%] mb-1">
                      {item.title}
                    </h4>
                    {item.locked && (
                      <img
                        src={Lock}
                        alt="lock"
                        className={` ${
                          isMac && " mb-1"
                        } !max-w-[12px] !min-w-[12px] !max-h-[12px] !min-h-[12px]`}
                      />
                    )}
                  </div>
                  <div>
                    <span className="pe-2 text-link_hover text-[15px] not-italic font-normal leading-[120%]">
                      <span className="text-U25_orange">{item.cards}</span>{" "}
                      Cards
                    </span>
                    {item.events !== 0 && (
                      <span className="text-link_hover text-[15px] not-italic font-normal leading-[120%] before:content-[''] before:inline-flex before:w-[3px] before:h-[3px] before:bg-gray_7 before:rounded before:align-middle before:me-2">
                        <span className="text-primary">{item.events}</span> New
                        Events
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                  {item.explorers} Explorers
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SpaceBox;
