import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { CloseDarkOutlined } from "../../Icons/Icons";
import { Link } from "react-router-dom";
import UserContactList from "../../UserContactList/UserContactList";
import userListData from "../../../data/userListData";
import FeedUser from "../../../assets/png/feedUser.png";
import { Avatar } from "antd";

const UpVotesModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [isVoted, setIsVoted] = useState(false);
  const [upVoteCount, setUpVoteCount] = useState(1);

  return (
    <div>
      <CustomModal
        className="house-modal"
        title={
          <div className="flex items-baseline justify-start border-b-gray_4 border-b border-solid w-full">
            <h3 className="pt-6 pb-4 py-6 pl-6  text-dark_85 text-[24px] not-italic font-medium leading-[100%]">
              Upvotes
            </h3>
            <span className="px-[6px] ml-2  text-white rounded-3xl bg-blue_1 text-[15px] not-italic font-[500] leading-[120%]">
              12
            </span>
          </div>
        }
        closeIcon={<CloseDarkOutlined />}
        width={615}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={<></>}
      >
        <div className=" px-6 py-4">
          <div className="w-full p-2 rounded-lg">
            <div className="flex flex-col">
              <div className="flex  justify-between">
                <div className="flex">
                  <Avatar
                    src={FeedUser}
                    className="h-[32px] w-[32px] rounded-md cursor-pointer "
                  />
                  <div className="flex flex-col ml-3">
                    <h6 className="text-[17px] not-italic font-medium leading-[120%] cursor-pointer">
                      e/anushaudupi
                    </h6>
                  </div>
                </div>
                <div className="flex items-center py-[6px] px-3  border rounded-[40px] h-[28px] border-solid border-[#F2F2F2]">
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setIsVoted((prevIsVoted: any) => !prevIsVoted);
                      setUpVoteCount((prevCount: any) =>
                        isVoted ? prevCount - 1 : prevCount + 1
                      );
                    }}
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1550_7251)">
                        <path
                          d="M10.6693 8.94084L8.0026 6.27417M8.0026 6.27417L5.33594 8.94084M8.0026 6.27417V11.6075M14.6693 8.94084C14.6693 12.6227 11.6845 15.6075 8.0026 15.6075C4.32071 15.6075 1.33594 12.6227 1.33594 8.94084C1.33594 5.25894 4.32071 2.27417 8.0026 2.27417C11.6845 2.27417 14.6693 5.25894 14.6693 8.94084Z"
                          stroke={isVoted ? "#001EFF" : "#1F1F1F"}
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1550_7251">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0 0.940918)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <p className="ml-2 text-base">1</p>
                </div>
              </div>
            </div>
            <div className="flex w-full mt-4    ">
              <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                Lorem ipsum dolor sit amet consectetur. Turpis consequat blandit
                eget sollicitudin. Amet posuere magna tortor hendrerit pharetra
                sapien.
              </p>
            </div>
          </div>

          <p className=" mt-6 text-[18px] not-italic font-medium leading-[normal] mb-4 ">
            Upvoted by
          </p>

          <UserContactList
            data={userListData}
            containerClassName="h-[344px]"
            noFooter={true}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default UpVotesModal;
