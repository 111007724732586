import React, { ChangeEvent, forwardRef } from "react";
import "./OpenSearch.scss";
import { XCloseMd } from "../Icons/Icons";

interface OpenSearchProps {
  className?: string;
  value?: string;
  inputClassName?: string;
  prefixClassName?: string;
  prefix?: React.ReactNode | string | number;
  ref?: any;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  isFocus?: boolean;
  onClose?: (e: any) => void;
}

const OpenSearch: React.FC<OpenSearchProps> = forwardRef<
  HTMLInputElement,
  OpenSearchProps
>(
  (
    {
      className = "",
      value,
      inputClassName = "",
      onChange = () => {},
      onBlur = () => {},
      onFocus = () => {},
      prefixClassName = "",
      prefix,
      isFocus = false,
      onClose = () => {},
    },
    ref
  ) => {
    return (
      <div className={`container h-fit ${className}`}>
        <form role="search" method="get" className="search-form" action="">
          <div className="flex items-center gap-4 relative">
            {prefix && (
              <span className={`search-icon absolute ${prefixClassName}`}>
                {prefix}
              </span>
            )}
            <input
              ref={ref}
              type="search"
              value={value}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              className={`search-field ${inputClassName}`}
              placeholder="Search"
              name="s"
              title="Search for:"
              autoComplete="off"
            />
            {isFocus && (
              <button onClick={onClose}>
                <XCloseMd />
              </button>
            )}
          </div>
          <input type="submit" className="search-submit" value="Search" />
        </form>
      </div>
    );
  }
);

export default OpenSearch;
