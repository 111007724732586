import React, { ReactNode } from "react";
import type { UploadProps } from "antd";
import { Upload, message } from "antd";
interface FileUploadProps {
  children: ReactNode;
  className?: string;
}
const FileUpload: React.FC<FileUploadProps> = ({ children, className }) => {
  const { Dragger } = Upload;

  const checkFileSize = (file: File) => {
    if (file.size > 20971520) {
      message.error(`File size must be less than 20MB`);
      return false;
    }
    return true;
  };

  const props: UploadProps = {
    name: "file",
    multiple: false,
    accept: ".pdf",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    beforeUpload: checkFileSize,
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  return (
    <Dragger className={className} {...props}>
      {children}
    </Dragger>
  );
};

export default FileUpload;
