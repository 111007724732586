import React, { ChangeEvent, FormEvent, useState, useEffect } from "react";
import OnboardingCarousel from "../components/OnboardingCarousel/OnboardingCarousel";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { Under25Logo } from "../components/Icons/Icons";
import CustomInput from "../components/CustomInput/CustomInput";
import PhotoUpload from "../components/PhotoUpload/PhotoUpload";
import { CreateProfileInputTypes } from "../type/types";
import U25DatePicker from "../components/U25DatePicker/U25DatePicker";

const CreateProfile = () => {
  const navigate = useNavigate();

  const initialValues: CreateProfileInputTypes = {
    fullName: "",
    email: "",
    date: "",
    userName: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    date: "",
    userName: "",
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);

  const handleFormSubmit = (e: FormEvent<HTMLFormElement>): any => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (
      errors.fullName === "" &&
      errors.email === "" &&
      errors.date === "" &&
      errors.userName === ""
    ) {
      setIsSubmit(true);
    }
  };
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );

  const [startDate, setStartDate] = useState<Date | null>(
    new Date(selectedYear)
  );

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
    }
  }, [formErrors, formValues, isSubmit]);

  const validate = (values: CreateProfileInputTypes) => {
    const errors = {
      fullName: "",
      email: "",
      date: "",
      userName: "",
    };
    const fullNameRegx = /^[a-zA-Z\s]+$/;
    var validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!values.fullName) {
      errors.fullName = "Please enter a name";
    } else if (!fullNameRegx.test(values.fullName)) {
      errors.fullName = "Please enter valid name!";
    }
    if (!values.email) {
      errors.email = "Please enter a email id";
    } else if (!validEmailRegex.test(values.email)) {
      errors.email = "Please enter valid email!";
    }
    if (!startDate) {
      errors.date = "Please select a date";
    }
    if (!values.userName) {
      errors.userName = "Please enter a username";
    }
    return errors;
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    const errors: any = validate({ ...formValues, [name]: value });
    setFormErrors({ ...formErrors, [name]: errors[name] });
  };

  return (
    <div className="flex justify-center items-center bg-white h-screen">
      <div className="flex shadow-[0px_4px_12px_4px_rgba(31,31,31,0.12)] h-[588px] rounded-3xl">
        <div className="pt-8 pb-16 pl-16 pr-20 w-[544px] overflow-y-auto theme-scrollbar">
          <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] mb-12">
            <Under25Logo />
          </div>
          <h1 className="text-primary text-[35px] not-italic font-medium leading-[100%] mb-2">
            Create Profile
          </h1>
          <p className="text-gray_1 text-xl not-italic font-normal leading-[120%] opacity-80 mb-6">
            Setup your profile so others can identify you
          </p>

          <PhotoUpload crop={true} className="mb-6" />

          <form className="flex flex-col gap-5" onSubmit={handleFormSubmit}>
            <div className="flex flex-col name-input">
              <label htmlFor="name" className="font-label">
                Full name
              </label>
              <CustomInput
                name="fullName"
                value={formValues.fullName}
                onChange={handleInputChange}
                containerStyle={`mt-2 `}
                className={` rounded-[12px] ${
                  formErrors.fullName && " !border !border-danger "
                } `}
                id="name"
                type="text"
                placeholder="Name"
              />
              {formErrors.fullName && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.fullName}
                </p>
              )}
            </div>
            <div className="flex flex-col email">
              <label htmlFor="email" className="font-label">
                Email
              </label>
              <CustomInput
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
                className={` rounded-[12px] ${
                  formErrors.email && " !border !border-danger "
                } `}
                containerStyle="mt-2"
                type="email"
                placeholder="Email"
              />
              {formErrors.email && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.email}
                </p>
              )}
            </div>
            <div className="flex flex-col dob">
              <label htmlFor="date" className="font-label">
                Date of birth
              </label>
              <U25DatePicker
                // startDate={startDate}
                // setStartDate={setStartDate}
                showSecondDatePicker={showSecondDatePicker}
                setShowSecondDatePicker={setShowSecondDatePicker}
                containerClass="mt-1"
              />
              {formErrors.date && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.date}
                </p>
              )}
            </div>
            <div className="flex flex-col user-name">
              <label htmlFor="username" className="font-label">
                Username
              </label>
              <CustomInput
                name="userName"
                value={formValues.userName}
                onChange={handleInputChange}
                className={` rounded-[12px] ${
                  formErrors.userName &&
                  !formValues.userName &&
                  " !border !border-danger "
                } `}
                containerStyle="my-2"
                type="text"
                placeholder="e/ Enter Username"
              />
              {formErrors.userName && !formValues.userName && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mb-2">
                  {formErrors.userName}
                </p>
              )}
              <div className="flex gap-2 mb-2">
                <div
                  onClick={() =>
                    setFormValues({ ...formValues, userName: "Siddharth122" })
                  }
                  className={`flex suggestion-tag py-2.5 px-3  rounded-3xl hover:bg-gray_1_hover transition-all duration-200 cursor-pointer ${
                    formValues.userName === "Siddharth122"
                      ? "bg-gray_1_hover"
                      : "bg-gray_3"
                  }`}
                >
                  <span className="text-gray_3_hover text-sm not-italic cursor-pointer font-normal leading-[120%]">
                    e/Siddharth122
                  </span>
                </div>
                <div
                  onClick={() =>
                    setFormValues({ ...formValues, userName: "Siddharth402" })
                  }
                  className={`flex suggestion-tag py-2.5 px-3  rounded-3xl hover:bg-gray_1_hover transition-all duration-200 cursor-pointer ${
                    formValues.userName === "Siddharth402"
                      ? "bg-gray_1_hover"
                      : "bg-gray_3"
                  }`}
                >
                  <span className="text-gray_3_hover text-sm not-italic cursor-pointer font-normal leading-[120%]">
                    e/Siddharth402
                  </span>
                </div>
              </div>
              <p className="text-gray_1 text-[17px] not-italic font-normal leading-[120%] mb-1">
                <span className="inline-flex text-U25_orange text-[17px] not-italic font-medium leading-[120%]">
                  Note:
                </span>{" "}
                username cannot be changed once chosen
              </p>
            </div>
            <PrimaryButton
              // disabled={!isSubmit}
              buttonType="submit"
              title="Explore the Club"
              width="full"
              onClick={() => {
                if (isSubmit) {
                  navigate("/select-house");
                }
              }}
            />
          </form>
        </div>
        <OnboardingCarousel />
      </div>
    </div>
  );
};

export default CreateProfile;
