import React from "react";
import { Button } from "antd";
import { PrimaryButtonProps } from "../../type/types";
import "./PrimaryButton.scss";
import useIsMac from "../../hooks/useIsMac";

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  title,
  className,
  fontClass,
  onClick,
  width = "fit-content",
  containerStyle,
  theme = "dark",
  size = "large",
  buttonType = "button",
  tabs,
  disabled = false,
  btnClass,
}) => {
  const isMac = useIsMac();

  return (
    <div className={containerStyle}>
      <Button
        disabled={disabled}
        onClick={onClick}
        htmlType={buttonType}
        className={`primary-btn flex justify-center items-center group ${btnClass} ${
          theme === "dark"
            ? "font-primary border border-solid border-primary bg-primary shadow-[0px_4px_0px_0px_#7C7C7C] hover:bg-dark_75 active:bg-dark_75"
            : theme === "light"
            ? "font-dark border border-solid border-gray_4 bg-white shadow-[0px_4px_0px_0px_#D2D2D2]"
            : theme == "warning"
            ? "font-primary border  border-solid border-[#FF5050] bg-[#FF5050] shadow-[0px_4px_0px_0px_#892323]"
            : theme == "orange"
            ? "font-primary border  border-solid border-[#FC741E] bg-[#FC741E] text-white shadow-[0px_4px_0px_0px_#892323]"
            : ""
        }  rounded-lg ${
          size === "large"
            ? "px-8 py-4"
            : size === "regular"
            ? "px-3 py-2.5"
            : size === "small"
            ? "px-4 py-2"
            : size === "custom"
            ? null
            : null
        } h-auto ${className} ${
          width === "full" ? "w-full" : width === "fit-content" ? "w-fit" : null
        } ${
          tabs ? `!rounded-[30px] hover:!shadow-tab_hover border-0` : ""
        } disabled:bg-disabled disabled:shadow-disabled_shadow disabled:border-[transparent]`}
      >
        <span
          className={`${
            theme === "dark"
              ? "font-primary "
              : theme === "light"
              ? "font-dark group-hover:!text-primary"
              : theme === "orange"
              ? " !font-sans  text-white"
              : ""
          } ${fontClass} ${
            tabs && theme === "light"
              ? "!text-gray_6 !text-[13px] !not-italic !font-normal !leading-[normal]"
              : ""
          } ${isMac && "mt-1"} `}
        >
          {title}
        </span>
      </Button>
    </div>
  );
};

export default PrimaryButton;
