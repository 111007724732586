import React, { useState } from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";
import { Input } from "antd";
import SelectData from "../../../themes/SelectData/SelectData";
import CustomInput from "../../CustomInput/CustomInput";

interface SocialModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onBtnClick: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}

const SocialModal: React.FC<SocialModalProps> = ({
  popover,
  setPopover,
  title,
  onBtnClick,
}) => {
  const [componentCount, setComponentCount] = useState(1);

  const DropdownOptions = [
    { value: "Twitter", label: "Twitter" },
    { value: "Youtube", label: "Youtube" },
    { value: "Instagram", label: "Instagram" },
    { value: "Facebook", label: "Facebook" },
    { value: "Linked In", label: "Linked In" },
  ];

  const handleClosePopover = () => {
    setPopover(false);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );

  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleClosePopover();
        }}
        title="Back"
      />
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          onBtnClick(true);
        }}
        title="Save"
      />
    </div>
  );

  const renderComponents = () => {
    const components = [];
    for (let i = 0; i < componentCount; i++) {
      components.push(
        <div className="flex flex-col gap-1" key={i}>
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
            htmlFor={`my-modal-${i}`}
          >
            Username
          </label>
          <div className="flex items-center gap-4">
            <CustomInput
              containerStyle="w-full"
              placeholder="Enter Username"
              className="social-link-input"
            />
            <SelectData
              popupClassName={"social-link-dropdown-popup"}
              className="social-link-dropdown"
              defaultValue="Instagram"
              optionsData={DropdownOptions}
            />
          </div>
        </div>
      );
    }
    return components;
  };

  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="mb-1 mt-4 pl-5 pr-3 mr-2 flex flex-col gap-4 overflow-y-auto theme-scrollbar-sm">
        {renderComponents()}
        <PrimaryButton
          containerStyle="mb-2"
          btnClass=""
          fontClass="!text-[13px] "
          size="regular"
          theme="light"
          title="Add a social link"
          onClick={() => setComponentCount(componentCount + 1)}
        />
      </div>
    </CustomModal>
  );
};

export default SocialModal;
