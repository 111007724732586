import React, { useEffect, useRef, useState, useCallback } from "react";
import { DropdownArrow, Under25Logo } from "../components/Icons/Icons";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import OnboardingCarousel from "../components/OnboardingCarousel/OnboardingCarousel";
import CustomInput from "../components/CustomInput/CustomInput";
import CustomCheckbox from "../components/CustomCheckbox/CustomCheckbox";
import { Link, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import axios from "axios";
import useOutsideClick from "../hooks/useOutsideClick";

interface SignUpProps {}

interface Country {
  name: string;
  alpha2Code: string;
  callingCodes: string[];
}

const SignUp: React.FC<SignUpProps> = () => {
  const [phNumber, setPhNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+91");
  const location = useLocation();
  const isReferralSignUp = location.pathname === "/referral-sign-up";
  const [validation, setValidation] = useState<boolean>(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const countryCodeDropdownRef = useRef(null);

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownVisible(visible);
  };

  useOutsideClick(countryCodeDropdownRef, () => setIsDropdownVisible(false));

  const truncateString = (country: string) => {
    const strLen = country.length;
    return strLen > 12 ? country.slice(0, 11) + "..." : country;
  };

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [query, setQuery] = useState("");
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownVisible(false);
      }
    },
    [setIsDropdownVisible]
  );

  useEffect(() => {
    setQuery("");
  }, [isDropdownVisible]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    axios
      .get<Country[]>("https://restcountries.com/v2/all")
      .then((response) => setCountries(response.data))
      .catch((error) => console.log("Error fetching country data:", error));
  }, []);

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (e: any) => {
    setChecked(e.target.checked);
  };

  return (
    <div className="flex justify-center items-center bg-white h-screen login">
      <div className="flex shadow-[0px_4px_12px_4px_rgba(31,31,31,0.12)] h-[588px] rounded-3xl">
        <div className="pt-8 pb-16 pl-16 pr-20 max-w-[544px] overflow-y-auto theme-scrollbar">
          <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] mb-12">
            <Under25Logo />
          </div>
          <h1 className="text-primary text-[35px] not-italic font-medium leading-[100%] mb-2">
            Sign In/Sign Up
          </h1>
          <p className="text-gray_1 text-xl not-italic font-normal leading-[120%] opacity-80 mb-[22px] ">
            Enter your details below to register
          </p>

          <form>
            <div className="input-number mb-8">
              <div className="container mt-2">
                <div className="col-md-6 flex flex-col">
                  <label htmlFor="phoneNumber" className="font-label text-left">
                    Phone Number
                  </label>
                  <div
                    ref={countryCodeDropdownRef}
                    className={`mt-2  inline-flex border rounded-lg items-center relative   ${
                      validation &&
                      phNumber.length === 0 &&
                      " !sborder !border-danger"
                    } `}
                  >
                    <div
                      className={`py-3.5 px-3 flex border-r justify-center items-center cursor-pointer h-11`}
                      onClick={() =>
                        handleDropdownVisibleChange(!isDropdownVisible)
                      }
                    >
                      <span className="text-[17px] not-italic font-normal leading-[120%] text-gray_1">
                        {countryCode}
                      </span>
                      <span className="ml-2">
                        <DropdownArrow />
                      </span>
                    </div>
                    <input
                      autoFocus
                      type="text"
                      className={` border-none focus:outline-none p-[14px] h-11 w-full rounded-lg `}
                      id="ec-mobile-number"
                      aria-describedby="emailHelp"
                      onKeyDown={(e) => {
                        if (
                          !/^\d$/.test(e.key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value.length === 0) {
                          setValidation(true);
                        }
                      }}
                      onChange={(e) => {
                        setPhNumber(e.target.value);
                        if (e.target.value === "") {
                          setValidation(true);
                        }
                      }}
                      placeholder="99999 99999"
                    />
                  </div>
                  {validation && phNumber.length === 0 && (
                    <div className="flex mt-2">
                      <span className="text-danger text-[17px] not-italic font-normal leading-[120%]">
                        Please enter a phone number
                      </span>
                    </div>
                  )}
                  <div
                    ref={countryCodeDropdownRef}
                    className="absalute top-0 w-full "
                    style={{ flex: 1 }}
                  >
                    <AnimatePresence>
                      {isDropdownVisible && (
                        <motion.ul
                          style={{
                            boxShadow:
                              "0px 8px 12px 0px rgba(164, 164, 164, 0.6)",
                          }}
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0 }}
                          transition={{ duration: 0.1 }}
                          className=" z-10 mt-1 w-[200px] absolute overflow-hidden text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border bg-white shadow-dropdown rounded-lg border-solid border-gray_4"
                          tabIndex={-1}
                          role="listbox"
                          aria-labelledby="listbox-label"
                          // aria-activedescendant="listbox-option-3"
                        >
                          <div className="sticky top-0 z-10 bg-white">
                            <li className=" text-gray-900 cursor-default select-none relative py-2 px-3">
                              <input
                                type="text"
                                name="search"
                                autoComplete={"off"}
                                className="!text-[16px] block w-full phone_number !focus-visible:outline-none !focus-visible:border-none sm:text-sm ![&:has(:focus-visible)]:border:none border-gray-300 rounded-md focus-visible:!
                                -none"
                                placeholder={"Search a country"}
                                onChange={(e) => setQuery(e.target.value)}
                              />
                            </li>

                            <hr />
                          </div>

                          <div className="p-2">
                            <div className="h-[172px] scrollbar overflow-y-scroll theme-scrollbar-sm">
                              {countries.filter((country) =>
                                country.name
                                  .toLowerCase()
                                  .startsWith(query.toLowerCase())
                              ).length === 0 ? (
                                <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                                  No countries found
                                </li>
                              ) : (
                                countries
                                  .filter((country) =>
                                    country.name
                                      .toLowerCase()
                                      .startsWith(query.toLowerCase())
                                  )
                                  .map((value, index) => {
                                    return (
                                      <li
                                        key={`-${index}`}
                                        className="text-gray-900 cursor-pointer select-none relative pt-1.5 pb-0.5 px-2 [&:not(:last-child)]:mb-2 flex items-center justify-between hover:bg-gray_2 hover:rounded-sm transition"
                                        id="listbox-option-0"
                                        // eslint-disable-next-line jsx-a11y/role-has-required-aria-props
                                        role="option"
                                        onClick={() => {
                                          setCountryCode(
                                            "+" + value.callingCodes[0]
                                          );
                                          setIsDropdownVisible(false);
                                        }}
                                      >
                                        <div className="flex items-center">
                                          <img
                                            alt={`${value.alpha2Code}`}
                                            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.alpha2Code}.svg`}
                                            className={
                                              "inline mr-2 h-4 rounded-sm"
                                            }
                                          />

                                          <span
                                            title={value.name}
                                            className="truncate text-gray_1 text-[17px] not-italic font-normal leading-[120%]"
                                          >
                                            {truncateString(value.name)}
                                          </span>
                                        </div>
                                        <p className="text-gray_1 text-[17px] not-italic font-normal leading-[120%]">
                                          {"+" + value.callingCodes[0]}
                                        </p>
                                      </li>
                                    );
                                  })
                              )}
                            </div>
                          </div>
                        </motion.ul>
                      )}
                    </AnimatePresence>
                  </div>
                </div>
              </div>
            </div>
            {isReferralSignUp && (
              <div className="referral-code-input">
                <label htmlFor="referralCode" className="font-label">
                  Referral Code (optional)
                </label>
                <CustomInput
                  containerStyle="mb-10 mt-2"
                  placeholder="Enter Code"
                  type="number"
                />
              </div>
            )}
            <CustomCheckbox className="mb-6" onChange={handleCheckboxChange}>
              <div className="text-gray_1 text-[17px] not-italic font-normal leading-[120%] select-none">
                By clicking on continue you agree to
                <span className="text-U25_orange mx-1 underline-offset-1 decoration-1 select-none text-[17px] not-italic font-normal leading-[120%] underline">
                  Under25’s Terms
                </span>
                and
                <span className="text-U25_orange ml-1 underline-offset-1 decoration-1 select-none text-[17px] not-italic font-normal leading-[120%] underline">
                  privacy policy
                </span>
                <span className="text-gray_1 underline-offset-1 decoration-1 select-none text-[17px] not-italic font-normal leading-[120%] underline">
                  .
                </span>
              </div>
            </CustomCheckbox>
            <Link to={`${checked ? "/verify-otp" : ""}`}>
              <PrimaryButton
                buttonType="submit"
                title="Continue"
                width="full"
              />
            </Link>
          </form>
        </div>
        <OnboardingCarousel />
      </div>
    </div>
  );
};

export default SignUp;
