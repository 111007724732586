import React from "react";
import { ArrowNarrowLeftSm } from "../../Icons/Icons";
import { tabsDataProps } from "../../../type/types";
import PrimaryTabs from "../../PrimaryTabs/PrimaryTabs";
import ImageAttachment from "./ImageAttachment";
import EmptyState from "../../../themes/EmptyState/EmptyState";

interface SpaceAttachmentsProps {
  setMediaView: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpaceAttachments: React.FC<SpaceAttachmentsProps> = ({
  setMediaView,
}) => {
  const tabsData: tabsDataProps[] = [
    {
      id: "1",
      label: "Image",
      children: <ImageAttachment />,
    },
    {
      id: "2",
      label: "Video",
      children: (
        <>
          <EmptyState
            title=" No media"
            desc="No media files yet."
            image="/images/svg/empty-media.svg"
          />
        </>
      ),
    },
    {
      id: "2",
      label: "Card",
      children: (
        <EmptyState
          title=" No media"
          desc="No media files yet."
          image="/images/svg/empty-media.svg"
        />
      ),
    },
    {
      id: "2",
      label: "Document",
      children: (
        <EmptyState
          title=" No media"
          desc="No media files yet."
          image="/images/svg/empty-media.svg"
        />
      ),
    },
  ];

  return (
    <div className="bg-white pl-4 h-[calc(100vh_-_104px)] theme-scrollbar overflow-y-auto">
      <div className="pl-4 pr-5 pt-5 pb-0">
        <button
          className="inline-flex items-center justify-center max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] rounded-md hover:bg-gray_20 transition-all duration-200 ease-[ease] cursor-pointer"
          onClick={() => {
            setMediaView(false);
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <ArrowNarrowLeftSm />
        </button>
      </div>
      <div className="pt-6 px-4">
        <PrimaryTabs tabsData={tabsData} innerClass="!mb-6" />
      </div>
    </div>
  );
};

export default SpaceAttachments;
