import React, { ReactNode } from "react";
import FeedHeader from "./Header/FeedHeader";
import FeedFooter from "./Footer/FeedFooter";

interface FeedLayoutProps {
  children: ReactNode;
  userAvatar: any;
  name: any;
  subName?: any;
  closeIcon?: boolean | ReactNode;
  handleClose?: () => void;
  containerClass?: string;
}

const FeedLayout: React.FC<FeedLayoutProps> = ({
  children,
  userAvatar,
  name,
  subName,
  closeIcon,
  handleClose,
  containerClass,
}) => {
  return (
    <div className={`layout bg-white p-4 rounded-xl ${containerClass}`}>
      <FeedHeader
        userAvatar={userAvatar}
        name={name}
        subName={subName}
        closeIcon={closeIcon}
        handleClose={handleClose}
      />
      <main>{children}</main>
      <FeedFooter />
    </div>
  );
};

export default FeedLayout;
