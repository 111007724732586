import React from "react";
import ReactDOM from "react-dom";
import AppRouter from "./AppRouter";
import "tailwindcss/tailwind.css";
import "../src/styles/fonts.css";
import "./styles/global.scss";
import { GlobalProvider } from "./context/GlobalContext";

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <AppRouter />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
