import React, { useEffect, useState } from "react";
import FeedLayout from "../../../Feeds/FeedLayout";
import StartDiscussionPanel from "../../../StartDiscussionPanel/StartDiscussionPanel";
import FeedUser from "../../../../assets/png/feedUser.png";
import Anonymous from "../../../../assets/png/anno.png";
import SnapUser from "../../../../assets/png/snap.png";
import PrimaryTabs from "../../../PrimaryTabs/PrimaryTabs";
import { tabsDataProps } from "../../../../type/types";
import Poll from "../../../Feeds/Poll/Poll";
import { Avatar } from "antd";
import FeedImageContent from "../../../../assets/png/feedImageContent.png";
import feedVideoContent from "../../../../assets/png/feedVideoContent.png";
import FeedSongAuthor from "../../../../assets/png/songAuthor.png";
import CommunityCarousel from "../CommunityCarousel/CommunityCarousel";
import SortButton from "../SortButton/SortButton";
import CustomCheckbox from "../../../CustomCheckbox/CustomCheckbox";
import Breath from "../../../../assets/audio/breath-126545.mp3";
import "./Main.scss";
import FeedData from "../../../../data/feedData.json";
import {
  CardDeckOutlined,
  SortByGlassesRd,
  SortByHashTag,
  SortByHorizontalBarChar,
  SortByMicrophone,
  SortByVolumeMin,
} from "../../../Icons/Icons";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import DetailView from "../../../Feeds/Modal/DetailView";
import Waveform from "../../../WaveForm/WaveForm";
import useIsMac from "../../../../hooks/useIsMac";
import InfiniteScroll from "react-infinite-scroll-component";

const Main = ({ setSelectedTab, selectedTab }: any) => {
  const [isFeedModalOpen, setIsFeedModalOpen] = useState(false);
  const [userType, setUserType] = useState("e");

  const [sortOpen, setSortOpen] = React.useState(false);
  const SortOnOpenChange = () => {
    setSortOpen((prev) => !prev);
  };
  const handleFeedModalOpen = () => {
    setIsFeedModalOpen(true);
  };

  useEffect(() => {
    if (selectedTab === "Outerspace") {
      setUserType("tl");
    } else {
      setUserType("e");
    }
  }, [selectedTab]);

  const [checked, setChecked] = useState<any>({
    check1: false,
    check2: false,
    check3: false,
    check4: false,
    check5: false,
    check6: false,
  });

  const homeTabs: tabsDataProps[] = [
    {
      id: "1",
      label: "Universe",
      children: <></>,
    },
    {
      id: "2",
      label: "Outerspace",
      children: <></>,
    },
    {
      id: "3",
      label: "For you",
      children: <></>,
    },
  ];

  const pollIconProps = {
    width: 16,
    height: 16,
    className: `[&_path]:stroke-primary mr-3 [&_path]:ease-[ease] [&_path]:duration-200 [&_path]:transition-all`,
  };

  const pollData = [
    {
      id: "check1",
      label: "Threads",
      icon: <SortByHashTag {...pollIconProps} />,
    },
    {
      id: "check2",
      label: "Poll",
      icon: <SortByHorizontalBarChar {...pollIconProps} />,
    },
    {
      id: "check3",
      label: "Podcards",
      icon: <SortByMicrophone {...pollIconProps} />,
    },
    {
      id: "check4",
      label: "Dictionary",
      icon: <SortByVolumeMin {...pollIconProps} />,
    },
    {
      id: "check5",
      label: "Confession",
      icon: <SortByGlassesRd {...pollIconProps} />,
    },
    {
      id: "check6",
      label: "Decks",
      icon: <CardDeckOutlined {...pollIconProps} />,
    },
  ];

  const checkboxHandler = (e: CheckboxChangeEvent) => {
    const { name } = e.target as { name: string };
    setChecked({
      ...checked,
      [name]: e.target.checked,
    });
  };

  const pollOptions = ["Hollywood", "Bollywood", "Mollywood", "Tollywood"];
  const isMac = useIsMac();

  const popoverCont = (
    <div
      style={{
        fontFamily: "OskariG2Regular",
      }}
      className="px-0 py-3"
    >
      <div className="mb-4">
        <p className="text-hashtag_text text-sm not-italic font-normal leading-[120%] px-2 py-0 mb-1">
          Category
        </p>
        <div
          onClick={() => setSortOpen(false)}
          className="pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4"
        >
          <button className="text-primary text-[17px] not-italic font-normal leading-[120%] w-full text-left">
            Trending
          </button>
        </div>
        <div
          onClick={() => setSortOpen(false)}
          className="pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4"
        >
          <button className="text-primary text-[17px] not-italic font-normal leading-[120%] w-full text-left">
            What’s New
          </button>
        </div>
      </div>
      <div>
        <p className="text-hashtag_text mb-1   text-sm not-italic font-normal leading-[120%] px-2 py-0">
          Type
        </p>
        {pollData.map((item) => (
          <CustomCheckbox
            key={item.id}
            name={item.id}
            onChange={checkboxHandler}
            className="sortpopover-checkbox flex flex-row-reverse justify-end [&>*:nth-child(2)]:!ml-0 [&>*:nth-child(2)]:!mr-auto py-1 px-2 hover:bg-gray_4 rounded-lg [&_span]:!self-center"
          >
            <div
              className={`flex items-center ${
                checked[item.id] ? "[&_svg_path]:stroke-U25_orange" : ""
              }`}
            >
              {item.icon}
              <p
                className={`text-primary text-[17px] not-italic font-normal leading-[120%] transition-all duration-200 select-none ${
                  checked[item.id] ? "!text-U25_orange" : ""
                }  ${isMac && "mt-1"}`}
              >
                {item.label}
              </p>
            </div>
          </CustomCheckbox>
        ))}
      </div>
    </div>
  );

  const [items, setItems] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = () => {
    const newData: any = FeedData.feedData;
    const itemsPerPage = 5;
    const startIndex = items.length;
    const endIndex = startIndex + itemsPerPage;

    setItems((prevItems) => [
      ...prevItems,
      ...newData.slice(startIndex, endIndex),
    ]);

    if (endIndex >= newData.length) {
      setHasMore(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        if (hasMore) {
          fetchMoreData();
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [hasMore]);

  return (
    <>
      <DetailView
        isModalOpen={isFeedModalOpen}
        handleCancel={() => setIsFeedModalOpen(false)}
        handleOk={handleFeedModalOpen}
      />
      <div>
        <StartDiscussionPanel outerClass="mb-6" />
        <div className="flex items-center mb-5">
          <div className="mr-4">
            <SortButton
              open={sortOpen}
              onOpenChange={SortOnOpenChange}
              trigger="click"
              popupTitle={
                <h2 className="text-dark_85 pt-5 pb-0 px-2 text-[17px] not-italic font-medium leading-[120%]">
                  Sort by
                </h2>
              }
              content={popoverCont}
              overlayInnerStyle={{
                padding: "0px 8px",
              }}
            />
          </div>
          <PrimaryTabs
            containerClass="!mb-0"
            innerClass="!my-2"
            tabsData={homeTabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <CommunityCarousel />

        <InfiniteScroll
          dataLength={items.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
        >
          <div className="mt-6 space-y-5 h-full">
            <FeedLayout userAvatar={FeedUser} name={`${userType}/anushaudupi`}>
              <div className="mt-4">
                <div className="flex items-center gap-4">
                  <Avatar
                    className=" rounded-xl h-[65px] w-[65px]"
                    src={FeedSongAuthor}
                  ></Avatar>
                  <h4 className="text-2xl not-italic font-medium leading-[100%] w-1/3">
                    weekend - After hours cover by me
                  </h4>
                </div>
                <div className=" mt-3 flex  w-full justify-between  ">
                  <div className="w-full rounded-[10px] py-[9px] ">
                    <Waveform url={Breath} type={"feed"} />
                  </div>
                </div>
              </div>
            </FeedLayout>
            {items.map((item: any, index: any) => {
              switch (item.content.type) {
                case "text":
                  return (
                    <FeedLayout
                      userAvatar={FeedUser}
                      name={`${userType}/anushaudupi`}
                    >
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        Are you a desi Munda or a hollywood geek?
                      </h2>
                      <Poll options={pollOptions} />
                    </FeedLayout>
                  );
                case "image":
                  return (
                    <FeedLayout
                      userAvatar={SnapUser}
                      name={"snachatopinionleader"}
                      subName={`${userType}/anushaudupi`}
                    >
                      <div
                        onClick={handleFeedModalOpen}
                        className="bg-cover bg-center cursor-pointer h-[394px] w-full  rounded-xl mt-4"
                        style={{ backgroundImage: `url(${FeedImageContent})` }}
                      ></div>
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        Are you a desi Munda or a hollywood geek?
                      </h2>
                    </FeedLayout>
                  );
                case "video":
                  return (
                    <FeedLayout
                      userAvatar={FeedUser}
                      name={`${userType}/anushaudupi`}
                    >
                      <div
                        className="bg-cover bg-center h-[394px] w-full  rounded-xl mt-4 relative"
                        style={{ backgroundImage: `url(${feedVideoContent})` }}
                      >
                        <span className="bg-white bg-opacity-25 p-1 right-[16px] top-[16px]  w-7 h-7 flex justify-center items-right absolute rounded-[7px]     ">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.85543 2.01245C11.3035 2.01245 12.4681 2.01245 13.4183 2.08138L10.7176 6.13247H7.03138L9.77806 2.01245H9.85543Z"
                              fill="white"
                            />
                            <path
                              d="M3.15707 3.16171C4.13988 2.1789 5.63494 2.03656 8.36098 2.01594L5.61663 6.13247H2.08957C2.20512 4.74807 2.48401 3.83477 3.15707 3.16171Z"
                              fill="white"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M2.00781 9.86007C2.00781 8.88952 2.00781 8.04629 2.02857 7.30961H17.6823C17.7031 8.04629 17.7031 8.88952 17.7031 9.86007C17.7031 13.5595 17.7031 15.4092 16.5538 16.5584C15.4045 17.7077 13.5548 17.7077 9.85543 17.7077C6.15603 17.7077 4.30633 17.7077 3.15707 16.5584C2.00781 15.4092 2.00781 13.5595 2.00781 9.86007ZM10.6512 10.3193C11.6902 10.9897 12.2097 11.3249 12.2097 11.822C12.2097 12.319 11.6902 12.6542 10.6512 13.3246C9.59793 14.0042 9.0713 14.344 8.6786 14.0945C8.28591 13.845 8.28591 13.1707 8.28591 11.822C8.28591 10.4733 8.28591 9.79893 8.6786 9.54945C9.0713 9.29997 9.59792 9.63975 10.6512 10.3193Z"
                              fill="white"
                            />
                            <path
                              d="M17.6213 6.13247C17.5057 4.74807 17.2269 3.83477 16.5538 3.16171C16.085 2.69295 15.4998 2.41539 14.72 2.25104L12.1323 6.13247H17.6213Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                      </div>
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        Are you a desi Munda or a hollywood geek?
                      </h2>
                    </FeedLayout>
                  );
                // eslint-disable-next-line no-duplicate-case
                case "text":
                  return (
                    <FeedLayout
                      userAvatar={FeedUser}
                      name={`${userType}/anushaudupi`}
                    >
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        Are you a desi Munda?
                      </h2>
                    </FeedLayout>
                  );

                case "Confession":
                  return (
                    <FeedLayout
                      userAvatar={FeedUser}
                      name={`${userType}/anushaudupi`}
                    >
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        Clown Munde
                      </h2>
                      <div>
                        <span className="flex mt-2 items-center  cursor-pointer">
                          <svg
                            width="19"
                            height="20"
                            viewBox="0 0 19 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.57266 9.75599C1.60196 8.31028 1.61661 7.58743 2.09683 6.96863C2.18448 6.85568 2.31309 6.72148 2.42088 6.63048C3.01144 6.1319 3.79115 6.1319 5.35056 6.1319C5.90769 6.1319 6.18626 6.1319 6.45181 6.059C6.50699 6.04386 6.56158 6.02639 6.61547 6.00665C6.8748 5.91165 7.10739 5.74812 7.57257 5.42107C9.40787 4.13074 10.3255 3.48557 11.0957 3.76584C11.2434 3.81958 11.3863 3.89714 11.514 3.99281C12.1799 4.49178 12.2304 5.65268 12.3316 7.97449C12.3691 8.8342 12.3946 9.57 12.3946 9.97927C12.3946 10.3885 12.3691 11.1243 12.3316 11.984C12.2304 14.3059 12.1799 15.4668 11.514 15.9657C11.3863 16.0614 11.2434 16.139 11.0957 16.1927C10.3255 16.473 9.40787 15.8278 7.57257 14.5375C7.10739 14.2104 6.8748 14.0469 6.61547 13.9519C6.56158 13.9321 6.50699 13.9147 6.45181 13.8995C6.18626 13.8266 5.9077 13.8266 5.35056 13.8266C3.79115 13.8266 3.01144 13.8266 2.42088 13.3281C2.31309 13.2371 2.18448 13.1029 2.09683 12.9899C1.61661 12.3711 1.60196 11.6483 1.57266 10.2025C1.57112 10.1266 1.57031 10.0521 1.57031 9.97927C1.57031 9.90645 1.57112 9.83192 1.57266 9.75599Z"
                              fill="#666666"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M15.2954 4.91927C15.5251 4.71523 15.8663 4.74831 16.0577 4.99316L15.6419 5.36262C16.0577 4.99316 16.0575 4.9929 16.0577 4.99316L16.0585 4.99422L16.0594 4.99541L16.0616 4.99819L16.067 5.00532C16.0711 5.01078 16.0762 5.01766 16.0822 5.02596C16.0943 5.04256 16.11 5.06487 16.1288 5.09301C16.1665 5.14928 16.2167 5.22885 16.2751 5.33261C16.3919 5.54022 16.5412 5.84429 16.6883 6.25195C16.9828 7.06864 17.2655 8.2931 17.2655 9.97945C17.2655 11.6658 16.9828 12.8903 16.6883 13.707C16.5412 14.1146 16.3919 14.4187 16.2751 14.6263C16.2167 14.7301 16.1665 14.8096 16.1288 14.8659C16.11 14.894 16.0943 14.9163 16.0822 14.9329C16.0762 14.9412 16.0711 14.9481 16.067 14.9536L16.0616 14.9607L16.0594 14.9635L16.0585 14.9647C16.0583 14.9649 16.0577 14.9657 15.6419 14.5963L16.0577 14.9657C15.8663 15.2106 15.5251 15.2437 15.2954 15.0396C15.0669 14.8366 15.0351 14.4752 15.2234 14.2304C15.2238 14.2298 15.2247 14.2286 15.2259 14.2269C15.2297 14.2217 15.2373 14.2111 15.248 14.195C15.2695 14.1629 15.3039 14.109 15.3469 14.0325C15.4331 13.8794 15.5544 13.6352 15.678 13.2926C15.9246 12.6088 16.1831 11.5249 16.1831 9.97945C16.1831 8.43402 15.9246 7.35006 15.678 6.66628C15.5544 6.3237 15.4331 6.07952 15.3469 5.92644C15.3039 5.84986 15.2695 5.79597 15.248 5.76388C15.2373 5.74783 15.2297 5.73723 15.2259 5.73196C15.2247 5.73026 15.2238 5.72912 15.2234 5.72853C15.2234 5.72853 15.2234 5.72853 15.2234 5.72853"
                              fill="#1F1F1F"
                            />
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M13.9359 7.16664C14.1972 7.01186 14.5267 7.11224 14.6719 7.39086L14.1988 7.67112C14.6719 7.39086 14.6717 7.39061 14.6719 7.39086L14.6724 7.39187L14.6729 7.39294L14.6741 7.39528L14.6769 7.40076L14.6839 7.41488C14.6891 7.42571 14.6955 7.43937 14.7029 7.45592C14.7177 7.48903 14.7364 7.53366 14.7576 7.59025C14.8001 7.70349 14.8525 7.86426 14.9034 8.07603C15.0054 8.49997 15.1008 9.12534 15.1008 9.97954C15.1008 10.8337 15.0054 11.4591 14.9034 11.883C14.8525 12.0948 14.8001 12.2556 14.7576 12.3688C14.7364 12.4254 14.7177 12.4701 14.7029 12.5032C14.6955 12.5197 14.6891 12.5334 14.6839 12.5442L14.6769 12.5583L14.6741 12.5638L14.6729 12.5661L14.6724 12.5672C14.6723 12.5675 14.6719 12.5682 14.1988 12.288L14.6719 12.5682C14.5267 12.8468 14.1972 12.9472 13.9359 12.7924C13.6768 12.639 13.5821 12.2923 13.722 12.0148L13.7256 12.0069C13.7306 11.9959 13.74 11.9738 13.7526 11.9402C13.7778 11.8731 13.8156 11.7597 13.8549 11.5964C13.9334 11.2701 14.0183 10.7412 14.0183 9.97954C14.0183 9.21785 13.9334 8.68901 13.8549 8.36271C13.8156 8.19937 13.7778 8.08602 13.7526 8.01891C13.74 7.98533 13.7306 7.96323 13.7256 7.95215L13.722 7.94428C13.5821 7.66682 13.6768 7.32012 13.9359 7.16664Z"
                              fill="#1F1F1F"
                            />
                          </svg>
                          <p className="text-[17px] cursor-pointer text-[#666666] ml-[10px] not-italic font-normal leading-[120%]">
                            / “Am i a joke to you?” /
                          </p>
                        </span>
                        <p className="text-[17px] not-italic font-normal leading-[120%] mt-3 items-center  ">
                          Lorem ipsum dolor sit amet consectetur. Facilisis sed
                          id dolor purus turpis pulvinar.
                        </p>
                      </div>
                    </FeedLayout>
                  );
                case "quote":
                  return (
                    <FeedLayout
                      userAvatar={Anonymous}
                      name={`${userType} /anonymous`}
                    >
                      <h2 className="text-2xl not-italic font-medium leading-[100%] text-primary mt-4">
                        I hate brocolli
                      </h2>
                    </FeedLayout>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default Main;
