import { useEffect, useState } from "react";
import {
  DocumentOutlined,
  DownloadLined,
  EditOutlined,
} from "../../Icons/Icons";
import AddItemComp from "./AddItemComp";
import AddResumeModal from "./AddResumeModal";
import "../user_about.scss";
import useIsMac from "../../../hooks/useIsMac";
interface ResumeProps {
  edit?: true | false;
}
const Resume: React.FC<ResumeProps> = ({ edit = false }) => {
  const pdfData = [
    {
      key: 1,
      name: "Carsol Dsliva cv.pdf",
    },
  ];
  const [view, setView] = useState(false);
  const [popover, setPopover] = useState(false);
  const [title, setTitle] = useState("Add Resume");

  useEffect(() => {
    if (!edit) {
      setView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMac = useIsMac();

  return (
    <div className="edit-wrapper">
      <div className="flex justify-between mb-4">
        <h6 className="text-[#1F1F1F] text-[17px] font-medium leading-[120%] ">
          Resume
        </h6>
        {edit && view ? (
          <span
            onClick={() => {
              setTitle("Edit Resume");
              setPopover(true);
            }}
            className=" cursor-pointer flex justify-center items-center edit-icon "
          >
            <EditOutlined />
          </span>
        ) : null}
      </div>
      <>
        {view &&
          pdfData.map((pdf) => {
            return (
              <div
                key={pdf.key}
                className="flex cursor-pointer justify-between items-center border border-[#DBD9D9] px-4 py-3 rounded-[8px] file-wrapper"
              >
                <div className="flex gap-2 items-center">
                  <span className="max-w-[20px] min-w-[20px] max-h-[20px] min-h-[20px] flex justify-center items-center">
                    <DocumentOutlined className={`${isMac && "mb-[4px]"}`} />
                  </span>
                  <h6 className="text-[#1F1F1F] text-[15px] font-normal leading-[120%]">
                    {pdf.name}
                  </h6>
                </div>
                {!edit && (
                  <span className="download-icon">
                    <DownloadLined />
                  </span>
                )}
              </div>
            );
          })}
      </>
      {!view && (
        <AddItemComp
          onBtnClick={() => setPopover(true)}
          title="Upload Resume"
          desc="Add your resume to highlight in feed"
        />
      )}
      <AddResumeModal
        onBtnClick={() => {
          setView(true);
        }}
        title={title}
        popover={popover}
        setPopover={setPopover}
      />
    </div>
  );
};

export default Resume;
