import React from "react";
import { Avatar } from "antd";
import Emoji from "../Emoji/Emoji";
import { UserReactionData } from "../../../type/types";

interface UserReactionProps {
  data: UserReactionData[];
}

const UserReaction: React.FC<UserReactionProps> = ({ data }) => {
  return (
    <div className="max-h-[344px] overflow-y-auto theme-scrollbar-sm pr-4">
      {data.map((item) => (
        <div
          key={item.id}
          className="flex items-center justify-between [&:not(:last-child)]:mb-4"
        >
          <div className="flex items-center gap-3">
            <Avatar
              src={item.picture}
              size={40}
              shape="square"
              alt="user"
              className="rounded-md max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
            />
            <div>
              <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-1">
                {item.fullName}
              </p>
              <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                {item.userName}
              </p>
            </div>
          </div>
          <Emoji symbol={item.emoji} label={item.emoji} size={16} />
        </div>
      ))}
    </div>
  );
};

export default UserReaction;
