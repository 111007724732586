import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import profilePic from "../assets/png/community-user-picture.png";
import useIsMac from "../hooks/useIsMac";

import { EditOutlined, FeedKababIcon } from "../components/Icons/Icons";
import FeedComp from "../components/Profile/Main/components/FeedComp/FeedComp";
import CreateDeck from "../components/Feeds/Modal/CreateDeck";
import { Popover } from "antd";

const DeckDetails = () => {
  const isMac = useIsMac();
  const [deckName, setDeckName] = useState("Fashion");
  const [isCardDeckModalOpen, setIsCardDeckModalOpen] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);

  const dataItems = (
    <>
      <div>
        <button
          onClick={() => setIsPopOverOpen(false)}
          className="flex items-center gap-3 w-full  pt-1.5 pb-1.5 px-2 rounded-lg hover:!bg-gray_4"
        >
          <h6 className="text-primary text-[17px] not-italic font-normal leading-[120%]">
            Delete deck
          </h6>
        </button>
      </div>
    </>
  );

  return (
    <Layout>
      <CreateDeck
        deckName={deckName}
        setDeckName={setDeckName}
        isModalOpen={isCardDeckModalOpen}
        isEdit={true}
        handleOk={() => setIsCardDeckModalOpen(false)}
        handleCancel={() => setIsCardDeckModalOpen(false)}
        handleOkaySaveToDeck={() => setIsCardDeckModalOpen(false)}
        handleCancelSaveToDeck={() => setIsCardDeckModalOpen(false)}
      />
      <div className="bg-gray_4 p-6 !w-full items-start justify-center flex h-fit">
        <div className="flex flex-col">
          <div className="w-full bg-white py-5 px-4 rounded-[12px] flex gap-4">
            <span className="lg:min-w-[100px] lg:max-w-[100px] lg:min-h-[100px] lg:max-h-[100px] flex justify-center items-center ">
              <img src={profilePic} alt="user" className="rounded-[12px]" />
            </span>
            <div className="flex justify-between items-center w-full">
              <div className="flex gap-1 mt-1 flex-wrap flex-col justify-center items-center">
                <div className="flex gap-1 items-center">
                  <h6 className="text-[#1F1F1F] text-2xl font-medium leading-[100%]">
                    {deckName}
                  </h6>
                </div>
                <div className="flex gap-1 items-center">
                  <h4 className="text-[#001EFF] text-[15px] font-medium leading-[120%]">
                    30
                  </h4>
                  <h5 className="text-[#666] text-[15px] font-normal leading-[120%]">
                    Cards
                  </h5>
                </div>
              </div>
            </div>
            <div className="flex items-start   justify-start">
              <button
                onClick={() => {
                  setIsCardDeckModalOpen(true);
                }}
                className="flex justify-center items-center  w-[35px] h-[35px] hover:bg-[#F4F4F4] transition-colors rounded-[8px]"
              >
                <EditOutlined />
              </button>
              <Popover
                placement="bottomLeft"
                arrow={false}
                content={dataItems}
                trigger={["click"]}
                open={isPopOverOpen}
                onOpenChange={() => {
                  setIsPopOverOpen((prev) => !prev);
                }}
                overlayClassName="header-dropdown pt-2 pb-4 px-2 min-w-[170px]"
              >
                <button
                  onClick={(e) => e.preventDefault()}
                  className="flex justify-center items-center  w-[35px] h-[35px] hover:bg-[#F4F4F4] transition-colors rounded-[8px]"
                >
                  <FeedKababIcon />
                </button>
              </Popover>
            </div>
          </div>
          <div className="mt-5">
            <FeedComp FeedUser={profilePic} userId={"a/kani"} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeckDetails;
