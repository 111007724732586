// Community.tsx
import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import {
  InviteStep1,
  InviteStep3,
  InviteStep2,
  ReferBg,
  LockIcon,
  CloseDarkOutlined,
} from "../components/Icons/Icons";
import { Link } from "react-router-dom";
import { Steps } from "antd";
import User1 from "../assets/png/avt-grp-2.png";
import User2 from "../assets/png/avt-grp-2.png";
import InviteBox from "../components/Invite/InviteBox";
import CustomModal from "../components/CustomModal/CustomModal";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import useIsMac from "../hooks/useIsMac";

const Refer: React.FC = () => {
  const [isInvited, setIsInvited] = useState(true);
  const [isKycModalOpen, setIsKycModalOpen] = useState(false);
  const [isRewardActive, setIsRewardActive] = useState(false);

  const [current, setCurrent] = useState(0);

  const birthdayData = [
    {
      id: "1",
      image: User1,
      fullName: "Akshada’s",
      description: "e/akshada",
    },
    {
      id: "2",
      image: User2,
      fullName: "Brandie",
      description: "e/Brandie",
    },
    {
      id: "3",
      image: User1,
      fullName: "Colleen",
      description: "e/Colleen",
    },
  ];

  const steps = [
    {
      title: "First",
    },
    {
      title: "Second",
    },
    {
      title: "Last",
    },
    {
      title: "Last",
    },
    {
      title: "Last",
    },
  ];

  const items = steps.map((item, index) => ({
    key: item.title,
    icon: (
      <>
        {current < index ? (
          <span className=" p-2 text-[17px]  w-[40px] h-[35px] bg-disabled text-white rounded-xl">
            ₹10
          </span>
        ) : (
          <span className=" p-2 text-[17px]  w-[40px] h-[35px] bg-blue_1 text-white rounded-xl">
            ₹10
          </span>
        )}
      </>
    ),
  }));

  const kycModalFooter = (
    <div className="flex justify-end items-center gap-4 rounded-[0_0_12px_12px] bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <PrimaryButton
        onClick={() => {
          setIsKycModalOpen(false);
          setIsRewardActive(true);
        }}
        title="Initiate on U25 App"
        width="fit-content"
      />
    </div>
  );

  const isMac = useIsMac();

  return (
    <Layout>
      <div className="h-fit ">
        <div className="flex justify-between refer_res1155:flex-col bg-gray_4 h-fit py-6 px-8  ">
          <div className="mr-[30px] refer_res1155:mr-0">
            <div className="bg-white max-w-full rounded-xl flex-1">
              <div className="flex p-6">
                <Link to={"/community"} className=" mr-3">
                  <span className="mr-3 cursor-pointer">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 12H5M5 12L12 19M5 12L12 5"
                        stroke="#101828"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </Link>
                <h3 className="text-2xl not-italic font-medium leading-[100%]">
                  Refer & Earn
                </h3>
              </div>
              <div className="pl-[72px] pr-6 py-10 tablet_md:flex-col flex">
                <div className="">
                  <div className="space-y-6">
                    <h3 className=" text-[32px] not-italic font-medium leading-[100%]">
                      Invite 5 friends and earn ₹50 when they get onboarded.
                    </h3>
                    <div className="flex rounded-[10px] border-dashed border-2 items-center bg-gray_3 border-gray_2 w-fit pl-[31px] pt-[15px] pb-[12px] p-8">
                      <div className="pl-4 pr-0 pt-0 pb-1 mr-[58px]   ">
                        <span className="text-base not-italic font-normal leading-[normal] text-gray_6">
                          Your referral code
                        </span>
                        <h3 className="text-[34px] not-italic font-medium leading-[100%]">
                          “20492”
                        </h3>
                      </div>
                      <span>
                        <svg
                          width="1"
                          height="35"
                          viewBox="0 0 1 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <line
                            x1="0.5"
                            y1="0.521484"
                            x2="0.500001"
                            y2="34.5215"
                            stroke="#DBD9D9"
                          />
                        </svg>
                      </span>
                      <div className="ml-4">
                        <button
                          onClick={() => {
                            if (current < 4 && !isInvited) {
                              setIsInvited(false);
                              setCurrent(current + 1);
                            } else {
                              setCurrent(current);
                              setIsInvited(false);
                            }
                          }}
                          className="text-[17px] cursor-pointer text-U25_orange not-italic font-normal leading-[120%] underline"
                        >
                          Copy Code
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className="pt-4">Share your referral code</p>
                  <div className="flex mt-[10px]">
                    <span
                      onClick={() => {
                        if (current < 4 && !isInvited) {
                          setIsInvited(false);
                          setCurrent(current + 1);
                        } else {
                          setCurrent(current);
                          setIsInvited(false);
                        }
                      }}
                      className="w-11 cursor-pointer h-11 border border-gray_4 flex rounded-[14px] mr-3 justify-center items-center"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_508_24252)">
                          <path
                            d="M17.0854 2.91005C16.164 1.98398 15.0665 1.24972 13.8569 0.750108C12.6473 0.250493 11.3499 -0.0044801 10.0402 5.95695e-05C4.55276 5.95695e-05 0.0804022 4.45005 0.0804022 9.91003C0.0804022 11.66 0.542714 13.36 1.40704 14.86L0 20L5.27638 18.62C6.73367 19.41 8.37186 19.83 10.0402 19.83C15.5276 19.83 20 15.38 20 9.92003C20 7.27004 18.9648 4.78005 17.0854 2.91005ZM10.0402 18.15C8.55276 18.15 7.09548 17.75 5.81909 17L5.51759 16.82L2.38191 17.64L3.21608 14.6L3.01508 14.29C2.18869 12.977 1.74989 11.4593 1.74874 9.91003C1.74874 5.37004 5.46734 1.67005 10.0301 1.67005C12.2412 1.67005 14.3216 2.53005 15.8794 4.09005C16.6507 4.85401 17.262 5.7627 17.6778 6.76346C18.0936 7.76422 18.3056 8.83714 18.3015 9.92003C18.3216 14.46 14.603 18.15 10.0402 18.15ZM14.5829 11.99C14.3317 11.87 13.1055 11.27 12.8844 11.18C12.6533 11.1 12.4925 11.06 12.3216 11.3C12.1508 11.55 11.6784 12.11 11.5377 12.27C11.397 12.44 11.2462 12.46 10.995 12.33C10.7437 12.21 9.9397 11.94 8.99497 11.1C8.25126 10.44 7.75879 9.63003 7.60804 9.38003C7.46734 9.13003 7.58794 9.00003 7.71859 8.87003C7.82914 8.76003 7.96985 8.58003 8.09045 8.44003C8.21105 8.30003 8.26131 8.19003 8.34171 8.03004C8.42211 7.86004 8.38191 7.72004 8.32161 7.60004C8.26131 7.48004 7.75879 6.26004 7.55779 5.76004C7.35678 5.28004 7.14573 5.34004 6.99497 5.33004H6.51256C6.34171 5.33004 6.0804 5.39004 5.84925 5.64004C5.62814 5.89004 4.98492 6.49004 4.98492 7.71004C4.98492 8.93003 5.8794 10.11 6 10.27C6.1206 10.44 7.75879 12.94 10.2513 14.01C10.8442 14.27 11.3065 14.42 11.6683 14.53C12.2613 14.72 12.804 14.69 13.2362 14.63C13.7186 14.56 14.7136 14.03 14.9146 13.45C15.1256 12.87 15.1256 12.38 15.0553 12.27C14.9849 12.16 14.8342 12.11 14.5829 11.99Z"
                            fill="#1F1F1F"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_508_24252">
                            <rect width="20" height="20" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                    <span
                      onClick={() => {
                        if (current < 4 && !isInvited) {
                          setIsInvited(false);
                          setCurrent(current + 1);
                        } else {
                          setCurrent(current);
                          setIsInvited(false);
                        }
                      }}
                      className="w-11 cursor-pointer h-11 border border-gray_4 flex rounded-[14px] justify-center items-center"
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17.5 7.50001L17.5 2.50001M17.5 2.50001H12.5M17.5 2.50001L10 10M8.33333 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V11.6667"
                          stroke="#1F1F1F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                <div className=" flex tablet_md:justify-center">
                  <ReferBg />
                </div>
              </div>
            </div>
            <div className="p-6 flex flex-col bg-white mt-6 rounded-xl">
              {isInvited ? (
                <>
                  <h3 className="text-[17px] not-italic font-normal leading-[120%] uppercase text-gray_6">
                    How it works
                  </h3>
                  <h2 className="mt-3 text-2xl not-italic font-medium leading-[100%]">
                    It’s super easy to refer a friend. Give it a go!
                  </h2>
                  <div className="flex  gap-[31px]  tablet_md:flex-col tablet_md:justify-between tablet_md:items-center">
                    <div className="max-w-[313px] mt-10 flex justify-center items-center flex-col">
                      <span>
                        <InviteStep1 />
                      </span>
                      <span className="mt-6 text-gray_1">
                        Invite 5 friends using your referral code
                      </span>
                    </div>
                    <div className="max-w-[313px] mt-10 flex justify-center items-center flex-col">
                      <span>
                        <InviteStep2 />
                      </span>
                      <span className="mt-6 flex text-gray_1">
                        you get
                        <p className="mx-1 font-medium text-primary">₹50</p>
                        when they get onboarded
                      </span>
                    </div>
                    <div className="max-w-[313px] mt-8 flex justify-center items-center flex-col">
                      <span>
                        <InviteStep3 />
                      </span>
                      <span className="mt-6 flex text-gray_1 text-[17px]">
                        each of your friend also earns
                        <p className="mx-1 font-medium text-primary"> ₹10</p>
                      </span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className=" flex gap-[30px]">
                    <div className=" flex">
                      <span className=" w-[50px] h-[50px] bg-[#2FB08B] flex justify-center items-center rounded-[50%]">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 3.9043H18M6 8.9043H18M14.5 21.9043L6 13.9043H9C15.667 13.9043 15.667 3.9043 9 3.9043"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3 capitalize ">
                        <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                          total money earned
                        </p>
                        <h6 className="text-[20px] font-medium">
                          ₹{(current + 1) * 10}
                        </h6>
                      </div>
                    </div>
                    <span>
                      <svg
                        width="1"
                        height="38"
                        viewBox="0 0 1 38"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <line
                          x1="0.5"
                          y1="-2.18557e-08"
                          x2="0.500002"
                          y2="38"
                          stroke="#DBD9D9"
                        />
                      </svg>
                    </span>
                    <div className=" flex">
                      <span className=" w-[50px] h-[50px] bg-[#E7637D] flex justify-center items-center rounded-[50%]">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22 21.9043V19.9043C22 18.0405 20.7252 16.4744 19 16.0303M15.5 4.19506C16.9659 4.78844 18 6.22561 18 7.9043C18 9.58299 16.9659 11.0202 15.5 11.6135M17 21.9043C17 20.0405 17 19.1086 16.6955 18.3736C16.2895 17.3935 15.5108 16.6148 14.5307 16.2088C13.7956 15.9043 12.8638 15.9043 11 15.9043H8C6.13623 15.9043 5.20435 15.9043 4.46927 16.2088C3.48915 16.6148 2.71046 17.3935 2.30448 18.3736C2 19.1086 2 20.0405 2 21.9043M13.5 7.9043C13.5 10.1134 11.7091 11.9043 9.5 11.9043C7.29086 11.9043 5.5 10.1134 5.5 7.9043C5.5 5.69516 7.29086 3.9043 9.5 3.9043C11.7091 3.9043 13.5 5.69516 13.5 7.9043Z"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                      <div className="ml-3 capitalize ">
                        <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                          total friends invited
                        </p>
                        <h6 className="text-[20px] font-medium">
                          ₹{current + 1}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="p-4 border border-gray_2 mt-8 rounded-[10px]">
                    <h3 className="text-xl not-italic font-medium leading-[100%]">
                      {current + 1} Invite sent
                    </h3>
                    <p className="text-[15px] text-input_border mt-3 not-italic font-normal leading-[120%]">
                      {current < 4 && `Claim by inviting ${4 - current} more`}
                    </p>
                    <div className="mt-6 invite">
                      <Steps current={current} items={items} />
                    </div>
                  </div>
                  <div className="mt-5 w-full justify-end flex">
                    <button
                      onClick={() =>
                        current === 4 &&
                        !isRewardActive &&
                        setIsKycModalOpen(true)
                      }
                      className={`text-white text-[15px] flex not-italic font-[400] leading-[normal] px-8 py-4 rounded-lg  ${
                        current === 4
                          ? "bg-primary"
                          : " bg-disabled cursor-not-allowed   "
                      }`}
                    >
                      {isRewardActive ? (
                        "Claim ₹50"
                      ) : current === 4 ? (
                        "Complete KYC to claim ₹50"
                      ) : (
                        <>
                          <p className={`${isMac && "pt-1"}`}>
                            Invite 5 to Claim
                          </p>
                          <LockIcon className="ml-2" />
                        </>
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={`bg-white w-fit px-4 py-5 rounded-xl min-w-[300px] max-w-[300px] refer_res1155:min-w-full refer_res1155:mt-6 ${
              isInvited
                ? " min-h-[242px] max-h-[242px] justify-center "
                : " h-fit"
            } `}
          >
            <h4 className="text-xl not-italic font-medium leading-[100%] ">
              {!isInvited ? "Invited by you" : "Invites"}
            </h4>

            <div
              className={`flex w-full items-center ${
                !isInvited ? "h-fit" : "h-full"
              } `}
            >
              {!isInvited ? (
                <>
                  <div className="mt-4 w-full">
                    <InviteBox data={birthdayData} />
                  </div>
                </>
              ) : (
                <div className="mt-4 w-full h-full flex justify-center items-center">
                  <p className="text-link_hover">No Invites </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <CustomModal
            className="house-modal"
            title={
              <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
                Complete KYC
              </h3>
            }
            closeIcon={<CloseDarkOutlined />}
            width={615}
            isModalOpen={isKycModalOpen}
            handleOk={() => {
              setIsKycModalOpen(false);
              setIsRewardActive(true);
            }}
            handleCancel={() => setIsKycModalOpen(false)}
            footer={kycModalFooter}
          >
            <div className="mt-4  mb-3 py-4 px-6  text-center flex justify-center items-center flex-col">
              <h6 className="text-xl not-italic font-medium leading-[100%]">
                Continue on the Under25 App
              </h6>
              <p className="w-4/6 text-center text-gray_6 mt-2">
                To complete the process and claim your rewards, please continue
                on our mobile app.
              </p>
            </div>
          </CustomModal>
        </div>
      </div>
    </Layout>
  );
};

export default Refer;
