import { Avatar } from "antd";
import React, { useState } from "react";
import {
  SlashCircled,
  ThreadArrowRight,
  VideoPlay,
} from "../../../Icons/Icons";
import ChatReaction from "../ChatReaction/ChatReaction";
import Emoji from "../../Emoji/Emoji";
import Reactions from "../../../Feeds/Modal/Reactions";
import ForwardTo from "../../../Feeds/Modal/ForwardTo";
import { useGlobal } from "../../../../context/GlobalContext";
import MediaPreviewModal from "../../MediaPreviewModal/MediaPreviewModal";
import { SpaceType, UserType } from "../../../../type/types";
import img from "../../../../assets/png/sender-picture.png";
import DeleteMessageModal from "../../DeleteMessageModal/DeleteMessageModal";
import useIsMac from "../../../../hooks/useIsMac";

interface ReceiverProps {
  userName: string;
  message?: string;
  time: string;
  deleted?: boolean;
  picture: string;
  setThreadActive?: React.Dispatch<React.SetStateAction<boolean>>;
  thread?: boolean;
  media?: string;
  sharedProfileData?: UserType | boolean;
  sharedSpaceData?: SpaceType | boolean;
  link?: string;
}

const Receiver: React.FC<ReceiverProps> = ({
  userName = "",
  message = "",
  time = "",
  deleted = false,
  picture = "",
  thread = false,
  media,
  sharedProfileData,
  sharedSpaceData,
  link,
}) => {
  const { threadActive, setThreadActive } = useGlobal();
  const [chatReaction, setChatReaction] = useState(false);
  const [openMediaPreviewModal, setOpenMediaPreviewModal] = useState(false);
  const [reactedEmoji, setReactedEmoji] = useState<string[]>([]);
  const [sortEmoji, setSortEmoji] = useState<string[]>(reactedEmoji);
  const [openReactionModal, setOpenReactionModal] = useState(false);
  const [openForwardTo, setOpenForwardTo] = useState(false);
  const lastIndexOfExtension =
    message?.lastIndexOf(".png") || message?.lastIndexOf(".jpg");
  const isVideo = media?.endsWith(".mp4");
  const isImage = media?.endsWith(".jpg") || media?.endsWith(".png");
  const isLink = link?.startsWith("https://");
  const [openDeleteMsgModal, setOpenDeleteMsgModal] = useState(false);
  const isMac = useIsMac();

  const handleOpenReactionModal = () => {
    setOpenReactionModal(true);
  };

  const handleCancelReactionModal = () => {
    setOpenReactionModal(false);
  };

  const handleOpenForwardToModal = () => {
    setOpenForwardTo(true);
  };

  const handleCancelForwardToModal = () => {
    setOpenForwardTo(false);
  };

  const handleOkayMediaPreviewModal = () => {
    setOpenMediaPreviewModal(true);
  };

  const handleCancelMediaPreviewModal = () => {
    setOpenMediaPreviewModal(false);
  };

  const handleOkayDeleteMsgModal = () => {
    setOpenDeleteMsgModal(true);
  };

  const handleCancelDeleteMsgModal = () => {
    setOpenDeleteMsgModal(false);
  };

  return (
    <>
      <div className="mx-8 mt-6 mb-2">
        <div className="flex items-center gap-3">
          <Avatar
            src={picture}
            size={28}
            shape="square"
            alt="user"
            className="rounded-md min-w-[28px] max-w-[28px] min-h-[28px] max-h-[28px]"
          />
          <div>
            <div
              onMouseEnter={() => setChatReaction(true)}
              onMouseLeave={() => setChatReaction(false)}
              className="relative bg-gray_3 rounded-[8px_8px_8px_0px] p-3 mb-2"
            >
              {chatReaction && (
                <ChatReaction
                  setOpenDeleteMsgModal={setOpenDeleteMsgModal}
                  setChatReaction={setChatReaction}
                  setReactedEmoji={setReactedEmoji}
                  setOpenForwardTo={setOpenForwardTo}
                  emojiList={(lists) =>
                    setSortEmoji((prev) =>
                      [...prev, lists].filter((item) => !prev.includes(item))
                    )
                  }
                />
              )}

              <span className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-1 me-1">
                {userName}
              </span>
              {typeof sharedProfileData === "object" && (
                <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                  shared profile
                </span>
              )}
              {typeof sharedSpaceData === "object" && (
                <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                  shared space
                </span>
              )}

              {!deleted && isVideo && !isImage ? (
                <div className="max-w-[265px] w-full h-auto cursor-pointer">
                  <div className="relative">
                    <video src={media} className="rounded-lg mb-2" />
                    <button
                      onClick={handleOkayMediaPreviewModal}
                      className="flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 cursor-pointer border bg-[rgba(68,68,68,0.40)] rounded-3xl border-solid border-[rgba(242,242,242,0.04)]"
                    >
                      <VideoPlay />
                    </button>
                  </div>
                  <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                    {message}
                  </p>
                </div>
              ) : !deleted &&
                lastIndexOfExtension === -1 &&
                !isImage &&
                !isVideo &&
                typeof sharedProfileData !== "object" &&
                typeof sharedSpaceData !== "object" &&
                !isLink ? (
                <>
                  <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                    {message}
                  </p>
                  {thread && (
                    <div
                      onClick={() => setThreadActive(!threadActive)}
                      className="flex justify-between items-center"
                    >
                      <div className="px-3 py-2.5 flex cursor-pointer">
                        <span>1</span>
                        <p className="ml-1 text-input_border">Thread Reply</p>
                      </div>
                      <span className="flex justify-center items-center cursor-pointer min-w-3 max-w-3 zmin-h-3 max-h-3">
                        <ThreadArrowRight />
                      </span>
                    </div>
                  )}
                </>
              ) : !deleted && isImage && !isVideo ? (
                <div>
                  <div
                    onClick={handleOkayMediaPreviewModal}
                    className="cursor-pointer"
                  >
                    <img
                      className="max-w-[253px] min-w-[253px] max-h-[253px] min-h-[253px] rounded-lg mb-2"
                      src={img}
                      alt="receiver chat"
                    />
                  </div>
                  <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                    {message}
                  </p>
                </div>
              ) : !deleted &&
                lastIndexOfExtension === -1 &&
                !isImage &&
                !isVideo &&
                typeof sharedProfileData === "object" ? (
                <>
                  <div className="flex items-center gap-2 py-3 border-y border-[#F2F2F2]">
                    <Avatar
                      src={sharedProfileData.profileImage}
                      size={48}
                      shape="square"
                      alt="user"
                      className="rounded-md min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px]"
                    />
                    <div>
                      <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-[6px]">
                        {sharedProfileData.fullName}
                      </p>
                      <p className="text-gray_6 text-sm not-italic font-normal leading-[120%]">
                        {sharedProfileData.userName}
                      </p>
                    </div>
                  </div>
                  <button className="pt-2.5 pb-0 px-3 w-full">
                    <span className="text-blue_1 text-sm not-italic font-normal leading-[120%]">
                      View Profile
                    </span>
                  </button>
                </>
              ) : !deleted &&
                lastIndexOfExtension === -1 &&
                !isImage &&
                !isVideo &&
                typeof sharedProfileData !== "object" &&
                typeof sharedSpaceData === "object" ? (
                <>
                  <div className="flex items-center gap-2 py-3 border-y border-[#F2F2F2]">
                    <Avatar
                      src={sharedSpaceData.picture}
                      size={48}
                      shape="square"
                      alt="user"
                      className="rounded-md min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px]"
                    />
                    <div className="w-[123px]">
                      <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-[6px]">
                        {sharedSpaceData.title}
                      </p>
                    </div>
                  </div>
                  <button className="pt-2.5 pb-0 px-3 w-full">
                    <span className="text-blue_1 text-sm not-italic font-normal leading-[120%]">
                      View Space
                    </span>
                  </button>
                </>
              ) : isLink ? (
                <div>
                  <p>{message}</p>
                  <br />
                  <a
                    className="text-blue_1 text-[17px] not-italic font-normal leading-[120%]"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link}
                  </a>
                </div>
              ) : (
                <div className="flex items-center gap-1">
                  <SlashCircled />
                  <p className="text-[rgba(102,102,102,0.60)] text-[17px] not-italic font-normal leading-[120%]">
                    This Message was deleted
                  </p>
                </div>
              )}
              {sortEmoji.length > 0 && (
                <div
                  onClick={() => {
                    handleOpenReactionModal();
                  }}
                  onMouseOver={() => setChatReaction(false)}
                  onMouseLeave={() => setChatReaction(true)}
                  className={`${
                    isMac && "pt-2.5"
                  } absolute -bottom-5 h-7 right-3 flex items-center gap-1 border bg-white px-2.5 py-1.5  rounded-[400px] border-solid border-gray_4 cursor-pointer"`}
                >
                  {sortEmoji?.map((emoji) => {
                    return <Emoji label={emoji} symbol={emoji} />;
                  })}
                  <span>{sortEmoji.length}</span>
                </div>
              )}
            </div>
            <p className="text-gray_6 text-[13px] not-italic font-normal leading-[normal]">
              {time}
            </p>
          </div>
        </div>
        <Reactions
          isModalOpen={openReactionModal}
          handleOk={handleOpenReactionModal}
          handleCancel={handleCancelReactionModal}
        />
        <ForwardTo
          isModalOpen={openForwardTo}
          handleOk={handleOpenForwardToModal}
          handleCancel={handleCancelForwardToModal}
        />
      </div>
      <MediaPreviewModal
        isModalOpen={openMediaPreviewModal}
        handleOk={handleOkayMediaPreviewModal}
        handleCancel={handleCancelMediaPreviewModal}
      >
        <div className="flex justify-center ">
          {isVideo ? (
            <>
              <video
                controls
                src={media}
                className="object-contain h-[calc(80vh_-_229px)]"
              />
            </>
          ) : isImage ? (
            <img
              src={media}
              className="object-contain h-[calc(80vh_-_229px)]"
              alt="receiver message"
            />
          ) : null}
        </div>
      </MediaPreviewModal>

      <DeleteMessageModal
        isModalOpen={openDeleteMsgModal}
        handleOk={handleOkayDeleteMsgModal}
        handleCancel={handleCancelDeleteMsgModal}
      />
    </>
  );
};

export default Receiver;
