import ProgressBar from "../../../themes/ProgressBar/ProgressBar";
import AddItemComp from "./AddItemComp";
import { useState } from "react";
import { useEffect } from "react";
import SkillsModal from "./AddSkillsModal";
import { EditOutlined } from "../../Icons/Icons";
import EditSkillModal from "./EditSkillModal";
interface SkillsProps {
  edit?: true | false;
}
const Skills: React.FC<SkillsProps> = ({ edit = false }) => {
  const Data = [
    {
      key: 1,
      title: "app testing and dev",
      percentage: 30,
    },
    {
      key: 2,
      title: "Design",
      percentage: 40,
    },
    {
      key: 3,
      title: "Card Creation",
      percentage: 60,
    },
  ];
  const [popover, setPopover] = useState(false);
  const [title, setTitle] = useState("Add Skills");
  const [view, setView] = useState(false);

  const [editPopover, setEditPopover] = useState(false);

  useEffect(() => {
    if (!edit) {
      setView(true);
    }
  }, []);
  return (
    <div className="edit-wrapper">
      <div className="flex justify-between mb-4">
        <h6 className="text-[#1F1F1F] text-[17px] font-medium leading-[120%] ">
          Skills
        </h6>
        {edit && view ? (
          <span
            onClick={() => {
              setTitle("Edit Skill");
              setEditPopover(true);
            }}
            className=" cursor-pointer flex justify-center items-center edit-icon "
          >
            <EditOutlined />
          </span>
        ) : null}
      </div>
      {view ? (
        <div className="mt-4 flex flex-col gap-3">
          {Data.map((item) => {
            return (
              <ProgressBar
                key={item.key}
                label={item.title}
                percentage={item.percentage}
              />
            );
          })}
        </div>
      ) : null}
      {!view && (
        <AddItemComp
          onBtnClick={() => setPopover(true)}
          title="This looks blank"
          desc="add your skills to highlight in feed"
        />
      )}
      <SkillsModal
        onBtnClick={setView}
        title={title}
        popover={popover}
        setPopover={setPopover}
      />
      <EditSkillModal
        setTitle={setTitle}
        onBtnClick={() => {
          setPopover(true);
        }}
        title={title}
        popover={editPopover}
        setPopover={setEditPopover}
      />
    </div>
  );
};

export default Skills;
