import Stories from "react-insta-stories";

const TestiMonialsDetails = ({ testiMonialDetail }: any) => {
  const { img } = testiMonialDetail;
  const timer = 10000;
  const stories = img;
  return (
    <Stories
      stories={stories}
      defaultInterval={timer}
      storyStyles={{ borderRadius: "14px" }}
      storyContainerStyles={{
        background: "transparent",
        height: "calc(100vh - 10px)",
        borderRadius: "14px",
      }}
    />
  );
};

export default TestiMonialsDetails;
