import { Avatar } from "antd";
import avatar1 from "../../assets/svg/avatar1.svg";
import avatar2 from "../../assets/svg/avatar2.svg";
import avatar3 from "../../assets/svg/avatar3.svg";
import "./avatar_comp.scss";
import { AvatarCompProps } from "../../type/types";

const AvatarComp: React.FC<AvatarCompProps> = ({
  avatarClassName,
  shape = "square",
  size = 24,
}) => {
  const AvatarData = [
    {
      key: 1,
      src: avatar3,
    },
    {
      key: 2,
      src: avatar2,
    },
    {
      key: 3,
      src: avatar1,
    },
  ];
  return (
    <Avatar.Group shape="square" className="avatar-comp">
      {AvatarData.map((item) => {
        return (
          <Avatar
            key={item.key}
            src={item.src}
            size={size}
            shape={shape}
            className={`rounded-md ${avatarClassName}`}
            alt="user"
          />
        );
      })}
    </Avatar.Group>
  );
};

export default AvatarComp;
