import { SpacesInterestedTypes } from "../type/types";
import U25BgViolet from "../assets/png/space-8.png";

const interestedSpaces: SpacesInterestedTypes[] = [
  {
    id: "1",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "2",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "3",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "4",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "5",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "6",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "7",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
  {
    id: "8",
    title: "Guru Nanak Institutions",
    image: U25BgViolet,
    locked: true,
    explorers: "12k",
    mutuals: 12,
    maxCount: 3,
  },
];

export default interestedSpaces;
