import { Input } from "antd";
import React from "react";
import "./CustomInput.scss";
import { InputProps } from "../../type/types";
import useIsMac from "../../hooks/useIsMac";

const CustomInput: React.FC<InputProps> = ({
  placeholder,
  containerStyle,
  className,
  id,
  type = "text",
  value,
  name,
  maxLength,
  onClick,
  onChange,
  prefix,
  suffix,
  readOnly = false,
  onFocus,
  onBlur,
  displayCount,
}) => {
  const isMac = useIsMac();

  return (
    <div className={`relative ${containerStyle}`}>
      <Input
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
        onClick={onClick}
        maxLength={maxLength}
        className={` ${
          isMac && "is-mac"
        } custom-input h-11 text-gray_3_hover text-[17px] not-italic font-normal leading-[120%] border p-4 rounded-[12px] border-solid border-gray_2 ${className}`}
        placeholder={placeholder}
        prefix={prefix}
        suffix={suffix}
        readOnly={readOnly}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {displayCount && (
        <div className="absolute top-[16px] right-[20px]">
          <p className=" text-[#666666CC] text-[13px] not-italic font-normal leading-[120%]">
            {value?.length}/{maxLength}
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomInput;
