import { useEffect, useState } from "react";
import { ChatMuteIconGray, KebabMenu, PinnedChatIcon } from "../../Icons/Icons";
import { Popover } from "antd";

const ChatListItem = ({
  item,
  // menuContent,
  setIsDeleteOpen,
  setIsMuteOpen,
  archiveMenu,
}: any) => {
  const [openArchiveMenu, setOpenArchiveMenu] = useState<boolean>(false);

  const handleOpenChange = (newOpen: boolean) => {
    setOpenArchiveMenu(newOpen);
  };

  const hide = () => {
    setOpenArchiveMenu(false);
  };

  const menuContent = (
    <ul>
      <li
        onClick={() => {
          setOpenArchiveMenu(false);
        }}
        className=" py-2 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          Archive
        </span>
      </li>
      <li
        onClick={() => {
          setOpenArchiveMenu(false);
        }}
        className=" py-2 p-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          Mark as read
        </span>
      </li>
      <li
        onClick={() => {
          setOpenArchiveMenu(false);
        }}
        className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          {item.isPinned ? "UnPin" : "Pin"}
        </span>
      </li>
      <li
        onClick={() => {
          setIsMuteOpen(true);
          setOpenArchiveMenu(false);
        }}
        className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          Mute notifications
        </span>
      </li>
      <li
        onClick={() => {
          setIsDeleteOpen(true);
          setOpenArchiveMenu(false);
        }}
        className=" py-2 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
          Delete
        </span>
      </li>
    </ul>
  );

  const items = (
    <div>
      <button
        onClick={hide}
        className="flex text-primary text-[17px] not-italic hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease] font-normal leading-[120%] pt-1.5 pb-0.5 px-2 max-w-[134px] w-full mx-auto cursor-pointer"
      >
        Unarchive
      </button>
    </div>
  );

  return (
    <div
      className={`${
        item.isActive && "bg-gray_4"
      } p-2 flex cursor-pointer rounded-lg`}
    >
      <span className="p-[13px] flex justify-center items-center bg-lightViolet w-[56px] h-[56px] rounded-lg">
        {item.img}
      </span>
      <div className="flex justify-between w-full ml-3 items-center">
        <div className="flex-col gap-1 flex">
          <div className="flex">
            <h6 className="text-[15px] not-italic font-medium leading-[120%]">
              {item.name}
            </h6>
          </div>
          <span className="flex text-[13px] not-italic font-normal leading-[120%]">
            {item.user}
            <p className="ml-1 text-gray_6">{item.action}</p>
          </span>
        </div>
        <div className="flex items-center gap-4">
          <div
            className={`${
              item.isActive ? "justify-center" : ""
            } gap-2 flex flex-col items-end`}
          >
            <>
              <span className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                {item.timeAgo}
              </span>
              <div className="flex ">
                {item.isPinned && (
                  <div className="flex">
                    <span className="inline-flex justify-center items-center hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease] max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px]">
                      <PinnedChatIcon />
                    </span>
                  </div>
                )}
                {item.isMuted && (
                  <span className="inline-flex justify-center items-center hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease] max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px]">
                    <ChatMuteIconGray />
                  </span>
                )}
                <Popover
                  overlayClassName="w-[190px]"
                  content={menuContent}
                  trigger={["click"]}
                  open={openArchiveMenu}
                  onOpenChange={handleOpenChange}
                  placement="bottomRight"
                  arrow={false}
                  overlayInnerStyle={{
                    borderRadius: 12,
                    paddingLeft: 12,
                    paddingRight: 20,
                    paddingTop: 20,
                    paddingBottom: 20,
                  }}
                >
                  <button className="flex self-start ml-auto rounded-[50%] hover:bg-gray_4_hover transition-all duration-200 ease-[ease]">
                    <KebabMenu />
                  </button>
                </Popover>
              </div>
            </>
          </div>
          {archiveMenu && (
            <Popover
              content={items}
              trigger="click"
              open={openArchiveMenu}
              onOpenChange={handleOpenChange}
              placement="bottomRight"
              arrow={false}
              overlayStyle={{
                width: 150,
              }}
              overlayInnerStyle={{
                padding: "12px 0",
              }}
            >
              <button className="inline-flex justify-center items-center hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease] max-w-[36px] min-w-[36px] max-h-[36px] min-h-[36px]">
                <KebabMenu />
              </button>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;
