import { useState, useEffect } from "react";
import AddItemComp from "./AddItemComp";
import InterestModal from "./InterestModal";
import { EditOutlined } from "../../Icons/Icons";
import "../user_about.scss";
import useIsMac from "../../../hooks/useIsMac";

interface InterestProps {
  edit: boolean;
}

function Interest({ edit }: InterestProps) {
  const [popover, setPopover] = useState(false);
  const [Data, setData] = useState<string[]>([]);

  const defaultData = ["Formula 1", "Road trips", "dancing"];

  useEffect(() => {
    if (!edit) {
      setData(defaultData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit]);

  const isMac = useIsMac();

  const [interestTitle, setInterestTitle] = useState("");
  return (
    <div className="edit-wrapper">
      <div className="flex justify-between">
        <h6 className="text-[#1F1F1F] text-[17px] font-medium leading-[120%] ">
          Interests
        </h6>
        {edit && Data.length !== 0 ? (
          <span
            onClick={() => {
              setInterestTitle("Edit Interests");
              setPopover(true);
            }}
            className=" cursor-pointer flex justify-center items-center edit-icon "
          >
            <EditOutlined />
          </span>
        ) : null}
      </div>
      {Data.length !== 0 && (
        <div className="mt-4 flex gap-2 flex-wrap">
          {Data.map((item: string) => {
            return (
              <div className="p-2 border rounded-lg border-solid border-[#DBD9D9] flex justify-center items-center">
                <h6
                  className={`${
                    isMac && "pt-1"
                  } text-[#666] text-sm font-normal leading-[120%]`}
                >
                  {item}
                </h6>
              </div>
            );
          })}
        </div>
      )}
      {Data.length === 0 && edit ? (
        <div className="mt-4">
          <AddItemComp
            onBtnClick={() => {
              setPopover(true);
              setInterestTitle("Add Interests");
            }}
            title="Pick Interests (0/5)"
            desc="pick interests to match better"
          />
        </div>
      ) : null}
      <InterestModal
        title={interestTitle}
        setSelectData={setData}
        popover={popover}
        setPopover={setPopover}
      />
    </div>
  );
}

export default Interest;
