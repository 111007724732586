import React from "react";
import { Avatar } from "antd";

interface ExplorerCardProps {
  explorerCount: string;
  connections: string[];
  avatars: string[];
}

const ExplorerCard: React.FC<ExplorerCardProps> = ({
  explorerCount,
  connections,
  avatars,
}) => {
  return (
    <div className="bg-white rounded-xl px-4 py-5 h-fit mb-5">
      <h3 className="text-primary text-xl not-italic font-medium leading-[100%] mb-2">
        {explorerCount} Explorers
      </h3>
      <p className="text-primary text-[15px] not-italic font-normal leading-[120%] mb-2">
        {connections}
      </p>
      <div className="flex items-center gap-2">
        {avatars.map((avatarSrc, index) => (
          <Avatar
            key={index}
            src={avatarSrc}
            size={40}
            shape="square"
            alt="user"
            className="rounded-md mb-[2px] max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
          />
        ))}
        <div className="border rounded-md border-solid border-gray_2 w-10 h-10 shrink-0 flex items-center justify-center">
          <p>+49</p>
        </div>
      </div>
    </div>
  );
};

export default ExplorerCard;
