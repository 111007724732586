import React, { useState } from "react";
import { Avatar } from "antd";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import { SpacesInterestedTypes } from "../../../../type/types";
import LockIcon from "../../../../assets/svg/lock-02.svg";
import AvatarComp from "../../../../themes/AvatarComp/AvatarComp";
import useIsMac from "../../../../hooks/useIsMac";

interface SpacesInterestedProps {
  data: SpacesInterestedTypes[];
}

const SpacesInterested: React.FC<SpacesInterestedProps> = ({ data }) => {
  const isMac = useIsMac();
  const [spacesJoined, setSpacesJoined] = useState(
    Array(data.length).fill(true)
  );
  const [joinStatus, setJoinStatus] = useState(Array(data.length).fill(""));

  const handleJoinSpace = (index: number) => {
    const newSpaceJoined = [...spacesJoined];
    newSpaceJoined[index] = !newSpaceJoined[index];
    setSpacesJoined(newSpaceJoined);

    if (spacesJoined[index]) {
      setJoinStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = "Requested";
        return newStatus;
      });
    }

    setTimeout(() => {
      setJoinStatus((prevStatus) => {
        const newStatus = [...prevStatus];
        newStatus[index] = "Joined";
        return newStatus;
      });
    }, 1000);
  };

  return (
    <>
      {data.map((item, index) => {
        return (
          <div
            key={item.id}
            className="border rounded-lg border-solid border-gray_4 p-2 [&:not(:last-child)]:mb-4 w-full "
          >
            <div className="flex items-center gap-2 pb-2 border-b border-solid border-b-gray_4">
              <Avatar
                src={item.image}
                size={44}
                shape="square"
                alt="user"
                className="rounded-md mb-[2px] max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
              />
              <div>
                <div className="flex gap-2">
                  <p className="text-primary text-[15px] not-italic font-normal leading-[120%]">
                    {item.title}
                  </p>
                  {item.locked && (
                    <img
                      src={LockIcon}
                      alt="lock"
                      className={`${isMac ? "mb-1" : ""}`}
                    />
                  )}
                </div>
                <p className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                  {item.explorers} Explorers
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between mt-2">
              <div className="flex items-center gap-2">
                <div className="flex">
                  <AvatarComp />
                </div>
                <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                  <span className="text-primary text-[15px] not-italic font-normal leading-[120%] mr-1">
                    {item.mutuals}
                  </span>
                  Mutuals
                </p>
              </div>
              <PrimaryButton
                title={
                  joinStatus[index] === "Requested"
                    ? "Requested"
                    : joinStatus[index] === "Joined"
                    ? "Joined"
                    : "Join"
                }
                onClick={
                  joinStatus[index] === "Joined" ||
                  joinStatus[index] === "Requested"
                    ? () => {}
                    : () => handleJoinSpace(index)
                }
                theme="light"
                size="small"
                fontClass="!text-[13px]"
                className="!rounded-md"
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default SpacesInterested;
