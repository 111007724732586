import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { XCloseMd } from "../../Icons/Icons";
import { HouseModalProps, tabsDataProps } from "../../../type/types";
import SearchBar from "../../SearchBar/SearchBar";
import PrimaryTabs from "../../PrimaryTabs/PrimaryTabs";
import UserContactList from "../../UserContactList/UserContactList";
import { forwardToData } from "../../../data/forwardToData";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

const ForwardTo: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [emojiLists, setEmojiLists] = useState<boolean[]>();
  const anyChecked = emojiLists?.some((item) => item);

  const tabsData: tabsDataProps[] = [
    {
      id: "1",
      label: "Recents",
      children: (
        <UserContactList
          containerClassName="!h-auto max-h-[344px]"
          data={forwardToData}
          type={"checkbox"}
          checkedLists={(checkedLists) => setEmojiLists(checkedLists)}
        />
      ),
    },
    {
      id: "2",
      label: "Connections",
      children: (
        <UserContactList
          containerClassName="!h-auto max-h-[344px]"
          data={forwardToData}
          type={"checkbox"}
          checkedLists={(checkedLists) => setEmojiLists(checkedLists)}
        />
      ),
    },
    {
      id: "3",
      label: "Groups",
      children: (
        <UserContactList
          containerClassName="!h-auto max-h-[344px]"
          data={forwardToData}
          type={"checkbox"}
          checkedLists={(checkedLists) => setEmojiLists(checkedLists)}
        />
      ),
    },
  ];

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <PrimaryButton
        onClick={handleCancel}
        theme="light"
        title="Back"
        className="!px-8 !py-4"
        width="fit-content"
      />
      <PrimaryButton
        disabled={!anyChecked}
        onClick={handleOk}
        title="Send"
        className="!px-8 !py-4"
        width="fit-content"
      />
    </div>
  );

  return (
    <CustomModal
      className="feed-modal"
      isModalOpen={isModalOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
      title={
        <div className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
          <h3 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
            Forward to
          </h3>
        </div>
      }
      style={{
        inset: 0,
        top: "5%",
      }}
      footer={modalFooter}
      width={615}
      closeIcon={<XCloseMd />}
    >
      <div className="px-6 my-6 ">
        <SearchBar placeholder="Search for members" />
        <PrimaryTabs
          containerClass="!mb-0"
          tabsData={tabsData}
          tabsChildrenClass={" max-h-full overflow-y-auto theme-scrollbar-sm"}
          innerClass="!my-6"
        />
      </div>
    </CustomModal>
  );
};

export default ForwardTo;
