import React, { useState } from "react";
import { Under25Logo } from "../components/Icons/Icons";
import OTPInput from "../components/OTPInput/OTPInput";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import OnboardingCarousel from "../components/OnboardingCarousel/OnboardingCarousel";
import { Link } from "react-router-dom";

interface VerifyOTPProps {
  verifyNumber?: number;
}

const VerifyOtp: React.FC<VerifyOTPProps> = ({ verifyNumber = 9842424153 }) => {
  const [isOtpComplete, setIsOtpComplete] = React.useState(false);

  const handleOTPChange = (otp: string) => {
    setIsOtpComplete(otp.length === 6);
  };

  const handleOTPComplete = (otp: string) => {
    console.log(otp);
  };

  const spliceNumber = (verifyNumber: number): string => {
    const stringifyNumber = String(verifyNumber);
    const numArr = stringifyNumber.split("");
    numArr.splice(1, 4, "xxxx");
    const modifiedNumber = numArr.join("");
    return modifiedNumber;
  };

  const [otp, setOTP] = useState<string[]>(new Array(6).fill(""));
  const hasEmptyOtp = otp.some((element) => element === "");

  console.log(hasEmptyOtp, "otp");

  return (
    <div className="flex justify-center items-center bg-white h-screen">
      <div className="flex shadow-[0px_4px_12px_4px_rgba(31,31,31,0.12)] h-[588px] rounded-3xl">
        <div className="pt-8 pb-16 pl-16 pr-20 max-w-[544px] overflow-y-auto">
          <div className="min-w-[60px] max-w-[60px] min-h-[60px] max-h-[60px] mb-12">
            <Under25Logo />
          </div>
          <h1 className="text-primary text-[35px] not-italic font-medium leading-[100%] mb-2">
            Verify OTP
          </h1>
          <p className="text-gray_1 text-xl not-italic font-normal leading-[120%] mb-6">
            An OTP was sent to{" "}
            <span className="inline-flex text-primary text-xl not-italic font-medium leading-[120%]">
              {spliceNumber(verifyNumber)}
            </span>
          </p>
          <OTPInput
            containerStyle="mb-6"
            timerDuration={30}
            length={6}
            setOTP={setOTP}
            otp={otp}
            hasEmptyOtp={false}
            onComplete={handleOTPComplete}
          />
          <Link to={`${!hasEmptyOtp ? "/create-profile" : ""}`}>
            <PrimaryButton
              disabled={hasEmptyOtp}
              title="Continue"
              width="full"
            />
          </Link>
        </div>
        <OnboardingCarousel />
      </div>
    </div>
  );
};
export default VerifyOtp;
