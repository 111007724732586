import React, { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "./U25DatePicker.scss";
import { format, getMonth, getYear } from "date-fns";
import { Button } from "antd";
import { DobPicker, DropdownArrow, NextArrow, PrevArrow } from "../Icons/Icons";
import DatePicker, {
  CalendarContainer,
  ReactDatePickerCustomHeaderProps,
} from "react-datepicker";
import {
  CalendarOuterBoxProps,
  DatePickerInputProps,
  U25DatePickerProps,
} from "../../type/types";
import moment from "moment";

const U25DatePicker: React.FC<U25DatePickerProps> = ({
  suffixIcon = <DobPicker />,
  containerClass,
  showSecondDatePicker,
  type,
  setShowSecondDatePicker,
}) => {
  const [selectedYear, setSelectedYear] = useState<any>(
    new Date().getFullYear()
  );
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(selectedYear)
  );
  const [showYearDropdown, setShowYearDropdown] = useState(false);
  const datePickerRef = useRef<HTMLDivElement | null>(null);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      datePickerRef.current &&
      !datePickerRef.current.contains(event.target as Node)
    ) {
      setShowSecondDatePicker(false);
    }
  };

  useEffect(() => {
    setStartDate(new Date(selectedYear, 0, 1));
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const DatePickerInput: React.FC<DatePickerInputProps> = ({ value, ref }) => (
    <div
      className=" relative cursor-pointer "
      onClick={() => {
        setShowSecondDatePicker(!showSecondDatePicker);
        setShowYearDropdown(false);
      }}
    >
      <button
        className=" w-full cursor-pointer flex items-center text-left  h-11 text-primary text-[17px] not-italic font-normal leading-[120%] border p-4 rounded-lg border-solid border-gray_2"
        ref={ref}
      >
        {type?.length ? moment(value).format("dddd, MMM D") : value}
      </button>
      <div className="absolute -translate-y-2/4 p-0 right-4 top-2/4">
        {suffixIcon}
      </div>
    </div>
  );

  const DatePickerYearInput: React.FC<DatePickerInputProps> = ({
    value,
    ref,
  }) => (
    <div className=" relative">
      <button
        onClick={() => {
          setShowYearDropdown(!showYearDropdown);
        }}
        className="flex items-center text-primary text-center text-xl not-italic font-normal leading-[normal] "
        ref={ref}
      >
        {value}
        <DropdownArrow
          height={16}
          width={16}
          className={`${
            !showYearDropdown ? "rotate-0" : "rotate-180"
          } ml-2 [&_path]:fill-primary`}
        />
      </button>
    </div>
  );

  const CalendarOuterBox: React.FC<CalendarOuterBoxProps> = ({
    className,
    children,
  }) => (
    <div className="bg-white w-[342px] shadow-[0px_15px_30px_0px_rgba(0,0,0,0.15)] backdrop-blur-[15px] p-5 rounded-[10px] mb-5">
      <CalendarContainer className={className}>
        <div className="relative">{children}</div>
      </CalendarContainer>
    </div>
  );

  const CustomHeader: React.FC<ReactDatePickerCustomHeaderProps> = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => {
    const formatDate = (dateString: Date | number) => {
      const formattedDate = format(dateString, "E, d MMMM");
      return formattedDate;
    };

    const renderYearContent = (year: any) => {
      const tooltipText = `Tooltip for year: ${year}`;
      return <span title={tooltipText}>{year}</span>;
    };

    const handleYearSelect = (date: Date) => {
      const selectedYear = date.getFullYear();
      setShowYearDropdown(false);
      setShowSecondDatePicker(true);
      setSelectedYear(selectedYear);
      setStartDate(date);
    };

    return (
      <div className="flex flex-col gap-8 w-full">
        <div>
          <div className="bg-white">
            <p
              style={{
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}
              className="text-primary text-left text-xl not-italic font-normal leading-[normal] mb-4 focus:outline-none"
            >
              <div
                className={`custom-year-datepicker inline-flex items-center rounded-md cursor-pointer px-2`}
              >
                <DatePicker
                  key={selectedYear}
                  selected={startDate}
                  renderDayContents={renderYearContent}
                  showYearPicker
                  customInput={<DatePickerYearInput />}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  onChange={handleYearSelect}
                  open={showYearDropdown}
                  popperClassName="bg-white  text-white p-4 rounded-lg shadow-lg"
                />
                {}
              </div>
            </p>
            <h3
              style={{
                fontFeatureSettings: "'clig' off, 'liga' off",
              }}
              className="text-primary text-left text-[32px] not-italic font-normal leading-[normal]"
            >
              {formatDate(date)}
            </h3>
          </div>
        </div>
        <div className="flex items-center justify-between w-full px-4 pb-4">
          <Button
            className="flex justify-center items-center max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] p-0 border-0 hover:!border hover:!border-solid hover:!border-primary hover:!text-primary"
            onClick={decreaseMonth}
            disabled={prevMonthButtonDisabled}
          >
            <PrevArrow />
          </Button>
          <div className="flex gap-1">
            <p className="text-primary text-center text-lg not-italic font-medium leading-[normal]">
              {months[getMonth(date)]}
            </p>
            <p className="text-primary text-center text-lg not-italic font-medium leading-[normal]">
              {getYear(date)}
            </p>
          </div>
          <Button
            className="flex justify-center items-center max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px] p-0 border-0 hover:!border hover:!border-solid hover:!border-primary hover:!text-primary"
            onClick={increaseMonth}
            disabled={nextMonthButtonDisabled}
          >
            <NextArrow />
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className={`custom-datepicker ${containerClass}`} ref={datePickerRef}>
      <DatePicker
        selected={startDate}
        endDate={new Date()}
        key={selectedYear}
        onChange={(date: Date) => {
          setStartDate(date);
          setShowSecondDatePicker(false);
        }}
        maxDate={new Date()}
        customInput={<DatePickerInput />}
        calendarContainer={CalendarOuterBox}
        renderCustomHeader={CustomHeader}
        open={showSecondDatePicker}
      />
    </div>
  );
};

export default U25DatePicker;
