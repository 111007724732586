import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { HouseModalProps } from "../../../type/types";
import { XCloseMd } from "../../Icons/Icons";
import CheckIcon from "../../../assets/svg/check-filled-light.svg";

const Archived: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Archived
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton
        onClick={() => {
          handleCancel();
        }}
        title="Ok"
        width="fit-content"
      />
    </div>
  );

  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={modalFooter}
        width={615}
        closeIcon={<XCloseMd />}
      >
        <div className="my-6 px-6">
          <div className="mb-4">
            <div className="flex items-center justify-center mx-auto bg-U25_orange w-20 h-[80px] rounded-[400px] border-r-2 border-r-orange_1 border-b-2 border-b-orange_1 border-solid mb-4">
              <img className="object-contain" src={CheckIcon} alt="check" />
            </div>
            <p className="text-U25_orange text-[17px] not-italic font-normal leading-[120%] uppercase text-center">
              Awaiting approval
            </p>
          </div>
          <div>
            <p className="text-gray_6 text-xl not-italic font-medium leading-[100%] text-center mb-2">
              You’re
              <span className="text-primary me-1 ms-1 text-xl not-italic font-medium leading-[100%]">
                #1029
              </span>
              on the waitlist
            </p>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] text-center">
              we will notify you when we have approved your application.
            </p>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Archived;
