import { KebabMenu, PinnedChatIcon } from "../../Icons/Icons";
import { Popover } from "antd";

const ChatListGroup = ({
  item,
  menuContent,
  isPopOverOpen,
  setIsPopOverOpen,
}: any) => {
  return (
    <div
      className={`${
        item.isActive && "bg-gray_4"
      } p-2 flex cursor-pointer rounded-lg `}
    >
      <img
        src={item.img}
        className="flex justify-center items-center bg-lightViolet w-[56px] h-[56px] rounded-lg"
        alt="Groupimage"
      />
      <div className="flex justify-between w-full ml-3 items-center">
        <div className="flex-col gap-1 flex">
          <div className="flex">
            <h6 className="text-[15px] not-italic font-medium leading-[120%]">
              {item.name}
            </h6>
          </div>
          <span className="flex text-[13px] not-italic font-normal leading-[120%]">
            {item.user}
            <p className="ml-1 text-gray_6">{item.action}</p>
          </span>
        </div>
        <div
          className={`${
            item.isActive && "justify-center"
          } gap-2 flex flex-col items-center`}
        >
          {item.isActive ? (
            <>
              <Popover
                overlayClassName="w-[190px]"
                content={menuContent}
                trigger={["click"]}
                open={isPopOverOpen}
                onOpenChange={() => {
                  setIsPopOverOpen((prev: any) => !prev);
                }}
                placement="bottomRight"
                arrow={false}
                overlayInnerStyle={{
                  padding: 8,
                  borderRadius: 12,
                }}
              >
                <button className="flex self-start ml-auto rounded-[50%] hover:bg-gray_4_hover transition-all duration-200 ease-[ease]">
                  <KebabMenu />
                </button>
              </Popover>
            </>
          ) : (
            <>
              <span className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                {item.timeAgo}
              </span>
              {item.isPinned && (
                <span className="">
                  <PinnedChatIcon />
                </span>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatListGroup;
