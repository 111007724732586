import { Avatar } from "antd";
import React from "react";
import { U25VerifiedUser } from "../../../Icons/Icons";
import { OuterSpaceBoxProps } from "../../../../type/types";

const OuterSpaceBox: React.FC<OuterSpaceBoxProps> = ({
  containerClass = "",
  data,
}) => {
  return (
    <div
      className={`mt-4 mb-5 [&_div:not(:last-child)]:mb-4 ${containerClass}`}
    >
      {data.map((item) => (
        <div key={item.id} className="flex items-start gap-2">
          <Avatar
            src={item.image}
            size={40}
            shape="square"
            alt="user"
            className="rounded-md max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
          />
          <div>
            <p className="text-primary text-[15px] not-italic font-medium leading-[120%] mb-[2px]">
              {item.title}
            </p>
            <div className="flex items-center">
              <p className="text-primary text-[13px] not-italic font-normal leading-[120%] mr-[2px]">
                {item.userName}
              </p>
              <span className="inline-flex mr-[2px]">
                {item.isVerified ? <U25VerifiedUser /> : null}
              </span>
              <p className="text-link_hover ms-[4px] text-[13px] not-italic font-normal leading-[120%]">
                ~{item.lastSent} ago
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OuterSpaceBox;
