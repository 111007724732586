export const BlockList = [
    {
        key: 1,
        name: "akshayachandar",
        id: "a/kani",
        src: "blocked-user"
    },
    {
        key: 2,
        name: "akshayachandar",
        id: "a/kani",
        src: "blocked-user"
    },
    {
        key: 3,
        name: "akshayachandar",
        id: "a/kani",
        src: "blocked-user"
    },
]