import data from "../../../data/myMissionsData";
import { MissionsDataType } from "../../../type/types";
import AvatarComp from "../../../themes/AvatarComp/AvatarComp";
import { GiveAwayIcon, IntershipIcon, MissionsClock } from "../../Icons/Icons";
import { Link } from "react-router-dom";
import useIsMac from "../../../hooks/useIsMac";

const MissionsBox = () => {
  const isMac = useIsMac();
  return (
    <>
      {data &&
        data.map((item: MissionsDataType) => {
          return (
            <div
              key={item.id}
              className="flex lg:pr-4 sm:pr-0 md:pr-0 justify-between [&:not(:last-child)]:pb-5 [&:not(:last-child)]:mb-5 [&:not(:last-child)]:border-b [&:not(:last-child)]:border-b-gray_2"
            >
              <div className="flex items-center gap-4">
                <Link to="/missions/details" className=" cursor-pointer">
                  <div>
                    <img
                      className="max-w-[64px] min-w-[64px] max-h-[64px] min-h-[64px] rounded-md object-cover"
                      src={item.image}
                      alt="space group"
                    />
                  </div>
                </Link>

                <div>
                  <div className="flex items-center gap-2">
                    <Link to="/missions/details" className=" cursor-pointer">
                      <h4 className="text-primary text-xl not-italic font-medium leading-[100%] mb-1">
                        {item.title}
                      </h4>
                    </Link>
                  </div>
                  <div className="mb-1">
                    <span className="pe-2 text-link_hover text-[15px] not-italic font-normal leading-[120%]">
                      {item.cards.length > 0 ? (
                        <span className="text-gray_6">{item.cards}</span>
                      ) : (
                        <span
                          className={` ${
                            isMac && " pt-2"
                          } px-2 py-1 text-[#CD8025]  text-[13px] bg-[#FC741E1A] bg-opacity-10 rounded-lg`}
                        >
                          Coming Soon
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center  text-[15px]">
                    {item.cash.length > 0 ? (
                      <span className="text-gray_6 flex items-center">
                        <span
                          className={`${isMac && "pb-1 leading-none"} mr-[3px]`}
                        >
                          <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_3634_39931)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.26465 3.66611H-0.00250345V4.99945H5.26465C5.1999 5.29672 5.09314 5.52642 4.96915 5.70658C4.76259 6.00672 4.4755 6.21663 4.14372 6.36388C3.46251 6.66621 2.6893 6.66614 2.34113 6.66611H0.664721H-0.945312L4.85943 12.4709L5.80223 11.528L2.27364 7.99944H2.33083C2.34205 7.99944 2.35413 7.99947 2.36706 7.99949C2.72726 8.00011 3.73986 8.00187 4.6846 7.58257C5.18616 7.35997 5.69073 7.00996 6.06751 6.46248C6.34506 6.05919 6.53599 5.57374 6.61839 4.99945H7.9975V3.66611H6.61839C6.53599 3.09182 6.34506 2.60636 6.06751 2.20308C6.01854 2.13191 5.9674 2.06409 5.9144 1.99945H7.99682L7.99745 0.666113H2.46545C2.42874 0.665962 2.39582 0.666019 2.36706 0.666069C2.35413 0.666092 2.34205 0.666113 2.33083 0.666113H-0.00217211L-0.00249016 1.99945H2.36298C2.72083 1.99954 3.47642 2.00552 4.14372 2.30168C4.4755 2.44893 4.76259 2.65884 4.96915 2.95898C5.09314 3.13914 5.1999 3.36883 5.26465 3.66611Z"
                                fill="#666666"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_3634_39931">
                                <rect
                                  width="8"
                                  height="10.6667"
                                  fill="white"
                                  transform="translate(0 0.666016)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                        {item.cash}
                      </span>
                    ) : item.intership ? (
                      <span className="flex items-center ">
                        <IntershipIcon className={`${isMac && "pb-1"}`} />
                        <p className="ml-1 text-link_hover">Internship</p>
                      </span>
                    ) : item.mission ? (
                      <span className="flex items-center">
                        <GiveAwayIcon className={`${isMac && "pb-[3px]"}`} />
                        <p className="ml-1 text-link_hover ">Giveaway</p>
                      </span>
                    ) : null}
                    <span className=" flex items-center text-link_hover text-[15px]">
                      <span className="px-3">
                        <svg
                          width="2"
                          height="16"
                          viewBox="0 0 2 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M1 0V16" stroke="#DBD9D9" />
                        </svg>
                      </span>
                      <span>
                        <MissionsClock className={`${isMac && "pb-[3px]"}`} />
                      </span>
                      <span className="ml-1">{item.date}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                  <div className="flex items-center gap-2">
                    <div className="flex">
                      <AvatarComp />
                    </div>
                    <p className="text-link_hover text-[15px] not-italic font-normal leading-[120%]">
                      <span className="text-primary text-[15px] not-italic font-medium leading-[120%] mr-1">
                        {item.participatedCount}
                      </span>
                      have participated
                    </p>
                  </div>
                </p>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default MissionsBox;
