import { Avatar, Popover } from "antd";
import React, { useEffect, useState } from "react";
import { CommentNotificationTypes } from "../../type/types";
import { KebabMenu } from "../Icons/Icons";

interface CommentNotificationProps {
  data: CommentNotificationTypes[];
  containerClass?: string;
  menu?: boolean;
  allAsRead?: boolean;
  popoverNotificationBadge?: boolean;
}

const CommentNotification: React.FC<CommentNotificationProps> = ({
  data,
  containerClass,
  menu = false,
  allAsRead = false,
  popoverNotificationBadge,
}) => {
  const [notificationBadge, setNotificationBadge] = useState(
    Array(data?.length).fill(true)
  );

  const handleMarkAsRead = (index: number) => {
    setNotificationBadge(
      notificationBadge.map((item, i) => (i === index ? false : item))
    );
  };

  const handleMarkAsUnread = (index: number) => {
    setNotificationBadge(
      notificationBadge.map((item, i) => (i === index ? true : item))
    );
  };

  const handleMarkAllAsRead = () => {
    setNotificationBadge(Array(data?.length).fill(false));
  };

  const handleMarkAllAsUnread = () => {
    setNotificationBadge(Array(data?.length).fill(true));
  };

  useEffect(() => {
    if (allAsRead) {
      handleMarkAllAsRead();
    } else {
      handleMarkAllAsUnread();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAsRead]);

  return (
    <div className={`mb-4 ${containerClass}`}>
      {data?.map((item, index) => (
        <div key={item.id} className="flex gap-3 [&:not(:last-child)]:mb-4">
          <Avatar
            src={item.image}
            size={45}
            shape="square"
            alt="user"
            className="rounded-md mb-[2px] max-w-[45px] min-w-[45px] max-h-[45px] min-h-[45px]"
          />
          <div>
            <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%] mb-1">
              <span className="text-primary text-[17px] not-italic font-medium leading-[120%] mr-1">
                {item.fullName}
              </span>
              commented on your card
              <span className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                :
              </span>
              <span className="text-primary text-[17px] not-italic font-medium leading-[120%] mx-1">
                “{item.comment}”
              </span>
            </p>
            <div className="flex items-center h-[11px]">
              <p className="text-gray_6 text-[13px] not-italic font-normal leading-[normal]">
                {item.timeAgo} ago
              </p>
              {popoverNotificationBadge && notificationBadge[index] && (
                <span className="before:inline-flex before:content-[''] before:rounded-[50%] before:bg-U25_orange before:w-[8px] before:h-[8px] ml-1 mr-[7px]"></span>
              )}
            </div>
          </div>
          {menu && (
            <div className="flex">
              {notificationBadge[index] && (
                <span className="before:inline-flex before:content-[''] before:rounded-[50%] before:bg-U25_orange before:w-[8px] before:h-[8px] mr-[7px]"></span>
              )}
              <Popover
                overlayClassName="w-[190px]"
                content={
                  <ul>
                    <li
                      onClick={() => {
                        if (notificationBadge[index]) {
                          handleMarkAsRead(index);
                        } else {
                          handleMarkAsUnread(index);
                        }
                      }}
                      className=" pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
                    >
                      <span className=" text-primary text-[17px] not-italic font-normal leading-[120%]">
                        {notificationBadge[index]
                          ? "Mark as read"
                          : "Mark as unread"}
                      </span>
                    </li>
                    <li className=" pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer">
                      <span className=" text-primary text-[17px] not-italic font-normal leading-[120%]">
                        Remove this notification
                      </span>
                    </li>
                  </ul>
                }
                trigger="click"
                placement="bottomRight"
                arrow={false}
                overlayInnerStyle={{
                  padding: 8,
                  borderRadius: 12,
                }}
              >
                <button className="flex self-start ml-auto rounded-[50%] hover:bg-gray_4_hover transition-all duration-200 ease-[ease]">
                  <KebabMenu />
                </button>
              </Popover>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default CommentNotification;
