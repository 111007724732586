import React from "react";
import { ThemeCardLayoutProps } from "../../type/types";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";

const ThemeCardLayout: React.FC<ThemeCardLayoutProps> = ({
  title,
  buttonOnClick,
  btnTitle = "View Profile",
  children,
  titleClass,
  containerClass,
}) => {
  return (
    <div className={`bg-wrapper_bg rounded-xl px-4 py-5   ${containerClass}`}>
      <h3
        className={`text-primary text-xl not-italic font-medium leading-[100%] ${titleClass}`}
      >
        {title === "user-account" ? "" : title}
      </h3>
      {children}
      <Link
        to={
          title === "My Spaces"
            ? "/spaces"
            : title === "My Decks"
            ? "/user-account/decks"
            : title === "user-account"
            ? "/user-account"
            : title === "Active Missions"
            ? "/missions"
            : "#"
        }
      >
        <PrimaryButton
          title={btnTitle}
          onClick={buttonOnClick}
          width="full"
          size="regular"
          className="!px-2 !h-[33px]"
          containerStyle="mt-5"
          fontClass="text-[15px]"
        />
      </Link>
    </div>
  );
};

export default ThemeCardLayout;
