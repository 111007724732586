import React from "react";
import { Avatar } from "antd";
import { SpacesBoxProps } from "../../../../type/types";

const SpacesBox: React.FC<SpacesBoxProps> = ({ data }) => {
  return (
    <div className="[&_div:not(:last-child)]:mb-4">
      {data.map((item) => (
        <div key={item.id} className="flex items-center gap-2 not:mb-5">
          <Avatar
            src={item.spacePicture}
            size={40}
            shape="square"
            alt="user"
            className="rounded-md min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]"
          />
          <div>
            <p className="text-primary text-[15px] not-italic font-normal leading-[120%] mb-0.5">
              {item.title}
            </p>
            <p className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
              {item.count} Explorers
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SpacesBox;
