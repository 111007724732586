import { OuterSpaceBoxDataType } from "../type/types";
import OuterSpace1 from "../assets/png/out-user-1.png"
import OuterSpace2 from "../assets/png/out-user-2.png"
import OuterSpace3 from "../assets/png/out-user-3.png"

const outerSpaceData: OuterSpaceBoxDataType[] = [
    {
      id: "1",
      title: "Calm Down Remake",
      image: OuterSpace1,
      userName: "tl/rapperbigdeal",
      isVerified: true,
      lastSent: "5m",
    },
    {
      id: "2",
      title: "Hi under 25 universe!! Good to be here!!",
      image: OuterSpace1,
      userName: "tl/rapperbigdeal",
      isVerified: true,
      lastSent: "5m",
    },
    {
      id: "3",
      title: "Design workshop ft. tl/santanu",
      image: OuterSpace2,
      userName: "tl/santanu",
      isVerified: true,
      lastSent: "50m",
    },
    {
      id: "4",
      title: "Help me choose, good folks",
      image: OuterSpace1,
      userName: "tl/rapperbigdeal",
      isVerified: true,
      lastSent: "50m",
    },
    {
      id: "5",
      title: "Illusion",
      image: OuterSpace2,
      userName: "tl/santanu",
      isVerified: true,
      lastSent: "5m",
    },
    {
      id: "6",
      title: "Good morning Monday",
      image: OuterSpace3,
      userName: "tl/antonphilip",
      isVerified: true,
      lastSent: "15m",
    },
  ];

  export default outerSpaceData;