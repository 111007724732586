import { useState } from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import UnBlockModal from "../../UnBlockModal/UnBlockModal";
import { BlockList } from "./BlockList";
import EmptyState from "../../../themes/EmptyState/EmptyState";

interface User {
  key: number;
  name: string;
  id: string;
  src: string;
}

const BlockListTab = () => {
  const [modal, setModal] = useState(false);
  const [blockedList, setBlockedList] = useState<User[]>(BlockList);
  const [key, setKey] = useState(0);

  const handleUnblockClick = (keyToRemove: number) => {
    const updatedBlockedList = blockedList.filter(
      (user) => user.key !== keyToRemove
    );
    setBlockedList(updatedBlockedList);
  };
  return (
    <div className="flex flex-col  w-full h-full">
      <h4 className="text-[#1F1F1F] text-start text-[24px] font-medium leading-[100%]">
        Blocked accounts
      </h4>
      {blockedList.length ? (
        <div className="flex flex-col mt-6">
          {blockedList.map((user) => {
            return (
              <div className="flex pb-3 justify-between items-start border-[#F2F2F2] border-t pt-3 first:pt-0 first:border-none">
                <div className="flex gap-3 items-start">
                  <img src={`/images/img/${user.src}.png`} alt="blocked user" />
                  <div className="flex flex-col gap-1">
                    <h4 className="text-[#1F1F1F] text-[17px] font-medium leading-[1.2]">
                      {user.name}
                    </h4>
                    <h6 className="text-[#808080] text-[17px] font-normal leading-[1.2]">
                      {user.id}
                    </h6>
                  </div>
                </div>
                <PrimaryButton
                  onClick={() => {
                    setModal(true);
                    setKey(user.key);
                  }}
                  title="Unblock"
                  fontClass="text-[15px]"
                  size="custom"
                  className="px-4 py-3"
                  btnClass="max-h-[33px]"
                />
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyState
          title="No blocked accounts"
          desc="You haven't blocked anyone yet"
          image="/images/svg/block-empty-icon.svg"
        />
      )}
      <UnBlockModal
        onOk={() => {
          handleUnblockClick(key);
        }}
        modal={modal}
        setModal={setModal}
      />
    </div>
  );
};

export default BlockListTab;
