import React, { useState } from "react";
import { Select } from "antd";
import "./select_tags.scss";
import useIsMac from "../../hooks/useIsMac";

interface SelectTagsProps {
  selectValue: string[];
  setSelectedValue: React.Dispatch<React.SetStateAction<string[]>>;
  optionData: { value: string; label: string }[];
}

const SelectTags: React.FC<SelectTagsProps> = ({
  selectValue,
  setSelectedValue,
  optionData,
}) => {
  const handleChange = (value: string[]) => {
    if (value.length <= 5) {
      setSelectedValue(value);
    }
  };
  const [suggestion, setSuggestion] = useState(false);
  const isMac = useIsMac();

  return (
    <div>
      <Select
        popupClassName={
          suggestion
            ? "social-link-dropdown-popup personal-info-popup"
            : "social-link-dropdown-popup personal-info-popup suggestion-close"
        }
        className={`${isMac && "is-mac"} interest-select-tags`}
        mode="tags"
        style={{ width: "100%" }}
        placeholder="Fill in your Interest, comma separated"
        onChange={(value) => {
          handleChange(value);
          setSuggestion(true);
        }}
        value={selectValue}
        options={optionData}
        tokenSeparators={[","]}
        onSearch={() => {
          setSuggestion(true);
        }}
        onBlur={() => {
          setSuggestion(false);
        }}
      />
    </div>
  );
};

export default SelectTags;
