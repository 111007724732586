import React, { useRef, useState } from "react";
import {
  BellOutlined,
  ChatOutlined,
  ChatOutlinedFilled,
  CommunityFilled,
  HambergerIcon,
  HeaderLogo,
  MissionsFilled,
  MissionsOutlined,
  SpacesOutlined,
  XCloseMd,
} from "../../Icons/Icons";
import { Link, useLocation } from "react-router-dom";
import SearchBar from "../../SearchBar/SearchBar";
import { Avatar, Popover } from "antd";
import U25User from "../../../assets/png/community-user-picture.png";
import NotificationContent from "../../Notification/NotificationContent";
import AccountDrop from "./AccountDrop";
import useIsMac from "../../../hooks/useIsMac";
import "./header.scss";
import OpenSearch from "../../SearchBar/OpenSearch";

const Header: React.FC = () => {
  const location = useLocation();
  const [hoveredItem, setHoveredItem] = useState<string | null>(null);
  const isMac = useIsMac();
  const openSearchRef = useRef<HTMLInputElement>(null);
  const [openSearchInput, setOpenSearchInput] = useState("");
  const [isOpenSearchFocused, setIsOpenSearchFocused] = useState(false);
  const [openMenuDrawer, setOpenMenuDrawer] = useState<boolean>(false);

  const handleBlur = () => {
    if (openSearchInput.length > 0) {
      openSearchRef?.current?.focus();
      setIsOpenSearchFocused(true);
    } else {
      setIsOpenSearchFocused(false);
    }
  };

  const onClose = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpenSearchFocused(false);
    setOpenSearchInput("");
    if (openSearchInput.length > 0) {
      openSearchRef?.current?.blur();
    }
  };

  return (
    <>
      <header className="header min-h-[93px]">
        <div className="bg-white flex items-center justify-between py-[24px] px-[32px]">
          {!isOpenSearchFocused && (
            <Link to={"/community"}>
              <div className="flex justify-center items-center w-[50px] h-[50px]">
                <HeaderLogo />
              </div>
            </Link>
          )}
          <div className="menu-container flex xl:w-full lg:max-w-[854px] xl:max-w-[1000px] justify-between items-center">
            <nav
              className={`nav-items-container ${
                openMenuDrawer ? "visible" : ""
              } lg:flex items-center gap-6`}
            >
              <div className="flex lg:hidden justify-between items-center py-6 mb-10">
                <Link to={"/community"}>
                  <div className="flex justify-center items-center w-[50px] h-[50px]">
                    <HeaderLogo />
                  </div>
                </Link>
                <button
                  className="hover:bg-gray_4 rounded-lg"
                  onClick={() => setOpenMenuDrawer(false)}
                >
                  <XCloseMd />
                </button>
              </div>
              <div
                onMouseEnter={() => setHoveredItem("community")}
                onMouseLeave={() => setHoveredItem(null)}
                className={`${hoveredItem ? "font-medium" : ""} nav-item`}
              >
                <NavLink to="/community" currentLocation={location}>
                  <CommunityFilled
                    fill={
                      location.pathname === "/community" ||
                      hoveredItem === "community"
                        ? "#1F1F1F"
                        : "none"
                    }
                  />
                  Community
                </NavLink>
              </div>
              <div
                onMouseEnter={() => setHoveredItem("spaces")}
                onMouseLeave={() => setHoveredItem(null)}
                className="nav-item"
              >
                <NavLink to="/spaces" currentLocation={location}>
                  <SpacesOutlined
                    fill={
                      location.pathname === "/spaces" ||
                      location.pathname === "/spaces/explorer" ||
                      hoveredItem === "spaces"
                        ? "#1F1F1F"
                        : "none"
                    }
                  />
                  <p
                    className={` ${
                      location.pathname === "/spaces" ||
                      location.pathname === "/spaces/explorer"
                        ? "font-medium"
                        : ""
                    }`}
                  >
                    Spaces
                  </p>
                </NavLink>
              </div>
              <div
                onMouseEnter={() => setHoveredItem("missions")}
                onMouseLeave={() => setHoveredItem(null)}
                className="nav-item"
              >
                <NavLink to="/missions" currentLocation={location}>
                  {location.pathname === "/missions" ||
                  location.pathname === "/missions/details" ||
                  hoveredItem === "missions" ? (
                    <MissionsFilled />
                  ) : (
                    <MissionsOutlined />
                  )}
                  <p
                    className={` ${
                      location.pathname === "/missions" ||
                      location.pathname === "/missions/details"
                        ? "font-medium"
                        : ""
                    }`}
                  >
                    Missions
                  </p>
                </NavLink>
              </div>
              <div
                onMouseEnter={() => setHoveredItem("chats")}
                onMouseLeave={() => setHoveredItem(null)}
                className="nav-item"
              >
                <NavLink to="/chats" currentLocation={location}>
                  {location.pathname === "/chats" || hoveredItem === "chats" ? (
                    <ChatOutlinedFilled />
                  ) : (
                    <ChatOutlined />
                  )}
                  Chats
                </NavLink>
              </div>
            </nav>
            <div className="flex items-center gap-6 lg:ml-4">
              <SearchBar
                placeholder={"Search"}
                className={`hidden headerSearch ${
                  isMac && "is-mac"
                } lg:flex lg:w-[220px] h-11 tablet_md:w-[100px] header_tablet_search:hidden [&_input]:pt-[2px] rounded-[8px] [&_input]:placeholder:text-gray_60 [&_input]:placeholder:text-[15px] [&_input]:text-[15px] [&_input]:placeholder:leading-[120%] [&_input]:leading-[120%]`}
              />

              <div className="w-full flex lg:hidden cursor-pointer">
                <OpenSearch
                  value={openSearchInput}
                  ref={openSearchRef}
                  onChange={(e) => {
                    setOpenSearchInput(e.target.value);
                  }}
                  onClose={onClose}
                  onBlur={handleBlur}
                  isFocus={isOpenSearchFocused}
                  onFocus={() => setIsOpenSearchFocused(true)}
                  className={`flex lg:hidden transition-all duration-200 ease-[ease]`}
                  inputClassName={`bg-[length:24px_24px] bg-no-repeat focus:bg-[length:16px_16px] focus:bg-no-repeat focus:border focus:rounded-lg focus:border-solid focus:border-gray_2 focus:p-4 focus:pl-10 focus:bg-[left_16px_center]`}
                />
              </div>

              {!isOpenSearchFocused && (
                <>
                  <Popover
                    overlayClassName="w-[370px]"
                    content={<NotificationContent />}
                    trigger="click"
                    placement="bottomRight"
                    arrow={false}
                    overlayInnerStyle={{
                      padding: 0,
                      position: "relative",
                      borderRadius: 12,
                    }}
                  >
                    <button className="group">
                      <BellOutlined className="group-hover:[&_path]:fill-primary [&_path]:transition-all [&_path]:duration-200 [&_path]:ease-[ease]" />
                    </button>
                  </Popover>
                  <AccountDrop>
                    <div className="cursor-pointer">
                      <Avatar
                        src={U25User}
                        size={40}
                        shape="square"
                        alt="user"
                        className="rounded-lg max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
                      />
                    </div>
                  </AccountDrop>
                </>
              )}

              {!isOpenSearchFocused && (
                <button
                  onClick={() => setOpenMenuDrawer(true)}
                  className="flex lg:hidden cursor-pointer"
                >
                  <HambergerIcon />
                </button>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

const NavLink: React.FC<{
  to: any;
  currentLocation: any;
  children: any;
}> = ({ to, children, currentLocation }) => {
  const isActive = currentLocation.pathname === to;
  const activeClassName = isActive
    ? "text-primary  font-medium "
    : " not-italic font-normal leading-[120%]";
  return (
    <Link to={to} className={`flex items-center ${isActive ? "active" : ""}`}>
      <div
        className={`inline-grid place-items-center min-w-[24px] max-w-[24px] min-h-[24px] max-h-6 mr-2 ${activeClassName}`}
      >
        {children[0]}
      </div>
      <span
        className={`not-italic text-[17px] leading-[120%] ${activeClassName}`}
      >
        {children[1]}
      </span>
    </Link>
  );
};

export default Header;
