import React, { useState } from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import CustomInput from "../../CustomInput/CustomInput";
import CustomTextArea from "../../CustomTextArea/CustomTextArea";
import { HouseModalProps } from "../../../type/types";
import CustomModal from "../../CustomModal/CustomModal";
import { notification } from "antd";
import { NotificationPlacement } from "antd/es/notification/interface";
import Archived from "./Archived";

const CreateSpace: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [description, setDescription] = useState("");
  const [openArchived, setOpenArchived] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const handleOpenArchived = () => {
    setOpenArchived(true);
  };

  const handleCancelArchived = () => {
    setOpenArchived(false);
  };

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: "",
      className:
        "under25-notification auto-close !bg-primary !w-[340px] !shadow-[0px_12px_8px_0px_rgba(0,0,0,0.08),0px_3px_24px_0px_rgba(0,0,0,0.04)] !py-3 !px-4 !rounded-lg ![&_.ant-notification-notice-message]:m-0",
      description: (
        <div className="flex items-center">
          <div className="border-r-gray_1 border-r border-solid pr-5">
            <p className="text-white text-[17px] not-italic font-medium leading-[120%]">
              Your Space is in review
            </p>
            <p className="text-white text-sm not-italic font-normal leading-[120%]">
              Check status of space you created
            </p>
          </div>
          <PrimaryButton
            size="small"
            className="!rounded-lg !ml-5"
            theme="light"
            title="Check"
            onClick={() => {
              handleOpenArchived();
              closeNotification();
            }}
          />
        </div>
      ),
      closeIcon: false,
      duration: 4.5,
      placement,
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(e.target.value.slice(0, 100));
  };

  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Create your Space
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton
        onClick={() => {
          openNotification("bottomRight");
          handleCancel();
        }}
        title="Save"
        width="fit-content"
      />
    </div>
  );
  return (
    <>
      {contextHolder}
      <Archived
        isModalOpen={openArchived}
        handleOk={handleOpenArchived}
        handleCancel={handleCancelArchived}
      />
      <div>
        <CustomModal
          isModalOpen={isModalOpen}
          handleOk={handleOk}
          handleCancel={handleCancel}
          title={CardHeader}
          footer={modalFooter}
          width={615}
        >
          <div className="mx-0 my-6 px-6 py-0">
            <form action="">
              <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-5">
                Fill the below details to get your space approved
              </p>
              <PhotoUpload
                title="Upload Event Thumbnail"
                description="max file size 500kb"
                className="mb-5"
              />
              <div className="flex flex-col gap-5">
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Space Name
                  </label>
                  <CustomInput
                    name="fullName"
                    containerStyle="mt-1"
                    id="name"
                    type="text"
                    placeholder="Enter Name"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Space Bio
                  </label>
                  <CustomInput
                    name="fullName"
                    containerStyle="mt-1"
                    id="name"
                    type="text"
                    placeholder="Enter Bio"
                  />
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="name"
                    className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Reason for creating
                  </label>
                  <CustomInput
                    name="fullName"
                    //   value={formValues.fullName}
                    //   onChange={handleInputChange}
                    containerStyle="mt-1"
                    id="name"
                    type="text"
                    placeholder="Give a Reason"
                  />
                  {/* {formErrors.fullName && (
                  <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                    {formErrors.fullName}
                  </p>
                )} */}
                </div>
                <div className="flex flex-col">
                  <label
                    htmlFor="comments"
                    className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                  >
                    Additional Comments
                  </label>
                  <div className="relative">
                    <CustomTextArea
                      value={description}
                      onChange={handleDescriptionChange}
                      placeholder="Give a Reason"
                      noBorder={false}
                      autoSize={{ minRows: 2, maxRows: 2 }}
                      className="text-primary placeholder:text-gray_3_hover text-[17px] not-italic font-normal leading-[120%] pt-4 pb-2 px-4 mt-1"
                    />
                    <div className="absolute bottom-2 right-4">
                      {description.length}/100
                    </div>
                  </div>
                  {/* {formErrors.date && (
                  <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                    {formErrors.date}
                  </p>
                )} */}
                </div>
              </div>
            </form>
          </div>
        </CustomModal>
      </div>
    </>
  );
};

export default CreateSpace;
