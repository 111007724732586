import React, { useState } from "react";
import { HouseModalProps } from "../../../type/types";
import CustomModal from "../../CustomModal/CustomModal";
import { XCloseMd } from "../../Icons/Icons";
import RequestsBox from "../../RequestsBox/RequestsBox";
import Requests1 from "../../../assets/png/requests-1.png";
import Requests2 from "../../../assets/png/requests-2.png";
import SearchBar from "../../SearchBar/SearchBar";
import useIsMac from "../../../hooks/useIsMac";

const Requests: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const requestsData = [
    {
      id: "1",
      image: Requests1,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "2",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "3",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "4",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "5",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "6",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "7",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
    {
      id: "8",
      image: Requests2,
      fullName: "Kanishka Reddy",
      userName: "e/kani",
    },
  ];

  const isMac = useIsMac();
  const [itemLen, setItemLen] = useState<number>();

  const CardHeader = (
    <div className="flex items-center gap-2 pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Requests
      </h4>
      <div
        className={`flex ${
          isMac && "mb-[6px]"
        } items-center justify-center bg-blue_1 rounded-3xl py-[6px] px-[6px] w-[25px] h-[21px]`}
      >
        <span
          className={`text-white text-[15px] not-italic font-medium leading-[120%] ${
            isMac && "pt-[3px]"
          }`}
        >
          {itemLen}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={<div className="rounded-[0_0_12px_12px] p-1"></div>}
        width={615}
        closeIcon={<XCloseMd />}
      >
        <div className="mt-6 mx-6">
          <SearchBar className="mb-6" placeholder="Search for Requests" />
          <div className="pr-4 h-[344px] overflow-y-auto theme-scrollbar-sm">
            <RequestsBox
              data={requestsData}
              requestsCount={(count) => setItemLen(count)}
              notificationRequest={false}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default Requests;
