import React from "react";
import Layout from "../components/Layout/Layout";
import { ArrowNarrowLeft, CakeOutlinedGray } from "../components/Icons/Icons";
import SearchBar from "../components/SearchBar/SearchBar";
import { useNavigate } from "react-router-dom";
import BirthdayBox from "../components/Notification/BirthdayBox";
import User1 from "../assets/png/requests-3.png";
import User2 from "../assets/png/requests-6.png";
import useIsMac from "../hooks/useIsMac";

const Birthday = () => {
  const navigate = useNavigate();
  const isMac = useIsMac();

  const handleNavigateToNotification = () => {
    navigate("/notification");
  };

  const birthdayData = [
    {
      id: "1",
      image: User1,
      fullName: "Akshada’s",
      description: "Wish him a happy birthday",
    },
    {
      id: "2",
      image: User2,
      fullName: "Akshada’s",
      description: "Wish her a happy birthday",
    },
    {
      id: "3",
      image: User1,
      fullName: "Akshada’s",
      description: "Wish him a happy birthday",
    },
  ];

  return (
    <Layout>
      <div className="bg-gray_4 p-6 w-full h-[calc(100vh_-_98px)]">
        <div className="w-[716px] bg-white mx-auto rounded-xl">
          <div className="flex items-center gap-3 p-6 rounded-[12px_12px_0px_0px]">
            <button onClick={handleNavigateToNotification}>
              <ArrowNarrowLeft />
            </button>
            <h3 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              Birthday
            </h3>
            <div
              className={` ${
                isMac && "pt-2 mb-1"
              } flex items-center justify-center bg-blue_1 rounded-3xl py-[6px] px-[6px] w-[25px] h-[21px]`}
            >
              <span className="text-white text-[15px] not-italic font-medium leading-[120%]">
                {birthdayData.length}
              </span>
            </div>
          </div>
          <div className="pt-0 pb-6 px-6">
            <SearchBar placeholder="Search names" className="mb-6" />
            <p className="text-primary text-[15px] not-italic font-normal leading-[normal] mb-4">
              New
            </p>
            <div className="flex items-center gap-3 mb-4">
              <CakeOutlinedGray />
              <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] capitalize">
                Birthday
              </span>
            </div>
            <BirthdayBox data={birthdayData} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Birthday;
