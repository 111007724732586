import { osName } from "react-device-detect";
import { useState, useEffect } from "react";

const useIsMac = (): boolean => {
  const [isMac, setIsMac] = useState(osName === "Mac OS" || osName === "iOS");

  useEffect(() => {
    setIsMac(osName === "Mac OS" || osName === "iOS");
  }, []);

  return isMac;
};

export default useIsMac;
