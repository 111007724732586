import * as React from "react";
import Layout from "../components/Layout/Layout";
import ThemeCardLayout from "../components/ThemeCardLayout/ThemeCardLayout";
import MissionLeaderBoard from "../data/MissionLeaderBoard";
import PrimaryTabs from "../components/PrimaryTabs/PrimaryTabs";
import { tabsDataProps } from "../type/types";
import {
  ArrowNarrowLeft,
  BriefCase,
  GiftOutlinedDarkMd,
  MissionCreatIcon,
  RupeesSignDarkMd,
  TargetAim,
} from "../components/Icons/Icons";
import MissionsBox from "../components/Missions/MissionsBox/MissionsBox";
import MissionLeaderboard from "../components/Missions/MissionLeaderboard";
import CurrendBalanceBg from "../assets/png/CurrentBalance.png";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import CustomModal from "../components/CustomModal/CustomModal";

import { CreateOpportunity } from "../components/Missions/Create/CreateOpportunity";
import { useLocation } from "react-router-dom";
import useIsMac from "../hooks/useIsMac";
import { Popover } from "antd";
import SortButton from "../components/Home/Community/SortButton/SortButton";
import CustomCheckbox from "../components/CustomCheckbox/CustomCheckbox";
import { CheckboxChangeEvent } from "antd/es/checkbox";

export interface IAppProps {}

const Missions = () => {
  const tabsData: tabsDataProps[] = [
    {
      id: "1",
      label: "Explore",
      children: <MissionsBox />,
    },
    {
      id: "2",
      label: "My Missions",
      children: <MissionsBox />,
    },
  ];
  const [sortOpen, setSortOpen] = React.useState(false);
  const SortOnOpenChange = () => {
    setSortOpen((prev) => !prev);
  };
  const items = (
    <div>
      <h4 className="text-dark_85 text-[17px] not-italic font-medium leading-[120%] pt-5 pb-0 px-2 mb-1">
        Create Opportunity
      </h4>
      <div
        onClick={() => {
          setPopover(true);
          hide();
        }}
        className="flex items-center gap-3 p-2 hover:bg-gray_20 rounded-lg cursor-pointer transition-[background] duration-200 ease-[ease]"
      >
        <div className="bg-gray_2 rounded-lg p-3">
          <TargetAim />
        </div>
        <div>
          <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-[2px]">
            Mission
          </p>
          <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
            Lorem ipsum dolor sit amet consectetur.
          </p>
        </div>
      </div>
      <div className="flex items-center gap-3 p-2 hover:bg-gray_20 rounded-lg cursor-pointer transition-[background] duration-200 ease-[ease]">
        <div className="bg-gray_2 rounded-lg p-3">
          <BriefCase />
        </div>
        <div>
          <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-[2px]">
            Job listing
          </p>
          <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
            Lorem ipsum dolor sit amet consectetur.
          </p>
        </div>
      </div>
    </div>
  );

  const [popover, setPopover] = React.useState(false);
  const [current, setCurrent] = React.useState(0);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdminRole: any = queryParams?.get("role") === "admin";
  const isMac = useIsMac();
  const [openCreateOpportunityPopup, setOpenCreateOpportunityPopup] =
    React.useState(false);
  const [currentTab, setCurrentTab] = React.useState("Explore");

  const handleClosePopover = () => {
    setPopover(false);
    setCurrent(0);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenCreateOpportunityPopup(newOpen);
  };

  const hide = () => {
    setOpenCreateOpportunityPopup(false);
  };

  const [checked, setChecked] = React.useState<any>({
    explorerCheck1: false,
    explorerCheck2: false,
  });

  const [missionsTypeCheck, setMissionsTypeCheck] = React.useState<any>({
    missionsCheck1: false,
    missionsCheck2: false,
  });

  const checkboxHandler = (e: CheckboxChangeEvent) => {
    const { name } = e.target as { name: string };
    setChecked({
      ...checked,
      [name]: e.target.checked,
    });
  };

  const missionsTypeCheckboxHandler = (e: CheckboxChangeEvent) => {
    const { name } = e.target as { name: string };
    setMissionsTypeCheck({
      ...missionsTypeCheck,
      [name]: e.target.checked,
    });
  };

  React.useEffect(() => {
    setChecked({
      explorerCheck1: false,
      explorerCheck2: false,
    });
    setMissionsTypeCheck({
      missionsCheck1: false,
      missionsCheck2: false,
    });
  }, [currentTab]);

  const Header = (
    <div className="flex items-center gap-3 pt-6 pb-4 px-6 border-b border-gray_4">
      {current > 0 && (
        <button
          onClick={() => {
            setCurrent(current - 1);
          }}
          className="rounded-md hover:bg_gray_20 transition-all duration-200 ease-[ease]"
        >
          <ArrowNarrowLeft />
        </button>
      )}
      <h6 className="text-primary text-[24px] font-medium leading-[100%] capitalize">
        Create Opportunity
      </h6>
    </div>
  );

  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          current < 3 ? setCurrent(current + 1) : handleClosePopover();
        }}
        title={` ${
          current === 2
            ? " Publish"
            : current === 3
            ? "Publish Mission"
            : "Next"
        }`}
      />
    </div>
  );

  const pollIconProps = {
    width: 16,
    height: 16,
    className: `[&_path]:stroke-primary mr-3 [&_path]:ease-[ease] [&_path]:duration-200 [&_path]:transition-all`,
  };

  const rupeesIconProps = {
    className:
      "mr-3 [&_path]:ease-[ease] [&_path]:duration-200 [&_path]:transition-all",
  };

  const explorerTypeData = [
    {
      id: "explorerCheck1",
      label: "Missions",
      fillType: "stroke",
      icon: <TargetAim {...pollIconProps} />,
    },
    {
      id: "explorerCheck2",
      label: "Jobs",
      fillType: "stroke",
      icon: <BriefCase {...pollIconProps} />,
    },
  ];

  const missionsTypeData = [
    {
      id: "missionsCheck1",
      label: "Giveaway",
      fillType: "stroke",
      icon: <GiftOutlinedDarkMd {...pollIconProps} />,
    },
    {
      id: "missionsCheck2",
      label: "Money",
      fillType: "fill",
      icon: <RupeesSignDarkMd {...rupeesIconProps} />,
    },
  ];

  const popoverExplorerCont = (
    <>
      <div className="mb-4">
        <button
          onClick={() => setSortOpen(false)}
          className="flex w-full pt-1.5 pb-0.5 px-2 text-primary text-[17px] not-italic font-normal leading-[120%] hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease]"
        >
          Recent
        </button>
        <button
          onClick={() => setSortOpen(false)}
          className="flex w-full pt-1.5 pb-0.5 px-2 text-primary text-[17px] not-italic font-normal leading-[120%] hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease]"
        >
          Trending
        </button>
      </div>
      <span className="text-hashtag_text text-sm not-italic font-normal leading-[120%] px-2 mb-1">
        Type
      </span>
      <div>
        {explorerTypeData?.map((item) => (
          <CustomCheckbox
            key={item.id}
            name={item.id}
            onChange={checkboxHandler}
            className="sortpopover-checkbox flex flex-row-reverse justify-end [&>*:nth-child(2)]:!ml-0 [&>*:nth-child(2)]:!mr-auto pt-1.5 pb-0.5 px-2 hover:bg-gray_4 rounded-lg"
          >
            <div
              className={`flex items-center ${
                checked[item.id] && item.fillType === "stroke"
                  ? "[&_svg_path]:stroke-U25_orange"
                  : checked[item.id] && item.fillType === "fill"
                  ? "[&_svg_path]:fill-U25_orange"
                  : ""
              }`}
            >
              {item.icon}
              <p
                className={`text-primary text-[17px] not-italic font-normal leading-[120%] transition-all duration-200 select-none ${
                  checked[item.id] ? "!text-U25_orange" : ""
                }`}
              >
                {item.label}
              </p>
            </div>
          </CustomCheckbox>
        ))}
      </div>
    </>
  );

  const popoverMissionCont = (
    <>
      <div className="mb-4">
        <button
          onClick={() => setSortOpen(false)}
          className="flex w-full pt-1.5 pb-0.5 px-2 text-primary text-[17px] not-italic font-normal leading-[120%] hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease]"
        >
          Recent
        </button>
        <button
          onClick={() => setSortOpen(false)}
          className="flex w-full pt-1.5 pb-0.5 px-2 text-primary text-[17px] not-italic font-normal leading-[120%] hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease]"
        >
          Completed
        </button>
        <button
          onClick={() => setSortOpen(false)}
          className="flex w-full pt-1.5 pb-0.5 px-2 text-primary text-[17px] not-italic font-normal leading-[120%] hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease]"
        >
          Participated
        </button>
      </div>
      <span className="text-hashtag_text text-sm not-italic font-normal leading-[120%] px-2 mb-1">
        Type
      </span>
      <div>
        {missionsTypeData?.map((item) => (
          <CustomCheckbox
            key={item.id}
            name={item.id}
            onChange={missionsTypeCheckboxHandler}
            className="sortpopover-checkbox flex flex-row-reverse justify-end [&>*:nth-child(2)]:!ml-0 [&>*:nth-child(2)]:!mr-auto pt-1.5 pb-0.5 px-2 hover:bg-gray_4 rounded-lg"
          >
            <div
              className={`flex items-center ${
                missionsTypeCheck[item.id] && item.fillType === "stroke"
                  ? "[&_svg_path]:stroke-U25_orange"
                  : missionsTypeCheck[item.id] && item.fillType === "fill"
                  ? "[&_svg_path]:fill-U25_orange"
                  : ""
              }`}
            >
              {item.icon}
              <p
                className={`text-primary text-[17px] not-italic font-normal leading-[120%] transition-all duration-200 select-none ${
                  missionsTypeCheck[item.id] ? "!text-U25_orange" : ""
                }`}
              >
                {item.label}
              </p>
            </div>
          </CustomCheckbox>
        ))}
      </div>
    </>
  );

  return (
    <Layout>
      <div className="grid min-h-[100vh]  lg:grid-cols-[1fr] lg:justify-center md:justify-center xl:grid-cols-[3fr_1fr] gap-[30px] py-6 px-8 bg-gray_4 ">
        <div className="p-5 rounded-lg bg-white h-fit">
          <div className="flex justify-between">
            <h3 className="text-primary text-xl not-italic font-medium leading-[100%]">
              Opportunities
            </h3>
            <div className="flex justify-center items-center gap-2">
              <SortButton
                open={sortOpen}
                onOpenChange={SortOnOpenChange}
                theme="light"
                trigger="click"
                btnClass="border bg-dull_white_1 rounded-lg border-solid border-gray_2 max-w-[42px] min-w-[42px] max-h-[42px] min-h-[42px] flex items-center justify-center"
                popupTitle={
                  <span className="text-hashtag_text text-sm not-italic font-normal leading-[120%] pt-1.5 pb-0 px-2 mb-1">
                    Sort by
                  </span>
                }
                content={
                  currentTab === "Explore"
                    ? popoverExplorerCont
                    : currentTab === "My Missions"
                    ? popoverMissionCont
                    : ""
                }
                overlayInnerStyle={{
                  padding: "12px 8px",
                }}
              />
              {isAdminRole && (
                <>
                  <Popover
                    content={items}
                    trigger="click"
                    open={openCreateOpportunityPopup}
                    onOpenChange={handleOpenChange}
                    placement="bottomLeft"
                    arrow={false}
                    overlayInnerStyle={{
                      padding: "0 8px 12px",
                    }}
                  >
                    <button className="p-3 w-fit flex h-10 justify-center items-center border rounded-lg border-gray_2 mr-4 cursor-pointer">
                      <span className="w-5 flex h-5 justify-center items-center cursor-pointer mr-2">
                        <MissionCreatIcon
                          className={` ${isMac && "mb-[4px] "}`}
                        />
                      </span>
                      Create
                    </button>
                  </Popover>
                </>
              )}
            </div>
          </div>
          <PrimaryTabs
            tabsData={tabsData}
            onChange={(value) => setCurrentTab(value)}
          />
        </div>
        <div className=" w-full h-fit theme-scrollbar-sm ">
          <div
            style={{ backgroundImage: `url(${CurrendBalanceBg})  ` }}
            className="p-5 rounded-xl text-white w-full bg-no-repeat bg-center bg-cover mb-5"
          >
            <h6 className="text-[14px] leading-[120%] opacity-80 ">
              Current Balance
            </h6>
            <div className=" flex justify-between items-center">
              <div className=" flex items-center mt-4 ">
                <span
                  className={`mt-1 pl-0 pr-px pt-0 pb-0.5 ${
                    isMac && "mb-[6px]"
                  }`}
                >
                  <svg
                    width="15"
                    height="18"
                    viewBox="0 0 15 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M10.159 4.38657H3.02005V6.19374H10.159C10.0713 6.59667 9.92656 6.90799 9.75851 7.15218C9.47855 7.55898 9.08943 7.84348 8.63975 8.04306C7.71645 8.45284 6.66846 8.45275 6.19656 8.4527H3.92439H1.74219L9.6098 16.3203L10.8877 15.0424L6.10508 10.2599H6.1826C6.1978 10.2599 6.21418 10.2599 6.23171 10.2599C6.71991 10.2608 8.09236 10.2632 9.37285 9.69486C10.0526 9.39315 10.7365 8.91874 11.2472 8.17671C11.6234 7.6301 11.8822 6.97213 11.9939 6.19374H13.8631V4.38657H11.9939C11.8822 3.60819 11.6234 2.95022 11.2472 2.40361C11.1808 2.30716 11.1115 2.21523 11.0397 2.12761H13.8621L13.863 0.320444H6.36506C6.3153 0.32024 6.27069 0.320317 6.23171 0.320385C6.21418 0.320415 6.1978 0.320444 6.1826 0.320444H3.0205L3.02007 2.12761H6.22618C6.7112 2.12774 7.7353 2.13584 8.63975 2.53725C9.08943 2.73683 9.47855 3.02134 9.75851 3.42814C9.92656 3.67232 10.0713 3.98365 10.159 4.38657Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span className="ml-1 text-[24px] font-medium font-mediumOskar leading-[120%]">
                  12
                </span>
              </div>
              <PrimaryButton
                theme="light"
                btnClass="max-h-[42px] !px-3 !py-2"
                onClick={() => {}}
                title="Open"
              />
            </div>
          </div>
          <ThemeCardLayout
            btnTitle="View All"
            title="Leaderboard"
            titleClass="mb-4"
            containerClass=" !bg-white relative  !max-w-full"
          >
            <MissionLeaderboard data={MissionLeaderBoard} />
          </ThemeCardLayout>
        </div>
        <CustomModal
          isModalOpen={popover}
          handleCancel={handleClosePopover}
          handleOk={handleOpenPopover}
          title={Header}
          footer={Footer}
          width={615}
        >
          <div className="px-6">
            <CreateOpportunity
              setCurrent={setCurrent}
              current={current}
              handleClosePopover={handleClosePopover}
              setPopover={setPopover}
            />
          </div>
        </CustomModal>
      </div>
    </Layout>
  );
};

export default Missions;
