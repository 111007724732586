import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { BookmarkOutlined, PlusSign } from "../components/Icons/Icons";
import CardImg1 from "../assets/png/card-img1.png";
import CardImg2 from "../assets/png/card-img2.png";
import { CardDeckProps } from "../type/types";
import { Button } from "antd";
import useIsMac from "../hooks/useIsMac";
import CreateDeck from "../components/Feeds/Modal/CreateDeck";
import { Navigate, useNavigate } from "react-router";

const MyDecks = () => {
  const isMac = useIsMac();
  const [deckName, setDeckName] = useState("");
  const [isCardDeckModalOpen, setIsCardDeckModalOpen] = useState(false);

  const [cardsDeckData, setCardsDeckData] = useState<CardDeckProps[]>([
    {
      id: 1,
      title: "My Saved",
      image: CardImg2,
    },
    {
      id: 2,
      title: "Fashion",
      image: CardImg1,
    },
    {
      id: 3,
      title: "Dance",
      image: CardImg2,
    },
    {
      id: 4,
      title: "Song",
      image: CardImg2,
    },
    {
      id: 5,
      title: "Dance",
      image: CardImg2,
    },
    {
      id: 6,
      title: "Song",
      image: CardImg2,
    },
  ]);

  const [activeCard, setActiveCard] = useState(
    Array(cardsDeckData.length).fill(false)
  );

  const handleActiveCard = (index: number) => {
    const updatedActiveCard = activeCard.map((item, i) =>
      index === i ? !item : item
    );
    setActiveCard(updatedActiveCard);
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <CreateDeck
        deckName={deckName}
        setDeckName={setDeckName}
        isModalOpen={isCardDeckModalOpen}
        handleOk={() => setIsCardDeckModalOpen(false)}
        handleCancel={() => setIsCardDeckModalOpen(false)}
        handleOkaySaveToDeck={() => setIsCardDeckModalOpen(false)}
        handleCancelSaveToDeck={() => setIsCardDeckModalOpen(false)}
      />
      <div className="bg-gray_4 p-6 !w-full items-start justify-center flex h-[calc(100vh_-_98px)]">
        <div className="flex bg-white rounded-xl px-4 py-4 flex-col items-center justify-center gap-0  overflow-x-auto overflow-hidden theme-scrollbar-sm">
          <div className="flex items-center w-full justify-between border-b-gray_4 px-2 pb-4">
            <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              My Decks
            </h4>
            <Button
              style={{
                fontFamily: "OskariG2Regular",
              }}
              onClick={() => setIsCardDeckModalOpen(true)}
              className="flex items-center p-3 h-auto rounded-lg border-[1.5px] border-solid border-gray_2 hover:!border-primary transition-all duration-200 ease-[ease]"
            >
              <PlusSign className={`mr-2 ${isMac && "mb-1"}`} />
              <button className="text-primary text-lg not-italic font-normal leading-[normal] capitalize cursor-pointer">
                Create New Deck
              </button>
            </Button>
          </div>
          <div className="flex gap-4 max-w-[684px] flex-wrap">
            {cardsDeckData.map((item, index) => (
              <div
                key={item.id}
                onClick={() => {
                  handleActiveCard(index);
                  navigate("/user-account/decks/details");
                }}
                className={`bg-white rounded-[12px] border-[1.3px] p-[6px] hover:border-[1.3px] hover:border-solid hover:border-gray_2 transition-all duration-200 ease-[ease] cursor-pointer ${
                  activeCard[index]
                    ? "border-[1.3px] border-solid border-U25_orange hover:border-U25_orange"
                    : ""
                }`}
              >
                {item.image ? (
                  <div className="flex items-center justify-center bg-gray_2 rounded-[7.8px] border-[1.3px] border-solid border-white">
                    <img
                      className="object-cover w-[106px] h-[80px] rounded-md "
                      src={item.image}
                      alt="Card"
                    />
                  </div>
                ) : (
                  <div className="flex items-center justify-center w-[106px] h-[80px] bg-gray_2 rounded-[7.8px] border-[1.3px] border-solid border-white">
                    <BookmarkOutlined />
                  </div>
                )}
                <div className="p-3">
                  <p className="text-primary text-lg not-italic font-normal leading-[normal] text-center">
                    {item.title}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default MyDecks;
