import React from "react";
import Layout from "../components/Layout/Layout";
import LeftSection from "../components/Profile/LeftSection/LeftSection";
import Main from "../components/Profile/Main/Main";
import RightSection from "../components/Profile/RightSection/RightSection";
import useStickyScroll from "../hooks/useStickyScroll";

function Profile() {
  useStickyScroll(".right-section");
  useStickyScroll(".left-section");

  return (
    <Layout>
      <div className="grid bg-gray_4 xl:grid-cols-[1fr_3fr_1fr]  lg:grid-cols-[1fr] gap-[50px] py-6 px-8 ">
        <div className="xl:top-0 xl:sticky h-fit overscroll-y-auto no-scrollbar mb-6 left-section">
          <LeftSection />
        </div>

        <div className="no-scrollbar overflow-y-hidden main-section ">
          <Main />
        </div>
        <div className="right-section xl:sticky xl:top-0 h-fit">
          <RightSection />
        </div>
      </div>
    </Layout>
  );
}

export default Profile;
