import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps, tabsDataProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { CloseDarkOutlined } from "../../Icons/Icons";
import SearchBar from "../../SearchBar/SearchBar";
import UserContactList from "../../UserContactList/UserContactList";
import userListData from "../../../data/userListData";
import PrimaryTabs from "../../PrimaryTabs/PrimaryTabs";

const ShareModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [checkedLists, setCheckedLists] = useState<boolean[]>();

  const anyChecked = checkedLists?.some((item) => item);

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <PrimaryButton
        disabled={!anyChecked}
        onClick={handleOk}
        title="Share"
        width="fit-content"
      />
    </div>
  );

  const homeTabs: tabsDataProps[] = [
    {
      id: "1",
      label: "Recents",
      children: <></>,
    },
    {
      id: "2",
      label: "Connections",
      children: <></>,
    },
    {
      id: "3",
      label: "Groups",
      children: <></>,
    },
  ];

  return (
    <div>
      <CustomModal
        className="house-modal"
        title={
          <div className="flex items-baseline justify-start border-b-gray_4 border-b border-solid w-full">
            <h3 className="pt-6 pb-4 py-6 pl-6  text-dark_85 text-[24px] not-italic font-medium leading-[100%]">
              Share
            </h3>
          </div>
        }
        closeIcon={<CloseDarkOutlined />}
        width={615}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={modalFooter}
      >
        <div className=" px-6 py-4 -mb-1">
          <SearchBar className="" placeholder="Search for contacts" />
          <div className=" my-6 ">
            <PrimaryTabs tabsData={homeTabs} />
          </div>
          <UserContactList
            type={"checkbox"}
            data={userListData}
            containerClassName=""
            noFooter={true}
            checkedLists={(lists) => setCheckedLists(lists)}
          />
          {anyChecked && (
            <div className="mt-6 flex space-x-6">
              <button className="flex items-center cursor-pointer bg-gray_4 w-fit pl-1 pr-4 py-1 rounded-md border border-solid hover:border-primary transition-all duration-200 ease-[ease]">
                <span className="h-8 w-8   items-center flex justify-center ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.354 8.906L10.452 11.294M10.446 4.706L6.354 7.094M13.8 3.8C13.8 4.79411 12.9941 5.6 12 5.6C11.0059 5.6 10.2 4.79411 10.2 3.8C10.2 2.80589 11.0059 2 12 2C12.9941 2 13.8 2.80589 13.8 3.8ZM6.6 8C6.6 8.99411 5.79411 9.8 4.8 9.8C3.80589 9.8 3 8.99411 3 8C3 7.00589 3.80589 6.2 4.8 6.2C5.79411 6.2 6.6 7.00589 6.6 8ZM13.8 12.2C13.8 13.1941 12.9941 14 12 14C11.0059 14 10.2 13.1941 10.2 12.2C10.2 11.2059 11.0059 10.4 12 10.4C12.9941 10.4 13.8 11.2059 13.8 12.2Z"
                      stroke="#1F1F1F"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <p className="ml-2">Share</p>
              </button>
              <button className="flex items-center cursor-pointer bg-gray_4 w-fit pl-1 pr-4 py-1 rounded-md border border-solid hover:border-primary transition-all duration-200 ease-[ease]">
                <span className=" cursor-pointer h-8 w-8 items-center flex justify-center ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.0026 11.3334H4.66927C2.82832 11.3334 1.33594 9.84103 1.33594 8.00008C1.33594 6.15913 2.82832 4.66675 4.66927 4.66675H6.0026M10.0026 11.3334H11.3359C13.1769 11.3334 14.6693 9.84103 14.6693 8.00008C14.6693 6.15913 13.1769 4.66675 11.3359 4.66675H10.0026M4.66927 8.00008L11.3359 8.00008"
                      stroke="#1F1F1F"
                      stroke-width="1.2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
                <p className="ml-2">Copy Link</p>
              </button>
              <button className="flex items-center cursor-pointer bg-gray_4 w-fit pl-1 pr-4 py-1 rounded-md border border-solid hover:border-primary transition-all duration-200 ease-[ease]">
                <span className="h-8 w-8 items-center flex justify-center ">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7005 3.27207C12.0893 2.65469 11.3613 2.16518 10.5589 1.8321C9.75658 1.49903 8.89593 1.32904 8.02719 1.33207C4.38719 1.33207 1.42052 4.29874 1.42052 7.93874C1.42052 9.1054 1.72719 10.2387 2.30052 11.2387L1.36719 14.6654L4.86719 13.7454C5.83385 14.2721 6.92052 14.5521 8.02719 14.5521C11.6672 14.5521 14.6339 11.5854 14.6339 7.9454C14.6339 6.17874 13.9472 4.51874 12.7005 3.27207ZM8.02719 13.4321C7.04052 13.4321 6.07385 13.1654 5.22719 12.6654L5.02719 12.5454L2.94719 13.0921L3.50052 11.0654L3.36719 10.8587C2.81902 9.98338 2.52795 8.97157 2.52719 7.93874C2.52719 4.91207 4.99385 2.4454 8.02052 2.4454C9.48719 2.4454 10.8672 3.01874 11.9005 4.05874C12.4122 4.56805 12.8177 5.17385 13.0934 5.84102C13.3692 6.5082 13.5099 7.22348 13.5072 7.9454C13.5205 10.9721 11.0539 13.4321 8.02719 13.4321ZM11.0405 9.3254C10.8739 9.2454 10.0605 8.8454 9.91385 8.7854C9.76052 8.73207 9.65385 8.7054 9.54052 8.8654C9.42719 9.03207 9.11385 9.4054 9.02052 9.51207C8.92719 9.6254 8.82719 9.63874 8.66052 9.55207C8.49385 9.47207 7.96052 9.29207 7.33385 8.73207C6.84052 8.29207 6.51385 7.75207 6.41385 7.5854C6.32052 7.41874 6.40052 7.33207 6.48719 7.2454C6.56052 7.17207 6.65385 7.05207 6.73385 6.95874C6.81385 6.8654 6.84719 6.79207 6.90052 6.6854C6.95385 6.57207 6.92719 6.47874 6.88719 6.39874C6.84719 6.31874 6.51385 5.5054 6.38052 5.17207C6.24719 4.85207 6.10719 4.89207 6.00719 4.8854H5.68719C5.57385 4.8854 5.40052 4.9254 5.24719 5.09207C5.10052 5.25874 4.67385 5.65874 4.67385 6.47207C4.67385 7.2854 5.26719 8.07207 5.34719 8.17874C5.42719 8.29207 6.51385 9.95874 8.16719 10.6721C8.56052 10.8454 8.86719 10.9454 9.10719 11.0187C9.50052 11.1454 9.86052 11.1254 10.1472 11.0854C10.4672 11.0387 11.1272 10.6854 11.2605 10.2987C11.4005 9.91207 11.4005 9.5854 11.3539 9.51207C11.3072 9.43874 11.2072 9.4054 11.0405 9.3254Z"
                      fill="#1F1F1F"
                    />
                  </svg>
                </span>
                <p className="ml-2">Whatsapp </p>
              </button>
            </div>
          )}
        </div>
      </CustomModal>
    </div>
  );
};

export default ShareModal;
