import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

type GlobalContextType = {
  threadActive: boolean;
  setThreadActive: Dispatch<SetStateAction<boolean>>;
  anotherState: string;
  setAnotherState: Dispatch<SetStateAction<string>>;
  setIsReplied: Dispatch<SetStateAction<boolean>>;
  isReplied: boolean;
  setRefreshCount: Dispatch<SetStateAction<number>>;
  refreshCount: number;
};

const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

type GlobalProviderProps = {
  children: ReactNode;
};

export const GlobalProvider: React.FC<GlobalProviderProps> = ({ children }) => {
  const [threadActive, setThreadActive] = useState(false);
  const [anotherState, setAnotherState] = useState("Initial Value");
  const [isReplied, setIsReplied] = useState(false);
  const [refreshCount, setRefreshCount] = useState(60);

  const value: GlobalContextType = {
    threadActive,
    setThreadActive,
    anotherState,
    setAnotherState,
    isReplied,
    setRefreshCount,
    refreshCount,
    setIsReplied,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};
