import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import SearchBar from "../../SearchBar/SearchBar";
import Member1 from "../../../assets/png/chatMember1.png";
import {
  ChatAddMemberIcon,
  ChatMemberTickIcon,
  MemberCloseIcon,
} from "../../Icons/Icons";
import { useEffect, useState } from "react";
import { useGlobal } from "../../../context/GlobalContext";
interface AddGroupMembersModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsGroupDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMembersData: any;
}
const AddGroupMembersModal: React.FC<AddGroupMembersModalProps> = ({
  modal,
  setModal,
  setIsGroupDetailModalOpen,
  setSelectedMembersData,
}) => {
  const data = [
    {
      id: 1,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 2,
      name: "Kanishka Reddy",
      username: "e/kanid hawak murph",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 3,
      name: "Kanishka Reddy",
      username: "e/scoot",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 4,
      name: "Kanishka Reddy",
      username: "e/kahani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 5,
      name: "Kanishka Reddy",
      username: "e/bawar",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 6,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 7,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 8,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 9,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 10,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 11,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 12,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 13,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 14,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 15,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 16,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 17,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 18,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
    {
      id: 19,
      name: "Kanishka Reddy",
      username: "e/kani",
      isMemberAdded: false,
      imageSrc: Member1,
    },
  ];

  const [items, setItems] = useState(data);

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        Add group Members
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        theme="dark"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          setIsGroupDetailModalOpen(true);
        }}
        title="Next"
      />
    </div>
  );
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };

  type Item = {
    id: number;
    isMemberAdded: boolean;
    name: string;
    username: string;
    imageSrc: any;
  };

  const [filteredItems, setFilteredItems] = useState<Item[]>([]);
  const { refreshCount } = useGlobal();

  useEffect(() => {
    const filteredItems = items.filter((item) => item.isMemberAdded === true);
    setFilteredItems(filteredItems);
    setSelectedMembersData(filteredItems);
  }, [items]);

  return (
    <CustomModal
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6 py-4 pb-2 ">
        <SearchBar
          className="h-10 cursor-pointer  "
          placeholder="Search for members"
        />
        <div className=" py-6">
          {filteredItems.length > 0 && (
            <span className="text-base not-italic font-normal leading-[normal]">
              {refreshCount} Members
            </span>
          )}
          <div className="flex gap-4 overflow-y-auto theme-scrollbar-sm">
            {filteredItems?.map((item, index) => (
              <div className=" relative w-fit mt-4 flex flex-col min-w-[56px] max-w-[56px]">
                <img
                  className=" relative min-w-[56px] max-w-[56px] min-h-[56px] max-h-[56px]"
                  src={Member1}
                  alt="member"
                ></img>
                <span
                  onClick={() => {
                    const itemId = item.id;
                    const updatedItems = [...items];
                    const itemIndex = updatedItems.findIndex(
                      (item) => item.id === itemId
                    );

                    if (itemIndex !== -1) {
                      updatedItems[itemIndex].isMemberAdded = false;
                      setItems(updatedItems);
                    }
                  }}
                  className="p-1 border border-gray_4 h-5 w-5 absolute top-[-6px] right-[-9px] bg-white rounded-[400px] flex justify-center items-center cursor-pointer"
                >
                  <MemberCloseIcon />
                </span>
                <p className="flex mt-3 justify-center items-center text-gray-1 text-sm not-italic font-normal leading-normal line-clamp-1 max-w-full truncate ">
                  {item.username}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="gap-2 space-y-2  overflow-auto  theme-scrollbar-sm ">
          {items.map((item, index) => (
            <div key={index} className="flex justify-between">
              <div className="flex">
                <img
                  className="relative min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] rounded-md"
                  src={item.imageSrc}
                  alt="member"
                />
                <div className="ml-3">
                  <h6>{item.name}</h6>
                  <span className="mt-1 text-link_hover text-sm not-italic font-normal leading-[normal]">
                    {item.username}
                  </span>
                </div>
              </div>
              <span
                className="flex justify-center items-center cursor-pointer"
                onClick={() => {
                  const itemId = item.id;
                  const updatedItems = [...items];
                  const itemIndex = updatedItems.findIndex(
                    (item) => item.id === itemId
                  );

                  if (itemIndex !== -1) {
                    updatedItems[itemIndex].isMemberAdded =
                      !updatedItems[itemIndex].isMemberAdded;
                    setItems(updatedItems);
                  }
                }}
              >
                {item.isMemberAdded ? (
                  <ChatMemberTickIcon />
                ) : (
                  <ChatAddMemberIcon />
                )}
              </span>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default AddGroupMembersModal;
