import React from "react";
import FeedComp from "../Profile/Main/components/FeedComp/FeedComp";
import profilePic from "../../assets/png/community-user-picture.png";
import BannerCard from "../Profile/Main/components/BannerCard/BannerCard";
import { EditProfile } from "../EditProfile/EditProfile";
import { useState } from "react";

function MainSection() {
  const [editModal, setEditModal] = useState(false);
  return (
    <section className="flex flex-col gap-6 no-scrollbar  overflow-y-scroll">
      <BannerCard
        image={profilePic}
        Edit={true}
        onEditClick={() => {
          setEditModal(true);
        }}
      />
      <FeedComp FeedUser={profilePic} userId={"a/kani"} />
      <EditProfile popover={editModal} setPopover={setEditModal} />
    </section>
  );
}

export default MainSection;
