import Experience from "./components/Experience";
import Interest from "./components/Interest";
import Resume from "./components/Resume";
import Skills from "./components/Skills";
import { AboutData } from "./components/About";
import SocialLinks from "./components/SocialLinks";
import useIsMac from "../../hooks/useIsMac";
import { useEffect, useState } from "react";
import { EditOutlined } from "../Icons/Icons";
import { EditProfile } from "../EditProfile/EditProfile";

interface UserAboutProps {
  edit?: boolean;
}
const UserAbout: React.FC<UserAboutProps> = ({ edit = false }) => {
  const isMac = useIsMac();
  const [view, setView] = useState(false);
  const [editModal, setEditModal] = useState(false);

  useEffect(() => {
    if (!edit) {
      setView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-5 bg-white rounded-xl h-fit max-w-full">
      <h6 className="text-primary text-xl font-medium leading-[100%]">
        {edit ? "About Me" : "About"}
      </h6>
      <div
        onMouseEnter={() => setView(true)}
        onMouseLeave={() => setView(false)}
        className="flex flex-col mt-5 gap-4"
      >
        <div className="flex justify-between mb-4">
          <h6 className="text-primary text-[17px] font-medium leading-[120%]">
            Basics
          </h6>
          {edit && view ? (
            <span
              onClick={() => {
                setEditModal(!editModal);
              }}
              className=" cursor-pointer flex justify-center items-center edit-icon "
            >
              <EditOutlined />
            </span>
          ) : null}
          <EditProfile
            isBasics={true}
            popover={editModal}
            setPopover={setEditModal}
          />
        </div>
        <div className="flex flex-col gap-3">
          {AboutData.map((item) => {
            return (
              <div key={item.key} className="flex gap-1 items-center">
                <span
                  className={`${
                    isMac && "mb-[6px]"
                  } max-w-[16px] min-w-[16px] min-h-[16px] max-h-4 flex justify-center items-center`}
                >
                  {item.icon}
                </span>
                <h6 className="ml-[3px] text-gray_6 text-[15px] font-normal leading-[120%] capitalize">{`${item.valueKey} :`}</h6>
                <h6 className="text-primary text-[15px] font-normal leading-[120%] capitalize">
                  {item.valueData}
                </h6>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-6">
        <h6 className="text-primary text-[17px] font-medium leading-[120%]">
          Bio
        </h6>
        <p className="mt-4">
          Bio Lorem ipsum dolor sit amet consectetur. Proin proin mattis eget
          ipsum congue sed vitae. Duis suspendisse fermentum quis morbi
          porttitor.
        </p>
      </div>
      <div className="mt-6">
        <h6 className="text-primary text-[17px] font-medium leading-[120%]">
          House
        </h6>
        <div className="flex gap-4 items-center mt-4">
          <span className="min-w-[40px] max-w-[40px] min-h-[40px] flex justify-center items-center rounded-[50%]">
            <div
              className="rounded-[466px] border border-gray_8 min-w-[40px] min-h-[40px] flex justify-center items-center "
              style={{ backgroundColor: "yellow" }}
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.4974 17.5164V11.3497C7.4974 10.883 7.4974 10.6496 7.58823 10.4714C7.66812 10.3146 7.79561 10.1871 7.95241 10.1072C8.13067 10.0164 8.36402 10.0164 8.83073 10.0164H11.1641C11.6308 10.0164 11.8641 10.0164 12.0424 10.1072C12.1992 10.1871 12.3267 10.3146 12.4066 10.4714C12.4974 10.6496 12.4974 10.883 12.4974 11.3497V17.5164M1.66406 7.93302L9.1974 2.28302C9.48428 2.06786 9.62772 1.96028 9.78526 1.91881C9.92432 1.88221 10.0705 1.88221 10.2095 1.91881C10.3671 1.96028 10.5105 2.06786 10.7974 2.28302L18.3307 7.93302M3.33073 6.68302V14.8497C3.33073 15.7831 3.33073 16.2498 3.51239 16.6063C3.67218 16.9199 3.92714 17.1749 4.24075 17.3347C4.59727 17.5164 5.06398 17.5164 5.9974 17.5164H13.9974C14.9308 17.5164 15.3975 17.5164 15.7541 17.3347C16.0677 17.1749 16.3226 16.9199 16.4824 16.6063C16.6641 16.2498 16.6641 15.7831 16.6641 14.8497V6.68302L11.5974 2.88302C11.0236 2.4527 10.7368 2.23754 10.4217 2.1546C10.1436 2.08139 9.85124 2.08139 9.57312 2.1546C9.25805 2.23754 8.97116 2.4527 8.3974 2.88302L3.33073 6.68302Z"
                  stroke="white"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>{" "}
          </span>
          <div className="flex flex-col gap-0.5 ">
            <h6 className="text-primary text-[15px] font-medium leading-[120%]">
              Yellow House
            </h6>
            <p className="text-purple_1 text-[13px] font-normal leading-[120%]">
              #standforlearning
            </p>
          </div>
        </div>
      </div>
      <div className="mt-6">
        <Interest edit={edit} />
      </div>
      <div className="mt-6">
        <Resume edit={edit} />
      </div>
      <div className="mt-6">
        <Experience edit={edit} />
      </div>
      <div className="mt-6">
        <SocialLinks edit={edit} />
      </div>
      <div className="mt-6">
        <Skills edit={edit} />
      </div>
    </div>
  );
};

export default UserAbout;
