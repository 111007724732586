import { U25black } from "../components/Icons/Icons";

export const archiveDataLists = [
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    isMuted: true,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: true,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
  {
    name: "Guru Nanak Institutions",
    user: "e/kani",
    action: "shared a profile",
    timeAgo: "2m ago",
    isPinned: false,
    isActive: false,
    img: <U25black />,
  },
];
