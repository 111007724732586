import ThemeCardLayout from "../../../ThemeCardLayout/ThemeCardLayout";
import PrimaryTabs from "../../../PrimaryTabs/PrimaryTabs";
import { tabsDataProps } from "../../../../type/types";
import { Avatar } from "antd";
import { EyeWhiteOutlined, U25VerifiedUser } from "../../../Icons/Icons";
import AllTab from "../AllTab/AllTab";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import U25User from "../../../../assets/png/community-user-picture.png";
import U25CoinsBg from "../../../../assets/svg/under25-coins.svg";
import { Link } from "react-router-dom";
import useIsMac from "../../../../hooks/useIsMac";

const LeftSection = () => {
  const tabsData: tabsDataProps[] = [
    {
      id: "1",
      label: "All",
      children: <AllTab />,
    },
    {
      id: "2",
      label: "Public",
      children: <AllTab />,
    },
    {
      id: "2",
      label: "Private",
      children: <AllTab />,
    },
  ];

  const isMac = useIsMac();

  return (
    <aside>
      <ThemeCardLayout
        containerClass="mb-5"
        title="user-account"
        btnTitle={
          <Link to={"/user-account"}>
            <span className="inline-flex items-center gap-2">
              <span className=" min-w-[15px] max-w-[15px] min-h-[15px] max-h-[15px]">
                <EyeWhiteOutlined className={`${isMac && "mb-1"}`} />
              </span>
              <span className="text-white text-[15px] not-italic font-normal leading-[normal]">
                View Profile
              </span>
            </span>
          </Link>
        }
      >
        <div className="flex items-center gap-2">
          <Avatar
            src={U25User}
            size={80}
            shape="square"
            alt="user"
            className="rounded-lg max-w-[80px] min-w-[80px] max-h-[80px] min-h-[80px]"
          />
          <div>
            <div className="mb-2">
              <div className="flex items-start gap-1 mb-1">
                <h2 className="text-primary text-xl not-italic font-medium leading-[100%]">
                  Kanishka Reddy
                </h2>
                <U25VerifiedUser className="mt-1" />
              </div>
              <p className="text-gray_1 text-[17px] not-italic font-normal leading-[120%]">
                a/kani
              </p>
            </div>
            <div className="flex items-center gap-3 lg:flex-col lg:gap-[2px] lg:items-start">
              <p className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                <span className="text-U25_orange text-[13px] not-italic font-medium leading-[120%]">
                  1.91k
                </span>{" "}
                Connections
              </p>
              <p className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                <span className="text-blue_1 text-[13px] not-italic font-medium leading-[120%]">
                  14
                </span>{" "}
                Cards
              </p>
            </div>
          </div>
        </div>
      </ThemeCardLayout>
      <ThemeCardLayout
        title="My Spaces"
        btnTitle="View All"
        containerClass="mb-5"
      >
        <div className="">
          <PrimaryTabs
            tabsData={tabsData}
            innerClass="!overflow-y-hidden !pb-2 !mb-2 theme-scrollbar-sm"
          />
        </div>
      </ThemeCardLayout>
      <div
        style={{
          backgroundImage: `url(${U25CoinsBg})`,
        }}
        className="flex bg-right bg-contain bg-no-repeat bg-primary shadow-[-1.42px_1.42px_1.42px_0px_rgba(255,255,255,0.24)_inset] backdrop-blur-[6px] rounded-xl p-4"
      >
        <div className="w-[144px]">
          <h3 className="text-white text-[17px] not-italic font-normal leading-[120%] mb-2">
            Invite any of your 5 friends and earn ₹50
          </h3>
          <Link to={"/invite"}>
            <PrimaryButton
              className="w-[60px] !h-7"
              fontClass="text-[13px]"
              title="Invite"
              theme="light"
              size="regular"
            />
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default LeftSection;
