import React, { useEffect, useState } from "react";
import { Avatar, Button } from "antd";
import { KebabMenu, XCloseSm } from "../Icons/Icons";
import { RequestsBoxTypes } from "../../type/types";

interface RequestsBoxProps {
  data: RequestsBoxTypes[];
  requestsCount?: (count: number) => void;
  notificationRequest?: boolean;
  containerClass?: string;
  menu?: boolean;
}

const RequestsBox: React.FC<RequestsBoxProps> = ({
  data,
  requestsCount,
  notificationRequest = false,
  containerClass,
  menu = false,
}) => {
  const [newItemsArray, setNewItemsArray] = useState(data);
  const [clickAccept, setClickAccept] = useState(
    new Array(data.length).fill(false)
  );

  const handleAccept = (position: number) => {
    const newCheckedState = clickAccept.map((item, index) => {
      return position === index ? !item : item;
    });
    setClickAccept(newCheckedState);
    setTimeout(() => {
      setNewItemsArray(
        newItemsArray.filter((_, index) => {
          return position !== index;
        })
      );
      setClickAccept(new Array(data.length).fill(false));
      requestsCount && requestsCount(newItemsArray.length);
    }, 1000);
  };

  const handleCloseRequest = (position: number) => {
    setNewItemsArray(
      newItemsArray.filter((item, index) => {
        return position !== index;
      })
    );
    setClickAccept(new Array(data.length).fill(false));
  };

  useEffect(() => {
    requestsCount && requestsCount(newItemsArray.length);
  }, [newItemsArray.length, requestsCount]);

  return (
    <div className={containerClass}>
      {newItemsArray.map((item, index) => {
        if (!notificationRequest) {
          return (
            <div
              key={item.id}
              className="flex items-center justify-between [&:not(:last-child)]:mb-4"
            >
              <div className="flex items-center gap-2">
                <Avatar
                  src={item.image}
                  size={40}
                  shape="square"
                  alt="user"
                  className="rounded-md mb-[2px]"
                />
                <div>
                  <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-1">
                    {item.fullName}
                  </p>
                  <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                    {item.userName}
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <Button
                  onClick={() => handleAccept(index)}
                  className="flex bg-blue_1 px-4 py-3 rounded-lg h-auto"
                >
                  <span className="text-white text-[15px] not-italic font-normal leading-[normal]">
                    {clickAccept[index] ? "Accepting" : "Accept"}
                  </span>
                </Button>
                <button
                  onClick={() => handleCloseRequest(index)}
                  className="flex border p-1.5 rounded-lg border-solid border-gray_4 hover:bg-gray_2 transition-all duration-200 ease-[ease] h-auto"
                >
                  <XCloseSm />
                </button>
              </div>
            </div>
          );
        } else {
          return (
            <div key={item.id} className="flex gap-3 [&:not(:last-child)]:mb-5">
              <Avatar
                src={item.image}
                size={40}
                shape="square"
                alt="user"
                className="rounded-md mb-[2px]"
              />
              <div>
                <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-1">
                  <span className="text-primary text-[17px] not-italic font-medium leading-[120%] mr-1">
                    {item.fullName}
                  </span>
                  sent a connection request.
                </p>
                <p className="text-gray_6 text-[13px] not-italic font-normal leading-[normal]">
                  {item.timeAgo} ago
                </p>
                <div className="flex items-center gap-3 mt-4">
                  <Button
                    onClick={() => handleAccept(index)}
                    className="flex bg-blue_1 px-4 py-3 rounded-lg h-auto"
                  >
                    <span className="text-white text-[15px] not-italic font-normal leading-[normal]">
                      {clickAccept[index] ? "Accepting" : "Accept"}
                    </span>
                  </Button>
                  <button
                    onClick={() => handleCloseRequest(index)}
                    className="flex border p-1.5 rounded-lg border-solid border-gray_4 hover:bg-gray_2 transition-all duration-200 ease-[ease] h-auto"
                  >
                    <XCloseSm />
                  </button>
                </div>
              </div>
              {menu && (
                <button className="flex self-start ml-auto">
                  <KebabMenu />
                </button>
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default RequestsBox;
