import React from "react";
import { LocationPin } from "../../../Icons/Icons";
import { EventDataTypes } from "../../../../type/types";
import { useNavigate } from "react-router-dom";
import useIsMac from "../../../../hooks/useIsMac";

interface EventBoxProps {
  data: EventDataTypes[];
}

const EventBox: React.FC<EventBoxProps> = ({ data }) => {
  const Location = useNavigate();
  const isMac = useIsMac();

  return (
    <>
      {data.map((item) => {
        return (
          <div
            key={item.id}
            onClick={() => Location(item.eventLink)}
            className="border lg:w-full bg-white shadow-[-1.42px_1.42px_1.42px_0px_rgba(255,255,255,0.24)_inset] backdrop-blur-[6px] rounded-xl border-solid border-gray_4 p-2 [&:not(:last-child)]:mb-4 cursor-pointer"
          >
            <div className="flex h-7 items-center mb-2">
              <h3 className="text-primary text-[15px] not-italic font-medium leading-[120%]">
                {item.title}
              </h3>
            </div>
            <div className="flex items-center mb-2">
              <p className="text-[rgba(31,31,31,0.6)] text-[13px] not-italic font-normal leading-[120%] me-3">
                {item.date}
              </p>
              <p className="text-[rgba(31,31,31,0.6)] text-[13px] not-italic font-normal leading-[120%] before:inline-block before:content-['•'] before:align-middle before:me-3">
                {item.timing}
              </p>
            </div>
            <div className="flex items-center">
              <div className="mr-2">
                <LocationPin className={`${isMac && "mb-[6px]"}`} />
              </div>
              <p className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                {item.venue}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EventBox;
