import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { EditOutlined } from "../../Icons/Icons";

interface EditSkillsModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  onBtnClick?: () => void;
  title: string;
}
const EditSkillModal: React.FC<EditSkillsModalProps> = ({
  popover,
  setTitle,
  setPopover,
  title,
  onBtnClick = () => {},
}) => {
  const Skills = ["App Testing and Dev", "Design", "Card Creation"];
  const handleClosePopover = () => {
    setPopover(false);
  };
  const handleOpenPopover = () => {
    setPopover(true);
  };
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Save"
      />
    </div>
  );
  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="mt-4 mx-6 flex flex-col gap-5 mb-5">
        {Skills.map((skill, index) => {
          return (
            <div className="flex flex-col gap-1">
              <label
                htmlFor="skill-container"
                className="text-[#808080] text-[17px] font-[400] leading-[120%]"
              >
                Skill Name
              </label>
              <div
                id="skill-container"
                className="py-3 px-4 flex justify-between items-center border border-[#F2F2F2] rounded-[8px] cursor-pointer"
                key={index}
              >
                <h4 className="text-[#1F1F1F] text-[17px] font-normal leading-[120%]">
                  {skill}
                </h4>
                <span
                  onClick={() => {
                    onBtnClick();
                    handleClosePopover();
                  }}
                  className="max-w-[28px] min-w-[28px] max-h-[28px] min-h[28px] flex justify-center items-center"
                >
                  <EditOutlined />
                </span>
              </div>
            </div>
          );
        })}
        <PrimaryButton
          onClick={() => {
            onBtnClick();
            handleClosePopover();
            setTitle("Add Skill");
          }}
          title="Add"
          size="regular"
          theme="light"
          btnClass="max-h-[28px]"
        />
      </div>
    </CustomModal>
  );
};

export default EditSkillModal;
