import { useState } from "react";
import PrimaryButton from "../../../PrimaryButton/PrimaryButton";
import OwlCarousel from "react-owl-carousel";
import U25IconDark from "../../../../assets/svg/u25-icon-dark.svg";
import "./SpacesContent.scss";
import SpaceCarousel from "../SpaceCarousel/SpaceCarousel";
import MySpaces from "../MySpaces/MySpaces";
import CreateSpace from "../../../Feeds/Modal/CreateSpace";
import StoryViewerModal from "../StoryViewerModal/StoryViewerModal";
import TestiMonials from "../Testimonials/TestiMonials";

const SpacesContent = () => {
  const [openCreateSpaceModal, setOpenCreateSpaceModal] = useState(false);
  const [openStoryModal, setOpenStoryModal] = useState(false);

  const handleOpenCreateSpaceModal = () => {
    setOpenCreateSpaceModal(true);
  };

  const handleCancelCreateSpaceModal = () => {
    setOpenCreateSpaceModal(false);
  };

  const handleOkayStoryModal = () => {
    setOpenStoryModal(true);
  };

  const handleCancelStoryModal = () => {
    setOpenStoryModal(false);
  };

  const storyData = [
    {
      id: "1",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "2",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "3",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "4",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "5",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "6",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "7",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "8",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "9",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "10",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "11",
      image: U25IconDark,
      userName: "Story",
    },
    {
      id: "12",
      image: U25IconDark,
      userName: "Story",
    },
  ];

  return (
    <>
      <StoryViewerModal
        isModalOpen={openStoryModal}
        handleCancel={handleCancelStoryModal}
        handleOk={handleOkayStoryModal}
      >
        <TestiMonials />
      </StoryViewerModal>
      <div className="p-5 bg-white rounded-xl ">
        <div className="flex justify-between items-center mb-5 w-full">
          <h1 className="text-primary text-2xl not-italic font-medium leading-[100%]">
            Spaces
          </h1>
          <PrimaryButton
            title="Create your own space"
            onClick={handleOpenCreateSpaceModal}
          />
        </div>
        <div className="w-full">
          <div className="grid ">
            <div className="lg:max-w-[1026px] max-w-[695px] flex justify-center px-0 py-5 mb-5 border-b border-[#F2F2F2]">
              <OwlCarousel
                className="owl-theme user-stories-carousel relative"
                navClass={[
                  "prev-btn nav-btn bg-gray_4 w-[28px] h-[28px] rounded-[50%] mt-0 absolute -translate-y-2/4 left-0 top-[45px] cursor-pointer select-none p-1",
                  "next-btn nav-btn bg-gray_4 w-[28px] h-[28px] rounded-[50%] mt-0 absolute -translate-y-2/4 right-0 top-[45px] cursor-pointer select-none p-1",
                ]}
                items={8}
                margin={30}
                dots={false}
                responsive={{
                  448: {
                    items: 5,
                  },
                  992: {
                    items: 5,
                  },
                  1024: {
                    items: 6,
                  },
                  1100: {
                    items: 8,
                  },
                }}
                navElement="div"
                nav
              >
                {storyData?.map((item) => (
                  <div onClick={handleOkayStoryModal} className="w-[88px]">
                    <div className="h-[88px] w-[88px] p-1 rounded-[50%] border border-solid border-blue_1 cursor-pointer mb-2">
                      <div className="flex items-center justify-center h-full w-full rounded-[50%] bg-lightViolet">
                        <div className="flex items-center justify-center h-[40px] w-[40px]">
                          <img
                            src={item.image}
                            className="max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
                            alt="user story"
                          />
                        </div>
                      </div>
                    </div>
                    <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%] text-center">
                      Story
                    </p>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
          <div className="grid">
            <div className="lg:max-w-[1026px] md:max-w-[695px]   mb-5">
              <SpaceCarousel />
            </div>
          </div>
          <MySpaces />
        </div>
        <CreateSpace
          isModalOpen={openCreateSpaceModal}
          handleOk={handleOpenCreateSpaceModal}
          handleCancel={handleCancelCreateSpaceModal}
        />
      </div>
    </>
  );
};

export default SpacesContent;
