import { Tabs } from "antd";
import Layout from "../components/Layout/Layout";
import { useMemo, useState } from "react";
import "../components/Settings/settings.scss";
import AccountTab from "../components/Settings/components/AccountTab";
import BlockListTab from "../components/Settings/components/BlockListTab";
import DeleteAccModal from "../components/DeleteAccModal/DeleteAccModal";

function Settings() {
  const [modal, setModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [position, setPosition] = useState<PositionType[]>(["left"]);

  const TabItems = [
    {
      key: "1",
      label: "Account information",
      children: <AccountTab />,
    },
    {
      key: "2",
      label: "Blocked accounts",
      children: <BlockListTab />,
    },
    {
      key: "3",
      label: "Privacy policy",
      children: "content 3",
    },
    {
      key: "4",
      label: "Terms of service",
      children: "content 4",
    },
    {
      key: "5",
      label: "Delete account",
      children: "content 5",
    },
  ];

  const handleTabChange = (key: string) => {
    if (key === "5") {
      setModal(true);
    } else {
      setActiveTab(key);
    }
  };

  type PositionType = "left";
  const OperationsSlot: Record<PositionType, React.ReactNode> = {
    left: (
      <h4 className="text-[#1F1F1F] text-start text-[20px] font-medium leading-[100%]">
        Settings{" "}
      </h4>
    ),
  };

  const slot = useMemo(() => {
    if (position.length === 0) return null;

    return position.reduce(
      (acc, direction) => ({ ...acc, [direction]: OperationsSlot[direction] }),
      {}
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position]);

  return (
    <Layout>
      <div className="bg-gray_4  py-6 flex justify-center h-[86vh]">
        <Tabs
          activeKey={activeTab}
          items={TabItems}
          type="card"
          tabPosition="left"
          className="settings-tab"
          tabBarExtraContent={slot}
          onChange={handleTabChange}
        />
      </div>
      <DeleteAccModal modal={modal} setModal={setModal} />
    </Layout>
  );
}

export default Settings;
