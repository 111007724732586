import { Tabs } from "antd";

interface TabData {
  key: string;
  label: string;
  children: React.ReactNode;
}

interface TabCompProps {
  className?: string;
  tabItem: TabData[];
  isBasics?: boolean;
  tabPosition?: "top" | "right" | "bottom" | "left";
  type?: "line" | "card" | "editable-card";
}
export const TabComp: React.FC<TabCompProps> = ({
  className,
  tabItem,
  tabPosition = "top",
  type = "line",
  isBasics,
}) => {
  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Tabs
      type={type}
      tabPosition={tabPosition}
      centered
      className={className}
      defaultActiveKey={`${isBasics ? "2" : " 1"}`}
      items={tabItem}
      onChange={onChange}
    />
  );
};
