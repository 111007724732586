import React from "react";
import { HouseModalProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";
import { XCloseMd } from "../../Icons/Icons";

const DeleteMessageModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const CardHeader = (
    <div className="pt-6 pb-4 px-6 border-b border-gray_4">
      <h6 className="text-dark_85 text-[24px] font-medium leading-[100%] capitalize">
        Delete Message
      </h6>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleCancel();
        }}
        title="Cancel"
      />
      <PrimaryButton
        theme="warning"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleCancel();
        }}
        title="Delete"
      />
    </div>
  );
  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={modalFooter}
        width={615}
        closeIcon={<XCloseMd />}
      >
        <div className="px-6 py-4 pb-2">
          <p className="text-gray_6 text-[17px] font-normal leading-[120%] ">
            Are you sure you want to delete this message?
          </p>
        </div>
      </CustomModal>
    </>
  );
};

export default DeleteMessageModal;
