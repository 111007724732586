import Media1 from "../../../assets/png/space-media-1.png";
import Media2 from "../../../assets/png/space-media-2.png";
import Media3 from "../../../assets/png/space-media-3.png";
import Media4 from "../../../assets/png/space-media-5.png";
import Media5 from "../../../assets/png/space-media-4.png";
import Media6 from "../../../assets/png/space-media-6.png";
import Media7 from "../../../assets/png/space-media-7.png";
import Media8 from "../../../assets/png/space-media-8.png";
import Media9 from "../../../assets/png/space-media-9.png";
import Media10 from "../../../assets/png/space-media-10.png";
import Media11 from "../../../assets/png/space-media-11.png";
import Media12 from "../../../assets/png/space-media-12.png";
import Media13 from "../../../assets/png/space-media-13.png";
import Media14 from "../../../assets/png/space-media-14.png";
import Media15 from "../../../assets/png/space-media-15.png";

const ImageAttachment = () => {
  return (
    <div>
      <div className="mb-6">
        <p className="text-link_hover text-sm not-italic font-normal leading-[normal] mb-3">
          Jul, 2023
        </p>

        <div className="flex flex-wrap items-center gap-2">
          <div className="rounded">
            <img
              src={Media1}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media2}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media3}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media4}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media5}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
        </div>
      </div>

      <div className="mb-6">
        <p className="text-link_hover text-sm not-italic font-normal leading-[normal] mb-3">
          Jun, 2023
        </p>

        <div className="flex flex-wrap items-center gap-2">
          <div className="rounded">
            <img
              src={Media6}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media7}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media8}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media9}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media10}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media11}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media12}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media13}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media14}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
          <div className="rounded">
            <img
              src={Media15}
              alt="media files"
              className="max-w-[190px] min-w-[190px] max-h-[190px] min-h-[190px] object-cover rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageAttachment;
