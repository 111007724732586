import { Slider } from "antd";

interface SliderLineProps {
    defaultValue?: number,
    className?: string,
    onChange: (value: number) => void;
}
const SliderLine: React.FC<SliderLineProps> = ({ defaultValue = 30, className, onChange }) => {
    const handleSliderChange = (value: number) => {
        // Call the onChange callback to update the state
        onChange(value);
    };
    return (
        <Slider onChange={handleSliderChange} className={className} max={100} min={10} step={5} defaultValue={defaultValue} />
    )
}

export default SliderLine