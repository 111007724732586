import React from 'react'
import PrimaryButton from '../../PrimaryButton/PrimaryButton'
import './add_item_comp.scss'
interface AddItemProps {
    title: string,
    desc: string,
    onBtnClick?: () => void;
}
const AddItemComp: React.FC<AddItemProps> = ({ title, desc, onBtnClick = () => { } }) => {
    return (
        <div onClick={onBtnClick} className='py-5 px-4 border border-dashed border-[#E8E8E8] rounded-lg flex flex-col items-center cursor-pointer add-item-comp-wrapper'>
            <h5 className="text-[#1F1F1F] text-[17px] font-normal leading-[100%] capitalize">{title}</h5>
            <p className="mt-3 lowercase text-[#989898] text-[14px] font-[400] leading-[100%]">{desc}</p>
            <PrimaryButton
                title="Add"
                theme='light'
                size='regular'
                btnClass='max-h-[28px]'
                containerStyle='mt-4'
                onClick={onBtnClick}
            />
        </div>
    )
}

export default AddItemComp