import { Modal } from "antd";
import React from "react";
import { FeedModalProps } from "../../../type/types";
import Image from "../../../assets/png/feedImageContent.png";
import "./FeedModal.scss";

const FeedModal: React.FC<FeedModalProps> = ({
  title,
  className,
  closeIcon = false,
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  footer = false,
}) => {
  return (
    <>
      <Modal
        className={`feed-modal ${className}`}
        closeIcon={closeIcon}
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={footer}
        centered
        width="100%"
        style={{ top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <div className="flex bg-white h-screen w-full gap-[52px] lg:flex-row  md:flex-col-reverse  ">
          <div className="flex justify-center items-center flex-1 bg-primary">
            <img
              className="max-h-full w-full object-contain px-4"
              src={Image}
              alt="feed content"
            />
          </div>
          <div>{children}</div>
        </div>
      </Modal>
    </>
  );
};

export default FeedModal;
