import { AboutCollege, AboutLanguage, AboutLocation, AboutSchool, AboutZodiac } from "../../Icons/Icons";
import { AboutDate } from './../../Icons/Icons';


export const AboutData = [
    {
        key: 1,
        icon: <AboutLocation />,
        valueKey: "city of birth",
        valueData: "bangalore",
    },
    {
        key: 2,
        icon: <AboutDate />,
        valueKey: "birthday",
        valueData: "Jan 24",
    },
    {
        key: 3,
        icon: <AboutZodiac />,
        valueKey: "zodiac",
        valueData: "Aquaius",
    },
    {
        key: 4,
        icon: <AboutLanguage />,
        valueKey: "mother tongue",
        valueData: "telugu",
    },
    {
        key: 5,
        icon: <AboutSchool />,
        valueKey: "school",
        valueData: "bhavya",
    },
    {
        key: 6,
        icon: <AboutCollege />,
        valueKey: "college",
        valueData: "bms institute",
    }
]