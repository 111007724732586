import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import Member1 from "../../../assets/png/chatMember1.png";
import { ModalBackArrowIcon } from "../../Icons/Icons";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import CustomInput from "../../CustomInput/CustomInput";
interface AddGroupDetailsModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setIsAddGroupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMembersData: any;
}
const AddGroupDetailsModal: React.FC<AddGroupDetailsModalProps> = ({
  modal,
  setModal,
  setIsAddGroupOpen,
  selectedMembersData,
}) => {
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2] flex">
      <span
        onClick={() => {
          handleClosePopover();
          setIsAddGroupOpen(true);
        }}
        className="flex justify-center items-center max-w-[24px] min-w-[24px] cursor-pointer"
      >
        <ModalBackArrowIcon />
      </span>
      <h6 className="text-[#101828] ml-3  text-[24px] font-medium leading-[100%] capitalize">
        Add group Members
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        theme="dark"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Save"
      />
    </div>
  );
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };

  return (
    <CustomModal
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6  pb-2 ">
        <div className=" pb-6 pt-4">
          <PhotoUpload
            title="Group Photo"
            description="Choose a Group Photo "
            closeIcon={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
          >
            Group Name
          </label>
          <CustomInput
            name="Group Name"
            containerStyle="mt-2"
            id="name"
            type="text"
            placeholder="Enter Group Name"
          />
        </div>
        {selectedMembersData.length > 0 && (
          <h6 className="text-base not-italic font-normal leading-[normal]">
            {selectedMembersData.length} Members
          </h6>
        )}
        <div className=" justify-start max-h-[250px] gap-5  flex flex-wrap overflow-y-auto  theme-scrollbar-sm ">
          {selectedMembersData.map((item: any, index: any) => (
            <div className=" relative w-fit mt-4 flex flex-col min-w-[56px] max-w-[56px]">
              <img
                className=" relative min-w-[56px] max-w-[56px] min-h-[56px] max-h-[56px]"
                src={Member1}
                alt="member"
              ></img>

              <p className="flex mt-3 justify-center items-center text-gray-1 text-sm not-italic font-normal leading-normal line-clamp-1 max-w-full truncate ">
                {item.username}
              </p>
            </div>
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default AddGroupDetailsModal;
