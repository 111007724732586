import img1 from "../assets/png/snap.png";
import img2 from "../assets/png/space-8.png";
import img3 from "../assets/png/space-5.png";
import { MissionsDataType } from "../type/types";

const data: MissionsDataType[] = [
    {
        id: "1",
        title: "Create and Flaunt your Filter Campaign",
        image: img1,
        intership: false,
        mission: false,
        cash:'12',
        cards: '12 Openings',
        events: 1,
        explorers: "13.5k",
        date: '12 PM, Dec 31st',
        participatedCount : '200+'
    },
    {
        id: "2",
        title: "Create and Flaunt your Filter Campaign",
        image: img1,
        intership: false,
        mission: false,
        cash:'12',        
        cards: '12 Openings',
        events: 2,
        explorers: "13.5k",
        date: '12 PM, Dec 31st',
        participatedCount : '200+'

    },
    {
        id: "3",
        title: "Mission 01 - Complete your KYC",
        image: img2,
        intership: false,
        mission: false,
        cash:'12',
        cards: '',
        events: 0,
        explorers: "13.5k",
        date: '12 PM, Dec 31st',
        participatedCount : '200+'

    },
    {
        id: "4",
        title: "Product Designer Intern - Under 25",
        image: img2,
        intership: true,
        mission: false,
        cash:'',
        cards: '2 Positions',
        events: 1,
        explorers: "13.5k",
        participatedCount : '200+',
        date: '12 PM, Dec 31st'


    },
    {
        id: "5",
        title: "Create and Flaunt your Filter Campaign",
        image: img3,
        intership: false,
        mission: true,
        cash:'',        
        cards: '12 Openings',
        events: 2,
        explorers: "13.5k",
        participatedCount : '200+',
        date: '12 PM, Dec 31st'

    },
  
]

export default data;