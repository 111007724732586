import { Avatar } from "antd";
import React from "react";
import { ActiveMissionBoxProps } from "../../../type/types";
import { IntershipIcon, StopWatchOutlined } from "../../Icons/Icons";
import useIsMac from "../../../hooks/useIsMac";

const JobsIntrestBox: React.FC<ActiveMissionBoxProps> = ({
  containerClass,
  data,
}) => {
  const isMac = useIsMac();
  return (
    <div
      className={`mt-4 mb-5 [&_div:not(:last-child)]:mb-4 ${containerClass}`}
    >
      {data.map((item) => (
        <div key={item.id} className="flex items-start gap-2">
          <Avatar
            src={item.image}
            size={40}
            shape="square"
            alt="user"
            className="rounded-[7.5px] max-w-[40px] min-w-[40px] max-h-[40px] min-h-[40px]"
          />
          <div>
            <p className="text-primary text-[15px] not-italic font-normal leading-[120%] mb-[2px]">
              {item.title}
            </p>
            <p className="flex items-center">
              <span className="inline-flex my-[2px] mx-[3px] align-middle">
                <IntershipIcon
                  className={` ${isMac && "mb-1"} w-[10px] h-[10px]`}
                />
              </span>
              <span className="inline-flex text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                {item.reward}
              </span>
            </p>
            <div className="flex items-start">
              <p className="text-gray_1 text-[13px] not-italic font-normal leading-[120%] border-r border-solid border-r-gray_2 pr-2 mr-2">
                {item.openings} Positions
              </p>
              <div className="flex items-center gap-[2px]">
                <span className="inline-flex">
                  <StopWatchOutlined className={` ${isMac && "mb-1"}`} />
                </span>
                <span className="inline-flex text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                  {item.endDate}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JobsIntrestBox;
