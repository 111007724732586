import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import SearchBar from "../../SearchBar/SearchBar";
import { XCloseMd } from "../../Icons/Icons";
import UserContactList from "../../UserContactList/UserContactList";
import userListData from "../../../data/userListData";
import useIsMac from "../../../hooks/useIsMac";

const RSVP: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [userLists, setUserLists] = useState<boolean[]>();
  const isMac = useIsMac();

  const CardHeader = (
    <div className="flex items-center gap-2 pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        RSVP
      </h4>
      <div
        className={`flex ${
          isMac && "mb-[6px]"
        } items-center justify-center bg-blue_1 rounded-3xl py-[6px] px-[6px] w-[25px] h-[21px]`}
      >
        <span
          className={`text-white text-[15px] not-italic font-medium leading-[120%] ${
            isMac && "pt-[3px]"
          }`}
        >
          {userLists?.length || 0}
        </span>
      </div>
    </div>
  );

  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={<div className="rounded-[0_0_12px_12px] p-1"></div>}
        width={615}
        closeIcon={<XCloseMd />}
      >
        <div className="mt-6 mx-6">
          <SearchBar className="mb-6" placeholder="Search for Requests" />
          <div className="pr-4 h-[344px] overflow-y-auto theme-scrollbar-sm">
            <UserContactList
              type={"checkbox"}
              data={userListData}
              containerClassName="h-[344px]"
              noFooter={true}
              checkedLists={(lists) => setUserLists(lists)}
              checkbox={false}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default RSVP;
