import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import { ArrowNarrowLeft, CloseDarkOutlined } from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import CustomInput from "../../CustomInput/CustomInput";
import { NotificationPlacement } from "antd/es/notification/interface";
import { notification } from "antd";

interface CreateDeckProps extends HouseModalProps {
  handleOkaySaveToDeck?: () => void;
  handleCancelSaveToDeck?: () => void;
  isEdit?: any;
  deckName?: any;
}

const CreateDeck: React.FC<CreateDeckProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  setDeckName,
  isEdit = false,
  handleOkaySaveToDeck = () => {},
  deckName,
}) => {
  const [inputData, setInputData] = useState(deckName);
  const [inputCount, setInputCount] = useState(inputData.length);
  const [api, contextHolder] = notification.useNotification();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 25) {
      setInputData(inputValue);
      setInputCount(inputValue.length);
    }
  };

  const OnClear = () => {
    setInputCount(0);
    setInputData("");
    handleCancel();
  };

  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <button
        onClick={() => {
          OnClear();
          handleOkaySaveToDeck();
        }}
        className="mr-4"
      >
        <ArrowNarrowLeft />
      </button>
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        {`${isEdit ? "Edit" : "Create"}`} Deck
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            if (inputData && inputData.length > 0) {
              setDeckName(inputData);
              OnClear();
              openNotification("bottomRight");
            }
          }}
          title="Save"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: "",
      className:
        "under25-notification auto-close !bg-primary !w-auto !shadow-[0px_12px_8px_0px_rgba(0,0,0,0.08),0px_3px_24px_0px_rgba(0,0,0,0.04)] !py-3 !px-4 !rounded-lg ![&_.ant-notification-notice-message]:m-0",
      description: (
        <div className="flex items-center justify-between">
          <div className="border-r-gray_1 border-r border-solid pr-5">
            <p className="text-white text-[17px] not-italic font-medium leading-[120%]">
              Saved to Collection
            </p>
            <p className="text-white text-sm not-italic font-normal leading-[120%]">
              Saved to All cards
            </p>
          </div>
          <PrimaryButton
            size="small"
            className="!rounded-lg !ml-5"
            theme="light"
            title="Undo"
            onClick={closeNotification}
          />
        </div>
      ),
      closeIcon: false,
      duration: 4.5,
      placement,
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={OnClear}
        title={CardHeader}
        closeIcon={<CloseDarkOutlined />}
        footer={modalFooter}
      >
        {contextHolder}
        <div className="mt-4 mx-5 mb-6">
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
            htmlFor="my-modal"
          >
            Deck Name
          </label>
          <div className="relative">
            <CustomInput
              id="my-modal"
              className="mt-[5px] "
              value={inputData}
              onChange={handleInputChange}
              maxLength={25}
            />
            <div className="absolute top-[18px] right-[20px]">
              <p className=" text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                {inputCount}/25
              </p>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default CreateDeck;
