import React, { useEffect, useState } from "react";
import { useReactMediaRecorder } from "react-media-recorder";

interface RecordViwProps {
  setFile: any;
  recordStatus: any;
}

const RecordView: React.FC<RecordViwProps> = ({ setFile, recordStatus }) => {
  const [second, setSecond] = useState<any>("00");
  const [minute, setMinute] = useState<any>("00");
  const [isActive, setIsActive] = useState(false);
  const { startRecording, stopRecording, pauseRecording, mediaBlobUrl } =
    useReactMediaRecorder({
      video: false,
      audio: true,
    });

  const [counter, setCounter] = useState(0);

  useEffect(() => {
    startRecording();
    setIsActive(true);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    setCounter(0);
    setSecond("00");
    setMinute("00");
  }

  if (mediaBlobUrl) {
    setFile(mediaBlobUrl);
  }

  return (
    <>
      <div className="text-2xl text-gray_60 not-italic font-normal leading-[normal] mt-4">
        {minute} : {second}
      </div>
      <div className=" flex border border-gray_4 gap-4 py-4 px-6 rounded-xl ">
        <span
          onClick={() => {
            if (!isActive) {
              startRecording();
            } else {
              pauseRecording();
            }
            setIsActive(!isActive);
          }}
        >
          {!isActive ? (
            <svg
              width="28"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="32" height="32" rx="16" fill="#FC741E" />
              <path
                d="M18.2233 13.2464C19.9432 14.4156 20.8031 15.0002 20.8031 16C20.8031 16.9998 19.9432 17.5844 18.2233 18.7536C17.7485 19.0764 17.2776 19.3802 16.8449 19.6334C16.4653 19.8556 16.0353 20.0854 15.5902 20.3109C13.8744 21.1805 13.0165 21.6152 12.247 21.1339C11.4775 20.6525 11.4076 19.6449 11.2677 17.6295C11.2282 17.0596 11.2031 16.5009 11.2031 16C11.2031 15.4991 11.2282 14.9404 11.2677 14.3705C11.4076 12.3551 11.4775 11.3475 12.247 10.8661C13.0165 10.3848 13.8744 10.8195 15.5902 11.6891C16.0353 11.9146 16.4653 12.1444 16.8449 12.3666C17.2776 12.6198 17.7485 12.9237 18.2233 13.2464Z"
                fill="white"
              />
            </svg>
          ) : (
            <svg
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.4974 29.4827C23.8612 29.4827 29.8307 23.5132 29.8307 16.1494C29.8307 8.78558 23.8612 2.81604 16.4974 2.81604C9.1336 2.81604 3.16406 8.78558 3.16406 16.1494C3.16406 23.5132 9.1336 29.4827 16.4974 29.4827ZM11.2656 11.6391C11.1641 11.8842 11.1641 12.1948 11.1641 12.816V19.4827C11.1641 20.104 11.1641 20.4146 11.2656 20.6596C11.4009 20.9863 11.6604 21.2459 11.9872 21.3812C12.2322 21.4827 12.5428 21.4827 13.1641 21.4827C13.7853 21.4827 14.0959 21.4827 14.341 21.3812C14.6677 21.2459 14.9272 20.9863 15.0626 20.6596C15.1641 20.4146 15.1641 20.104 15.1641 19.4827V12.816C15.1641 12.1948 15.1641 11.8842 15.0626 11.6391C14.9272 11.3124 14.6677 11.0529 14.341 10.9175C14.0959 10.816 13.7853 10.816 13.1641 10.816C12.5428 10.816 12.2322 10.816 11.9872 10.9175C11.6604 11.0529 11.4009 11.3124 11.2656 11.6391ZM17.9322 11.6391C17.8307 11.8842 17.8307 12.1948 17.8307 12.816V19.4827C17.8307 20.104 17.8307 20.4146 17.9322 20.6596C18.0675 20.9863 18.3271 21.2459 18.6538 21.3812C18.8988 21.4827 19.2095 21.4827 19.8307 21.4827C20.452 21.4827 20.7626 21.4827 21.0076 21.3812C21.3343 21.2459 21.5939 20.9863 21.7292 20.6596C21.8307 20.4146 21.8307 20.104 21.8307 19.4827V12.816C21.8307 12.1948 21.8307 11.8842 21.7292 11.6391C21.5939 11.3124 21.3343 11.0529 21.0076 10.9175C20.7626 10.816 20.452 10.816 19.8307 10.816C19.2095 10.816 18.8988 10.816 18.6538 10.9175C18.3271 11.0529 18.0675 11.3124 17.9322 11.6391Z"
                fill="#C8C8C8"
              />
            </svg>
          )}
        </span>

        <span>
          <svg
            width="1"
            height="33"
            viewBox="0 0 1 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.4"
              d="M0.5 0.149048V32.149"
              stroke="#1F1F1F"
              stroke-opacity="0.24"
            />
          </svg>
        </span>
        <span
          onClick={() => {
            stopTimer();
            stopRecording();
            pauseRecording();
          }}
          className=" cursor-pointer"
        >
          <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.4974 29.4827C23.8612 29.4827 29.8307 23.5132 29.8307 16.1494C29.8307 8.78558 23.8612 2.81604 16.4974 2.81604C9.1336 2.81604 3.16406 8.78558 3.16406 16.1494C3.16406 23.5132 9.1336 29.4827 16.4974 29.4827ZM11.9451 11.5971C11.1641 12.3781 11.1641 13.6352 11.1641 16.1494C11.1641 18.6635 11.1641 19.9206 11.9451 20.7017C12.7262 21.4827 13.9832 21.4827 16.4974 21.4827C19.0116 21.4827 20.2686 21.4827 21.0497 20.7017C21.8307 19.9206 21.8307 18.6635 21.8307 16.1494C21.8307 13.6352 21.8307 12.3781 21.0497 11.5971C20.2686 10.816 19.0116 10.816 16.4974 10.816C13.9832 10.816 12.7262 10.816 11.9451 11.5971Z"
              fill="#FC741E"
            />
          </svg>
        </span>
      </div>
    </>
  );
};

export default RecordView;
