import React from "react";
import { Avatar } from "antd";
import { MissionLeaderBoardTypes } from "../../type/types";

interface MissionLeaderBoardProps {
  data: MissionLeaderBoardTypes[];
}

const MissionLeaderboard: React.FC<MissionLeaderBoardProps> = ({ data }) => {
  return (
    <>
      {data.map((item) => (
        <div key={item.id} className="rounded-lg [&:not(:last-child)]:mb-4">
          {!item?.isOwn && (
            <>
              <div className="flex items-center ">
                <p className="text-gray_6 text-[17px] mr-2 not-italic font-[500] font-medium w-[15px] flex justify-start items-center ">
                  {item.id}
                </p>
                <Avatar
                  src={item.image}
                  size={48}
                  shape="square"
                  alt="user"
                  className="rounded-md  mr-2 mb-[2px] max-w-[48px] min-w-[48px] max-h-[48px] min-h-[48px]"
                />
                <div>
                  <div className="mb-0.5">
                    <p className="text-primary text-[15px] not-italic font-normal leading-[120%]">
                      {item.title}
                    </p>
                    <p className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                      {item.username}
                    </p>
                  </div>
                  <p className="flex text-gray_6 text-[13px] not-italic  leading-[120%]">
                    Total Earnings: &#8377;
                    <p className="text-primary"> {item.earnigs}</p>
                  </p>
                </div>
              </div>
            </>
          )}

          {item?.isOwn && (
            <div
              key={item.id}
              className="  bg-[#FC741E29] bg-opacity-[16%] px-4 py-2 mx-[-16px] mt-2"
            >
              <div className="flex items-center ">
                <p className="text-gray_6 text-[17px] mr-2 not-italic font-[500] font-medium w-[15px] flex justify-start items-center ">
                  {item.id}
                </p>
                <Avatar
                  src={item.image}
                  size={48}
                  shape="square"
                  alt="user"
                  className="rounded-md  mr-2 mb-[2px] max-w-[48px] min-w-[48px] max-h-[48px] min-h-[48px]"
                />
                <div>
                  <div className="flex gap-2">
                    <p className="text-primary text-[15px] not-italic font-normal leading-[120%]">
                      {item.title}
                    </p>
                  </div>
                  <p className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                    {item.username}
                  </p>
                  <span className="flex text-gray_6 text-[13px] not-italic  leading-[120%]">
                    Total Earnings: &#8377;
                    <p className="text-primary"> {item.earnigs}</p>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default MissionLeaderboard;
