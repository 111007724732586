import ThemeCardLayout from "../../../ThemeCardLayout/ThemeCardLayout";
import ActiveMissionBox from "../ActiveMissionBox/ActiveMissionBox";
import activeMissionData from "../../../../data/activeMissionData";
import OuterSpaceBox from "../OuterSpaceBox/OuterSpaceBox";
import outerSpaceData from "../../../../data/outerSpaceData";
import { Link } from "react-router-dom";

const RightSection = ({ setSelectedTab, selectedTab }: any) => {
  return (
    <div>
      <ThemeCardLayout
        title="Active Missions"
        btnTitle={<Link to={"/missions"}>View All</Link>}
        containerClass="mb-5"
      >
        <ActiveMissionBox data={activeMissionData} />
      </ThemeCardLayout>
      <ThemeCardLayout
        title="Outerspace"
        btnTitle={
          <p
            onClick={() => {
              setSelectedTab("Outerspace");
            }}
          >
            View All
          </p>
        }
      >
        <OuterSpaceBox data={outerSpaceData} />
      </ThemeCardLayout>
    </div>
  );
};

export default RightSection;
