import { Select } from "antd";
import React, { useState } from "react";
import "./search_select.scss";
import { DropdownArrow } from "../../components/Icons/Icons";
interface OptionData {
  label: string;
  value: string;
}

interface SearchSelectProps {
  optionsData: OptionData[];
  onSearchAddBtn?: () => void;
  placeholder?: string;
}

export const SearchSelect: React.FC<SearchSelectProps> = ({
  optionsData,
  onSearchAddBtn = () => {},
  placeholder,
}) => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };
  const customNotFoundContent = searchQuery ? (
    <div>
      <div
        onClick={() => {
          onSearchAddBtn();
        }}
        className="p-3 bg-[#FC741E0A] rounded-[8px] cursor-pointer "
      >
        <h6 className="text-[#1F1F1F] text-[17px] font-normal leading-[normal]">
          Add{" "}
          <span className="text-[#FC741E] truncate">{`"${searchQuery}"`}</span>
        </h6>
      </div>
      <div className="py-4 px-3 ">
        <h6 className="text-[#66666699] truncate text-[17px] font-normal leading-[normal] text-center">
          {`No Search results for “${searchQuery}”`}
        </h6>
      </div>
    </div>
  ) : (
    "No results found"
  );

  return (
    <Select
      className="personal-info-dropdown search-select"
      popupClassName={"social-link-dropdown-popup personal-info-popup"}
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "")
          .toLowerCase()
          .localeCompare((optionB?.label ?? "").toLowerCase())
      }
      options={optionsData}
      onSearch={handleSearch}
      notFoundContent={customNotFoundContent}
      suffixIcon={<DropdownArrow />}
    />
  );
};
