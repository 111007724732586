import { Avatar, Popover } from "antd";
import React, { useState } from "react";
import U25VioletBg from "../../../../../assets/svg/under25-purple-filled.svg";
import LockIcon from "../../../../../assets/svg/lock-03.svg";
import LinkIcon from "../../../../../assets/svg/link-01.svg";
import {
  ChatTextSquare,
  ClockOutlined,
  DropdownArrow,
  EditOutlined,
  PeopleOutlined,
  ShareTreeGray,
} from "../../../../Icons/Icons";
import PrimaryButton from "../../../../PrimaryButton/PrimaryButton";
import { SpaceInfoCardProps } from "../../../../../type/types";
import CustomModal from "../../../../CustomModal/CustomModal";
import DisconnectModal from "../../../../Profile/Main/components/DisconnectModal/DisconnectModal";
import EditSpace from "../../../../Feeds/Modal/EditSpace";
import Requests from "../../../../Feeds/Modal/Requests";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ShareModal from "../../../../Feeds/Modal/ShareModal";
import useIsMac from "../../../../../hooks/useIsMac";

const SpaceInfoCard: React.FC<SpaceInfoCardProps> = ({
  title,
  locked,
  containerClass = "",
  description,
  link,
  views,
  cards,
}) => {
  const [joined, setJoined] = useState(false);
  const [requested, setRequested] = useState(false);
  const [warningModal, setwarningModal] = useState(false);
  const [disconnectModal, setDisconnectModal] = useState(false);
  const [joinedPopover, setJoinedPopover] = useState(false);
  const [openEditSpaceModal, setOpenEditSpaceModal] = useState(false);
  const [openRequestsModal, setOpenRequestsModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdminRole: any = queryParams?.get("role") === "admin";
  const [openShareModal, setOpenShareModal] = useState(false);

  const handleDisconnectCancel = () => {
    setwarningModal(false);
    setJoined(false);
  };

  const handleDisconnectOkay = () => {
    setRequested(true);
    setwarningModal(false);
  };

  const handleJoinOkay = () => {
    setDisconnectModal(true);
  };

  const handleJoinCancel = () => {
    setDisconnectModal(false);
  };

  const handleDisconnectPopover = (newOpen: boolean) => {
    setJoinedPopover(newOpen);
  };

  const handleOkayEditSpaceModal = () => {
    setOpenEditSpaceModal(true);
  };

  const handleCancelEditSpaceModal = () => {
    setOpenEditSpaceModal(false);
  };

  const handleOpenRequestsModal = () => {
    setOpenRequestsModal(true);
  };

  const handleCancelRequestsModal = () => {
    setOpenRequestsModal(false);
  };

  const handleOkayShareModal = () => {
    setOpenShareModal(true);
  };

  const handleCancelShareModal = () => {
    setOpenShareModal(false);
  };

  const backToConnectHeader = <div className="pt-6 pb-4 px-6"></div>;
  const backToConnectFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleDisconnectOkay();
        }}
        title="No"
        theme="light"
      />
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleDisconnectCancel();
        }}
        theme="warning"
        title="Yes"
      />
    </div>
  );

  const joinedContent = (
    <div>
      <button
        onClick={() => {
          handleJoinOkay();
          setJoinedPopover(false);
        }}
        className="w-full text-left text-primary text-[15px] not-italic font-normal leading-[120%] py-1.5 px-2 rounded-lg hover:!bg-gray_4"
      >
        Leave
      </button>
    </div>
  );

  const isMac = useIsMac();

  return (
    <>
      <CustomModal
        isModalOpen={warningModal}
        handleCancel={handleDisconnectCancel}
        handleOk={handleDisconnectOkay}
        title={backToConnectHeader}
        footer={backToConnectFooter}
        width={600}
      >
        <div className="mx-6">
          <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
            Are you sure you want to cancel the request?
          </p>
        </div>
      </CustomModal>

      <EditSpace
        isModalOpen={openEditSpaceModal}
        handleCancel={handleCancelEditSpaceModal}
        handleOk={handleOkayEditSpaceModal}
      />

      <DisconnectModal
        isModalOpen={disconnectModal}
        handleOk={handleJoinOkay}
        handleCancel={handleJoinCancel}
        handleBtnClick={() => {
          setJoined(false);
          setRequested(false);
        }}
        image={U25VioletBg}
        userTitle="Guru Nanak Institutions"
        isVerified={false}
      />

      <Requests
        isModalOpen={openRequestsModal}
        handleOk={handleOpenRequestsModal}
        handleCancel={handleCancelRequestsModal}
      />
      <div className={`bg-white rounded-xl py-5 px-4 mb-6 ${containerClass}`}>
        <div className="flex">
          <Avatar
            src={U25VioletBg}
            size={100}
            shape="square"
            alt="user"
            className="rounded-md  mr-4 min-w-[100px] max-w-[100px] min-h-[100px] max-h-[100px]"
          />
          <div className="mr-auto">
            <div className="flex gap-1 mb-2 mr-1">
              <h1 className="text-primary text-2xl not-italic font-medium leading-[100%]">
                {title}
              </h1>
              {locked && <img src={LockIcon} alt="lock" />}
            </div>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-2">
              {description}
            </p>
            <div>
              <div className="flex items-center gap-2 mb-2">
                <div className="flex items-center justify-center max-w-[12px] min-w-[12px] min-h-[12px] max-h-3">
                  <img src={LinkIcon} alt="Link" />
                </div>
                <a
                  className="text-primary text-[17px] not-italic font-normal leading-[120%]"
                  href="www.gurunanak.com"
                >
                  {link}
                </a>
              </div>
              <div className="flex items-center gap-3">
                <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                  <span className="text-U25_orange text-[15px] not-italic font-medium leading-[120%] mr-1">
                    {views}
                  </span>
                  Views
                </p>
                <p className="text-gray_6 text-[15px] not-italic font-normal leading-[120%]">
                  <span className="text-blue_1 text-[15px] not-italic font-medium leading-[120%] mr-1">
                    {cards}
                  </span>
                  Cards
                </p>
              </div>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={handleOkayShareModal}
              className="flex items-center justify-center w-8 h-8"
            >
              <ShareTreeGray />
            </button>
            {isAdminRole && (
              <button
                onClick={handleOkayEditSpaceModal}
                className="flex items-center justify-center w-8 h-8"
              >
                <EditOutlined />
              </button>
            )}
          </div>
        </div>
        <div className="flex items-center justify-end gap-2">
          {!joined ? (
            <PrimaryButton
              title={<p className="font-[400]">Join</p>}
              size="small"
              className="!rounded-lg"
              fontClass="text-white text-[13px]"
              onClick={() => setJoined(true)}
            />
          ) : !requested && isAdminRole ? (
            <PrimaryButton
              title={
                <div className="flex items-center gap-1">
                  <ClockOutlined />
                  <p>Requested</p>
                </div>
              }
              theme="light"
              size="small"
              className="!rounded-lg"
              fontClass="text-gray_6 text-[13px]"
              onClick={() => setwarningModal(true)}
            />
          ) : (
            <Popover
              open={joinedPopover}
              content={joinedContent}
              onOpenChange={handleDisconnectPopover}
              trigger={["click"]}
              placement="bottomLeft"
              arrow={false}
              overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
            >
              <PrimaryButton
                title={
                  <div className="flex items-center gap-1">
                    <p>Joined</p>
                    <DropdownArrow />
                  </div>
                }
                theme="light"
                size="small"
                className="!rounded-lg"
                fontClass="text-gray_6 text-[13px]"
              />
            </Popover>
          )}
          {isAdminRole && (
            <PrimaryButton
              onClick={handleOpenRequestsModal}
              title={
                <div className="flex items-center gap-1">
                  <PeopleOutlined className={`${isMac && "mb-1"}`} />
                  <p className="text-primary text-[13px]">Requests</p>
                </div>
              }
              size="small"
              className="!rounded-lg"
              theme="light"
            />
          )}
          <Link to={"/chats"}>
            <PrimaryButton
              title={
                <div className="flex items-center gap-1">
                  <ChatTextSquare className={`${isMac && "mb-1"}`} />
                  <p className="text-primary text-[13px]">Chat</p>
                </div>
              }
              size="small"
              className="!rounded-lg"
              theme="light"
            />
          </Link>
        </div>
        <ShareModal
          isModalOpen={openShareModal}
          handleOk={handleOkayShareModal}
          handleCancel={handleCancelShareModal}
        />
      </div>
    </>
  );
};

export default SpaceInfoCard;
