import { Avatar } from "antd";
import React from "react";
import { ChatTextSquareLg } from "../Icons/Icons";
import { BirthdayBoxTypes } from "../../type/types";

interface BirthdayBoxProps {
  data: BirthdayBoxTypes[];
}

const BirthdayBox: React.FC<BirthdayBoxProps> = ({ data }) => {
  return (
    <div>
      {data?.map((item) => (
        <div className="flex gap-3 [&:not(:last-child)]:mb-4">
          <Avatar
            src={item.image}
            size={50}
            shape="square"
            alt="user"
            className="rounded-md mb-[2px]"
          />
          <div>
            <p className="text-primary text-[17px] not-italic font-medium leading-[120%] mb-1">
              {item.fullName} birthday
            </p>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
              {item.description}
            </p>
          </div>
          <button className="ml-auto">
            <ChatTextSquareLg />
          </button>
        </div>
      ))}
    </div>
  );
};

export default BirthdayBox;
