import React, { useEffect, useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { Avatar, Button } from "antd";
import { StartDiscussionProps } from "../../../type/types";
import {
  ArrowNarrowLeft,
  CloseLightOutlined,
  ClosePopup,
  FilmOutlined,
  GifOutlined,
  HeaderLogo,
  LinkOutlined,
  PictureCircle,
  TrashOutlined,
} from "../../Icons/Icons";
import U25Avatar from "../../../assets/png/u25_avatar.png";
import CustomCheckbox from "../../CustomCheckbox/CustomCheckbox";
import Anonymous from "../../../assets/png/anno.png";
import CustomTextArea from "../../CustomTextArea/CustomTextArea";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import VideoUpload from "../../VideoUpload/VideoUpload";
import SearchBar from "../../SearchBar/SearchBar";
import spaceData from "../../../data/spaceData";
import podUser from "../../../assets/png/podUser.png";
import QuillEditor from "../../Editor/QuillEditor";
import PodDiscussionTab from "../../Discussion/PodDiscussionTab";
import LinkUpload from "../../LinkUpload/LinkUpload";
import StartDiscussionTab from "../../StartDiscussionPanel/startDiscussionTab";
const StartDiscussion: React.FC<StartDiscussionProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  activeTab,
  setOpenModal,
  setActiveTab,
}) => {
  const [saveToSpace, setSaveToSpace] = useState(false);
  const pollOptions: string[] = ["A", "B", "C", "D"];
  const [limitOptions, setLimitOptions] = useState<number>(2);
  const [imageUpload, setImageUpload] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [linkUpload, setLinkUpload] = useState(false);
  const [openSaveToDrafts, setOpenSaveToDrafts] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isAnonymousChange, setIsAnonymousChange] = useState(false);

  const [optionText, setOptionText] = useState({
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });

  const handleAddOptions = () => {
    if (pollOptions.length > limitOptions) {
      setLimitOptions((prev) => prev + 1);
    }
  };

  const handleDeleteOption = (option: any) => {
    if (option) {
      option === "A"
        ? setOptionText({
            ...optionText,
            optionA: "",
          })
        : option === "B"
        ? setOptionText({
            ...optionText,
            optionB: "",
          })
        : option === "C"
        ? setOptionText({
            ...optionText,
            optionC: "",
            optionD: "",
          })
        : setOptionText({
            ...optionText,
            optionD: "",
          });
    }
    setLimitOptions((prev) => prev - 1);
  };

  const handleCancelSaveToDrafts = () => {
    setOpenSaveToDrafts(false);
  };

  const handleOkSaveToDrafts = () => {
    setOpenSaveToDrafts(true);
  };

  const handleSaveToDrafts = () => {
    if (saveToSpace) {
      handleOkSaveToDrafts();
    } else {
      handleCancel();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      setSaveToSpace(false);
    }
  }, [isModalOpen]);

  const handleTextArea = (e: any) => {
    setOptionText({
      ...optionText,
      [e.target.name]: e.target.value,
    });
  };

  const ModalHeader = (
    <h4 className="text-primary text-2xl not-italic font-medium !leading-[100%] pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      Create
    </h4>
  );

  const selectSpaceHeader = (
    <div className="flex items-center gap-3 pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <button onClick={() => setSaveToSpace(false)}>
        <ArrowNarrowLeft />
      </button>
      <h4 className="text-primary text-2xl not-italic font-medium !leading-[120%]">
        Select a space
      </h4>
    </div>
  );

  const saveToDraftsHeader = (
    <div className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-primary text-2xl not-italic font-medium !leading-[100%]">
        Save to Drafts
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton
        onClick={() => {
          setSaveToSpace(true);
          isModalOpen && saveToSpace && setOpenModal(false);
        }}
        title="Next"
        width="fit-content"
      />
    </div>
  );

  const saveToDraftsFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton
        onClick={() => {
          handleCancelSaveToDrafts();
          handleCancel();
        }}
        title="Save"
        width="fit-content"
      />
    </div>
  );

  const threadsBtn = [
    {
      id: "1",
      icon: <PictureCircle />,
      handler: () => {
        setImageUpload(true);
        setVideoUpload(false);
        setLinkUpload(false);
      },
    },
    {
      id: "2",
      icon: <FilmOutlined />,
      handler: () => {
        setVideoUpload(true);
        setImageUpload(false);
        setLinkUpload(false);
      },
    },
    {
      id: "3",
      icon: <GifOutlined />,
      handler: () => {},
    },
    {
      id: "4",
      icon: <LinkOutlined />,
      handler: () => {
        setLinkUpload(true);
        setImageUpload(false);
        setVideoUpload(false);
      },
    },
  ];

  const AnonymousChange = () => {
    setIsAnonymousChange(!isAnonymousChange);
  };

  return (
    <>
      <CustomModal
        isModalOpen={openSaveToDrafts}
        handleCancel={handleCancelSaveToDrafts}
        handleOk={handleOkSaveToDrafts}
        closeIcon={false}
        title={saveToDraftsHeader}
        footer={saveToDraftsFooter}
        width={600}
      >
        <div className="m-6">
          <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
            Do you want to save this card to drafts to publish later?
          </p>
        </div>
      </CustomModal>
      <CustomModal
        isModalOpen={isModalOpen}
        handleCancel={handleSaveToDrafts}
        handleOk={handleOk}
        closeIcon={
          <span>
            <ClosePopup className="[&_rect]:hover:fill-gray_20 [&_rect]:transition-all [&_rect]:duration-200 [&_rect]:ease-[ease]" />
          </span>
        }
        title={saveToSpace ? selectSpaceHeader : ModalHeader}
        footer={modalFooter}
        width={615}
        style={{ maxHeight: "618px", minHeight: "484px" }}
      >
        {!saveToSpace ? (
          <>
            <div className="flex items-center gap-2 pl-6 pr-2 pt-6 pb-4 mb-2 ">
              <StartDiscussionTab
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
            <div>
              {activeTab === 4 && (
                <div className="mx-6">
                  <div className="flex items-center justify-between mb-2">
                    <div className="flex items-center gap-3 py-[10px] justify-center">
                      <div>
                        <Avatar
                          src={isAnonymousChange ? Anonymous : U25Avatar}
                          size={40}
                          shape="square"
                          alt="Avatar"
                          className="rounded-md mix-blend-luminosity min-w-10 max-w-10 "
                        />
                      </div>
                      <div>
                        <p className="text-primary text-lg not-italic font-normal !leading-[normal]">
                          {isAnonymousChange ? "e/anonymous" : "e/defnotkani"}
                        </p>
                        <p className="text-link_hover text-base not-italic font-normal !leading-[normal">
                          confession
                        </p>
                      </div>
                    </div>
                    <div>
                      <CustomCheckbox
                        onChange={() => AnonymousChange()}
                        className="text-primary text-base not-italic font-normal !leading-[normal]"
                      >
                        Anonymous
                      </CustomCheckbox>
                    </div>
                  </div>
                  <div>
                    <CustomTextArea
                      focus={true}
                      className="text-primary text-[32px] not-italic font-medium !leading-[130%] placeholder:text-gray_10 theme-scrollbar-sm"
                      placeholder="Share what’s on your mind"
                      autoSize={{ minRows: 3, maxRows: 5 }}
                    />
                    <CustomTextArea
                      className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize"
                      placeholder="#Type a Hashtag"
                    />
                  </div>
                </div>
              )}
              {activeTab === 3 && (
                <div className="mx-6 gap-5 flex flex-col  ">
                  <CustomTextArea
                    focus={true}
                    className="text-primary text-[32px] not-italic font-medium !leading-[130%] placeholder:text-gray_10 theme-scrollbar-sm  "
                    placeholder="Add the word"
                  />
                  <CustomTextArea
                    className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal capitalize leading-[100%] !min-h-fit "
                    placeholder="What it sounds like"
                  />
                  {/* <QuillEditor /> */}
                  <CustomTextArea
                    className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize mb-2"
                    placeholder="Give it a meaning (Optional)"
                    autoSize={{ minRows: 5, maxRows: 5 }}
                  />
                  <CustomTextArea
                    className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize"
                    placeholder="#Type a Hashtag"
                  />
                </div>
              )}
              {activeTab === 2 && <PodDiscussionTab podUser={podUser} />}
              {activeTab === 1 && (
                <div className="mx-6">
                  <CustomTextArea
                    focus={true}
                    className="text-primary text-[32px] not-italic font-medium !leading-[130%] placeholder:text-gray_10 theme-scrollbar-sm mb-5"
                    placeholder="Add a question"
                  />
                  <div>
                    {pollOptions
                      .slice(0, limitOptions)
                      .map((option: any, index) => {
                        const optionValue: string =
                          option === "A"
                            ? optionText.optionA
                            : option === "B"
                            ? optionText.optionB
                            : option === "C"
                            ? optionText.optionC
                            : optionText.optionD;
                        return (
                          <div key={index} className="relative">
                            <CustomTextArea
                              className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize mb-5 w-full cursor-pointer"
                              placeholder={`Option ${option}`}
                              name={`option${option}`}
                              value={optionValue}
                              onChange={handleTextArea}
                              onMouseEnter={() => setSelectedOption(option)}
                              onMouseLeave={() => setSelectedOption(null)}
                              onFocus={() => setSelectedOption(null)}
                            />
                            {optionValue.length > 0 &&
                              index > 1 &&
                              option === selectedOption && (
                                <button
                                  onMouseEnter={() => setSelectedOption(option)}
                                  onMouseLeave={() => setSelectedOption(null)}
                                  onClick={() => handleDeleteOption(option)}
                                  className="flex absolute items-center justify-center rounded-lg w-[35px] h-[35px] px-1 bg-white top-[-8px] right-0 hover:bg-gray_20 transition-[background] duration-200 ease-[ease]"
                                >
                                  <TrashOutlined />
                                </button>
                              )}
                          </div>
                        );
                      })}
                  </div>
                  {limitOptions < pollOptions.length && (
                    <PrimaryButton
                      onClick={handleAddOptions}
                      title="Add Option"
                      theme="light"
                      size="regular"
                      className="mb-5 text-[13px] py-2.5 px-3 "
                    />
                  )}
                  <CustomTextArea
                    className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize"
                    placeholder="#Type a Hashtag"
                  />
                </div>
              )}
              {activeTab === 0 && (
                <div className={`mx-6 theme-scrollbar-sm  overflow-auto`}>
                  <CustomTextArea
                    focus={true}
                    className="text-primary text-[32px] not-italic font-medium !leading-[130%] placeholder:text-gray_10 theme-scrollbar-sm mb-2 "
                    placeholder="What’s Happening"
                  />
                  <QuillEditor />
                  <CustomTextArea
                    className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize"
                    placeholder="#Type a Hashtag"
                  />
                  {imageUpload && (
                    <PhotoUpload
                      className="mb-[30px]"
                      threadUpload
                      crop={false}
                      title={"Add image"}
                      handleClose={() => setImageUpload(false)}
                      closeIcon={<CloseLightOutlined />}
                    />
                  )}
                  {videoUpload && (
                    <VideoUpload
                      className="mb-[30px]"
                      handleClose={() => setVideoUpload(false)}
                    />
                  )}
                  {linkUpload && (
                    <LinkUpload handleClose={() => setLinkUpload(false)} />
                  )}
                  <div className="flex items-center gap-[10px]">
                    {threadsBtn &&
                      threadsBtn.map((btn) => (
                        <Button
                          key={btn.id}
                          onClick={btn.handler}
                          className="flex items-center justify-center w-[55px] h-[55px] bg-gray_4 rounded-lg hover:!border-link_hover"
                        >
                          {btn.icon}
                        </Button>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mx-6">
              <SearchBar className="mb-6" placeholder="Search for Spaces" />
              <div className="flex items-center justify-between border bg-white p-4 rounded-xl border-solid border-gray_4 mb-5 hover:bg-gray_3 transition-all duration-200 ease-[ease]">
                <div className="flex items-center justify-start w-[50%]">
                  <span className=" mr-3 h-fit w-fit ">
                    <HeaderLogo className=" max-h-[40px] min-h-[40px] max-w-[40px] min-w-[40px] " />
                  </span>
                  <div>
                    <p className="text-primary text-[17px] not-italic font-normal leading-[120%] capitalize">
                      Your Feed
                    </p>
                    <p className="text-link_hover text-[15px] not-italic font-normal leading-[120%]">
                      under 25 universe
                    </p>
                  </div>
                </div>
                <CustomCheckbox size="large" className="w-5 h-5">
                  <></>
                </CustomCheckbox>
              </div>
            </div>
            <div className="mx-6 mt-2 mb-[18px]">
              <div className="flex items-center justify-between gap-3">
                <p className="text-gray_1 text-[17px] not-italic font-normal leading-[120%] capitalize">
                  Spaces
                </p>
                <div className="border-t-gray_4 border-t border-solid h-[1px] w-full"></div>
              </div>
            </div>
            <div className="mx-6 h-[230px] pr-4 overflow-y-auto theme-scrollbar-sm">
              {spaceData.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between bg-white mb-5 last:mb-0"
                >
                  <div className="flex items-center gap-3">
                    <Avatar
                      src={item.image}
                      size={40}
                      shape="square"
                      alt="user"
                      className="rounded-md cursor-pointer"
                    />
                    <div>
                      <p className="text-primary text-[17px] not-italic font-normal leading-[120%] capitalize">
                        {item.title}
                      </p>
                      <p className="text-link_hover text-[17px] not-italic font-normal leading-[120%]">
                        {item.explorers} Explorers
                      </p>
                    </div>
                  </div>
                  <CustomCheckbox size="large" className="w-5 h-5">
                    <></>
                  </CustomCheckbox>
                </div>
              ))}
            </div>
          </>
        )}
      </CustomModal>
    </>
  );
};

export default StartDiscussion;
