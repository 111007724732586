import React, { useState } from "react";
import MediaPreviewModal from "../../MediaPreviewModal/MediaPreviewModal";
import { VideoPlay } from "../../../Icons/Icons";

interface SenderProps {
  message: string;
  time: string;
  media?: string;
  repliedInfo?: {
    fullName: string;
    message: string;
  };
}

const Sender: React.FC<SenderProps> = ({
  message = "",
  time = "",
  media,
  repliedInfo,
}) => {
  const isImage = media?.endsWith(".jpg") || media?.endsWith(".png");
  const isVideo = media?.endsWith(".mp4");
  const [openMediaPreview, setOpenMediaPreview] = useState(false);

  const handleOkayMediaPreview = () => {
    setOpenMediaPreview(true);
  };

  const handleCancelMediaPreview = () => {
    setOpenMediaPreview(false);
  };

  return (
    <>
      <div className="flex justify-end mt-2 mx-8">
        <div className="mb-2">
          <div className="bg-[rgba(0,30,255,0.04)] rounded-[8px_8px_0px_8px] p-3">
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
              {isImage && !isVideo ? (
                <div
                  onClick={handleOkayMediaPreview}
                  className="cursor-pointer"
                >
                  <img
                    className="max-w-[253px] min-w-[253px] max-h-[253px] min-h-[253px] rounded-lg"
                    src={media}
                    alt="sender chat"
                  />
                </div>
              ) : !isImage && isVideo ? (
                <div className="max-w-[265px] w-full h-auto cursor-pointer">
                  <div className="relative">
                    <video src={media} className="rounded-lg mb-2" />
                    <button
                      onClick={handleOkayMediaPreview}
                      className="flex items-center justify-center absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-2 cursor-pointer border bg-[rgba(68,68,68,0.40)] rounded-3xl border-solid border-[rgba(242,242,242,0.04)]"
                    >
                      <VideoPlay />
                    </button>
                  </div>
                  <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                    {message}
                  </p>
                </div>
              ) : !isImage && !isVideo && typeof repliedInfo === "object" ? (
                <div className=" rounded-[8px_8px_0px_8px] ">
                  <div className="bg-[#E7E9FF] pl-4 pr-3 py-2 rounded-lg border-l-4 border-l-blue_1 border-solid mb-2">
                    <p className="text-primary text-[17px] not-italic font-normal leading-[120%] mb-2">
                      {repliedInfo.fullName}
                    </p>
                    <p className="text-primary text-[17px] not-italic font-normal leading-[120%] opacity-60">
                      {repliedInfo.message}
                    </p>
                  </div>
                  <p className="text-gray_6 text-right text-[17px] not-italic font-normal leading-[120%]">
                    {message}
                  </p>
                </div>
              ) : (
                message
              )}
            </p>
          </div>
          <p className="text-gray_6 text-[13px] not-italic font-normal leading-[normal] text-right mt-2">
            {time}
          </p>
        </div>
      </div>
      <MediaPreviewModal
        isModalOpen={openMediaPreview}
        handleCancel={handleCancelMediaPreview}
        handleOk={handleOkayMediaPreview}
      >
        <>
          {isVideo ? (
            <div className="flex justify-center ">
              <video
                controls
                src={media}
                className="object-contain h-[calc(80vh_-_229px)]"
              />
            </div>
          ) : (
            <div className="flex justify-center">
              <img
                className="object-contain h-[calc(80vh_-_229px)]"
                src={isImage ? media : ""}
                alt=""
              />
            </div>
          )}
        </>
      </MediaPreviewModal>
    </>
  );
};

export default Sender;
