import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useRef } from "react";
import "./CustomTextArea.scss";

interface CustomTextAreaProps {
  className?: string;
  autoSize?: boolean | object;
  placeholder?: string;
  value?: string;
  name?: string;
  focus?: boolean;
  onFocus?: () => void;
  noBorder?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  autoSize = true,
  className,
  placeholder,
  name,
  value,
  onChange,
  noBorder = true,
  focus = false,
  onMouseEnter,
  onMouseLeave,
  onFocus,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (focus && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [focus]);

  return (
    <div>
      <TextArea
        ref={textAreaRef}
        style={{
          fontFamily: "OskariG2Regular",
        }}
        className={`custom-text-area ${
          noBorder
            ? "border-none p-0 focus:border-0 focus:outline-0 focus:shadow-none focus:border-transparent"
            : "border rounded-lg border-solid border-gray_2 hover:border-gray_1_hover focus:shadow-input focus:border-gray_3_hover"
        }  ${className}`}
        autoSize={autoSize}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onMouseEnter={onMouseEnter}
        onFocus={onFocus}
        onMouseLeave={onMouseLeave}
      />
    </div>
  );
};

export default CustomTextArea;
