import Layout from "../components/Layout/Layout";
import ThemeCardLayout from "../components/ThemeCardLayout/ThemeCardLayout";
import { ActiveMissionListProps } from "../type/types";
import ExplorerCard from "../components/Home/Spaces/ExplorerCard/ExplorerCard";
import Explorer1 from "../assets/png/explorer-1.png";
import Explorer2 from "../assets/png/explorer-2.png";
import Explorer3 from "../assets/png/explorer-3.png";
import Explorer4 from "../assets/png/explorer-4.png";
import ActiveMissionBox from "../components/Home/Community/ActiveMissionBox/ActiveMissionBox";
import activeMissionData from "../data/activeMissionData";
import MissionInfoCard from "../components/Missions/MissionInfoCard/MissionInfoCard";
import { Avatar, Steps } from "antd";
import Mission1 from "../assets/png/mission1.jpg";
import Mission2 from "../assets/png/mission2.png";
import JobsIntrestBox from "../components/Missions/JobsIntrestBox.tsx/JobsIntrestBox";
import U25PurpleFilled from "../assets/svg/under25-purple-filled.svg";
import "../styles/MissionDetails.scss";

export interface IAppProps {}

const MissionsDetails = () => {
  const avatars: any = [Mission1, Mission2];
  const JobsIntrestBoxData: ActiveMissionListProps[] = [
    {
      id: "1",
      title: "Product Designer Intern - Under 25",
      image: U25PurpleFilled,
      reward: "Internship",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "2",
      title: "Product Designer Intern - Under 25",
      image: U25PurpleFilled,
      reward: "Internship",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "3",
      title: "Product Designer Intern - Under 25",
      image: U25PurpleFilled,
      reward: "Internship",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
    {
      id: "4",
      title: "Product Designer Intern - Under 25",
      image: U25PurpleFilled,
      reward: "Internship",
      openings: "12",
      endDate: "12 PM, Dec 31st",
    },
  ];

  return (
    <Layout>
      <div className="grid bg-gray_4 lg:grid-cols-[300px_3fr_300px] md:grid-cols-[1fr] gap-[30px] py-6 px-8 ">
        <div>
          <ExplorerCard
            explorerCount={"500"}
            connections={["Including melo and 48 other connections"]}
            avatars={[Explorer1, Explorer2, Explorer3, Explorer4]}
          />
          <div className=" bg-white rounded-xl px-4 py-5 my-5">
            <h3 className="text-primary text-xl not-italic font-medium leading-[100%] mb-4">
              Guidelines
            </h3>
            <ul className="text-primary text-[15px] list-disc ml-5 not-italic font-normal leading-[120%]">
              <li>Lorem ipsum dolor sit amet consectetur.</li>
              <li>A bibendum viverra etiam non ut eget egestas.</li>
              <li>Sollicitudin neque nisl phasellus mauris posuere.</li>
              <li>At convallis sit pellentesque consequat.</li>
            </ul>
          </div>
          <div className=" bg-white rounded-xl px-4 py-5 mb-4">
            <h3 className="text-primary text-xl not-italic font-medium leading-[100%] mb-4">
              Contact
            </h3>
            <p className="text-primary text-[15px] not-italic font-normal leading-[120%]">
              @hello.snapchat.com
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <MissionInfoCard
            title="Create and Flaunt your Filter Campaign"
            locked={true}
            description="Snapchat "
          />
          <div className="h-fit  bg-white p-4 rounded-xl">
            <div className="gap-2 flex  w-full">
              {avatars.map((avatarSrc: any, index: any) => (
                <Avatar
                  key={index}
                  src={avatarSrc}
                  shape="square"
                  alt="user"
                  className="rounded-md mb-[2px] w-1/2 max-h-[160px] max-w-[354px] min-h-[160px]"
                />
              ))}
              <div className="border rounded-md border-solid border-gray_2 min-w-[80px]  shrink-0 flex items-center justify-center">
                <p>+49</p>
              </div>
            </div>
            <p className="text-gray_6 mt-4">
              Lorem ipsum dolor sit amet consectetur. A bibendum viverra etiam
              non ut eget egestas. Sollicitudin neque nisl phasellus mauris
              posuere. At convallis sit pellentesque consequat.
            </p>
            <div className="flex items-center gap-2 mb-2">
              <div className="flex items-center justify-center max-w-[12px] min-w-[12px] min-h-[12px] max-h-3">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.4691 12.2423L7.52629 13.1851C6.22455 14.4868 4.114 14.4868 2.81225 13.1851C1.5105 11.8834 1.5105 9.7728 2.81225 8.47106L3.75506 7.52825M12.2403 8.47105L13.1831 7.52825C14.4849 6.2265 14.4849 4.11595 13.1831 2.8142C11.8814 1.51245 9.77085 1.51245 8.4691 2.8142L7.52629 3.75701M5.66436 10.333L10.331 5.6663"
                    stroke="#808080"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <a
                className="text-link_hover text-[17px] not-italic leading-[120%]"
                href="www.gurunanak.com"
              >
                www.gurunanak.com
              </a>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <div className="flex items-center justify-center max-w-[12px] min-w-[12px] min-h-[12px] max-h-3">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.4691 12.2423L7.52629 13.1851C6.22455 14.4868 4.114 14.4868 2.81225 13.1851C1.5105 11.8834 1.5105 9.7728 2.81225 8.47106L3.75506 7.52825M12.2403 8.47105L13.1831 7.52825C14.4849 6.2265 14.4849 4.11595 13.1831 2.8142C11.8814 1.51245 9.77085 1.51245 8.4691 2.8142L7.52629 3.75701M5.66436 10.333L10.331 5.6663"
                    stroke="#808080"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <a
                className=" text-link_hover text-[17px] not-italic leading-[120%]"
                href="www.gurunanak.com"
              >
                www.gurunanak.com
              </a>
            </div>

            <h3 className="text-primary text-xl mt-4 not-italic font-medium leading-[100%] mb-3">
              Task at hand
            </h3>
            <Steps
              progressDot
              current={0}
              direction="vertical"
              className="task-at-hand"
              items={[
                {
                  title: "Start Mission",
                },
                {
                  title: "Select Document Type",
                },
                {
                  title: "Enter Document Details",
                },
                {
                  title: "Enter Pan Details",
                },
              ]}
            />
          </div>
        </div>
        <div>
          <ThemeCardLayout
            title="Missions you must be interested in"
            titleClass="max-w-[250px]"
            btnTitle="View All"
            containerClass="mb-5"
          >
            <ActiveMissionBox data={activeMissionData} />
          </ThemeCardLayout>
          <ThemeCardLayout
            title="Jobs you must be interested in"
            btnTitle="View All"
            containerClass="mb-5"
          >
            <JobsIntrestBox data={JobsIntrestBoxData} />
          </ThemeCardLayout>
        </div>
      </div>
    </Layout>
  );
};

export default MissionsDetails;
