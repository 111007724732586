import React from "react";
import { Input } from "antd";
import { SearchBarProps } from "../../type/types";
import "./SearchBar.scss";
import { SearchDarkOutlined, XCloseSm } from "../Icons/Icons";

const SearchBar: React.FC<SearchBarProps> = ({
  id = "search-bar",
  value,
  onChange,
  prefix = <SearchDarkOutlined />,
  placeholder = "Search",
  className,
  customPlaceholderContainerClass,
  containerStyle,
  allowClear = {
    clearIcon: (
      <>
        <XCloseSm className="hover:[&_path]:fill-gray_60" />
      </>
    ),
  },
}) => {
  return (
    <div className={`relative ${containerStyle}`}>
      <Input
        id={id}
        value={value}
        onChange={onChange}
        prefix={prefix}
        allowClear={allowClear}
        placeholder={placeholder}
        className={`search-bar py-3 px-4 h-10 border rounded-xl border-solid border-gray_2 ${className}`}
      />
    </div>
  );
};

export default SearchBar;
