import { CheckboxTick, EditOutlined } from "../../Icons/Icons";
import type { RcFile, UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import AvatarPic from "../../../assets/png/edit-avatar.png";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, Tabs, Upload } from "antd";
import { ImageUploadOutlined } from "../../Icons/Icons";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import EditProfile from "../../../assets/png/edit-profile.png";
import avatar from "../../../assets/png/editAvatar.png";
import defaultAvatar from "../../../assets/png/avatar/body/Orange.original.png";
import hat1 from "../../../assets/png/avatar/hat/Thought_Leader_Halo.original.png";
import hat2 from "../../../assets/png/avatar/hat/christmas_hate_snow_1.original.png";
import dress1 from "../../../assets/png/avatar/dress/Dungaree.original.png";
import dress2 from "../../../assets/png/avatar/dress/Thought_Leader_Jacket.original.png";
import dress3 from "../../../assets/png/avatar/dress/Ugly_Sweater_Black.original.png";
import dress4 from "../../../assets/png/avatar/dress/Ugly_Sweater_Greeen.original.png";
import dress5 from "../../../assets/png/avatar/dress/Ugly_Sweater_Red.original.png";
import dress6 from "../../../assets/png/avatar/dress/Ugly_Sweater_Teal.original.png";
import dress7 from "../../../assets/png/avatar/dress/tShirt4.original.png";
import useIsMac from "../../../hooks/useIsMac";
import ImgCrop from "antd-img-crop";

interface EditPictureProps {
  handleClosePopover: () => void;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditPicture: React.FC<EditPictureProps> = ({
  handleClosePopover,
  setPopover,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editAvatarModalOpen, setEditAvatarModalOpen] = useState(false);
  const [activePhotoTab, setActivePhotoTab] = useState(0);
  const [showGlasses, setShowGlasses] = useState(false);
  const [isCardActive, setIsCardActive] = useState(null);
  const [glassImage, setGlassImage] = useState({ image: "", style: "" });
  const [hatImage, setHatImage] = useState({ image: "", style: "" });
  const [showHat, setShowHat] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [selectedHouseAvatar, setSelectedHouseAvatar] = useState(defaultAvatar);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const storedValue: any = localStorage.getItem("selectedHouseAvatar");
    const storedeValueJson = JSON.parse(storedValue);
    if (storedeValueJson?.length > 0) {
      setSelectedHouseAvatar(storedeValueJson);
    } else {
      setSelectedHouseAvatar(defaultAvatar);
    }
  }, []);
  const [fileList, setFileList] = useState<UploadFile[]>([
    {
      uid: "-1",
      name: "",
      status: "done",
      url: EditProfile,
    },
  ]);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as any);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    setFileList([
      {
        uid: "-1",
        name: "",
        status: "done",
        url: "",
      },
    ]);
  };

  const openFileDialogOnClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const updatedFileList = [...fileList];
      if (updatedFileList.length > 0) {
        updatedFileList[0].url = URL.createObjectURL(e.target.files[0]);
      }
      setFileList(updatedFileList);
    }
  };

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b flex border-[#F2F2F2]">
      <span
        className="mr-3 cursor-pointer"
        onClick={() => {
          setEditAvatarModalOpen(false);
          setPopover(true);
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19 12H5M5 12L12 19M5 12L12 5"
            stroke="#1F1F1F"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <h6 className="text-[#1F1F1F] text-[24px] font-medium leading-[100%] capitalize">
        Edit Avatar Wear
      </h6>
    </div>
  );

  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <a id="download" download="avatar.png" href="/">
        <PrimaryButton
          btnClass="max-h-[42px]"
          onClick={() => {
            setEditAvatarModalOpen(false);
            handleClosePopover();
          }}
          title="Save"
        />
      </a>
    </div>
  );

  const GlassAccessory = ({ image, show, style }: any) =>
    show && (
      <img
        src={image}
        alt="hat"
        style={{
          position: "absolute",
          ...style,
        }}
      />
    );

  const HatAccessory = ({ image, show, style }: any) =>
    show && (
      <img
        src={image}
        alt="dress"
        style={{
          position: "absolute",
          ...style,
        }}
      />
    );

  const data = [
    {
      id: 1,
      title: "Green Shirt with sprinkles",
      imageUrl: avatar,
      data: {
        type: "glasses",
        image: avatar,
        style: {
          top: "-1px",
          left: "0px",
          width: "59%",
        },
      },
    },
    {
      id: 2,
      title: "Green Shirt with sprinkles",
      imageUrl: avatar,
      data: {
        type: "hat",
        image: avatar,
        style: {
          top: "-14px",
          left: "10px",
          width: "100%",
        },
      },
    },
    {
      id: 3,
      title: "Another Item",
      imageUrl: avatar,
      data: {
        type: "glasses",
        image: avatar,
        style: {
          top: "58px",
          left: "38px",
          width: "59%",
        },
      },
    },
  ];

  const dataHat = [
    {
      id: 1,
      title: "Green Shirt with sprinkles",
      imageUrl: hat1,
      data: {
        type: "hat",
        image: hat1,
        style: {
          top: "5px",
          left: "0px",
          width: "100%",
        },
      },
    },
    {
      id: 2,
      title: "Green Shirt with sprinkles",
      imageUrl: hat2,
      data: {
        type: "hat",
        image: hat2,
        style: {
          top: "9px",
          left: "3px",
          transform: `rotate(20deg) scale(1.1)`,
          width: "100%",
        },
      },
    },
  ];

  const dataDress = [
    {
      id: 1,
      title: "Green Shirt with sprinkles",
      imageUrl: dress1,
      data: {
        type: "dress",
        image: dress1,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },

    {
      id: 2,
      title: "Green Shirt with sprinkles",
      imageUrl: dress2,
      data: {
        type: "dress",
        image: dress2,
        style: {
          top: "10px",
          left: "5px",
          width: "97%",
        },
      },
    },
    {
      id: 3,
      title: "Green Shirt with sprinkles",
      imageUrl: dress3,
      data: {
        type: "dress",
        image: dress3,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },
    {
      id: 4,
      title: "Green Shirt with sprinkles",
      imageUrl: dress4,
      data: {
        type: "dress",
        image: dress4,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },

    {
      id: 5,
      title: "Green Shirt with sprinkles",
      imageUrl: dress5,
      data: {
        type: "dress",
        image: dress5,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },
    {
      id: 6,
      title: "Green Shirt with sprinkles",
      imageUrl: dress6,
      data: {
        type: "dress",
        image: dress6,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },
    {
      id: 7,
      title: "Green Shirt with sprinkles",
      imageUrl: dress7,
      data: {
        type: "dress",
        image: dress7,
        style: {
          top: "0px",
          left: "0px",
          width: "100%",
        },
      },
    },
  ];

  const applyAccessory = ({ type, image, style }: any) => {
    if (type === "hat") {
      setShowGlasses(true);
      setGlassImage({ image, style });
    } else if (type === "dress") {
      setShowHat(true);
      setHatImage({ image, style });
    }
  };

  // const download = () => {
  //   generatePoster();
  //   const downloadLink = document.getElementById(
  //     "download"
  //   ) as HTMLAnchorElement;
  //   downloadLink.href = avatarImage;
  //   downloadLink.click();
  // };

  // const generatePoster = () => {
  //   window.scrollTo(0, 0);
  //   document.getElementById("render")!.innerHTML = "";
  //   html2canvas(document.querySelector("#poster") as HTMLElement, {
  //     scale: 2,
  //   }).then((canvas) => {
  //     document.getElementById("render")!.appendChild(canvas);
  //   });
  //   window.scrollTo(
  //     0,
  //     document.body.scrollHeight || document.documentElement.scrollHeight
  //   );
  // };

  const Poster = ({
    avatarImage,
    glassImage,
    hatImage,
    showGlasses,
    showHat,
    glassPosition,
    hatPosition,
  }: any) => (
    <div id="poster" className=" relative bottom-[-10px]  ">
      <img src={avatarImage} alt="Avatar" className="h-[80%] " />
      <GlassAccessory
        image={glassImage}
        show={showGlasses}
        style={glassPosition}
      />
      <HatAccessory image={hatImage} show={showHat} style={hatPosition} />
    </div>
  );

  const isMac = useIsMac();

  return (
    <div className="flex justify-center items-center flex-col gap-6">
      <div className="flex gap-4 items-center">
        <div className=" p-1.5 bg-white border-[#F2F2F2]  rounded-[16px]">
          {fileList.length > 0 ? null : (
            <div className="flex justify-center items-center h-[100px] w-[100px] bg-gray_4 border rounded-[16px]">
              <ImageUploadOutlined />
            </div>
          )}
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileInputChange}
          />
          <div
            onClick={() => {
              setActivePhotoTab(0);
            }}
            className={` border p-1.5 rounded-2xl cursor-pointer relative  flex justify-center items-center ${
              activePhotoTab === 0 ? "border-U25_orange" : "border-gray_2"
            } `}
          >
            <ImgCrop rotationSlider>
              <Upload
                accept="image/*"
                className={`profile-photo-upload`}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
              ></Upload>
            </ImgCrop>

            {activePhotoTab === 0 && (
              <span className=" absolute bottom-3 right-3 z-10 w-6 h-6 bg-U25_orange flex justify-center items-center rounded-3xl border border-[#FFEDE1] ">
                <CheckboxTick className="" />
              </span>
            )}
          </div>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <div className="w-full max-h-full min-h-content flex justify-center items-center mb-2">
              <img
                alt="example"
                src={previewImage}
                className=" min-w-[200px] min-h-[200px]"
              />
            </div>
          </Modal>
        </div>
        <div
          onClick={() => {
            setActivePhotoTab(1);
          }}
          className={`p-1.5 bg-white border relative ${
            activePhotoTab === 1 ? "border-U25_orange" : "border-gray_2 "
          } rounded-[16px] cursor-pointer `}
        >
          <img
            src={AvatarPic}
            alt=" "
            className="rounded-[16px] bg-lightgray mix-blend-luminosity"
          />
          {activePhotoTab === 1 && (
            <span className=" absolute bottom-3 right-3 z-10 w-6 h-6 bg-U25_orange flex justify-center items-center rounded-3xl border border-[#FFEDE1] ">
              <CheckboxTick />
            </span>
          )}
        </div>
      </div>

      <CustomModal
        isModalOpen={editAvatarModalOpen}
        handleCancel={() => setEditAvatarModalOpen(false)}
        handleOk={() => setEditAvatarModalOpen(false)}
        title={Header}
        footer={Footer}
        width={615}
      >
        <div className=" px-8 w-full flex justify-center items-center  ">
          <div className="p-3 border-gray_4 border rounded-xl mt-6">
            <div className=" rounded-xl min-w-[280px] max-w-[280px] min-h-[300px] max-h-[300px] bg-avatar-blue-bg flex justify-center items-center ">
              <Poster
                avatarImage={selectedHouseAvatar}
                glassImage={glassImage.image}
                hatImage={hatImage.image}
                glassPosition={glassImage.style}
                hatPosition={hatImage.style}
                showGlasses={showGlasses}
                showHat={showHat}
              />
            </div>
          </div>
        </div>
        <div className="px-8  editAvatarTab">
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                label: (
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.42012 12.7132C2.28394 12.4975 2.21584 12.3897 2.17772 12.2234C2.14909 12.0985 2.14909 11.9015 2.17772 11.7766C2.21584 11.6103 2.28394 11.5025 2.42012 11.2868C3.54553 9.50484 6.8954 5 12.0004 5C17.1054 5 20.4553 9.50484 21.5807 11.2868C21.7169 11.5025 21.785 11.6103 21.8231 11.7766C21.8517 11.9015 21.8517 12.0985 21.8231 12.2234C21.785 12.3897 21.7169 12.4975 21.5807 12.7132C20.4553 14.4952 17.1054 19 12.0004 19C6.8954 19 3.54553 14.4952 2.42012 12.7132Z"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.0004 15C13.6573 15 15.0004 13.6569 15.0004 12C15.0004 10.3431 13.6573 9 12.0004 9C10.3435 9 9.0004 10.3431 9.0004 12C9.0004 13.6569 10.3435 15 12.0004 15Z"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                ),
                key: "1",
                children: (
                  <div className="flex justify-between  p-2 flex-wrap gap-3">
                    {data.map((item: any) => (
                      <div
                        onClick={() => {
                          setIsCardActive(item.id);
                        }}
                        key={item.id}
                        className={`${
                          isCardActive === item.id ? "bg-gray_4" : ""
                        } border-gray_4  border text-center p-2.5 rounded-xl w-[168px] cursor-pointer`}
                      >
                        <div className="bg-avatar-blue-bg relative w-[148px] rounded-lg h-[87px] flex justify-center items-center">
                          <img
                            src={item.imageUrl}
                            alt={item.title}
                            className="w-[60px] absolute bottom-0"
                          />
                        </div>
                        <p className="text-[17px] mt-3 not-italic font-normal leading-[120%]">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                label: (
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15 4L21 6V11H18V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H7C6.73478 20 6.48043 19.8946 6.29289 19.7071C6.10536 19.5196 6 19.2652 6 19V11H3V6L9 4C9 4.79565 9.31607 5.55871 9.87868 6.12132C10.4413 6.68393 11.2044 7 12 7C12.7956 7 13.5587 6.68393 14.1213 6.12132C14.6839 5.55871 15 4.79565 15 4Z"
                        stroke="#1F1F1F"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                ),
                key: "2",
                children: (
                  <div className="flex justify-between  p-2 flex-wrap gap-3">
                    {dataDress.map((item: any) => (
                      <div
                        onClick={() => {
                          setIsCardActive(item.id);
                          applyAccessory(item.data);
                        }}
                        key={item.id}
                        className={`${
                          isCardActive === item.id ? "bg-gray_4" : ""
                        } border-gray_4  border text-center p-2.5 rounded-xl w-[168px] cursor-pointer`}
                      >
                        <div className="bg-avatar-blue-bg w-[148px] relative overflow-hidden rounded-lg h-[87px] flex justify-center items-center">
                          <img
                            src={item.imageUrl}
                            alt={item.title}
                            className="w-[180px] absolute bottom-0 "
                          />
                        </div>
                        <p className="text-[17px] mt-3 not-italic font-normal leading-[120%]">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                ),
              },
              {
                label: (
                  <span>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_511_24711)">
                        <path
                          d="M3 12C3 9.61305 3.94821 7.32387 5.63604 5.63604C7.32387 3.94821 9.61305 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12V17.25C20.9998 17.5273 20.9228 17.7991 20.7775 18.0353C20.6321 18.2715 20.4242 18.4627 20.1767 18.5878C19.9292 18.7129 19.6519 18.767 19.3755 18.744C19.0992 18.721 18.8346 18.6218 18.6113 18.4575C17.1122 17.3681 14.9062 16.5 12 16.5C9.09375 16.5 6.88781 17.3681 5.38875 18.4575C5.16536 18.6218 4.9008 18.721 4.62445 18.744C4.3481 18.767 4.07078 18.7129 3.8233 18.5878C3.57582 18.4627 3.36786 18.2715 3.22252 18.0353C3.07718 17.7991 3.00016 17.5273 3 17.25V12Z"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 14.25C5.59644 12.3027 8.75445 11.25 12 11.25C15.2456 11.25 18.4036 12.3027 21 14.25"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8.28906 11.7131C8.68 6.32156 12.0016 3 12.0016 3C12.0016 3 15.3231 6.32156 15.7141 11.7131"
                          stroke="#111111"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_511_24711">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                ),
                key: "3",
                children: (
                  <div className="flex justify-start  p-2 flex-wrap gap-3">
                    {dataHat.map((item: any) => (
                      <div
                        onClick={() => {
                          setIsCardActive(item.id);
                          applyAccessory(item.data);
                        }}
                        key={item.id}
                        className={`${
                          isCardActive === item.id ? "bg-gray_4" : ""
                        } border-gray_4  border text-center p-2.5 rounded-xl w-[168px] cursor-pointer`}
                      >
                        <div className=" overflow-hidden relative bg-avatar-blue-bg w-[148px] rounded-lg h-[87px] flex justify-center items-center">
                          <img
                            src={item.imageUrl}
                            alt={item.title}
                            className="w-[100px] absolute top-5"
                          />
                        </div>
                        <p className="text-[17px] mt-3 not-italic font-normal leading-[120%]">
                          {item.title}
                        </p>
                      </div>
                    ))}
                  </div>
                ),
              },
            ]}
          />
        </div>
      </CustomModal>
      <Button
        onClick={() => {
          activePhotoTab === 0
            ? openFileDialogOnClick()
            : setEditAvatarModalOpen(true);
        }}
        className="personal-info-btn"
      >
        <div className="flex items-center gap-2">
          <span className="max-w-[12px] min-w-[12px] min-h-[12px] max-h-[12px]">
            <EditOutlined width={12} height={12} />
          </span>
          <h6
            className={`${
              isMac && "pt-[3px]"
            } text-[#1F1F1F] text-[16px] font-normal leading-[normal]`}
          >
            {" "}
            {activePhotoTab === 0 ? "Edit photo" : "Edit avatar"}
          </h6>
        </div>
      </Button>
    </div>
  );
};

export default EditPicture;
