import { tabsDataProps } from "../../../../type/types";
import PrimaryTabs from "../../../PrimaryTabs/PrimaryTabs";
import AllTabs from "../MySpaceTabs/AllTabs/AllTabs";

const MySpaces = () => {
  const tabsData: tabsDataProps[] = [
    {
      id: "1",
      label: "All",
      children: <AllTabs />,
    },
    {
      id: "2",
      label: "Public",
      children: <AllTabs />,
    },
    {
      id: "2",
      label: "Private",
      children: <AllTabs />,
    },
    {
      id: "2",
      label: "Requested",
      children: <AllTabs />,
    },
  ];
  return (
    <div>
      <h3 className="text-primary text-xl not-italic font-medium leading-[100%]">
        My Spaces
      </h3>
      <PrimaryTabs tabsData={tabsData} />
    </div>
  );
};

export default MySpaces;
