import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import SpacesContent from "../components/Home/Spaces/SpacesContent/SpacesContent";
import ThemeCardLayout from "../components/ThemeCardLayout/ThemeCardLayout";
import SpacesInterested from "../components/Home/Spaces/SpacesInterested/SpacesInterested";
import interestedSpaces from "../data/interestedSpaces";

const Spaces = () => {
  const [sliceData, setSliceData] = useState(4);

  const handleSliceData = () => {
    if (sliceData === 4) setSliceData(interestedSpaces.length);
    if (sliceData === interestedSpaces.length) setSliceData(4);
  };

  return (
    <Layout>
      <div className="grid bg-gray_4  lg:grid-cols-[1fr] lg:justify-center md:justify-center xl:grid-cols-[3fr_1fr] gap-[50px] py-6 px-8 ">
        <SpacesContent />
        <ThemeCardLayout
          buttonOnClick={handleSliceData}
          btnTitle={sliceData === 4 ? "View All" : "View Less"}
          title="Spaces you must be interested in"
          titleClass="mb-4"
          containerClass="h-fit !bg-white "
        >
          <SpacesInterested data={interestedSpaces.slice(0, sliceData)} />
        </ThemeCardLayout>
      </div>
    </Layout>
  );
};

export default Spaces;
