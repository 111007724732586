import { Button, Popover } from "antd";
import React from "react";
import { SortArrows } from "../../../Icons/Icons";
import { SortButtonProps } from "../../../../type/types";
import "../SortButton/SortButton.scss";
const SortButton: React.FC<SortButtonProps> = ({
  name,
  trigger = "hover",
  placement = "bottomLeft",
  arrow = false,
  popupTitle = "Title",
  overlayInnerStyle,
  content,
  btnClass,
  iconClass,
  theme = "default",
  open,
  onOpenChange = () => { }
}) => {
  return (
    <>
      <Popover
        placement={placement}
        content={content}
        title={popupTitle}
        trigger={trigger}
        arrow={arrow}
        overlayClassName="custom-popover !p-0"
        overlayInnerStyle={overlayInnerStyle}
        className={"sort-button"}
        open={open}
        onOpenChange={onOpenChange}
      // onOpenChange={ }
      >
        <Button
          className={`border group ${theme === "default" ? "bg-dull_white_1" : "bg-white"
            }  rounded-lg border-solid border-dull_white_2 p-2 hover:!border-primary hover:!bg-primary hover:!text-white ${btnClass}`}
        >
          {name || (
            <SortArrows
              className={`${theme === "default"
                ? ""
                : "[&_path]:stroke-primary group-hover:[&_path]:stroke-white [&_path]:ease-[ease] [&_path]:duration-200 [&_path]:transition-all"
                } ${iconClass}`}
            />
          )}
        </Button>
      </Popover>
    </>
  );
};

export default SortButton;
