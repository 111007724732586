import Anna from '../../assets/png/anna.png'
import charlotte from '../../assets/png/charlotte.png'
import archie from '../../assets/png/archie.png'
import ella from '../../assets/png/ella.png'
import james from '../../assets/png/james.png'
import evie from '../../assets/png/evie.png'
import liam from '../../assets/png/liam.png'

export const PeopleYouMayKnowData = [
    {
        key: 1,
        src: Anna,
        name: "Grace Anna",
        id: "e/anna",
        connection: "1.15k",
        card: 14,
        connected: false
    },
    {
        key: 2,
        src: charlotte,
        name: "Charlotte Ella",
        id: "e/charlotte",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 3,
        src: archie,
        name: "Scott Archie",
        id: "e/archie",
        connection: "1.15k",
        card: 14,
        connected: false
    },
    {
        key: 4,
        src: ella,
        name: "William Ella",
        id: "e/ella",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 5,
        src: james,
        name: "James Thomas",
        id: "e/james",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 6,
        src: evie,
        name: "Isla Evie",
        id: "e/evie",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 7,
        src: liam,
        name: "Shane Liam",
        id: "e/liam",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 8,
        src: Anna,
        name: "Grace Anna",
        id: "e/anna",
        connection: "1.15k",
        card: 14,
        connected: false
    },
    {
        key: 9,
        src: charlotte,
        name: "Charlotte Ella",
        id: "e/charlotte",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 10,
        src: archie,
        name: "Scott Archie",
        id: "e/archie",
        connection: "1.15k",
        card: 14,
        connected: false
    },
    {
        key: 11,
        src: ella,
        name: "William Ella",
        id: "e/ella",
        connection: "1.91k",
        card: 14,
        connected: false
    },
    {
        key: 12,
        src: james,
        name: "James Thomas",
        id: "e/james",
        connection: "1.91k",
        card: 14,
        connected: false
    },
]