import React from "react";

interface EmojiProps {
  label: string;
  symbol: string;
  size?: number;
}

const Emoji: React.FC<EmojiProps> = ({ label, symbol, size = 16 }) => {
  const emojiSize = {
    maxWidth: size,
    minWidth: size,
    maxHeight: size,
    minHeight: size,
  };

  return (
    <span
      className="c inline-flex justify-center items-center"
      style={emojiSize}
      role="img"
      aria-label={label ? label : ""}
      aria-hidden={label ? "false" : "true"}
    >
      {symbol}
    </span>
  );
};

export default Emoji;
