import React, { useEffect } from "react";
import Layout from "../components/Layout/Layout";
import LeftSection from "../components/UserAccount/LeftSection";
import MainSection from "../components/UserAccount/MainSection";
import RightSection from "../components/UserAccount/RightSection";
import useStickyScroll from "../hooks/useStickyScroll";

function UserAccount() {
  useStickyScroll(".right-section");
  useStickyScroll(".left-section");
  return (
    <Layout>
      <div className="grid bg-gray_4 xl:grid-cols-[300px_3fr_300px] lg:grid-cols-[1fr] gap-[30px] py-6 px-8 ">
        <div className="xl:top-0 xl:sticky h-fit overscroll-y-auto no-scrollbar mb-6 left-section">
          <LeftSection />
        </div>
        <div className="no-scrollbar overflow-y-hidden main-section ">
          <MainSection />
        </div>
        <div className="right-section xl:sticky xl:top-0 h-fit">
          <RightSection />
        </div>
      </div>
    </Layout>
  );
}

export default UserAccount;
