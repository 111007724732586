import { SpaceItemProps } from "../type/types";
import Space1 from "../assets/png/space-1.png";
import Space2 from "../assets/png/space-2.png";
import Space3 from "../assets/png/space-3.png";
import Space4 from "../assets/png/space-4.png";
import Space5 from "../assets/png/space-5.png";
import Space6 from "../assets/png/space-6.png";
import Space7 from "../assets/png/space-7.png";

const allTabData: SpaceItemProps[] = [
    {
      id: "1",
      spacePicture: Space1,
      title: "Under 25 Hardcore",
      count: "13.5K",
    },
    {
      id: "2",
      spacePicture: Space2,
      title: "Asking For A Friend",
      count: "17K",
    },
    {
      id: "3",
      spacePicture: Space3,
      title: "Under 25 Dictionary",
      count: "15K",
    },
    {
      id: "4",
      spacePicture: Space4,
      title: "Under 25 Studios",
      count: "15.5K",
    },
    {
      id: "5",
      spacePicture: Space5,
      title: "Under 25 Self Care",
      count: "17.5K",
    },
    {
      id: "6",
      spacePicture: Space6,
      title: "Student Sneaker club",
      count: "12K",
    },
    {
      id: "7",
      spacePicture: Space7,
      title: "Under 25 College Stories",
      count: "12K",
    },
  ];

  export default allTabData;