import React from "react";
import SpaceBox from "../../SpaceBox/SpaceBox";

const AllTabs = () => {
  return (
    <div>
      <SpaceBox />
    </div>
  );
};

export default AllTabs;
