import CustomModal from "../CustomModal/CustomModal";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

interface UnBlockModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  onCancel?: () => void;
  onOk?: () => void;
}
const UnBlockModal: React.FC<UnBlockModalProps> = ({
  modal,
  setModal,
  onOk = () => {},
  onCancel = () => {},
}) => {
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-gray_4">
      <h6 className="text-dark_85 text-[24px] font-medium leading-[100%] capitalize">
        Unblock akshayachandar?
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleClosePopover();
          onCancel();
        }}
        title="Cancel"
      />
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          onOk();
        }}
        title="Unblock"
      />
    </div>
  );
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };
  return (
    <CustomModal
      closeIcon={null}
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6 py-4 pb-2">
        <p className="text-gray_6 text-[17px] font-normal leading-[120%] ">
          They will be able to find, connect and message you.
        </p>
      </div>
    </CustomModal>
  );
};

export default UnBlockModal;
