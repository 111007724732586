import React, { useState } from "react";
import CustomModal from "../CustomModal/CustomModal";
import { HouseModalProps } from "../../type/types";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { CloseDarkOutlined } from "../Icons/Icons";
import MediaPreviewModal from "../Chat/MediaPreviewModal/MediaPreviewModal";
import SampleVideo2 from "../../assets/video/sample-beach.mp4";

const TutorialModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  setOpenConfirmModal,
}) => {
  const modalFooter = (
    <div className="flex justify-end items-center gap-4 rounded-[0_0_12px_12px] bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <PrimaryButton
        onClick={() => {
          handleCancel();
        }}
        title="Skip for now"
        theme="light"
        width="fit-content"
      />
    </div>
  );

  return (
    <>
      <CustomModal
        className="Tutorial"
        title={
          <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
            Tutorial
          </h3>
        }
        closeIcon={<CloseDarkOutlined />}
        width={615}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={modalFooter}
      >
        <div className="px-6 py-4 text-center flex justify-center items-center flex-col">
          <h6 className="text-xl not-italic font-medium leading-[100%]">
            Guide to U25
          </h6>
          <p className="text-gray_6 w-3/4 text-[17px] leading-[120%] mt-2 mb-6">
            Take a moment to watch a tutorial video and learn how to make the
            most of your experience.
          </p>
          <div className=" w-[567px]  h-[302px] tutorialVideo flex justify-center items-center cursor-pointer">
            <div
              onClick={() => handleOk()}
              className="py-4 px-5 flex justify-center items-center bg-white rounded-[409px] w-fit h-fit"
            >
              <span className="w-4 h-4 mr-2">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.7397 7.06862C15.0599 7.23891 15.3278 7.49311 15.5146 7.80399C15.7013 8.11487 15.8 8.47071 15.8 8.83339C15.8 9.19607 15.7013 9.55191 15.5146 9.86279C15.3278 10.1737 15.0599 10.4279 14.7397 10.5982L6.19771 15.2431C4.82227 15.9911 3.13281 15.0177 3.13281 13.479V4.18846C3.13281 2.64837 4.82227 1.67565 6.19771 2.42302L14.7397 7.06862Z"
                    fill="#1F1F1F"
                  />
                </svg>
              </span>
              <button className="text-[17px] leading-none">Watch Video</button>
            </div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default TutorialModal;
