import { AnimatePresence, motion } from "framer-motion";
import useOutsideClick from "../../../hooks/useOutsideClick";
import CustomInput from "../../CustomInput/CustomInput";
import { DropdownArrow } from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import U25DatePicker from "../../U25DatePicker/U25DatePicker";

interface Country {
  name: string;
  alpha2Code: string;
  callingCodes: string[];
}

const AccountTab = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState<boolean>(false);
  const [countryCode, setCountryCode] = useState<string>("+91");
  const [validation, setValidation] = useState<boolean>(false);
  const [phNumber, setPhNumber] = useState<string>("");
  const countryCodeDropdownRef = useRef(null);
  const [query, setQuery] = useState("");
  const [countries, setCountries] = useState<Country[]>([]);
  const [showSecondDatePicker, setShowSecondDatePicker] = useState(false);
  const [username, setUsername] = useState("a/kani");

  const handleDropdownVisibleChange = (visible: boolean) => {
    setIsDropdownVisible(visible);
  };
  useOutsideClick(countryCodeDropdownRef, () => setIsDropdownVisible(false));

  useEffect(() => {
    axios
      .get<Country[]>("https://restcountries.com/v2/all")
      .then((response) => setCountries(response.data))
      .catch((error) => console.log("Error fetching country data:", error));
  }, []);

  const truncateString = (country: string) => {
    const strLen = country.length;
    return strLen > 12 ? country.slice(0, 11) + "..." : country;
  };

  return (
    <div className="flex flex-col justify-between w-full h-[73vh]">
      <div className="">
        <h4 className="text-[#1F1F1F] text-start text-[24px] font-medium leading-[100%]">
          Account information
        </h4>
        <div className="flex flex-col gap-4 mt-6 max-w-[399px]">
          <div className="flex flex-col gap-1">
            <label
              className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              htmlFor="Username"
            >
              Username
            </label>
            <CustomInput
              id="Username"
              className="mt-[5px] "
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
            />
          </div>
          <div className="input-number settings_input_number">
            <div className="container">
              <div className="col-md-6 flex flex-col">
                <label
                  className="text-[#666666] text-[17px] not-italic font-normal leading-[120%] text-left"
                  htmlFor="phoneNumber"
                >
                  Phone Number
                </label>
                <div
                  ref={countryCodeDropdownRef}
                  className="mt-1 inline-flex border rounded-lg items-center relative "
                >
                  <div
                    className="py-3.5 px-3 flex border-r justify-center items-center cursor-pointer h-11"
                    onClick={() =>
                      handleDropdownVisibleChange(!isDropdownVisible)
                    }
                  >
                    <span className="text-[17px] not-italic font-normal leading-[120%] text-gray_1">
                      {countryCode}
                    </span>
                    <span className="ml-2">
                      <DropdownArrow />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="border-none focus:outline-none p-[14px] h-11 text-[17px] w-full"
                    id="ec-mobile-number"
                    aria-describedby="emailHelp"
                    onKeyDown={(e) => {
                      if (
                        !/^\d$/.test(e.key) &&
                        ![
                          "Backspace",
                          "Delete",
                          "ArrowLeft",
                          "ArrowRight",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setPhNumber(e.target.value);
                      setValidation(false);
                    }}
                    placeholder="99999 99999"
                  />
                </div>
                {validation && phNumber.length === 0 && (
                  <div className="flex mt-2">
                    <span className="text-danger text-[17px] not-italic font-normal leading-[120%]">
                      Please enter a phone number
                    </span>
                  </div>
                )}
                <div
                  ref={countryCodeDropdownRef}
                  className="absalute top-0 w-full "
                  style={{ flex: 1 }}
                >
                  <AnimatePresence>
                    {isDropdownVisible && (
                      <motion.ul
                        style={{
                          boxShadow:
                            "0px 8px 12px 0px rgba(164, 164, 164, 0.6)",
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                        className=" z-10 mt-1 w-[200px] absolute overflow-hidden text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm border bg-white shadow-dropdown rounded-lg border-solid border-gray_4"
                        tabIndex={-1}
                        role="listbox"
                        aria-labelledby="listbox-label"
                        aria-activedescendant="listbox-option-3"
                      >
                        <div className="sticky top-0 z-10 bg-white">
                          <li className=" text-gray-900 cursor-default select-none relative py-2 px-3">
                            <input
                              type="search"
                              name="search"
                              autoComplete={"off"}
                              className="!text-[16px] block w-full sm:text-sm border-gray-300 rounded-md focus-visible:!outline-none"
                              placeholder={"Search a country"}
                              onChange={(e) => setQuery(e.target.value)}
                            />
                          </li>
                          <hr />
                        </div>
                        <div className="p-2">
                          <div className="h-[172px] scrollbar overflow-y-scroll theme-scrollbar-sm">
                            {countries.filter((country) =>
                              country.name
                                .toLowerCase()
                                .startsWith(query.toLowerCase())
                            ).length === 0 ? (
                              <li className="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                                No countries found
                              </li>
                            ) : (
                              countries
                                .filter((country) =>
                                  country.name
                                    .toLowerCase()
                                    .startsWith(query.toLowerCase())
                                )
                                .map((value, index) => {
                                  return (
                                    <li
                                      key={`-${index}`}
                                      className="text-gray-900 cursor-pointer select-none relative pt-1.5 pb-0.5 px-2 [&:not(:last-child)]:mb-2 flex items-center justify-between hover:bg-gray_2 hover:rounded-sm transition"
                                      id="listbox-option-0"
                                      role="option"
                                      onClick={() => {
                                        setCountryCode(
                                          "+" + value.callingCodes[0]
                                        );
                                        setIsDropdownVisible(false);
                                      }}
                                    >
                                      <div className="flex items-center">
                                        <img
                                          alt={`${value.alpha2Code}`}
                                          src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.alpha2Code}.svg`}
                                          className={
                                            "inline mr-2 h-4 rounded-sm"
                                          }
                                        />

                                        <span
                                          title={value.name}
                                          className="truncate text-gray_1 text-[17px] not-italic font-normal leading-[120%]"
                                        >
                                          {truncateString(value.name)}
                                        </span>
                                      </div>
                                      <p className="text-gray_1 text-[17px] not-italic font-normal leading-[120%]">
                                        {"+" + value.callingCodes[0]}
                                      </p>
                                    </li>
                                  );
                                })
                            )}
                          </div>
                        </div>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label
              className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
              htmlFor="DateJoined"
            >
              Date Joined
            </label>
            <U25DatePicker
              showSecondDatePicker={showSecondDatePicker}
              setShowSecondDatePicker={setShowSecondDatePicker}
            />
          </div>
        </div>
      </div>
      <PrimaryButton
        title="Save"
        btnClass="max-h-[42px]"
        containerStyle="flex justify-end mt-[24px]"
      />
    </div>
  );
};

export default AccountTab;
