import User1 from "../assets/png/user-1.png";
import User2 from "../assets/png/user-2.png";
import User3 from "../assets/png/user-3.png";
import User4 from "../assets/png/user-4.png";
import User5 from "../assets/png/user-5.png";
import User6 from "../assets/png/user-6.png";

export const reactionsData = [
  {
    id: 1,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User1,
    emoji: "❤️",
  },
  {
    id: 2,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User2,
    emoji: "❤️",
  },
  {
    id: 3,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User3,
    emoji: "🥲",
  },
  {
    id: 4,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User4,
    emoji: "🥲",
  },
  {
    id: 5,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User5,
    emoji: "❤️",
  },
  {
    id: 6,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 7,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 8,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 9,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 10,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 11,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 12,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 13,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 14,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
];

export const reactionsData2 = [
  {
    id: 1,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User1,
    emoji: "❤️",
  },
  {
    id: 2,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User2,
    emoji: "❤️",
  },
  {
    id: 3,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User5,
    emoji: "❤️",
  },
  {
    id: 4,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 5,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 6,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 7,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 8,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 9,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 10,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 11,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
  {
    id: 12,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User6,
    emoji: "❤️",
  },
];

export const reactionsData3 = [
  {
    id: 1,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User3,
    emoji: "🥲",
  },
  {
    id: 2,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    picture: User4,
    emoji: "🥲",
  },
];
