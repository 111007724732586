import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import { ArrowNarrowLeft } from "../components/Icons/Icons";
import SearchBar from "../components/SearchBar/SearchBar";
import RequestsBox from "../components/RequestsBox/RequestsBox";
import Requests1 from "../assets/png/requests-2.png";
import Requests2 from "../assets/png/requests-3.png";
import Requests3 from "../assets/png/requests-5.png";
import { useNavigate } from "react-router-dom";
import useIsMac from "../hooks/useIsMac";

const Requests = () => {
  const navigate = useNavigate();
  const [requestsLists, setRequestsLists] = useState<number>();
  const isMac = useIsMac();

  const handleNavigateToNotification = () => {
    navigate("/notification");
  };

  const notificationRequestData = [
    {
      id: "1",
      image: Requests1,
      fullName: "akshayachandar",
      userName: "e/akshayachandar",
      timeAgo: "12hrs",
    },
    {
      id: "2",
      image: Requests2,
      fullName: "Shankar",
      userName: "e/Shankar",
      timeAgo: "12hrs",
    },
    {
      id: "3",
      image: Requests3,
      fullName: "Anupam",
      userName: "e/Anupam",
      timeAgo: "12hrs",
    },
  ];

  return (
    <Layout>
      <div className="bg-gray_4 p-6 w-full h-[calc(100vh_-_98px)]">
        <div className="w-[716px] bg-white mx-auto rounded-xl">
          <div className="flex items-center gap-3 p-6 rounded-[12px_12px_0px_0px]">
            <button onClick={handleNavigateToNotification}>
              <ArrowNarrowLeft />
            </button>
            <h3 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              Requests
            </h3>
            <div
              className={` ${
                isMac && "pt-2 mb-1"
              } flex items-center justify-center bg-blue_1 rounded-3xl py-[6px] px-[6px] w-[25px] h-[21px]`}
            >
              <span className="text-white text-[15px] not-italic font-medium leading-[120%]">
                {requestsLists}
              </span>
            </div>
          </div>
          <div className="pt-0 pb-6 px-6">
            <SearchBar placeholder="Search for Requests" className="mb-6" />
            <RequestsBox
              data={notificationRequestData}
              containerClass="mb-6"
              notificationRequest
              requestsCount={(count) => setRequestsLists(count)}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Requests;
