import React, { useState } from "react";
import { Avatar } from "antd";
import CustomInput from "../CustomInput/CustomInput";
import U25User from "../../assets/png/community-user-picture.png";
import StartDiscussion from "../Feeds/Modal/StartDiscussion";
import StartDiscussionTab from "./startDiscussionTab";

interface StartDiscussionPanelProps {
  outerClass?: string;
}

const StartDiscussionPanel: React.FC<StartDiscussionPanelProps> = ({
  outerClass,
}) => {
  const activeIndex = undefined ?? 0;
  const [activeBtn, setActiveBtn] = useState<number>(activeIndex);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <StartDiscussion
        isModalOpen={openModal}
        handleCancel={handleModalClose}
        handleOk={handleModalOpen}
        activeTab={activeBtn}
        setActiveTab={setActiveBtn}
        setOpenModal={setOpenModal}
      />
      <div className={`bg-white rounded-xl p-4 ${outerClass}`}>
        <div className="flex items-start gap-4 overflow-hidden">
          <Avatar
            src={U25User}
            size={44}
            shape="square"
            alt="user"
            className="rounded-md max-w-[44px] max-h-[44px] min-w-[44px] min-h-[44px]"
          />
          <div className="w-full overflow-hidden ">
            <CustomInput
              containerStyle="mb-4"
              className="!text-[15px] !py-3 !px-4 placeholder:!text-gray_60 placeholder:!text-[15px] placeholder:!leading-[120%]"
              placeholder="Start a discussion"
              readOnly
              onClick={() => {
                setOpenModal(true);
                setActiveBtn(0);
              }}
            />
            <StartDiscussionTab
              activeTab={activeBtn}
              setActiveTab={setActiveBtn}
              onClick={handleModalOpen}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StartDiscussionPanel;
