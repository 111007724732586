/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { PeopleYouMayKnowData } from "./Data";
import PrimaryButton from "../PrimaryButton/PrimaryButton";

function PeopleYouMayKnow() {
  const defaultCount = 7;
  const [count, setCount] = useState(defaultCount);
  const [peopleData, setPeopleData] = useState(PeopleYouMayKnowData);
  const [connectionStatus, setConnectionStatus] = useState(
    Array(peopleData.length).fill("")
  );

  const handleConnectClick = (key: number) => {
    setPeopleData((prevData) =>
      prevData.map((person) => {
        if (person.key === key) {
          return { ...person, connected: !person.connected };
        } else {
          return person;
        }
      })
    );

    setConnectionStatus((prevStatus) =>
      prevStatus.map((status, index) => {
        if (index === key - 1) {
          return "Requested";
        } else {
          return status;
        }
      })
    );

    setTimeout(() => {
      setConnectionStatus((prevStatus) =>
        prevStatus.map((status, index) => {
          if (index === key - 1 && status === "Requested") {
            return "Connected";
          } else {
            return status;
          }
        })
      );
    }, 1000);
  };
  return (
    <div className="bg-white py-5 px-4 rounded-[12px]">
      <h6 className="text-primary text-[20px] font-medium leading-[100%]">
        People you may know
      </h6>
      <div className="flex flex-col gap-4 mt-4">
        {peopleData.map((item) => {
          if (item.key <= count) {
            return (
              <div key={item.key} className="flex  justify-between gap-3">
                <div className="flex gap-1.5">
                  <span className="min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]">
                    <img
                      src={item.src}
                      alt={item.id}
                      className="rounded-[8px]"
                    />
                  </span>
                  <div className="ml-0.2">
                    <h6 className="text-primary text-[15px] font-normal leading-[120%]">
                      {item.name}
                    </h6>
                    <p className=" text-gray_6 text-[13px] font-normal leading-[120%]">
                      {item.id}
                    </p>

                    <div className="flex gap-2 mt-0.2">
                      <div className="flex gap-1 items-center">
                        <h4 className="text-U25_orange text-[13px] font-medium leading-[120%]">
                          {item.connection}
                        </h4>
                        <h5 className="text-gray_6 text-[13px] font-normal leading-[120%]">
                          Connections
                        </h5>
                      </div>
                      <div className="flex gap-1 items-center">
                        <h4 className="text-blue_1 text-[13px] font-medium leading-[120%]">
                          {item.card}
                        </h4>
                        <h5 className="text-gray_6 text-[13px] font-normal leading-[120%]">
                          Cards
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                {!item.connected ? (
                  <PrimaryButton
                    size="regular"
                    theme="light"
                    title="Connect"
                    fontClass="!text-[13px]"
                    onClick={() => {
                      handleConnectClick(item.key);
                    }}
                  />
                ) : (
                  <PrimaryButton
                    size="regular"
                    theme={
                      connectionStatus[item.key - 1] === "Connected"
                        ? "dark"
                        : "light"
                    }
                    title={
                      connectionStatus[item.key - 1] === "Connected"
                        ? "Connected"
                        : "Requested"
                    }
                    fontClass="!text-[13px]"
                  />
                )}
              </div>
            );
          }
        })}
      </div>
      <PrimaryButton
        title={count === defaultCount ? `View All` : `View Less`}
        width="full"
        size="regular"
        containerStyle="mt-5"
        fontClass="text-[13px]"
        onClick={() => {
          if (count === defaultCount) {
            setCount(PeopleYouMayKnowData.length);
          } else {
            setCount(defaultCount);
          }
        }}
      />
    </div>
  );
}

export default PeopleYouMayKnow;
