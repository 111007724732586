const eventData = [
    {
      id: "1",
      title: "Hyderabad-Meetup",
      date: "15th August",
      timing: "05:00 PM - 07:00 PM",
      venue: "Hyderabad Stadium",
      eventLink: "/spaces/event-detail"
    },
    {
      id: "2",
      title: "Hyderabad-Meetup",
      date: "15th August",
      timing: "05:00 PM - 07:00 PM",
      venue: "Hyderabad Stadium",
      eventLink: "/spaces/event-detail"
    },
    {
      id: "3",
      title: "Hyderabad-Meetup",
      date: "15th August",
      timing: "05:00 PM - 07:00 PM",
      venue: "Hyderabad Stadium",
      eventLink: "/spaces/event-detail"
    },
    {
      id: "4",
      title: "Hyderabad-Meetup",
      date: "15th August",
      timing: "05:00 PM - 07:00 PM",
      venue: "Hyderabad Stadium",
      eventLink: "/spaces/event-detail"
    },
  ];

  export default eventData;