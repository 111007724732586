import React from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";
import FileUpload from "../../../themes/FileUpload/FileUpload";

interface AddResumeModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onBtnClick: () => void;
  title: string;
}
const AddResumeModal: React.FC<AddResumeModalProps> = ({
  popover,
  setPopover,
  title,
  onBtnClick,
}) => {
  const handleClosePopover = () => {
    setPopover(false);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          onBtnClick();
        }}
        title="Add"
      />
    </div>
  );
  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="mt-4 mb-1 px-5 flex flex-col gap-4 max-h-[360px] overflow-y-auto overflow-x-hidden">
        <FileUpload className="add-resume-upload">
          <div className="flex gap-2 justify-center">
            <h6 className="text-[17px] font-normal leading-[130%] text-[#1F1F1F]">
              Drag and drop your files here or
            </h6>
            <p className="text-[17px] font-[500] leading-[120%] text-[#FC741E] underline hover:no-underline">
              browse
            </p>
          </div>
          <p className="text-[15px] font-normal leading-[normal] text-[#666666] mt-3">
            Max.file size : 20MB
          </p>
        </FileUpload>
      </div>
    </CustomModal>
  );
};

export default AddResumeModal;
