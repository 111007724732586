import { useState } from "react";
import {
  KebabMenu,
  CakeOutlinedGray,
  UserRequestOutlined,
} from "../Icons/Icons";
import AvatarComp from "../../themes/AvatarComp/AvatarComp";
import RequestsBox from "../RequestsBox/RequestsBox";
import Requests1 from "../../assets/png/requests-3.png";
import Requests2 from "../../assets/png/requests-2.png";
import Space1 from "../../assets/png/space-9.png";
import Space2 from "../../assets/png/space-8.png";
import CommentNotification from "./CommentNotification";
import RequestApproval from "./RequestApproval";
import { useNavigate } from "react-router-dom";
import { Popover } from "antd";

const NotificationContent = () => {
  const navigate = useNavigate();
  const [allAsRead, setAllAsRead] = useState(false);

  const handleNavigationToNotification = () => {
    navigate("/notification");
  };

  const handleNavigateToRequest = () => {
    navigate("/requests");
  };

  const handleNavigateToBirthday = () => {
    navigate("/birthday");
  };

  const notificationRequestData = [
    {
      id: "1",
      image: Requests1,
      fullName: "akshayachandar",
      userName: "e/akshayachandar",
      timeAgo: "5m",
    },
    {
      id: "2",
      image: Requests1,
      fullName: "akshayachandar",
      userName: "e/akshayachandar",
      timeAgo: "5m",
    },
  ];

  const notificationCommentData = [
    {
      id: "1",
      image: Requests2,
      fullName: "akshayachandar",
      comment: "Card title goes here, Card title goes here...",
      timeAgo: "5m",
    },
    {
      id: "2",
      image: Requests2,
      fullName: "akshayachandar",
      comment: "Card title goes here, Card title goes here...",
      timeAgo: "5m",
    },
  ];

  const thisWeekNotificationCommentData = [
    {
      id: "1",
      image: Space1,
      fullName: "akshayachandar",
      comment: "Card title goes here, Card title goes here...",
      timeAgo: "5m",
    },
  ];

  const requestApprovalData = [
    {
      id: "1",
      image: Space2,
      timeAgo: "5m",
    },
    {
      id: "2",
      image: Space2,
      timeAgo: "5m",
    },
  ];

  const handleAllAsUnread = () => {
    if (allAsRead) {
      setAllAsRead(false);
    } else {
      setAllAsRead(true);
    }
  };

  const menuContent = (
    <ul>
      <li
        onClick={handleAllAsUnread}
        className=" pt-1.5 pb-0.5 px-2 rounded-lg hover:bg-gray_4 transition-all duration-200 ease-[ease] cursor-pointer"
      >
        <span className=" text-primary text-[17px] not-italic font-normal leading-[120%]">
          {allAsRead ? "Mark all as unread" : "Mark all as read"}
        </span>
      </li>
    </ul>
  );

  return (
    <>
      <div className="flex items-center justify-between p-6 border border-solid border-gray_4">
        <h2 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
          Notification
        </h2>
        <Popover
          overlayClassName="w-[190px]"
          content={menuContent}
          trigger="click"
          placement="bottomRight"
          arrow={false}
          overlayInnerStyle={{
            padding: 8,
            borderRadius: 12,
          }}
        >
          <button className="flex self-start ml-auto rounded-[50%] hover:bg-gray_4_hover transition-all duration-200 ease-[ease]">
            <KebabMenu />
          </button>
        </Popover>
      </div>
      <div className="py-6 pl-6 pr-3 mr-3  h-[376px] overflow-y-auto theme-scrollbar-sm">
        <p className="text-primary text-[15px] not-italic font-normal leading-[normal] mb-4">
          New
        </p>
        <div className="flex items-center gap-3 mb-4">
          <CakeOutlinedGray />
          <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] capitalize">
            Birthday
          </span>
        </div>
        <div className="mb-6">
          <div
            onClick={handleNavigateToBirthday}
            className="flex items-center cursor-pointer"
          >
            <AvatarComp
              size={45}
              avatarClassName="[&:not(:first-child)]:me-[-40px]"
            />
            <div className="ml-[26px]">
              <p className="text-primary text-[15px] not-italic font-medium leading-[120%] mb-1">
                Akshada and 3 others
              </p>
              <p className="text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                Wish them a happy birthday
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center gap-3">
            <UserRequestOutlined />
            <span className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] capitalize">
              Requests
            </span>
          </div>
          <button
            onClick={handleNavigateToRequest}
            className="text-U25_orange text-right text-[15px] not-italic font-normal leading-[normal]"
          >
            See all
          </button>
        </div>
        <div className="mb-10">
          <RequestsBox
            data={notificationRequestData}
            containerClass="mb-6"
            notificationRequest
          />
          <CommentNotification
            data={notificationCommentData}
            popoverNotificationBadge
            allAsRead={allAsRead}
          />
          <p className="text-primary text-[15px] not-italic font-normal leading-[normal] mb-4">
            This week
          </p>
          <CommentNotification
            data={thisWeekNotificationCommentData}
            popoverNotificationBadge
            allAsRead={allAsRead}
          />
          <RequestApproval data={requestApprovalData} />
        </div>
        <button
          onClick={handleNavigationToNotification}
          className="absolute flex justify-center bg-white border-t-gray_4 rounded-[0_0_12px_12px] border-t border-solid right-0 left-0 bottom-0 py-4 px-3 w-full text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
        >
          View all
        </button>
      </div>
    </>
  );
};

export default NotificationContent;
