import React from "react";
import useIsMac from "../../hooks/useIsMac";
interface SuggestCompProps {
  title: string;
  data: string[];
  onChipClick: (value: string) => void;
}
const SuggestComp: React.FC<SuggestCompProps> = ({
  data,
  title,
  onChipClick,
}) => {
  const isMac = useIsMac();

  return (
    <div className="">
      <h6 className="mt-5 text-[#808080] text-[16px] font-[400]">{title}</h6>
      <div className="flex flex-wrap gap-2 ">
        {data.map((item) => {
          return (
            <div
              onClick={() => onChipClick(item)}
              className="h-[34px] flex items-center justify-center rounded-[16px] px-3  border border-[#F2F2F2] hover:bg-[#D6D6D6] transition cursor-pointer"
            >
              <h6
                className={`${
                  isMac && " pt-1"
                } text-[#1F1F1F] text-[16px] font-[400]`}
              >
                {item}
              </h6>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SuggestComp;
