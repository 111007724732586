import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { XCloseMd } from "../../Icons/Icons";
import { HouseModalProps } from "../../../type/types";
import { Tabs, TabsProps } from "antd";
import "../../../styles/Reactions.scss";
import UserReaction from "../../Chat/UserReaction/UserReaction";
import {
  reactionsData,
  reactionsData2,
  reactionsData3,
} from "../../../data/reactionsData";

const Reactions: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "All",
      children: <UserReaction data={reactionsData} />,
    },
    {
      key: "2",
      label: "❤️ 4",
      children: <UserReaction data={reactionsData2} />,
    },
    {
      key: "3",
      label: "🥲 2",
      children: <UserReaction data={reactionsData3} />,
    },
  ];

  return (
    <CustomModal
      className="feed-modal reaction"
      isModalOpen={isModalOpen}
      handleOk={handleOk}
      handleCancel={handleCancel}
      title={<div className=""></div>}
      bodyStyle={{
        paddingBottom: 16,
      }}
      footer={false}
      width={615}
      closeIcon={<XCloseMd />}
    >
      <Tabs
        className="reaction-modal px-6"
        defaultActiveKey="1"
        items={items}
      />
    </CustomModal>
  );
};

export default Reactions;
