import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import ThemeCardLayout from "../components/ThemeCardLayout/ThemeCardLayout";
import EventBox from "../components/Home/Spaces/EventBox/EventBox";
import eventData from "../data/eventData";
import EventBanner from "../assets/png/event-banner-img.png";
import { CalenderOutlinedBold, LocationPinMd } from "../components/Icons/Icons";
import AvatarComp from "../themes/AvatarComp/AvatarComp";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import RSVP from "../components/Feeds/Modal/RSVP";
import { NotificationPlacement } from "antd/es/notification/interface";
import { notification } from "antd";
import useIsMac from "../hooks/useIsMac";

const EventDetail = () => {
  const [openRSVPModal, setOpenRSVPModal] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const handleOpenRSVPModal = () => {
    setOpenRSVPModal(true);
  };

  const handleCancelRSVPModal = () => {
    setOpenRSVPModal(false);
  };

  const CardTitle = (
    <div className="flex items-center justify-between">
      <h3 className="text-primary text-xl not-italic font-medium leading-[100%]">
        Other events for you
      </h3>
    </div>
  );

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: "",
      className:
        "under25-notification auto-close !bg-primary !w-[350px] !shadow-[0px_12px_8px_0px_rgba(0,0,0,0.08),0px_3px_24px_0px_rgba(0,0,0,0.04)] !py-3 !px-4 !rounded-lg ![&_.ant-notification-notice-message]:m-0",
      description: (
        <div className="flex items-center justify-between">
          <div className="border-r-gray_1 border-r border-solid pr-5">
            <p className="text-white text-[17px] not-italic font-medium leading-[120%]">
              You have RSVP’ed
            </p>
            <p className="text-white text-sm not-italic font-normal leading-[120%]">
              We will notify a day before it starts
            </p>
          </div>
          <PrimaryButton
            size="small"
            className="!rounded-lg !ml-5"
            theme="light"
            title="Undo"
            onClick={closeNotification}
          />
        </div>
      ),
      closeIcon: false,
      duration: 4.5,
      placement,
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  const isMac = useIsMac();

  return (
    <>
      {contextHolder}
      <RSVP
        isModalOpen={openRSVPModal}
        handleOk={handleOpenRSVPModal}
        handleCancel={handleCancelRSVPModal}
      />
      <Layout>
        <div className="grid bg-gray_4 lg:grid-cols-[3fr_1fr] gap-[30px] py-6 px-8">
          <div>
            <div className="bg-white rounded-xl mb-5">
              <div>
                <img
                  src={EventBanner}
                  className="object-cover rounded-[12px_12px_0_0] mb-4"
                  alt="Event Banner"
                />
              </div>
              <div className="px-4 py-4">
                <p className="text-U25_orange text-[17px] not-italic font-normal leading-[120%]">
                  Online event
                </p>
                <p className="text-primary text-2xl not-italic font-medium leading-[100%] mb-4">
                  Hyderabad Event
                </p>
                <div className="flex items-center gap-3 mb-2">
                  <div className="flex items-center gap-3">
                    <CalenderOutlinedBold className={`${isMac && "mb-1"}`} />
                    <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                      Sunday, 30th July
                    </p>
                  </div>
                  <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] before:inline-block before:content-['•'] before:align-middle before:me-3">
                    05:00 PM - 07:00 PM
                  </p>
                </div>
                <div className="flex items-center gap-2 mb-4">
                  <LocationPinMd className={`${isMac && "mb-1"}`} />
                  <a
                    className="text-blue_1 text-[17px] not-italic font-normal leading-[120%] underline decoration-[1px]"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.google.com/maps/place/Bengaluru,+Karnataka/@12.8033049,77.7108574,15z/data=!4m6!3m5!1s0x3bae1670c9b44e6d:0xf8dfc3e8517e4fe0!8m2!3d12.9715987!4d77.5945627!16zL20vMDljMTc?entry=ttu"
                  >
                    Hyderabad Stadium
                  </a>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <AvatarComp />
                    <p
                      onClick={handleOpenRSVPModal}
                      className="text-link_hover text-lg not-italic font-normal leading-[normal] cursor-pointer"
                    >
                      <span className="text-primary text-lg not-italic font-medium leading-[normal] me-1">
                        124
                      </span>
                      People have RSVP’ed
                    </p>
                  </div>
                  <PrimaryButton
                    onClick={() => openNotification("bottomRight")}
                    title="RSVP"
                    className="!py-[10px] !px-6"
                  />
                </div>
              </div>
            </div>
            <div className="bg-white rounded-xl p-4">
              <h4 className="text-primary text-2xl not-italic font-medium leading-[100%] mb-2">
                About
              </h4>
              <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-2">
                Lorem ipsum dolor sit amet consectetur. Mauris libero habitasse
                gravida amet nibh faucibus. Iaculis libero tristique blandit
                justo sit amet tortor nam. Ecras eu arcu felis suspendisse.
                Porta feugiat et tristique justo lacus sit arcu.
              </p>
              <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
                Lorem ipsum dolor sit amet consectetur. Mauris libero habitasse
                gravida amet nibh faucibus. Iaculis libero tristique blandit
                justo sit amet tortor nam. Ecras eu arcu felis suspendisse.
                Porta feugiat et tristique justo lacus sit arcu. Lorem ipsum
                dolor sit amet consectetur. Mauris libero habitasse gravida amet
                nibh faucibus. Iaculis libero tristique blandit justo sit amet
                tortor nam. Ecras eu arcu felis suspendisse. Porta feugiat et
                tristique justo lacus sit arcu.
              </p>
            </div>
          </div>
          <ThemeCardLayout
            btnTitle="View All"
            title={CardTitle}
            titleClass="mb-4"
            containerClass="h-fit !bg-white mb-5"
          >
            <div className="w-full flex flex-col">
              <EventBox data={eventData} />
            </div>
          </ThemeCardLayout>
        </div>
      </Layout>
    </>
  );
};

export default EventDetail;
