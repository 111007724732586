import CustomInput from "../../CustomInput/CustomInput";
import CustomModal from "../../CustomModal/CustomModal";
import { PrevArrowLight } from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

interface AddCollegeProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onBackClick?: () => void;
}

export const AddCollegeModal: React.FC<AddCollegeProps> = ({
  popover,
  setPopover,
  onBackClick = () => {},
}) => {
  const handleClosePopover = () => {
    setPopover(false);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2] flex items-center gap-3">
      <span
        onClick={onBackClick}
        className="cursor-pointer hover:bg-[#F4F4F4] rounded-[4px] transition max-w-[24px] min-w-[24px] max-h-[24px] min-h-[24px]"
      >
        <PrevArrowLight />
      </span>
      <h6 className="text-[#1F1F1F] text-[24px] font-medium leading-[100%] capitalize">
        Add College
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Save Changes"
      />
    </div>
  );

  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="flex flex-col gap-5 px-[54px] pt-4 pb-6">
        <div className="flex flex-col gap-1">
          <label
            htmlFor="City"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            City
          </label>
          <CustomInput id="City" onChange={(e) => {}} />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="Abbreviation"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            Abbreviation
          </label>
          <CustomInput id="Abbreviation" onChange={(e) => {}} />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="collegePopulation"
            className="text-[#666666] text-[17px] not-italic font-normal leading-[120%]"
          >
            Approx. college population
          </label>
          <CustomInput
            className="add-college-number"
            type="number"
            id="collegePopulation"
            onChange={(e) => {}}
          />
        </div>
      </div>
    </CustomModal>
  );
};
