import { Avatar, Button } from "antd";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { ImageUploadOutlined, PodRecord, UploadPodCast } from "../Icons/Icons";
import CustomTextArea from "../CustomTextArea/CustomTextArea";
import PhotoUpload from "../PhotoUpload/PhotoUpload";
import Waveform from "../WaveForm/WaveForm";
import RecordView from "./Recorder";

interface PodDiscussionTabProps {
  podUser: string;
}

const PodDiscussionTab: React.FC<PodDiscussionTabProps> = ({ podUser }) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [recordStatus, setRecordStatus] = useState<any>(false);
  const [podRecordData, setPodRecordData] = useState<Blob | null | any>(null);

  useEffect(() => {
    setPodRecordData(null);
    setPodRecordData(null);
    setRecordStatus(false);
  }, []);

  const [source, setSource] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fileList = e.target.files[0];
    const url = URL.createObjectURL(fileList);
    setSource(url);
  };
  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      const audioUrl = URL.createObjectURL(file);
      const response = await fetch(audioUrl);
      const audioBlob = await response.blob();

      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Data = reader.result as string;
        const audioElement = new Audio();
        audioElement.src = base64Data;

        setSelectedFile(base64Data);
      };

      reader.readAsDataURL(audioBlob);
    }
  };

  const handleClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  return (
    <div>
      {selectedFile || podRecordData ? (
        <div className="mx-6 my-4  rounded-xl h-[334px]">
          <CustomTextArea
            className="text-primary text-[32px] not-italic font-medium !leading-[130%] placeholder:text-gray_10 theme-scrollbar-sm"
            placeholder="Add a title your audio"
            autoSize={{ minRows: 3, maxRows: 5 }}
          />

          <CustomTextArea
            className="text-primary placeholder:text-gray_10 text-[17px] not-italic font-normal !leading-[120%] capitalize"
            placeholder="#Type a Hashtag"
          />

          {source ? (
            <>
              <PhotoUpload
                title="Album Cover"
                description="Choose a album cover"
                closeIcon={false}
                noBorder
              />
              <div className="w-full rounded-[10px] py-[9px] "></div>
            </>
          ) : (
            <div className={`flex  border-gray_2`}>
              <div className="flex justify-center items-center w-20 h-[80px] p-7 bg-gray_4 border rounded-xl border-solid border-gray_2 mr-4">
                <ImageUploadOutlined />
              </div>
              <div className="py-1">
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="flex flex-col justify-center mb-2 cursor-text"
                >
                  <span className="text-dark_80 text-[15px] not-italic font-normal leading-[120%] mb-[2px]">
                    Album Cover
                  </span>
                  <span className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                    Choose a album cover
                  </span>
                </div>
                <Button
                  onClick={handleClick}
                  className="flex text-[13px] justify-center items-center h-auto border rounded-lg border-solid border-gray_2 text-U25_orange text-sm not-italic font-normal leading-[120%] py-2 px-3 hover:!text-U25_orange hover:!bg-gray_30 hover:!border-gray_2 cursor-pointer"
                >
                  Upload
                </Button>
                <input
                  ref={inputRef}
                  onChange={handleFileChange}
                  hidden
                  id="video-upload"
                  type="file"
                  accept="image/*"
                  width="535px"
                  height="312px"
                />
              </div>
            </div>
          )}

          {selectedFile && (
            <div className="mt-2">
              <Waveform
                url={selectedFile}
                type={"pod"}
                recordStatus={setRecordStatus}
                fileData={setSelectedFile}
              />
            </div>
          )}
          {podRecordData && (
            <div className="mt-2">
              <Waveform
                url={podRecordData}
                type={"pod"}
                recordStatus={setRecordStatus}
                fileData={setPodRecordData}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="mx-6 my-4 border-dashed border-2 border-[#E2E2E2] rounded-xl h-[334px] relative">
          <div className="flex justify-center items-center pt-6 flex-col">
            {recordStatus && (
              <span className=" bg-[#FFF4ED] text-U25_orange py-3 px-4 text-[17px] mb-6 rounded-[0px_0px_8px_8px] absolute top-[-1px]">
                Recording
              </span>
            )}

            {recordStatus ? (
              <>
                <div className="p-[5px] mt-[69px] border-[3px] rounded-[186px] border-U25_orange h-[76px] flex justify-center items-center relative">
                  <Avatar className={`w-[60px] h-[60px] z-10`} src={podUser} />
                </div>
              </>
            ) : (
              <Avatar className={` w-[60px] h-[60px]`} src={podUser} />
            )}

            {!recordStatus && (
              <>
                <p className="mt-5 text-center text-xl not-italic font-normal leading-[normal]">
                  cover song or a cool gossip
                </p>
                <p className="text-[17px] mt-3 text-link_hover not-italic font-[400] leading-[120%] capitalize">
                  Hit Record !!
                </p>
              </>
            )}

            {recordStatus ? (
              <div className=" py-4 gap-4 px-6 rounded-xl flex flex-col justify-center items-center ">
                <RecordView
                  setFile={setPodRecordData}
                  recordStatus={recordStatus}
                />
              </div>
            ) : (
              <span
                onClick={() => {
                  if (recordStatus) {
                    setRecordStatus(false);
                  } else {
                    setRecordStatus(true);
                  }
                }}
                className="p-4 bg-U25_orange rounded-[400px] mt-10 cursor-pointer"
              >
                <PodRecord />
              </span>
            )}
          </div>
          {!recordStatus && (
            <div className="flex w-full justify-start p-4 mt-4 cursor-pointer">
              <label
                htmlFor="fileInput"
                className="flex items-center justify-center cursor-pointer"
              >
                <UploadPodCast />
                <p className="ml-2">Upload from Files</p>
              </label>
              <input
                id="fileInput"
                type="file"
                accept="audio/*"
                style={{ display: "none" }}
                onChange={handleFileInputChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PodDiscussionTab;
