import React from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";

interface LeaveGroupModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeaveGroupModal: React.FC<LeaveGroupModalProps> = ({
  modal,
  setModal,
}) => {
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-gray_4">
      <h6 className="text-dark_85 text-[24px] font-medium leading-[100%] capitalize">
        Leave Group
      </h6>
    </div>
  );

  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleClosePopover();
        }}
        title="Cancel"
      />
      <PrimaryButton
        theme="warning"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
        }}
        title="Leave"
      />
    </div>
  );

  return (
    <CustomModal
      closeIcon={null}
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6 py-4 pb-2">
        <p className="text-gray_6 text-[17px] font-normal leading-[120%] ">
          Are you sure you want to leave this chat? you would be able to join
          again only if admin invites you.
        </p>
      </div>
    </CustomModal>
  );
};

export default LeaveGroupModal;
