import React, { useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import { Radio, RadioChangeEvent, Space } from "antd";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import SearchBar from "../../SearchBar/SearchBar";
import userListData from "../../../data/userListData";
import TextArea from "antd/es/input/TextArea";
import UserContactList from "../../UserContactList/UserContactList";

const ReportModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  setReportModalOpen,
  profile,
}) => {
  const [reportPeopleModalOpen, setReportPeopleModalOpen] = useState(false);
  const [reportingModalOpen, setReportingModalOpen] = useState(false);

  const [reportReasonModalOpen, setReportReasonModalOpen] = useState(false);
  const [reportConfirmModalOpen, setReportConfirmModalOpen] = useState(false);
  const [isProfile, setIsProfile] = useState(profile || false);
  const [radioValue, setRadioValue] = useState(1);
  const [radioValueData, setRadioValueData] = useState(0);
  const [selectedReport, setSelectedReport] = useState(null);

  const modalHeader = (
    <div className="flex items-center justify-between pt-6 pb-4 px-6 border-b  border-gray_4">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Report
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            handleCancel();
            setRadioValue(1);
            setReportingModalOpen(true);
          }}
          title="Begin Reporting"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const onChange = (e: RadioChangeEvent) => {
    setRadioValueData(e.target.value);
  };

  const modalFooterReport = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 py-4 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            if (radioValueData > 0) {
              if (isProfile) {
                if (radioValue === 2) {
                  setRadioValueData(0);
                  setRadioValue(3);
                  handleCancel();
                  setReportingModalOpen(true);
                } else if (radioValue === 1) {
                  setRadioValue(2);
                } else if (radioValue === 3 && reportPeopleModalOpen) {
                  setReportPeopleModalOpen(false);
                  setRadioValueData(0);
                  setReportConfirmModalOpen(true);
                } else if (
                  radioValue === 3 &&
                  !reportPeopleModalOpen &&
                  selectedReport === "Me"
                ) {
                  setReportingModalOpen(false);
                  setReportPeopleModalOpen(false);
                  setReportConfirmModalOpen(true);
                } else {
                  setReportReasonModalOpen(false);
                  setReportingModalOpen(false);

                  setReportPeopleModalOpen(true);
                }
              } else {
                if (radioValue === 2) {
                  setReportReasonModalOpen(true);
                  setRadioValueData(0);
                  setReportingModalOpen(false);
                } else {
                  setRadioValue(2);
                  setRadioValueData(0);
                  handleCancel();
                  setReportingModalOpen(true);
                }
              }
            }
          }}
          title="Next"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const modalFooterReportReason = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            setReportReasonModalOpen(false);
            setReportingModalOpen(false);
            setReportConfirmModalOpen(true);
            setRadioValue(1);
          }}
          title="Next"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const modalFooterReportReasonStatus = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            if (reportConfirmModalOpen) {
              setReportConfirmModalOpen(false);
            } else {
              setReportReasonModalOpen(false);
              setReportingModalOpen(false);
              setReportConfirmModalOpen(true);
            }
          }}
          title="Next"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const [inputData, setInputData] = useState("");

  const [inputCount, setInputCount] = useState(inputData.length);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 100) {
      setInputData(inputValue);
      setInputCount(inputValue.length);
    }
  };

  const radioOptions: any = profile
    ? {
        1: [
          "It’s posting content that should not be on Under 25",
          "It’s pretending to be someone else",
        ],
        2: [
          "It is spam",
          "Hate speech",
          "Hate speech",
          "Nudity or sexual activity",
          "Scam or fraud",
          "Violence",
          "Illegal activities",
          "Something else",
        ],
        3: [
          "Me",
          "Someone I know",
          "Celebrity public figure",
          "Brand or organisation",
        ],
      }
    : {
        1: ["Myself", "Someone else", "A Particular group of people"],
        2: [
          "It is spam",
          "Bullying or harassment",
          "Hate speech",
          "Nudity or sexual activity",
          "Scam or fraud",
          "Violence",
          "Illegal activities",
          "Something else",
        ],
      };

  return (
    <>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={modalHeader}
        footer={modalFooter}
        width={615}
      >
        <div className="flex items-center flex-col gap-4 py-6 px-6  overflow-x-auto overflow-hidden theme-scrollbar">
          <div>
            <h4 className="text-2xl not-italic font-medium leading-[100%]">
              Hello!
            </h4>
          </div>
          <div className="flex flex-col  text-[17px] not-italic font-normal leading-[120%] text-gray_1">
            <p className="text-left">
              We take reports very seriously. When we find a rule or guideline
              violation, we will ask the person to remove their content, lock or
              suspend their Under 25 account.
            </p>

            <p className="text-left mt-4">
              Before we proceed, please answer a few questions to help us
              understand the situation better.
            </p>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        isModalOpen={reportingModalOpen}
        handleOk={() => {
          setReportingModalOpen(false);
        }}
        handleCancel={() => {
          setReportReasonModalOpen(false);
          setReportingModalOpen(false);
          setReportConfirmModalOpen(false);
          setRadioValue(1);
        }}
        footer={modalFooterReport}
        title={
          <div className="flex items-center justify-start pt-6 pb-4 px-6 border-b  border-gray_4">
            <span
              onClick={() => {
                if (radioValue === 2) {
                  setRadioValue(1);
                } else {
                  setReportingModalOpen(false);
                  setReportModalOpen(true);
                }
              }}
              className="mr-3 cursor-pointer "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5M5 12L12 19M5 12L12 5"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              Who are you Reporting for?
            </h4>
          </div>
        }
        width={615}
      >
        <div className="flex flex-col gap-4 pb-6 pt-4 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full">
          <span className="flex text-[15px] not-italic font-normal leading-[120%] p-3 bg-gray_3 rounded-lg">
            <p className="text-primary">Note:</p>
            <p className="ml-1.5 text-gray_1">
              Your report will remain anonymous
            </p>
          </span>
          <Radio.Group
            onChange={onChange}
            value={radioValueData}
            className="w-full  max-h-[80vh]"
          >
            <div>
              {radioOptions[radioValue].map((option: any, index: any) => (
                <span
                  className="px-3 py-4 border border-1 border-gray_4 flex justify-start w-full rounded-lg my-2 hover:bg-gray_4 cursor-pointer"
                  key={index}
                >
                  <Radio
                    className="w-full h-full"
                    onClick={() =>
                      radioValue === 3 && setSelectedReport(option)
                    }
                    value={index + 1}
                  >
                    <span className="text-primary text-[17px]">{option}</span>
                  </Radio>
                </span>
              ))}
            </div>
          </Radio.Group>
        </div>
      </CustomModal>

      <CustomModal
        isModalOpen={reportPeopleModalOpen}
        handleOk={() => {
          setReportPeopleModalOpen(false);
        }}
        handleCancel={() => {
          setReportPeopleModalOpen(false);
        }}
        footer={modalFooterReport}
        title={
          <div className="flex items-center justify-start pt-6 pb-4 px-6 border-b  border-gray_4">
            <span
              onClick={() => {
                if (radioValue === 2) {
                  setRadioValue(1);
                } else if (radioValue === 3) {
                  setRadioValue(3);
                  setReportPeopleModalOpen(false);
                  setReportingModalOpen(true);
                } else {
                  setReportingModalOpen(false);
                  setReportModalOpen(true);
                }
              }}
              className="mr-3 cursor-pointer "
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5M5 12L12 19M5 12L12 5"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              {selectedReport}
            </h4>
          </div>
        }
        width={615}
      >
        <div className="flex flex-col gap-4 pb-6 pt-4 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full">
          <SearchBar className="" placeholder="Search for Requests" />
          <UserContactList
            type={"checkbox"}
            data={userListData}
            containerClassName="h-[344px]"
            noFooter={true}
            profile={true}
          />
        </div>
      </CustomModal>
      <CustomModal
        isModalOpen={reportReasonModalOpen}
        handleOk={() => {
          setReportReasonModalOpen(false);
        }}
        handleCancel={() => {
          setReportReasonModalOpen(false);
        }}
        footer={modalFooterReportReason}
        title={
          <div className="flex items-center justify-start pt-5 pb-0 px-6">
            <span
              onClick={() => {
                setReportingModalOpen(true);
                setReportReasonModalOpen(false);
              }}
              className="mr-3 cursor-pointer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5M5 12L12 19M5 12L12 5"
                  stroke="#101828"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
              Who are you Reporting for?
            </h4>
          </div>
        }
        width={615}
      >
        <div className="flex flex-col gap-4 pb-6 pt-4 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full">
          <div className="relative justify-center items-center">
            <TextArea
              autoSize={true}
              value={inputData}
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              id="about"
              className="misssion-create-textarea border focus:shadow-none  theme-scrollbar-sm font-sans"
              placeholder="Enter reason for reporting"
            />
            <div className="absolute bottom-[8px] right-[20px]">
              <p className=" text-gray_6 text-[13px] not-italic font-normal leading-[120%]">
                {inputCount}/100
              </p>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        isModalOpen={reportConfirmModalOpen}
        handleOk={() => {
          setReportReasonModalOpen(false);
          setReportingModalOpen(false);
          setReportConfirmModalOpen(false);
          setRadioValue(1);
        }}
        handleCancel={() => {
          setReportReasonModalOpen(false);
          setReportingModalOpen(false);
          setReportConfirmModalOpen(false);
          setRadioValue(1);
        }}
        footer={modalFooterReportReasonStatus}
        title={
          <div className="flex items-center justify-start py-6  px-6"></div>
        }
        width={615}
      >
        <div className="flex flex-col gap-4 mt-4 px-6 overflow-x-auto overflow-hidden theme-scrollbar w-full justify-center items-center">
          <span className=" p-[4.8px] w-12 h-12 bg-green_1 rounded-[240px] flex justify-center items-center">
            <svg
              width="28"
              height="21"
              viewBox="0 0 28 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d_448_20998)">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M25.7524 3.11153L10.5161 18.3478C10.0079 18.856 9.18383 18.856 8.67558 18.3478L1.24805 10.9202L3.08858 9.07971L9.59584 15.587L23.9118 1.271L25.7524 3.11153Z"
                  stroke="white"
                  stroke-width="1.8"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d_448_20998"
                  x="0.347656"
                  y="0.370972"
                  width="27.5047"
                  height="20.4579"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                  />
                  <feOffset dx="1.2" dy="1.2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.6 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_448_20998"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_448_20998"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </span>
          <h4 className="text-2xl not-italic font-medium leading-[100%]">
            {isProfile ? "Profile" : "Card"} Reported
          </h4>
          <p className="text-[17px] not-italic font-normal leading-[120%] capitaliz mb-4">
            Thank you for making the Under 25 App a safer space.
          </p>
        </div>
      </CustomModal>
    </>
  );
};

export default ReportModal;
