import { Modal } from "antd";
import React from "react";
import { ICustomModalProps } from "../../type/types";
import "./CustomModal.scss";
import { CloseDarkOutlined, ClosePopup } from "../Icons/Icons";

const CustomModal: React.FC<ICustomModalProps> = ({
  title,
  className,
  closeIcon = <CloseDarkOutlined />,
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  footer,
  width,
  style,
  bodyStyle,
}) => {
  return (
    <Modal
      className={`custom-modal ${className}`}
      closeIcon={closeIcon}
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      width={width}
      style={style}
      bodyStyle={bodyStyle}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
