import React from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { HouseModalProps } from "../../../type/types";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { CloseDarkOutlined } from "../../Icons/Icons";
import { Link } from "react-router-dom";
import UserContactList from "../../UserContactList/UserContactList";
import userListData from "../../../data/userListData";
import useIsMac from "../../../hooks/useIsMac";

const LikesModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const isMac = useIsMac();

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <PrimaryButton
        onClick={handleCancel}
        title="Cancel"
        theme="light"
        width="fit-content"
      />
      <Link to={"/community"}>
        <PrimaryButton onClick={handleOk} title="Confirm" width="fit-content" />
      </Link>
    </div>
  );

  return (
    <div>
      <CustomModal
        className="house-modal"
        title={
          <div className="flex pt-6 pb-4 py-6 pl-6  items-center justify-start border-b-gray_4 border-b border-solid w-full">
            <h3 className="  text-dark_85 text-[24px] not-italic font-medium leading-[100%]">
              Likes
            </h3>
            <div
              className={` ${
                isMac && "pt-2 mb-1"
              } flex items-center ml-2 justify-center bg-blue_1 rounded-3xl py-[6px] px-[6px] w-[25px] h-[21px]`}
            >
              <span className="text-white text-[15px] not-italic font-medium leading-[120%]">
                12
              </span>
            </div>
          </div>
        }
        closeIcon={<CloseDarkOutlined />}
        width={615}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={<></>}
      >
        <div className=" px-6 py-4">
          <UserContactList
            data={userListData}
            containerClassName="h-[344px]"
            noFooter={true}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default LikesModal;
