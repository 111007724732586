import { useEffect } from "react";

const useStickyScroll = (SectionSelector: string): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const Section = document.querySelector(SectionSelector) as HTMLElement;

    const windowHeight = window.innerHeight;

    if (window.innerWidth >= 1024) {
      if (Section && Section.offsetHeight > windowHeight) {
        const updatedStickyValue = windowHeight - Section.offsetHeight - 20;
        Section.style.top = `${updatedStickyValue}px`;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, SectionSelector]);
};

export default useStickyScroll;
