import UserAbout from "../../UserAbout/UserAbout";

const LeftSection = () => {
  return (
    <section>
      <UserAbout edit={false} />
    </section>
  );
};

export default LeftSection;
