import { cloneElement, ReactElement, type ReactNode } from "react";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import ProfilePic from "../../../assets/png/community-user-picture.png";
import { LogoutLined, SettingIcon } from "../../Icons/Icons";
import "./account_drop.scss";
import LogoutModal from "../../LogoutModal/LogoutModal";
import { useState } from "react";
interface AccountDropProps {
  children: ReactNode;
}

const AccountDrop: React.FC<AccountDropProps> = ({ children }) => {
  const navigateTo = (href: any) => {
    window.location.href = href;
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            navigateTo("/settings");
          }}
          className="flex gap-3 items-center"
        >
          <span className="flex justify-center items-center max-w-[24px] min-w-[24px] min-h-[24px] max-h-[24px]">
            <SettingIcon />
          </span>
          <h6 className="text-[#1F1F1F] text-[15px] font-normal leading-[120%]">
            Settings & Privacy policy
          </h6>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setModal(true);
          }}
          className="flex gap-3 items-center"
        >
          <span className="flex justify-center items-center max-w-[24px] min-w-[24px] min-h-[24px] max-h-[24px]">
            <LogoutLined height={24} width={24} />
          </span>
          <h6 className="text-[#1F1F1F] text-[15px] font-normal leading-[120%]">
            Logout
          </h6>
        </div>
      ),
    },
  ];

  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  const [modal, setModal] = useState(false);

  return (
    <>
      <Dropdown
        overlayClassName="account-dropdown"
        trigger={["click"]}
        menu={{ items }}
        dropdownRender={(menu) => (
          <div className="bg-white shadow-2xl rounded-[12px] pb-4">
            <div className="flex flex-col gap-3.5 p-6 pb-4">
              <div className="flex gap-4">
                <span className="max-w-[70px] min-w-[70px] min-h-[70px] max-h-[70px] flex justify-center items-center">
                  <img
                    src={ProfilePic}
                    alt="profile pic"
                    className="rounded-[12px]"
                  />
                </span>
                <div className="flex flex-col gap-1">
                  <h6 className="text-[#1F1F1F] text-[24px] font-medium leading-[100%]">
                    Kanishka Reddy
                  </h6>
                  <h6 className="text-[#666666] text-[17px] font-normal leading-[120%]">
                    a/kani
                  </h6>
                </div>
              </div>
              <PrimaryButton
                onClick={() => {
                  navigateTo("/user-account");
                }}
                fontClass="text-[15px]"
                width="full"
                size="regular"
                title="View Profile"
              />
            </div>
            {cloneElement(menu as ReactElement, { style: menuStyle })}
          </div>
        )}
      >
        {children}
      </Dropdown>
      <LogoutModal modal={modal} setModal={setModal} />
    </>
  );
};

export default AccountDrop;
