import { useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "./TestiMonials.scss";
import StoryBg2 from "../../../../assets/png/story-img-1.png";
import StoryBg3 from "../../../../assets/png/story-img-3.png";
import TestiMonialsDetails from "./TestiMonialsDetails/TestiMonialsDetails";
import Under25Logo from "../../../../assets/png/space-8.png";

const TestiMonials = () => {
  const storyIndex = useRef(1);

  const testiMonials = [
    {
      id: 1,
      name: "Rekob Ramya",
      description: "Lorem Ipsum is .",
      address: "USA",
      img: [
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: (
              <div className="min-hull min-w-full">
                <span className="text-[17px] not-italic font-medium leading-[120%]">
                  Guru Nanak Institutions
                </span>
              </div>
            ),
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },

          seeMoreCollapsed: ({ toggleMore, action }: any) => (
            <>
              <p className="text-left px-4 text-14 bottom-[15px] relative text-wrapper_bg  ">
                Lorem ipsum dolor sit amet consectetur.et rhoncus adipiscing
                sed. Lobortis rhonc...
                <button
                  className="text-white ml-1 cursor-pointer"
                  onClick={() => toggleMore(true)}
                >
                  see more
                </button>
              </p>
            </>
          ),
          seeMore: ({ close }: any) => (
            <div
              className=" relative "
              style={{
                maxWidth: "100%",
                minHeight: "100%",
                background: "transprenct",
                top: 0,
              }}
            >
              <p className="text-left px-4 text-14 bottom-[15px] absolute  text-wrapper_bg  ">
                Lorem ipsum dolor sit amet consectetur.et rhoncus adipiscing
                sed. Lobortis amet consectetur.et rhoncus
                <button
                  onClick={() => close()}
                  className="text-white ml-1 cursor-pointer"
                >
                  see less
                </button>
              </p>
            </div>
          ),
        },
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg3,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
      ],
    },
    {
      id: 2,
      name: "Rekob Ramya",
      description: "Lorem Ipsum is .",
      address: "USA",
      img: [
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: "Posted 30m ago",
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg3,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
      ],
    },
    {
      id: 3,
      name: "Rekob Ramya",
      description: "Lorem Ipsum is .",
      address: "USA",
      img: [
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: "Posted 30m ago",
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg3,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
      ],
    },
    {
      id: 4,
      name: "Rekob Ramya",
      description: "Lorem Ipsum is .",
      address: "USA",
      img: [
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: "Posted 30m ago",
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg2,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
        {
          url: StoryBg3,
          duration: 5000,
          header: {
            heading: "Mohit Karekar",
            subheading: (
              <span className="text-[14px] not-italic opacity-60  leading-[120%]">
                Posted 30m ago
              </span>
            ),
            profileImage: Under25Logo,
          },
        },
      ],
    },
  ];

  const options = {
    center: true,
    items: 1,
    margin: 250,
    autoplay: false,
    dots: false,
    stagePadding: 10,
    smartSpeed: 450,
    innerWidth: true,
    autoWidth: true,
    responsive: {
      0: {
        items: 1,
      },
    },
    navElement: "div",
    nav: true,
    onInitialized: counter,
    onTranslated: counter,
  };

  function counter(e: any) {
    const items = e.item.index + 1;
    storyIndex.current = items;
  }

  return (
    <section id="testimonial" className="story ">
      <div className=" mt-5">
        <div className="col-md-12">
          <OwlCarousel
            navClass={[
              "prev-btn nav-btn opacity-40 bg-gray_4 w-[48px] h-[48px] rounded-[50%] mt-0 absolute -translate-y-2/4 left-0 top-2/4 cursor-pointer select-none p-1",
              "next-btn nav-btn opacity-40 bg-gray_4 w-[48px] h-[48px] rounded-[50%] mt-0 absolute -translate-y-2/4 right-0 top-2/4 cursor-pointer select-none p-1",
            ]}
            className="user-stories-carousel relative"
            {...options}
          >
            {testiMonials.map((testiMonialDetail, index) => {
              return (
                <TestiMonialsDetails
                  key={index}
                  index={index}
                  storyIndex={storyIndex}
                  testiMonialDetail={testiMonialDetail}
                />
              );
            })}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default TestiMonials;
