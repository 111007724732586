import React, { useEffect, useState } from "react";
import SpacesInterested from "../components/Home/Spaces/SpacesInterested/SpacesInterested";
import interestedSpaces from "../data/interestedSpaces";
import ThemeCardLayout from "../components/ThemeCardLayout/ThemeCardLayout";
import ExplorerView from "../components/Home/Spaces/ExplorerView/ExplorerView";
import Layout from "../components/Layout/Layout";
import ExplorerCard from "../components/Home/Spaces/ExplorerCard/ExplorerCard";
import EventBox from "../components/Home/Spaces/EventBox/EventBox";
import eventData from "../data/eventData";
import CreateEvent from "../components/Feeds/Modal/CreateEvent";
import Explorer1 from "../assets/png/explorer-1.png";
import Explorer2 from "../assets/png/explorer-2.png";
import Explorer3 from "../assets/png/explorer-3.png";
import Explorer4 from "../assets/png/explorer-4.png";
import { useLocation } from "react-router";

type ThrottleFunction = (
  func: (...args: any[]) => void,
  limit: number
) => (...args: any[]) => void;

const debounce: ThrottleFunction = (func, delay) => {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: any) {
    const args: any = arguments;
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
};

const Explorer = () => {
  const [createEventModal, setCreateEventModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdminRole: any = queryParams?.get("role") === "admin";

  const handleOkayCreateEvent = () => {
    setCreateEventModal(true);
  };
  const handleCancelCreateEvent = () => {
    setCreateEventModal(false);
  };

  const CardTitle = (
    <div className="flex items-center justify-between">
      <h3 className="text-primary text-xl not-italic font-medium leading-[100%]">
        Events
      </h3>
      {isAdminRole && (
        <button
          onClick={handleOkayCreateEvent}
          className="text-U25_orange text-[15px] not-italic font-medium leading-[120%]"
        >
          Create Event
        </button>
      )}
    </div>
  );

  const handleScroll = () => {
    const mainSection = document.querySelector(".main-section") as HTMLElement;
    const leftSection = document.querySelector(".left-section") as HTMLElement;
    const rightSection = document.querySelector(
      ".right-section"
    ) as HTMLElement;

    if (mainSection && leftSection && rightSection) {
      const leftBottom = leftSection.getBoundingClientRect().bottom;
      const rightBottom = rightSection.getBoundingClientRect().bottom;
      const mainTop = mainSection.getBoundingClientRect().top;
      const stickyTop =
        mainTop < -window.innerHeight * 0 ? mainTop : -window.innerHeight * 0.1;

      if (leftBottom > 400) {
        leftSection.style.top = `${stickyTop}px`;
      }
      if (rightBottom > 400) {
        rightSection.style.top = `${stickyTop}px`;
      }
    }
  };
  const handleScrollDebounced = debounce(handleScroll, 10);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollDebounced);

    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [handleScrollDebounced]);

  return (
    <>
      <CreateEvent
        isModalOpen={createEventModal}
        handleOk={handleOkayCreateEvent}
        handleCancel={handleCancelCreateEvent}
      />
      <Layout>
        <div className="grid bg-gray_4 lg:grid-cols-[300px_3fr_300px]  md:grid-cols-[1fr] gap-[30px] py-6 px-8 ">
          <div className="xl:top-0 \sticky h-fit overscroll-y-auto no-scrollbar mb-6 left-section">
            <ExplorerCard
              explorerCount={"1.91K"}
              connections={["Including melo and 48 other connections"]}
              avatars={[Explorer1, Explorer2, Explorer3, Explorer4]}
            />
            <ThemeCardLayout
              btnTitle="View All"
              title={CardTitle}
              titleClass="mb-4"
              containerClass="h-fit !bg-white mb-5"
            >
              <EventBox data={eventData.slice(0, 3)} />
            </ThemeCardLayout>
            <div className=" bg-white rounded-xl px-4 py-5">
              <h3 className="text-primary text-xl not-italic font-medium leading-[100%] mb-4">
                Guidelines
              </h3>
              <p className="text-primary text-[15px] not-italic font-normal leading-[120%]">
                Do not promote, coordinate, or engage in harassment. <br />{" "}
                <br />
                Discord is a platform where everyone can find a place to belong,
                and harassment prevents users from building healthy communities.{" "}
                <br /> <br />
                We do not allow harassing behavior such as sustained bullying,
                ban or block evasion, doxxing, or coordinating server joins for
                the purposes of harassing server members (such as server
                raiding). <br /> <br /> We also do not allow the coordination,
                participation, or encouragement of sexual harassment, such as
                sending unsolicited sexually suggestive content, unwanted
                sexualization, or attacks on sexual activity.
              </p>
            </div>
          </div>
          <div className="no-scrollbar overflow-y-hidden main-section ">
            <ExplorerView />
          </div>
          <div className="right-section sticky top-0 h-fit">
            <ThemeCardLayout
              btnTitle="View All"
              title="Spaces you must be interested in"
              titleClass="mb-4"
              containerClass="h-fit !bg-white"
            >
              <SpacesInterested data={interestedSpaces} />
            </ThemeCardLayout>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Explorer;
