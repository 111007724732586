import React, { ReactNode } from "react";
import Header from "./Header/Header";

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, className = "" }) => {
  return (
    <div className={`${className} layout`}>
      <Header />
      <main>{children}</main>
    </div>
  );
};

export default Layout;
