import React, { useEffect, useState } from "react";
import { ImageCheckboxProps, ImageCheckboxDataProps } from "../../type/types";
import "./ImageCheckbox.scss";
import { HashTag } from "../Icons/Icons";

const ImageCheckbox: React.FC<ImageCheckboxProps> = ({
  data,
  setSelectedHouseAvatar,
  setHouseTitle,
  setHashTag,
  setHouseColor,
}) => {
  const [CheckedItems, setCheckedItems] = useState(data);

  const handleCheckboxChange = (index: number) => {
    setCheckedItems((prevState: ImageCheckboxDataProps[]) => {
      return prevState.map((item, i) => ({
        ...item,
        isChecked: i === index,
      }));
    });
  };

  return (
    <div className="image-checkbox-container flex gap-[50px] gap-y-[30px] row-gap flex-wrap">
      {CheckedItems &&
        CheckedItems.map((item, index) => (
          <div key={item.id}>
            <input
              type="checkbox"
              defaultChecked
              className="image-checkbox"
              checked={item.isChecked}
              onChange={() => {
                handleCheckboxChange(index);
                setSelectedHouseAvatar(item.avatar);
                setHouseTitle(item.title);
                setHashTag(item.hashtag);
                setHouseColor(item.src);
              }}
              id={`image-check-${item.id}`}
              hidden
            />
            <label
              style={{ backgroundColor: item.src }}
              className={`image-label border border-[#E2E2E2] `}
              htmlFor={`image-check-${item.id}`}
            >
              {item.title === "White House" ? (
                <>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.4974 17.5V11.3333C7.4974 10.8666 7.4974 10.6333 7.58823 10.455C7.66812 10.2982 7.79561 10.1707 7.95241 10.0908C8.13067 10 8.36402 10 8.83073 10H11.1641C11.6308 10 11.8641 10 12.0424 10.0908C12.1992 10.1707 12.3267 10.2982 12.4066 10.455C12.4974 10.6333 12.4974 10.8666 12.4974 11.3333V17.5M1.66406 7.91667L9.1974 2.26667C9.48428 2.0515 9.62772 1.94392 9.78526 1.90245C9.92432 1.86585 10.0705 1.86585 10.2095 1.90245C10.3671 1.94392 10.5105 2.05151 10.7974 2.26667L18.3307 7.91667M3.33073 6.66667V14.8333C3.33073 15.7668 3.33073 16.2335 3.51239 16.59C3.67218 16.9036 3.92714 17.1586 4.24075 17.3183C4.59727 17.5 5.06398 17.5 5.9974 17.5H13.9974C14.9308 17.5 15.3975 17.5 15.7541 17.3183C16.0677 17.1586 16.3226 16.9036 16.4824 16.59C16.6641 16.2335 16.6641 15.7668 16.6641 14.8333V6.66667L11.5974 2.86667C11.0236 2.43634 10.7368 2.22118 10.4217 2.13824C10.1436 2.06503 9.85124 2.06503 9.57312 2.13824C9.25805 2.22118 8.97116 2.43634 8.3974 2.86667L3.33073 6.66667Z"
                      stroke="#1F1F1F"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </>
              ) : (
                <svg
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.4974 17.5164V11.3497C7.4974 10.883 7.4974 10.6496 7.58823 10.4714C7.66812 10.3146 7.79561 10.1871 7.95241 10.1072C8.13067 10.0164 8.36402 10.0164 8.83073 10.0164H11.1641C11.6308 10.0164 11.8641 10.0164 12.0424 10.1072C12.1992 10.1871 12.3267 10.3146 12.4066 10.4714C12.4974 10.6496 12.4974 10.883 12.4974 11.3497V17.5164M1.66406 7.93302L9.1974 2.28302C9.48428 2.06786 9.62772 1.96028 9.78526 1.91881C9.92432 1.88221 10.0705 1.88221 10.2095 1.91881C10.3671 1.96028 10.5105 2.06786 10.7974 2.28302L18.3307 7.93302M3.33073 6.68302V14.8497C3.33073 15.7831 3.33073 16.2498 3.51239 16.6063C3.67218 16.9199 3.92714 17.1749 4.24075 17.3347C4.59727 17.5164 5.06398 17.5164 5.9974 17.5164H13.9974C14.9308 17.5164 15.3975 17.5164 15.7541 17.3347C16.0677 17.1749 16.3226 16.9199 16.4824 16.6063C16.6641 16.2498 16.6641 15.7831 16.6641 14.8497V6.68302L11.5974 2.88302C11.0236 2.4527 10.7368 2.23754 10.4217 2.1546C10.1436 2.08139 9.85124 2.08139 9.57312 2.1546C9.25805 2.23754 8.97116 2.4527 8.3974 2.88302L3.33073 6.68302Z"
                    stroke="white"
                    stroke-width="1.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              )}
            </label>
          </div>
        ))}
    </div>
  );
};

export default ImageCheckbox;
