import { Avatar } from "antd";
import React from "react";
import { ChatTextSquareLg } from "../Icons/Icons";
import { BirthdayBoxTypes } from "../../type/types";

interface InviteBoxProps {
  data: BirthdayBoxTypes[];
}

const InviteBox: React.FC<InviteBoxProps> = ({ data }) => {
  return (
    <div className="w-full">
      {data?.map((item) => (
        <div className="flex gap-3 [&:not(:last-child)]:mb-4 justify-center items-center">
          <Avatar
            src={item.image}
            size={50}
            shape="square"
            alt="user"
            className="rounded-md "
          />
          <div>
            <p className="text-primary text-[17px] not-italic font-medium leading-none mb-1">
              {item.fullName}
            </p>
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-none">
              {item.description}
            </p>
          </div>
          <button className="ml-auto">
            <ChatTextSquareLg />
          </button>
        </div>
      ))}
    </div>
  );
};

export default InviteBox;
