import React, { useEffect, useRef, useState } from "react";
import {
  ChatAttachmentIcon,
  ChatImojiClickIcon,
  ChatMuteIcon,
  PinnedChatIcon,
  UnMuteIcon,
  XCloseSm,
} from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { Avatar } from "antd";
import Receiver from "../Conversations/Messages/Receiver";
import Messenger1 from "../../../assets/png/chat-per-1.png";
import { useGlobal } from "../../../context/GlobalContext";
import SpaceInfo from "../SpaceInfo/SpaceInfo";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useLocation } from "react-router";
import OpenSearch from "../../SearchBar/OpenSearch";

interface ChatBoxProps {
  showSpaceInfo: boolean;
  setShowSpaceInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setIsMuteOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPopOverOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  isMute?: any;
  spaces?:
    | boolean
    | {
        picture: string;
        title: string;
        members: number;
        membersOnline: number;
      };
  connections?:
    | boolean
    | {
        picture: string;
        fullName: string;
        isOnline: boolean;
      };
  groups?:
    | boolean
    | {
        picture: string;
        title: string;
        members: number;
        membersOnline: number;
      };

  pinnedInfo?: {
    message: string;
  };
  picture?: string;
  title?: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({
  children,
  showSpaceInfo,
  setShowSpaceInfo,
  setIsMuteOpen,
  isMute,
  setIsPopOverOpen,
  spaces,
  connections,
  groups,
  pinnedInfo,
  picture,
  title,
}) => {
  const { threadActive, setThreadActive, isReplied, setIsReplied } =
    useGlobal();
  const [showEmoji, setShowEmoji] = useState(false);
  const fileInputRef: any = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isAdminRole: any = queryParams?.get("role") === "admin";
  const [isTextSliced, setIsTextSliced] = useState(true);

  const [top, setTop] = useState<number | null>(null);
  const targetRef = useRef<HTMLDivElement | null>(null);
  const positionedRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (targetRef.current && positionedRef.current) {
      const targetEl = targetRef.current;

      const targetRect = targetEl.getBoundingClientRect();
      const newPosition = targetRect.bottom + window.scrollY;

      setTop(newPosition);
    }
  }, []);

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e: any) => {
    const selectedFile = e.target.files[0];
  };
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleEmojiSelect = (emoji: any) => {
    const input: any = document.getElementById("inputField");
    const cursorPosition = input.selectionStart;

    const newValue =
      inputValue.slice(0, cursorPosition) +
      emoji.native +
      inputValue.slice(cursorPosition);

    setInputValue(newValue);
  };

  const sliceText = (text: string, slice: boolean) => {
    if (slice && text.length > 36) {
      return text.slice(0, 36) + "...";
    } else {
      return text;
    }
  };

  const emojiPickerRef: any = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmoji(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const searchContainer = document.getElementById("searchContainer");
  const openSearchInput = document.querySelector(".open-search-input"); // Replace with the actual class or tag of your OpenSearch input

  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const handleFocus = () => {
    setIsSearchFocused(true);
  };

  const handleBlur = () => {
    setIsSearchFocused(false);
  };
  return (
    <>
      {!showSpaceInfo ? (
        <div className="w-full flex justify-between">
          <div
            className={`flex flex-col ${
              threadActive ? " lg:w-3/5 lg:flex md:hidden" : "w-full"
            }`}
          >
            <div
              ref={targetRef}
              className="w-full flex border-b border-gray_2 bg-white h-fit pl-4 pr-8 py-5 justify-between "
            >
              <div onClick={() => setShowSpaceInfo(true)} className="flex">
                <div className="relative">
                  <Avatar
                    src={picture}
                    size={48}
                    shape="square"
                    alt="user"
                    className="rounded-md min-w-[48px] max-w-[48px] min-h-[48px] max-h-[48px]"
                  />
                  {typeof connections === "object" && connections.isOnline ? (
                    <span className="absolute inline-flex bottom-0 -right-[6px] bg-white p-[3px] rounded-[400px]">
                      <span className="inline-flex max-w-[12px] min-w-[12px] max-h-3 min-h-[12px] rounded-full bg-green_1"></span>
                    </span>
                  ) : null}
                </div>

                {typeof spaces === "object" || typeof groups === "object" ? (
                  <div className="ml-2 flex flex-col justify-center">
                    <h6 className="text-[15px] not-italic font-medium leading-[120%] cursor-pointer">
                      {typeof groups === "object"
                        ? groups?.title
                        : typeof spaces === "object"
                        ? spaces?.title
                        : ""}
                    </h6>
                    <p className="text-[13px] not-italic font-normal leading-[120%] text-gray_6">
                      {typeof groups === "object"
                        ? groups?.members
                        : typeof spaces === "object"
                        ? spaces?.members
                        : ""}{" "}
                      members,{" "}
                      {typeof groups === "object"
                        ? groups?.membersOnline
                        : typeof spaces === "object"
                        ? spaces?.membersOnline
                        : ""}{" "}
                      online
                    </p>
                  </div>
                ) : (
                  <div className="ml-2 flex flex-col justify-center">
                    <h6 className="text-[15px] not-italic font-medium leading-[120%] cursor-pointer">
                      {typeof connections === "object" && connections?.fullName}
                    </h6>
                    <p className="text-[13px] not-italic font-normal leading-[120%] text-gray_6">
                      {typeof connections === "object" &&
                        connections?.isOnline &&
                        "Online"}
                    </p>
                  </div>
                )}
              </div>
              <div className="flex items-center gap-4 h-[calc(100vh - 93px)] ">
                <span
                  onClick={() => {
                    setIsMuteOpen(true);
                    setIsPopOverOpen(false);
                  }}
                  className="p-2 rounded-lg hover:bg-gray_3 border border-gray_2 cursor-pointer w-10 h-10 flex justify-center items-center"
                >
                  {isMute ? <ChatMuteIcon /> : <UnMuteIcon />}
                </span>

                <span
                  className={`rounded-lg border group  hover:bg-gray_3 cursor-pointer flex justify-center items-center ${
                    isSearchFocused && "!bg-transparent"
                  } !min-w-10 !min-h-10 border-gray-2`}
                >
                  <OpenSearch
                    inputClassName="!focus:w-[250px] !max-w-[250px]"
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                </span>
              </div>
            </div>
            {typeof pinnedInfo === "object" && (
              <div
                ref={positionedRef}
                style={{ top: `${top}px`, position: "sticky" }}
                className="z-20 bg-[#F5F6FF] px-5 py-3 border-l-[3px] border-l-blue_1 border-solid"
              >
                <div className="flex items-center gap-2">
                  <span className="flex justify-center items-center">
                    <PinnedChatIcon />
                  </span>
                  <p className="text-[rgba(31,31,31,0.60)] text-[17px] not-italic font-normal leading-[120%]">
                    {sliceText(pinnedInfo.message, isTextSliced)}
                    <span className="ml-1">
                      <button
                        onClick={() => setIsTextSliced(!isTextSliced)}
                        className="text-primary text-[17px] not-italic font-normal leading-[120%]"
                      >
                        see {isTextSliced ? "more" : "less"}
                      </button>
                    </span>
                  </p>
                </div>
              </div>
            )}
            <div className="h-[calc(100vh_-_264px)] bg-white overflow-y-auto theme-scrollbar pb-[60px]">
              {children}
            </div>
            {isAdminRole ? (
              <div className="w-full sticky h-[73px] bottom-0 text-[#1F1F1F99] text-opacity-60 py-5  flex border-b border-gray_2 bg-white px-5 justify-between border-t ">
                <p className=" h-full w-full flex justify-center items-center text-primary text-opacity-60">
                  Only admins can send messages
                </p>
              </div>
            ) : (
              <>
                <div className="sticky bottom-0 border-b border-gray_2 bg-white px-5 justify-between border-t">
                  {isReplied && (
                    <div className="pt-3 pb-0 px-4 w-full">
                      <div className="bg-gray_4 px-4 py-2 rounded-lg border-l-4 border-l-U25_orange border-solid">
                        <p className="text-gray_60 text-base not-italic font-normal leading-[normal] mb-3">
                          Replying to{" "}
                          <span className="text-primary text-base not-italic font-normal leading-[normal]">
                            Scott
                          </span>
                        </p>
                        <p className="text-gray_6 text-lg not-italic font-normal leading-[normal]">
                          Hey Kanishka, I’ve finished with the requirements doc!
                          I made some notes in the gdoc.
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="w-full sticky h-[73px]  flex">
                    <input
                      id="inputField"
                      value={inputValue}
                      placeholder="Send messages"
                      onChange={handleInputChange}
                      className="w-full focus:border-none focus:outline-none"
                    ></input>
                    <div className="flex items-center justify-center">
                      <span
                        className=" absolute right-5 bottom-5 z-10"
                        ref={emojiPickerRef}
                      >
                        {showEmoji && (
                          <Picker
                            previewPosition="none"
                            data={data}
                            onEmojiSelect={(e: any) => {
                              setShowEmoji(false);
                              handleEmojiSelect(e);
                            }}
                          />
                        )}
                      </span>
                      <span
                        onClick={() => setShowEmoji(!showEmoji)}
                        className="rounded-lg  cursor-pointer"
                      >
                        <ChatImojiClickIcon />
                      </span>
                      <div className=" flex justify-center items-center">
                        <span
                          className="ml-3 cursor-pointer"
                          onClick={handleIconClick}
                        >
                          <ChatAttachmentIcon />
                        </span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </div>
                      <PrimaryButton
                        title="Send"
                        width="full"
                        size="regular"
                        className="!py-3 !h-[33px] px-[18px] cursor-pointer"
                        containerStyle=" ml-5"
                        fontClass="text-[15px]"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {threadActive && (
            <div
              className={`md:w-full lg:w-2/5   bg-white  border-y-gray_2 border border-t-0 `}
            >
              <div className="flex items-center gap-3 pl-4 pr-5 py-5 border-y-gray_2 border-solid border-b">
                <button
                  onClick={() => {
                    setThreadActive(false);
                  }}
                >
                  <XCloseSm />
                </button>
                <h3 className="text-primary text-[15px] not-italic font-medium leading-[120%]">
                  Thread Replies
                </h3>
              </div>
              <div className="overflow-y-auto h-[calc(100vh-64px)] w-full theme-scrollbar-sm ">
                <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                  Yesterday
                </p>
                <Receiver
                  userName="e/scott"
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />
                <p className="text-gray_6 text-sm not-italic font-normal leading-[120%] text-center mt-8 mb-3 ">
                  2 New replies
                </p>
                <Receiver
                  userName="e/scott"
                  message="Spot on Adam, definitely love the energy of this group"
                  time="07:00 PM"
                  picture={Messenger1}
                />
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />{" "}
                <Receiver
                  userName="e/scott"
                  deleted={true}
                  message="Hey Kanishka, I’ve finished with the requirements doc! I made some notes in the gdoc.."
                  time="07:00 PM"
                  picture={Messenger1}
                />
              </div>
              {isAdminRole ? (
                <div className="w-full sticky h-[73px] bottom-0 text-[#1F1F1F99] text-opacity-60 py-5  flex border-b border-gray_2 bg-white px-5 justify-between border-t ">
                  <p className=" h-full w-full flex justify-center items-center text-primary text-opacity-60">
                    Only admins can send messages
                  </p>
                </div>
              ) : (
                <>
                  <div className="w-full sticky h-[73px] bottom-0  flex border-b border-gray_2 bg-white px-5 justify-between border-t ">
                    <input
                      id="inputField"
                      value={inputValue}
                      placeholder="Send messages"
                      onChange={handleInputChange}
                      className="w-full focus:border-none focus:outline-none"
                    ></input>
                    <div className="flex items-center justify-center">
                      <span className=" absolute right-5 bottom-5 z-10">
                        {showEmoji && (
                          <Picker
                            previewPosition="none"
                            data={data}
                            onEmojiSelect={(e: any) => {
                              setShowEmoji(false);
                              handleEmojiSelect(e);
                            }}
                          />
                        )}
                      </span>

                      <span
                        onClick={() => setShowEmoji(!showEmoji)}
                        className="rounded-lg -pointer cursor-pointer"
                      >
                        <ChatImojiClickIcon />
                      </span>
                      <div className=" flex justify-center items-center">
                        <span
                          className="ml-3 cursor-pointer"
                          onClick={handleIconClick}
                        >
                          <ChatAttachmentIcon />
                        </span>
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                      </div>
                      <PrimaryButton
                        title="Send"
                        width="full"
                        size="regular"
                        className="!py-3 !h-[33px] px-[18px] cursor-pointer"
                        containerStyle=" ml-5"
                        fontClass="text-[15px]"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <SpaceInfo
          showSpaceInfo={showSpaceInfo}
          setShowSpaceInfo={setShowSpaceInfo}
          spaceInfo={spaces}
          groupInfo={groups}
          connectionInfo={connections}
        />
      )}
    </>
  );
};

export default ChatBox;
