import { MissionLeaderBoardTypes } from "../type/types";
import Ella from "../assets/png/ella.png";
import Anna from "../assets/png/anna.png";
import Archie from "../assets/png/explorer-1.png";
import Charlotte from "../assets/png/charlotte.png";
import Liam from "../assets/png/liam.png";
import Admin from "../assets/png/community-user-picture.png";

const missionLeaderBoard: MissionLeaderBoardTypes[] = [
  {
    id: "1",
    title: "William Ella",
    image: Ella,
    username: "e/ella",
    earnigs: "500",
  },
  {
    id: "2",
    title: "Guru Nanak Institutions",
    image: Anna,
    username: "e/ella",
    earnigs: "500",
  },
  {
    id: "3",
    title: "Guru Nanak Institutions",
    image: Archie,
    username: "e/ella",
    earnigs: "500",
  },
  {
    id: "4",
    title: "Guru Nanak Institutions",
    image: Charlotte,
    username: "e/ella",
    earnigs: "500",
  },
  {
    id: "5",
    title: "Guru Nanak Institutions",
    image: Liam,
    username: "e/ella",
    earnigs: "500",
  },
  {
    id: "6",
    title: "Guru Nanak Institutions",
    image: Ella,
    username: "e/ella",
    earnigs: "500",
  },

  {
    id: "10",
    title: "Guru Nanak Institutions",
    image: Admin,
    username: "e/ella",
    earnigs: "500",
    isOwn: true,
  },
];

export default missionLeaderBoard;
