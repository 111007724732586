import React, { useEffect, useState } from "react";
import CustomModal from "../../CustomModal/CustomModal";
import { CardDeckProps, HouseModalProps } from "../../../type/types";
import { Button, notification } from "antd";
import { BookmarkOutlined, PlusSign } from "../../Icons/Icons";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { Link } from "react-router-dom";
import CardImg1 from "../../../assets/png/card-img1.png";
import CardImg2 from "../../../assets/png/card-img2.png";
import CreateDeck from "./CreateDeck";
import { NotificationPlacement } from "antd/es/notification/interface";
import "./Notification.scss";

const SaveToDeck: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [isCardDeckModalOpen, setIsCardDeckModalOpen] = useState(false);
  const [deckName, setDeckName] = useState("");

  const handleModalOpen = () => {
    setIsCardDeckModalOpen(true);
    handleCancel();
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: "",
      className:
        "under25-notification auto-close !bg-primary !w-auto !shadow-[0px_12px_8px_0px_rgba(0,0,0,0.08),0px_3px_24px_0px_rgba(0,0,0,0.04)] !py-3 !px-4 !rounded-lg ![&_.ant-notification-notice-message]:m-0",
      description: (
        <div className="flex items-center justify-between">
          <div className="border-r-gray_1 border-r border-solid pr-5">
            <p className="text-white text-[17px] not-italic font-medium leading-[120%]">
              Saved to Collection
            </p>
            <p className="text-white text-sm not-italic font-normal leading-[120%]">
              Saved to All cards
            </p>
          </div>
          <PrimaryButton
            size="small"
            className="!rounded-lg !ml-5"
            theme="light"
            title="Undo"
            onClick={closeNotification}
          />
        </div>
      ),
      closeIcon: false,
      duration: 4.5,
      placement,
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  const modalHeader = (
    <div className="flex items-center justify-between px-6 py-4 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Save to Deck
      </h4>
      <Button
        style={{
          fontFamily: "OskariG2Regular",
        }}
        onClick={handleModalOpen}
        className="flex items-center p-3 mr-11 h-auto rounded-lg border-[1.5px] border-solid border-gray_2 hover:!border-primary transition-all duration-200 ease-[ease]"
      >
        <PlusSign className="mr-2" />
        <p className="text-primary text-lg not-italic font-normal leading-[normal] capitalize">
          Create New Deck
        </p>
      </Button>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px]">
      <Link to={""}>
        <PrimaryButton
          onClick={() => {
            handleOk();
            openNotification("bottomRight");
          }}
          title="Save"
          width="fit-content"
        />
      </Link>
    </div>
  );

  const [cardsDeckData, setCardsDeckData] = useState<CardDeckProps[]>([
    {
      id: 1,
      title: "All Cards",
      image: null,
    },
    {
      id: 2,
      title: "Fashion",
      image: CardImg1,
    },
    {
      id: 3,
      title: "Dance",
      image: CardImg2,
    },
    {
      id: 4,
      title: "Song",
      image: CardImg2,
    },
  ]);

  const [activeCard, setActiveCard] = useState(
    Array(cardsDeckData.length).fill(false)
  );

  const handleActiveCard = (index: number) => {
    const updatedActiveCard = activeCard.map((item, i) =>
      index === i ? !item : item
    );
    setActiveCard(updatedActiveCard);
  };

  useEffect(() => {
    if (deckName.length > 0) {
      const newItem: CardDeckProps = {
        id: cardsDeckData.length + 1,
        title: deckName,
        image: CardImg1,
      };
      setCardsDeckData((prevData) => [...prevData, newItem]);
      setDeckName("");
    }
  }, [deckName, cardsDeckData.length]);

  return (
    <>
      {contextHolder}
      <CreateDeck
        deckName={deckName}
        setDeckName={setDeckName}
        isModalOpen={isCardDeckModalOpen}
        handleOk={handleModalOpen}
        handleCancel={() => setIsCardDeckModalOpen(false)}
        handleOkaySaveToDeck={handleOk}
        handleCancelSaveToDeck={handleCancel}
      />
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={modalHeader}
        footer={modalFooter}
        width={615}
      >
        <div className="flex items-center gap-5 mx-5 mt-6 pb-2 overflow-x-auto overflow-hidden theme-scrollbar-sm">
          {cardsDeckData.map((item, index) => (
            <div
              key={item.id}
              onClick={() => handleActiveCard(index)}
              className={`bg-white rounded-2xl border-[1.3px] w-[142px] p-2 last:mr-3 hover:border-[1.3px] hover:border-solid hover:border-gray_2 transition-all duration-200 ease-[ease] cursor-pointer ${
                activeCard[index]
                  ? "border-[1.3px] border-solid border-U25_orange hover:border-U25_orange"
                  : ""
              }`}
            >
              {item.image ? (
                <div className="flex items-center justify-center w-[126px] h-[104px] bg-gray_2 rounded-[7.8px] border-[1.3px] border-solid border-white">
                  <img className="object-cover" src={item.image} alt="Card" />
                </div>
              ) : (
                <div className="flex items-center justify-center w-[126px] h-[104px] bg-gray_2 rounded-[7.8px] border-[1.3px] border-solid border-white">
                  <BookmarkOutlined />
                </div>
              )}
              <div className="p-4">
                <p className="text-primary text-lg not-italic font-normal leading-[normal] text-center">
                  {item.title}
                </p>
              </div>
            </div>
          ))}
        </div>
      </CustomModal>
    </>
  );
};

export default SaveToDeck;
