import React from "react";
import AddItemComp from "./AddItemComp";
import ExperienceModal from "./ExperienceModal";
import { useState } from "react";
import { EditOutlined, PlusIconLight } from "../../Icons/Icons";
import "../user_about.scss";
import { useEffect } from "react";

interface ExperienceProps {
  edit?: true | false;
}
const Experience: React.FC<ExperienceProps> = ({ edit = false }) => {
  const Data = [
    {
      key: 1,
      projectName: "Under 25",
      designation: "Product Designer",
      experience: "Jan 2021 - Feb 2022",
    },
    {
      key: 2,
      projectName: "Under 25",
      designation: "Product Designer",
      experience: "Jan 2021 - Feb 2022",
    },
  ];
  const [popover, setPopover] = useState(false);
  const [title, setTitle] = useState("Add Experience");
  const [view, setView] = useState(false);

  useEffect(() => {
    if (!edit) {
      setView(true);
    }
  }, []);
  return (
    <div className="edit-wrapper">
      <div className="flex justify-between mb-3.5">
        <h6 className="text-[#1F1F1F] text-[17px] font-medium leading-[120%]">
          Experience
        </h6>
        {edit && view ? (
          <div className="flex">
            <span
              onClick={() => {
                setTitle("Edit Experience");
                setPopover(true);
              }}
              className=" cursor-pointer flex justify-center items-center edit-icon"
            >
              <EditOutlined />
            </span>
            <span
              onClick={() => {
                setTitle("Add Experience");
                setPopover(true);
              }}
              className="cursor-pointer flex justify-center items-center edit-icon"
            >
              <PlusIconLight />
            </span>
          </div>
        ) : null}
      </div>
      {view && (
        <div className="flex flex-col gap-2.5">
          {Data.map((item) => {
            return (
              <div className="">
                <h5 className="text-[15px] font-medium text-[#1F1F1F] leading-[120%]">
                  {item.projectName}
                </h5>
                <h6 className="mt-1.5 text-[#555555] text-[15px] font-normal leading-[120%]">
                  {item.designation}
                </h6>
                <h6 className="mt-0.5 text-[#808080] text-[14px] font-normal leading-[120%]">
                  {item.experience}
                </h6>
              </div>
            );
          })}
        </div>
      )}
      {!view && (
        <AddItemComp
          onBtnClick={() => setPopover(true)}
          title="This looks blank"
          desc="add your experience to highlight in feed"
        />
      )}
      <ExperienceModal
        onBtnClick={setView}
        title={title}
        popover={popover}
        setPopover={setPopover}
      />
    </div>
  );
};

export default Experience;
