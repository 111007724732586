import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import "./OnboardingCarousel.scss";
import slide1 from "../../assets/png/slide1.png";
import slide2 from "../../assets/png/slide2.png";
import slide3 from "../../assets/png/slide3.png";

const OnboardingCarousel = () => {
  return (
    <div className="flex justify-center items-end w-full  bg-[rgba(252,116,30,0.10)] min-w-[320px] max-w-[320px] rounded-[0px_24px_24px_0px]">
      <OwlCarousel
        className="owl-theme onboarding-carousel !h-full"
        itemElement="div"
        items={1}
        autoplay
        loop
        controlsClass="mt-0"
        dotClass="diamond-dot"
      >
        <div
          className="item h-full pl-5 pr-3 pb-[30px] flex items-end "
          style={{
            backgroundImage: `url(${slide1})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          <div className="py-2.5 mb-6 h-fit ">
            <span className="p-[14px] flex justify-center items-center bg-[#001EFF] w-[56px] h-[56px] rounded-[350px] border-r-[2.624px] border-r-[#161B3F] border-b-[2.624px] border-b-[#161B3F] border-solid   ">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6052 4.26789L4.77729 10.1719C4.37089 10.4783 4.06836 10.9109 3.91316 11.4075C3.75797 11.904 3.75812 12.439 3.91357 12.9354L6.91068 22.51C7.06774 23.0049 7.37085 23.4356 7.77687 23.741C8.18289 24.0463 8.67114 24.2106 9.17213 24.2106H18.8392C19.3402 24.2106 19.8284 24.0463 20.2344 23.741C20.6404 23.4356 20.9436 23.0049 21.1006 22.51L24.095 12.9468C24.2524 12.4482 24.2531 11.9104 24.0968 11.4114C23.9405 10.9125 23.6354 10.4783 23.2258 10.1719L15.3979 4.26789C14.9925 3.95992 14.5035 3.79395 14.0016 3.79395C13.4996 3.79395 13.0106 3.95992 12.6052 4.26789Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className="py-2.5 mt-2">
              <h4 className="text-white text-[24px] not-italic font-medium leading-[100%] mb-2">
                Community
              </h4>
              <p className="text-white text-[17px] not-italic font-[300] font-sans leading-[120%]">
                Engage with like-minded individuals by liking, commenting,
                sharing, and participating in threads, polls, discussions.
              </p>
            </div>
          </div>
        </div>
        <div
          className="item h-full pl-5 pr-3 pb-[30px] flex items-end "
          style={{
            backgroundImage: `url(${slide2})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {" "}
          <div className="py-2.5 mb-6">
            <span className="p-[14px] flex justify-center items-center bg-[#001EFF] w-[56px] h-[56px] rounded-[350px] border-r-[2.624px] border-r-[#161B3F] border-b-[2.624px] border-b-[#161B3F] border-solid  ">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3872_42011)">
                  <path
                    d="M14.1092 -0.0346678C17.6771 0.0372774 20.9 1.26399 23.5912 3.80521C25.9885 6.06841 27.4225 8.84375 27.8627 12.1081C28.2944 15.3139 27.6713 18.3233 26.03 21.1145C25.8946 21.345 25.8556 21.5096 25.9983 21.7706C26.5397 22.7644 26.3495 24.0155 25.5715 24.8507C24.7679 25.7141 23.5363 25.9775 22.4498 25.4873C22.2194 25.3836 22.0718 25.4092 21.8767 25.5434C19.7245 27.0176 17.3455 27.8114 14.7384 27.959C8.98041 28.2846 3.77481 25.0434 1.35797 20.0329C0.547075 18.3526 0.0800465 16.5735 0.0178574 14.7017C-0.156516 9.46808 1.94084 5.35506 6.19775 2.35291C8.0366 1.05669 10.1169 0.33359 12.3557 0.0762979C12.8995 0.0141088 13.4458 -0.0261305 14.108 -0.0346678L14.1092 -0.0346678ZM26.4446 13.9872C26.4446 13.8042 26.4507 13.6213 26.4434 13.4396C26.4348 13.258 26.4104 13.0763 26.397 12.8946C26.2409 10.8923 25.6459 9.023 24.535 7.35852C21.7694 3.2138 17.8857 1.24814 12.8922 1.57615C10.7071 1.72004 8.69141 2.43705 6.92695 3.72229C2.82369 6.71224 1.01044 10.7716 1.6738 15.8089C2.13107 19.2842 3.90285 22.0644 6.74892 24.1154C9.20234 25.8824 11.9606 26.6274 14.9823 26.4018C17.1138 26.2421 19.0599 25.5592 20.8463 24.3983C21.0158 24.2886 21.0975 24.1996 21.0146 23.963C20.6427 22.9082 20.8098 21.9266 21.5853 21.1121C22.3389 20.3195 23.2839 20.0695 24.3411 20.3768C24.596 20.4512 24.6972 20.3878 24.8143 20.1805C25.8959 18.2563 26.4507 16.1955 26.4458 13.9872L26.4446 13.9872ZM23.5619 21.7974C22.9047 21.7986 22.3535 22.3424 22.3511 22.9936C22.3486 23.6387 22.9035 24.1911 23.5595 24.1947C24.2241 24.1984 24.7508 23.6692 24.7508 22.9948C24.7508 22.3144 24.2363 21.7949 23.5632 21.7962L23.5619 21.7974Z"
                    fill="white"
                  />
                  <path
                    d="M6.28636 9.95358C7.38983 8.02523 8.80862 6.78722 10.6244 5.99808C10.8011 5.92127 10.8889 5.83258 10.9238 5.63308C11.1386 4.40257 12.1337 3.48877 13.3463 3.38346C14.6225 3.27268 15.7532 3.97754 16.1758 5.18185C16.2714 5.45528 16.4138 5.57691 16.6839 5.6554C21.6476 7.08639 24.3692 12.6495 22.4619 17.448C20.6268 22.0629 15.5002 24.3381 10.8733 22.5215C6.16845 20.6739 4.15875 15.5556 5.7299 11.1783C5.90893 10.6805 6.12614 10.2018 6.28636 9.95358ZM17.8338 7.79495C17.4199 7.55595 16.9744 7.38608 16.5334 7.20609C16.2877 7.10502 16.1594 7.13939 16.0186 7.40027C15.0567 9.19071 12.5393 9.3368 11.3767 7.67288C11.2217 7.45106 11.103 7.44448 10.8867 7.55333C9.23648 8.38737 8.02589 9.64279 7.31183 11.3454C5.70437 15.1807 7.5717 19.5945 11.4676 21.1116C15.1789 22.5559 19.4856 20.7155 20.9808 17.0111C22.3695 13.5718 21.0256 9.63912 17.8332 7.796L17.8338 7.79495ZM14.6481 6.69631C14.9815 6.12138 14.7896 5.40512 14.21 5.06768C13.6314 4.73084 12.9111 4.9176 12.575 5.49236C12.2428 6.05807 12.4331 6.80155 12.9944 7.13407C13.5811 7.48123 14.3028 7.28963 14.6481 6.69631Z"
                    fill="white"
                  />
                  <path
                    d="M17.8467 13.9568C17.8467 16.109 16.1383 17.8137 13.9812 17.8125C11.8546 17.8125 10.1328 16.0895 10.1328 13.9617C10.1328 11.8265 11.8668 10.0974 14.0068 10.0986C16.131 10.1011 17.8467 11.8241 17.8467 13.9568ZM14.0007 11.646C12.6923 11.6436 11.6851 12.6411 11.679 13.9434C11.6729 15.2506 12.6679 16.2615 13.9702 16.2712C15.2957 16.281 16.3054 15.2798 16.3054 13.958C16.3054 12.6472 15.3104 11.6485 14.0007 11.646Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3872_42011">
                    <rect
                      width="28.0156"
                      height="27.9863"
                      fill="white"
                      transform="translate(-0.0078125 -0.00683594)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <div className="py-2.5 mt-2">
              <h4 className="text-white text-[24px] not-italic font-medium leading-[100%] mb-2">
                Thought Leaders
              </h4>
              <p className="text-white text-[17px] not-italic font-[300] font-sans leading-[120%]">
                Interacting & learn from CEOs, Co-Founders, Creators, Musicians,
                Actors and many more.
              </p>
            </div>
          </div>
        </div>
        <div
          className="item h-full pl-5 pr-3 pb-[30px] flex items-end "
          style={{
            backgroundImage: `url(${slide3})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        >
          {" "}
          <div className="py-2.5 mb-6">
            <span className="p-[14px] flex justify-center items-center bg-[#001EFF] w-[56px] h-[56px] rounded-[350px] border-r-[2.624px] border-r-[#161B3F] border-b-[2.624px] border-b-[#161B3F] border-solid  ">
              <svg
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.68742 26.25H17.9375C18.9818 26.25 19.9833 25.8351 20.7217 25.0967C21.4602 24.3583 21.875 23.3568 21.875 22.3125V10.0625C21.875 9.0182 21.4602 8.01669 20.7217 7.27826C19.9833 6.53984 18.9818 6.125 17.9375 6.125H5.68742C4.64313 6.125 3.6416 6.53984 2.90318 7.27826C2.16475 8.01669 1.7499 9.0182 1.7499 10.0625V22.3125C1.7499 23.3568 2.16475 24.3583 2.90318 25.0967C3.6416 25.8351 4.64313 26.25 5.68742 26.25Z"
                  fill="white"
                />
                <path
                  d="M19.25 4.37499H6.35022C6.62246 3.60786 7.12539 2.94376 7.79002 2.47378C8.45465 2.0038 9.24841 1.75098 10.0624 1.75H22.3125C23.3568 1.75 24.3583 2.16484 25.0967 2.90326C25.8352 3.64169 26.25 4.6432 26.25 5.68749V17.9375C26.249 18.7515 25.9962 19.5452 25.5262 20.2099C25.0562 20.8745 24.3921 21.3774 23.625 21.6496V8.74999C23.625 7.58967 23.164 6.47687 22.3436 5.6564C21.5231 4.83593 20.4103 4.37499 19.25 4.37499Z"
                  fill="white"
                />
              </svg>
            </span>
            <div className="py-2.5 mt-2">
              <h4 className="text-white text-[24px] not-italic font-medium leading-[100%] mb-2">
                Missions
              </h4>
              <p className="text-white text-[17px] not-italic font-[300] font-sans leading-[120%]">
                Engage with like-minded individuals by liking, commenting,
                sharing, and participating in threads, polls, discussions.{" "}
              </p>
            </div>
          </div>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default OnboardingCarousel;
