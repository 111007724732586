import OwlCarousel from "react-owl-carousel";
import U25IconDark from "../../../../assets/svg/u25-icon-dark.svg";
import SpaceCardBg from "../../../../assets/png/space-card-bg.png";
import Lock from "../../../../assets/svg/lock-01.svg";
import { useNavigate } from "react-router";

const SpaceCarousel = () => {
  const navigate = useNavigate();

  const spaceCardsData = [
    {
      id: "1",
      bgImage: SpaceCardBg,
      spaceImage: U25IconDark,
      spaceName: "Guru Nanak Institutions",
      explorers: "12k",
      onClick: () => navigate("/spaces/explorer"),
    },
    {
      id: "2",
      bgImage: SpaceCardBg,
      spaceImage: U25IconDark,
      spaceName: "Guru Nanak Institutions",
      explorers: "12k",
      onClick: () => navigate("/spaces/explorer"),
    },
    {
      id: "3",
      bgImage: SpaceCardBg,
      spaceImage: U25IconDark,
      spaceName: "Guru Nanak Institutions",
      explorers: "12k",
      onClick: () => navigate("/spaces/explorer"),
    },
  ];
  return (
    <div>
      <OwlCarousel
        className="owl-theme spaces-carousel"
        stageOuterClass="owl-stage-outer h-[305px] "
        itemElement="div"
        items={2}
        margin={16}
        dots
        autoplay
        controlsClass="mt-0"
        dotsClass="flex items-center justify-center w-fit mt-4 mx-auto bg-gray_4 p-2.5 rounded-[131px]"
        dotClass="white-dark-dot"
        stageClass="owl-stage"
        responsive={{
          600: {
            items: 1,
          },
          768: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1023: {
            items: 1,
          },
          1024: {
            items: 2,
          },
        }}
      >
        {spaceCardsData &&
          spaceCardsData.map((card) => (
            <div
              key={card.id}
              style={{
                backgroundImage: `url(${card.bgImage})`,
              }}
              className="relative bg-primary shadow-space_cards rounded-xl xl:h-[300px] p-[30px] bg-cover  bg-no-repeat"
            >
              <span className="absolute right-0 top-0 bg-dark_70 rounded-[0px_12px_0px_12px] p-3">
                <span className="text-white text-[17px] not-italic font-normal leading-[120%]">
                  Newly launched
                </span>
              </span>
              <div className="flex items-end justify-between mt-[85px]">
                <div>
                  <div className="inline-flex justify-center items-center bg-lightViolet shrink-0 rounded-xl p-[15px] mb-[20px]">
                    <img
                      src={U25IconDark}
                      alt="under25"
                      className="max-w-[50px] min-w-[50px] max-h-[50px] min-h-[50px]"
                    />
                  </div>
                  <div>
                    <div className="flex items-center gap-2 mb-4">
                      <p className="text-white text-xl not-italic font-medium leading-[100%]">
                        {card.spaceName}
                      </p>
                      <img src={Lock} alt="lock" className="!w-4 h-4" />
                    </div>
                    <p className="text-white text-[17px] text-opacity-60 not-italic font-normal leading-[120%]">
                      {card.explorers} Explorers
                    </p>
                  </div>
                </div>
                <button
                  onClick={card.onClick}
                  className="text-white text-[17px] not-italic font-normal leading-[120%] rounded-lg border-[1.5px] border-solid border-dull_white_2 px-4 py-3 hover:bg-white hover:text-primary transition-all duration-200 ease-[ease]"
                >
                  Join
                </button>
              </div>
            </div>
          ))}
      </OwlCarousel>
    </div>
  );
};

export default SpaceCarousel;
