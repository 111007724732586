import React, { ChangeEvent, useRef, useState } from "react";
import { CloseLightOutlined, ImageUploadOutlined } from "../Icons/Icons";
import { Button } from "antd";
import PhotoUpload from "../PhotoUpload/PhotoUpload";
import { VideoUploadProps } from "../../type/types";

const VideoUpload: React.FC<VideoUploadProps> = ({
  className,
  handleClose,
}) => {
  const [source, setSource] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const fileList = e.target.files[0];
    const url = URL.createObjectURL(fileList);
    setSource(url);
  };

  const handleClick = () => {
    if (inputRef.current) inputRef.current.click();
  };

  return (
    <div
      className={`relative border rounded-xl border-dashed p-4 ${className}`}
    >
      {source && (
        <video
          className="VideoInput_video mb-5 rounded-xl"
          width="100%"
          controls
          src={source}
        />
      )}
      {source ? (
        <PhotoUpload
          title="Thumbnail"
          description="Choose thumbnail"
          closeIcon={false}
          noBorder
        />
      ) : (
        <div className={`flex  border-gray_2`}>
          <div className="flex justify-center items-center w-20 h-[80px] p-7 bg-gray_4 border rounded-xl border-solid border-gray_2 mr-4">
            <ImageUploadOutlined />
          </div>
          <div className="py-1">
            <div
              onClick={(e) => e.stopPropagation()}
              className="flex flex-col justify-center mb-2 cursor-text"
            >
              <span className="text-dark_80 text-[15px] not-italic font-normal leading-[120%] mb-[2px]">
                Add video
              </span>
              <span className="text-gray_1 text-[13px] not-italic font-normal leading-[120%]">
                or drag and drop
              </span>
            </div>
            <Button
              onClick={handleClick}
              className="flex justify-center items-center h-auto border rounded-lg border-solid border-gray_2 text-U25_orange text-sm not-italic font-normal leading-[120%] py-2 px-3 hover:!text-U25_orange hover:!bg-gray_30 hover:!border-gray_2 cursor-pointer"
            >
              Upload
            </Button>
            <input
              ref={inputRef}
              onChange={handleFileChange}
              hidden
              id="video-upload"
              type="file"
              accept=".mov,.mp4"
              width="535px"
              height="312px"
            />
          </div>
        </div>
      )}
      <div
        style={{
          boxShadow: "0px 1px 11px 0px rgba(0, 0, 0, 0.08)",
        }}
        onClick={handleClose}
        className={`absolute flex justify-center items-center ${
          source ? "right-[30px] top-[26px]" : "right-4 top-4"
        } cursor-pointer w-5 h-5 bg-white shadow-3xl rounded-xl hover:bg-gray_2 [&_svg]:stroke-slate-500 transition-[background] duration-200 ease-[ease]`}
      >
        <CloseLightOutlined />
      </div>
    </div>
  );
};

export default VideoUpload;
