import React from "react";
import { ICustomModalProps } from "../../../type/types";
import { Modal } from "antd";
import {
  ArrowNarrowLeft,
  FeedKebabShareWhite,
  FeedKebabWhite,
} from "../../Icons/Icons";

const MediaPreviewModal: React.FC<ICustomModalProps> = ({
  title,
  className,
  closeIcon = false,
  children,
  isModalOpen,
  handleOk,
  handleCancel,
  footer = false,
  width = "100%",
  style = { top: 0, bottom: 0, left: 0, right: 0 },
  bodyStyle = { backgroundColor: "rgba(31, 31, 31, 0.95)" },
  isTutorial,
}) => {
  return (
    <Modal
      className={`feed-modal ${className}`}
      closeIcon={closeIcon}
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footer}
      width={width}
      style={style}
      bodyStyle={bodyStyle}
      centered
    >
      <div className=" overflow-y-auto ">
        <div className="flex items-center justify-between bg-hashtag_text px-6 py-5">
          <div className="flex items-center gap-4">
            <button onClick={handleCancel}>
              <ArrowNarrowLeft className="[&_path]:stroke-white" />
            </button>
            {!isTutorial && (
              <div>
                <p className="text-white text-xl not-italic font-normal leading-[normal] mb-2">
                  Kani
                </p>
                <p className="text-white text-base not-italic font-normal leading-[normal] opacity-60">
                  28 Jul, 12:06 PM
                </p>
              </div>
            )}
          </div>
          {!isTutorial && (
            <div className="flex items-center gap-4">
              <button>
                <FeedKebabShareWhite />
              </button>
              <button>
                <FeedKebabWhite />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={` w-[80vw] overflow-y-auto mx-auto p-8`}>{children}</div>
    </Modal>
  );
};

export default MediaPreviewModal;
