import React from "react";
import CustomModal from "../CustomModal/CustomModal";
import { HouseModalProps } from "../../type/types";
import PrimaryButton from "../PrimaryButton/PrimaryButton";
import { CloseDarkOutlined } from "../Icons/Icons";
import U25House from "../../assets/png/under25-house.png";
import { Link } from "react-router-dom";

const HouseModal: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  houseTitle,
  hashTag,
  houseColor = "#F8EA22",
}) => {
  const modalFooter = (
    <div className="flex justify-end items-center gap-4 rounded-[0_0_12px_12px] bg-gray_4 pl-3 pr-6 pt-4 pb-6">
      <PrimaryButton
        onClick={handleCancel}
        title="Cancel"
        theme="light"
        width="fit-content"
      />
      <Link to={"/community?&user=new"}>
        <PrimaryButton onClick={handleOk} title="Confirm" width="fit-content" />
      </Link>
    </div>
  );

  return (
    <div>
      <CustomModal
        className="house-modal"
        title={
          <h3 className="pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid text-dark_85 text-2xl not-italic font-medium leading-[100%]">
            Confirm House
          </h3>
        }
        closeIcon={<CloseDarkOutlined />}
        width={615}
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        footer={modalFooter}
      >
        <div className="my-6 px-6 py-4 w-full flex justify-center items-center flex-col">
          <div
            className="mb-6 rounded-xl border border-[#E2E2E2] w-[80px] h-[80px] flex justify-center items-center "
            style={{ backgroundColor: houseColor }}
          >
            {houseTitle === "White House" ? (
              <>
                <svg
                  width="41"
                  height="40"
                  viewBox="0 0 41 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.4323 35V22.6667C15.4323 21.7332 15.4323 21.2665 15.614 20.91C15.7737 20.5964 16.0287 20.3414 16.3423 20.1817C16.6988 20 17.1655 20 18.099 20H22.7656C23.6991 20 24.1658 20 24.5223 20.1817C24.8359 20.3414 25.0909 20.5964 25.2506 20.91C25.4323 21.2665 25.4323 21.7332 25.4323 22.6667V35M3.76562 15.8333L18.8323 4.53333C19.4061 4.10301 19.6929 3.88785 20.008 3.80491C20.2861 3.7317 20.5785 3.7317 20.8566 3.80491C21.1717 3.88785 21.4585 4.10301 22.0323 4.53333L37.099 15.8333M7.09897 13.3333V29.6667C7.09897 31.5335 7.09897 32.4669 7.46228 33.18C7.78185 33.8072 8.29179 34.3171 8.919 34.6367C9.63204 35 10.5655 35 12.4323 35H28.4323C30.2991 35 31.2326 35 31.9456 34.6367C32.5728 34.3171 33.0827 33.8072 33.4023 33.18C33.7656 32.4669 33.7656 31.5335 33.7656 29.6667V13.3333L23.6323 5.73333C22.4848 4.87269 21.911 4.44236 21.2809 4.27648C20.7246 4.13006 20.14 4.13006 19.5837 4.27648C18.9536 4.44236 18.3798 4.87269 17.2323 5.73333L7.09897 13.3333Z"
                    stroke="#1F1F1F"
                    stroke-width="2.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </>
            ) : (
              <svg
                width="41"
                height="40"
                viewBox="0 0 41 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.4323 35V22.6667C15.4323 21.7332 15.4323 21.2665 15.614 20.91C15.7737 20.5964 16.0287 20.3414 16.3423 20.1817C16.6988 20 17.1655 20 18.099 20H22.7656C23.6991 20 24.1658 20 24.5223 20.1817C24.8359 20.3414 25.0909 20.5964 25.2506 20.91C25.4323 21.2665 25.4323 21.7332 25.4323 22.6667V35M3.76562 15.8333L18.8323 4.53333C19.4061 4.10301 19.6929 3.88785 20.008 3.80491C20.2861 3.7317 20.5785 3.7317 20.8566 3.80491C21.1717 3.88785 21.4585 4.10301 22.0323 4.53333L37.099 15.8333M7.09897 13.3333V29.6667C7.09897 31.5335 7.09897 32.4669 7.46228 33.18C7.78185 33.8072 8.29179 34.3171 8.919 34.6367C9.63204 35 10.5655 35 12.4323 35H28.4323C30.2991 35 31.2326 35 31.9456 34.6367C32.5728 34.3171 33.0827 33.8072 33.4023 33.18C33.7656 32.4669 33.7656 31.5335 33.7656 29.6667V13.3333L23.6323 5.73333C22.4848 4.87269 21.911 4.44236 21.2809 4.27648C20.7246 4.13006 20.14 4.13006 19.5837 4.27648C18.9536 4.44236 18.3798 4.87269 17.2323 5.73333L7.09897 13.3333Z"
                  stroke="white"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
          <div className="text-center mb-6">
            <h4 className="text-primary text-xl not-italic font-medium leading-[100%] mb-3">
              {houseTitle}
            </h4>
            <div className="inline-block bg-purple_bg rounded-lg p-2">
              <span className="text-purple_1 text-[13px] not-italic font-normal leading-[120%] uppercase">
                {hashTag}
              </span>
            </div>
          </div>
          <p className="max-w-[394px] mx-auto text-gray_1 text-center text-[17px] not-italic font-normal leading-[120%]">
            You have selected
            <span className="text-primary text-[17px] not-italic font-medium leading-[120%] mx-1">
              {houseTitle}
            </span>
            as your house, you can only choose once.
          </p>
        </div>
      </CustomModal>
    </div>
  );
};

export default HouseModal;
