import React, { useState, useEffect, useRef } from "react";
import WaveSurfer from "wavesurfer.js";

interface WaveformProps {
  url: any;
  type?: any;
  fileName?: any;
  fileData?: any;
  recordStatus?: any;
}

const Waveform: React.FC<WaveformProps> = ({
  url,
  type,
  fileData,
  recordStatus,
}) => {
  const [playing, setPlaying] = useState<boolean>(false);
  const [waveSpeed, setWaveSpeed] = useState<number>(1);

  const waveformRef = useRef<WaveSurfer | null>(null);

  useEffect(() => {
    waveformRef.current = WaveSurfer.create({
      barWidth: 3,
      barRadius: 3,
      barGap: 2,
      barHeight: 1,
      cursorWidth: 1,
      container: "#waveform",
      height: 25,
      progressColor: "#FE6E00",
      waveColor: "#C4C4C4",
      cursorColor: "transparent",
    });
    if (type === "pod") {
      waveformRef.current = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barHeight: 1,
        cursorWidth: 1,
        container: "#waveformpod",
        height: 25,
        progressColor: "#FE6E00",
        waveColor: "#C4C4C4",
        cursorColor: "transparent",
      });
    }

    if (waveformRef.current) {
      waveformRef.current?.load(url);
    }

    return () => {
      if (waveformRef.current) {
        waveformRef.current?.destroy();
      }
    };
  }, []);

  const handlePlay = () => {
    setPlaying((prevPlaying) => !prevPlaying);
    if (waveformRef.current) {
      waveformRef.current?.playPause();
    }
  };

  const handleInputChange = (e: any) => {
    if (waveformRef.current) {
      if (waveSpeed < 2) {
        waveformRef.current?.setPlaybackRate(waveSpeed + 0.5);
        setWaveSpeed(waveSpeed + 0.5);
      } else {
        setWaveSpeed(1);
        waveformRef.current?.setPlaybackRate(1);
      }
    }
  };

  return (
    <div
      className={`flex items-center ${
        type ? "justify-start" : " justify-center"
      } h-25 w-full bg-transparent`}
    >
      {type === "feed" && <div className="w-full h-[25px]" id="waveform" />}

      {type === "pod" && <div className="w-full h-[25px]" id="waveformpod" />}
      <audio id="track" src={url} crossOrigin="anonymous" />
      <span
        className=" cursor-pointer ml-3 flex justify-center items-center"
        onClick={handlePlay}
      >
        {!playing ? (
          <svg
            width={`${type ? "32" : "39"} `}
            height="39"
            viewBox="0 0 39 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.710938"
              y="0.441406"
              width="37.6686"
              height="37.6686"
              rx="18.8343"
              fill="#FC741E"
            />
            <path
              d="M22.1544 16.0343C24.1789 17.4106 25.1912 18.0987 25.1912 19.2757C25.1912 20.4527 24.1789 21.1408 22.1544 22.5171C21.5955 22.897 21.0412 23.2547 20.5318 23.5528C20.0849 23.8143 19.5788 24.0848 19.0549 24.3503C17.0351 25.3738 16.0252 25.8856 15.1194 25.319C14.2136 24.7524 14.1313 23.5662 13.9667 21.1939C13.9201 20.523 13.8906 19.8653 13.8906 19.2757C13.8906 18.6861 13.9201 18.0284 13.9667 17.3575C14.1313 14.9852 14.2136 13.799 15.1194 13.2324C16.0252 12.6658 17.0351 13.1776 19.0549 14.2011C19.5788 14.4666 20.0849 14.7371 20.5318 14.9986C21.0412 15.2967 21.5955 15.6544 22.1544 16.0343Z"
              fill="white"
            />
          </svg>
        ) : (
          <svg
            width={`${type ? "32" : "32"} `}
            height="38"
            viewBox="0 0 38 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="37.6686"
              height="37.6686"
              rx="18.8343"
              fill="#FC741E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.1015 13.8263C13 14.0713 13 14.3819 13 15.0032V21.6698C13 22.2911 13 22.6017 13.1015 22.8468C13.2368 23.1735 13.4964 23.433 13.8231 23.5683C14.0681 23.6698 14.3787 23.6698 15 23.6698C15.6213 23.6698 15.9319 23.6698 16.1769 23.5683C16.5036 23.433 16.7632 23.1735 16.8985 22.8468C17 22.6017 17 22.2911 17 21.6698V15.0032C17 14.3819 17 14.0713 16.8985 13.8263C16.7632 13.4996 16.5036 13.24 16.1769 13.1047C15.9319 13.0032 15.6213 13.0032 15 13.0032C14.3787 13.0032 14.0681 13.0032 13.8231 13.1047C13.4964 13.24 13.2368 13.4996 13.1015 13.8263ZM19.7682 13.8263C19.6667 14.0713 19.6667 14.3819 19.6667 15.0032V21.6698C19.6667 22.2911 19.6667 22.6017 19.7682 22.8468C19.9035 23.1735 20.1631 23.433 20.4898 23.5683C20.7348 23.6698 21.0454 23.6698 21.6667 23.6698C22.2879 23.6698 22.5985 23.6698 22.8436 23.5683C23.1703 23.433 23.4298 23.1735 23.5652 22.8468C23.6667 22.6017 23.6667 22.2911 23.6667 21.6698V15.0032C23.6667 14.3819 23.6667 14.0713 23.5652 13.8263C23.4298 13.4996 23.1703 13.24 22.8436 13.1047C22.5985 13.0032 22.2879 13.0032 21.6667 13.0032C21.0454 13.0032 20.7348 13.0032 20.4898 13.1047C20.1631 13.24 19.9035 13.4996 19.7682 13.8263Z"
              fill="white"
            />
          </svg>
        )}
      </span>
      {type === "feed" ? (
        <span
          onClick={(e) => {
            handleInputChange(e);
          }}
          className=" min-w-[50px] text-[18.834px] font-sans ml-[14px]  justify-center  not-italic font-normal leading-[normal] flex items-center cursor-pointer bg-[#F2F2F2] px-[14.126px] py-[9.417px] rounded-[9px]"
        >
          {waveSpeed}
          <svg
            className="ml-[2px] mt-[2px]"
            width="8"
            height="7"
            viewBox="0 0 8 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.06671 3.23287L7.45395 6.53829H5.886L4.21916 4.17929L2.56646 6.53829H0.984375L3.38575 3.23287L1.13976 0.012207H2.72184L4.21916 2.28645L5.73062 0.012207H7.29857L5.06671 3.23287Z"
              fill="#B6B6B6"
            />
          </svg>
        </span>
      ) : (
        <span
          onClick={() => {
            fileData(null);
            recordStatus(false);
          }}
          className=" cursor-pointer ml-3 flex justify-center items-center rounded-[400px] bg-gray_4 p-2"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3307 4.66602L4.66406 11.3327M4.66406 4.66602L11.3307 11.3327"
              stroke="#666666"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      )}
    </div>
  );
};

export default Waveform;
