import { Radio } from "antd";
import CustomModal from "../../CustomModal/CustomModal";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

interface MuteNotificationModalProps {
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  setMute?: React.Dispatch<React.SetStateAction<boolean>>;
}
const MuteNotificationModal: React.FC<MuteNotificationModalProps> = ({
  modal,
  setModal,
  setMute = () => {},
}) => {
  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        Mute Notifications
      </h6>
    </div>
  );
  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px] ">
      <PrimaryButton
        btnClass="max-h-[42px]"
        theme="light"
        onClick={() => {
          handleClosePopover();
          setMute(false);
        }}
        title="Cancel"
      />
      <PrimaryButton
        theme="dark"
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          setMute(true);
        }}
        title="Mute"
      />
    </div>
  );
  const handleClosePopover = () => {
    setModal(false);
  };

  const handleOpenPopover = () => {
    setModal(true);
  };
  return (
    <CustomModal
      isModalOpen={modal}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="px-6 py-4 pb-2">
        <p className="text-[#666666] text-[17px] font-normal leading-[120%] ">
          other participants will not see that you muted this chat. You will
          still be notified if you are mentioned.
        </p>
        <Radio.Group className="flex flex-col gap-4 py-5 px-2 ">
          <Radio className="custom-radio flex" value={1}>
            <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
              8 hours
            </span>
          </Radio>
          <Radio className="custom-radio flex" value={2}>
            <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
              1 week
            </span>
          </Radio>
          <Radio className="custom-radio flex" value={3}>
            <span className="text-primary text-[17px] not-italic font-normal leading-[120%]">
              always
            </span>
          </Radio>
        </Radio.Group>
      </div>
    </CustomModal>
  );
};

export default MuteNotificationModal;
