import SpaceImg1 from "../assets/png/spaces-img-1.png";
import SpaceImg2 from "../assets/png/anno.png";

const spaceData = [
    {
        id: "1",
        image: SpaceImg1,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "2",
        image: SpaceImg1,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "3",
        image: SpaceImg2,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "4",
        image: SpaceImg2,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "4",
        image: SpaceImg1,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "6",
        image: SpaceImg1,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "7",
        image: SpaceImg2,
        title: "Developers Playground",
        explorers: "16.5k"
    },
    {
        id: "8",
        image: SpaceImg2,
        title: "Developers Playground",
        explorers: "16.5k"
    },
]

export default spaceData;