import React, { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import "./OTPInput.scss";

interface OTPInputProps {
  length: number;
  onComplete: (otp: string) => void;
  timerDuration?: number;
  containerStyle?: string;
  disableOnTimeEnd?: boolean;
  setOTP: any;
  otp: any;
  hasEmptyOtp: any;
}

const OTPInput: React.FC<OTPInputProps> = ({
  length,
  onComplete,
  timerDuration = 30,
  containerStyle,
  disableOnTimeEnd,
  setOTP,
  otp,
  hasEmptyOtp,
}) => {
  const inputRefs = useRef<any[]>([]);
  const [timer, setTimer] = useState(timerDuration);

  const handleCountDown = () => {
    setTimer(timerDuration);
  };

  useEffect(() => {
    if (timer > 0) {
      const countDown = setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);

      return () => clearTimeout(countDown);
    } else {
    }
  }, [timer]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      remainingSeconds < 10 ? "0" + remainingSeconds : remainingSeconds
    }`;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    if (!/^[0-9]*$/.test(value)) {
      return;
    }
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (value && index < length - 1) {
      inputRefs.current[index + 1].focus();
    } else if (!newOTP.includes("")) {
      onComplete(newOTP.join(""));
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      inputRefs.current[index - 1].focus();
    }
  };

  console.log(hasEmptyOtp, "hasEmptyOtp");

  return (
    <div className={`${containerStyle}`}>
      <div className="flex gap-2 mb-2">
        {otp.map((value: any, index: any) => (
          <Input
            key={index}
            type="text"
            placeholder=" "
            value={value}
            onChange={(e) => handleChange(e, index)}
            onKeyDown={(e) => handleKeyDown(e, index)}
            ref={(input) => (inputRefs.current[index] = input!)}
            maxLength={1}
            disabled={disableOnTimeEnd && timer === 0}
            className={`otp-input border shadow-[2px_2px_0px_0px_#F2F2F2] rounded-lg border-solid  ${
              hasEmptyOtp ? " border-danger " : "border-gray_2"
            } `}
          />
        ))}
      </div>
      {hasEmptyOtp && (
        <div className="flex  ">
          <span className="text-danger text-[17px] not-italic font-normal leading-[120%]">
            Entered OTP is wrong
          </span>
        </div>
      )}
      <div className="p-2 mt-5">
        {timer === 0 ? (
          <p className="text-gray text-center">
            Didn’t receive code?
            <span
              className="text-U25_orange underline underline-offset-1 decoration-1 text-[17px] not-italic font-medium leading-[120%] cursor-pointer ml-3"
              onClick={handleCountDown}
            >
              Resend
            </span>
          </p>
        ) : (
          <p className="text-gray text-center">
            Didn’t receive code? Resend in {formatTime(timer)}
          </p>
        )}
      </div>
    </div>
  );
};

export default OTPInput;
