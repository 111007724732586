import User1 from "../assets/png/user-1.png";
import User2 from "../assets/png/user-2.png";
import User3 from "../assets/png/user-3.png";
import User4 from "../assets/png/user-4.png";
import User5 from "../assets/png/user-5.png";
import User6 from "../assets/png/user-6.png";

export const forwardToData = [
  {
    id: 1,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User1,
  },
  {
    id: 2,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User2,
  },
  {
    id: 3,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User3,
  },
  {
    id: 4,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User4,
  },
  {
    id: 5,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User5,
  },
  {
    id: 6,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 7,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 8,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 9,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 10,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 11,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 12,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 13,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
  {
    id: 14,
    fullName: "Kanishka Reddy",
    userName: "e/kani",
    profileImage: User6,
  },
];
