/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useMemo, useRef, useState } from "react";
import { Avatar, Dropdown, MenuProps } from "antd";
import {
  FeedLoveIcon,
  FeedCommentIcon,
  FeedShareIcon,
  FeedLoveIconLiked,
  FeedKababIcon,
} from "../../Icons/Icons";
import FeedUser from "../../../assets/png/feedUser.png";
import LikesModal from "../Modal/LikesModal";
import UpVotesModal from "../Modal/UpVotesModal";
import ShareModal from "../Modal/ShareModal";
import useIsMac from "../../../hooks/useIsMac";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import { useGlobal } from "../../../context/GlobalContext";
export const Comment = (props: {
  username: any;
  isPinned: any;
  content: any;
  upvotes: any;
  timestamp: any;
  toggleAdditionalComment: any;
  setUpVotesModalOpen?: any;
}) => {
  const {
    isPinned,
    content,
    setUpVotesModalOpen,
    upvotes,
    timestamp,
    toggleAdditionalComment,
  } = props;

  const [isVoted, setIsVoted] = useState(false);
  const [upVoteCount, setUpVoteCount] = useState(upvotes);

  const items: MenuProps["items"] = [
    {
      label: (
        <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
          {isPinned ? "Unpin" : "Pin"}
        </p>
      ),
      key: "1",
    },
    {
      label: (
        <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
          Delete
        </p>
      ),
      key: "2",
    },
  ];

  const isMac = useIsMac();

  return (
    <div className="comments">
      <div className="w-full bg-gray_4 mt -2 p-2 rounded-lg">
        <div className="flex flex-col">
          <div className="flex  justify-between w-full">
            <div className="flex">
              <Avatar
                src={FeedUser}
                className="h-[32px] w-[32px] rounded-md cursor-pointer "
              />
              <div className="flex flex-col ml-3">
                <h6 className="text-[17px] not-italic font-medium leading-[120%] cursor-pointer">
                  e/anushaudupi
                </h6>
              </div>
            </div>
            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3">
              {isPinned && (
                <span className="">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75561 11.3519L1.98438 15.1231M7.9674 5.36888L6.92681 6.40947C6.84193 6.49435 6.79949 6.53679 6.75113 6.57051C6.70821 6.60045 6.66192 6.62522 6.6132 6.64433C6.55832 6.66586 6.49947 6.67763 6.38176 6.70117L3.93878 7.18977C3.30391 7.31674 2.98648 7.38023 2.83797 7.5476C2.70859 7.6934 2.64951 7.88853 2.67628 8.08162C2.70701 8.30325 2.93591 8.53216 3.39372 8.98997L8.11755 13.7138C8.57536 14.1716 8.80426 14.4005 9.0259 14.4312C9.21898 14.458 9.41411 14.3989 9.55992 14.2695C9.72729 14.121 9.79077 13.8036 9.91775 13.1687L10.4063 10.7258C10.4299 10.608 10.4417 10.5492 10.4632 10.4943C10.4823 10.4456 10.5071 10.3993 10.537 10.3564C10.5707 10.308 10.6132 10.2656 10.698 10.1807L11.7386 9.14011C11.7929 9.08584 11.82 9.05871 11.8499 9.03501C11.8764 9.01397 11.9044 8.99497 11.9338 8.97818C11.9669 8.95929 12.0022 8.94417 12.0727 8.91394L13.7356 8.20126C14.2207 7.99335 14.4633 7.88939 14.5735 7.7214C14.6699 7.5745 14.7043 7.3955 14.6694 7.22332C14.6295 7.02642 14.4429 6.83981 14.0697 6.4666L10.6409 3.03784C10.2677 2.66462 10.0811 2.47801 9.8842 2.4381C9.71202 2.40319 9.53302 2.43767 9.38611 2.53402C9.21812 2.6442 9.11416 2.88677 8.90625 3.3719L8.19358 5.03481C8.16334 5.10535 8.14823 5.14063 8.12933 5.1737C8.11255 5.20308 8.09355 5.23114 8.0725 5.25764C8.04881 5.28747 8.02167 5.31461 7.9674 5.36888Z"
                      stroke="#909090"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              )}
              <span className=" cursor-pointer">
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
                >
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="flex justify-center items-center   "
                  >
                    <FeedKababIcon />
                  </button>
                </Dropdown>
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full mt-4">
          <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
            {content}
          </p>
        </div>
      </div>
      <div className="flex justify-between mt-3">
        <div className="flex text-gray_6">
          {upvotes > 0 && (
            <>
              <div className="flex cursor-pointer py-2 px-3 gap-1 items-center rounded-[400px] bg-[#F2F2F2] text-[14px] not-italic font-normal leading-[120%]">
                <div className="flex items-center">
                  <span
                    className=" cursor-pointer"
                    onClick={() => {
                      setIsVoted((prevIsVoted: any) => !prevIsVoted);
                      setUpVoteCount((prevCount: any) =>
                        isVoted ? prevCount - 1 : prevCount + 1
                      );
                    }}
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.6693 8.44108L8.0026 5.77441M8.0026 5.77441L5.33594 8.44108M8.0026 5.77441V11.1077M14.6693 8.44108C14.6693 12.123 11.6845 15.1077 8.0026 15.1077C4.32071 15.1077 1.33594 12.123 1.33594 8.44108C1.33594 4.75918 4.32071 1.77441 8.0026 1.77441C11.6845 1.77441 14.6693 4.75918 14.6693 8.44108Z"
                        stroke={isVoted ? "#001EFF" : "#1F1F1F"}
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
                <p
                  className={`${isVoted ? "text-blue_1" : ""} ${
                    isMac && " pt-[4px]"
                  }  text-sm not-italic font-medium leading-[normal] `}
                >
                  {upVoteCount}
                </p>
                <p
                  className={` ${isMac && " pt-[4px]"}   `}
                  onClick={() => setUpVotesModalOpen(true)}
                >
                  upvotes
                </p>
              </div>
            </>
          )}
          <span
            onClick={toggleAdditionalComment}
            className="flex py-2.5 px-3 ml-2 cursor-pointer rounded-[400px] bg-[#F2F2F2] text-[14px] not-italic font-normal leading-[120%]"
          >
            <p className={` ${isMac && " pt-1"}   `}>React to</p>
          </span>
        </div>
        <p className="text-gray_60 text-sm not-italic font-normal leading-[normal]">
          {timestamp}
        </p>
      </div>
    </div>
  );
};

export const SubComment = (props: {
  username: any;
  isPinned: any;
  content: any;
  upvotes: any;
  timestamp: any;
  toggleAdditionalComment: any;
}) => {
  const { isPinned, content } = props;

  const items: MenuProps["items"] = [
    {
      label: (
        <p className="text-primary text-[17px] not-italic font-normal leading-[120%]">
          Delete
        </p>
      ),
      key: "2",
    },
  ];

  return (
    <div className="comments flex justify-end">
      <div className="w-full ml-9 bg-gray_4 p-2 rounded-lg">
        <div className="flex flex-col">
          <div className="flex  justify-between">
            <div className="flex">
              <Avatar
                src={FeedUser}
                className="h-[32px] w-[32px] rounded-md cursor-pointer "
              />
              <div className="flex flex-col ml-3">
                <h6 className="text-[17px] not-italic font-medium leading-[120%] cursor-pointer">
                  e/anushaudupi
                </h6>
              </div>
            </div>
            <div className="flex items-center cursor-pointer gap-2 py-1.5 px-3">
              {isPinned && (
                <span className="">
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.75561 11.3519L1.98438 15.1231M7.9674 5.36888L6.92681 6.40947C6.84193 6.49435 6.79949 6.53679 6.75113 6.57051C6.70821 6.60045 6.66192 6.62522 6.6132 6.64433C6.55832 6.66586 6.49947 6.67763 6.38176 6.70117L3.93878 7.18977C3.30391 7.31674 2.98648 7.38023 2.83797 7.5476C2.70859 7.6934 2.64951 7.88853 2.67628 8.08162C2.70701 8.30325 2.93591 8.53216 3.39372 8.98997L8.11755 13.7138C8.57536 14.1716 8.80426 14.4005 9.0259 14.4312C9.21898 14.458 9.41411 14.3989 9.55992 14.2695C9.72729 14.121 9.79077 13.8036 9.91775 13.1687L10.4063 10.7258C10.4299 10.608 10.4417 10.5492 10.4632 10.4943C10.4823 10.4456 10.5071 10.3993 10.537 10.3564C10.5707 10.308 10.6132 10.2656 10.698 10.1807L11.7386 9.14011C11.7929 9.08584 11.82 9.05871 11.8499 9.03501C11.8764 9.01397 11.9044 8.99497 11.9338 8.97818C11.9669 8.95929 12.0022 8.94417 12.0727 8.91394L13.7356 8.20126C14.2207 7.99335 14.4633 7.88939 14.5735 7.7214C14.6699 7.5745 14.7043 7.3955 14.6694 7.22332C14.6295 7.02642 14.4429 6.83981 14.0697 6.4666L10.6409 3.03784C10.2677 2.66462 10.0811 2.47801 9.8842 2.4381C9.71202 2.40319 9.53302 2.43767 9.38611 2.53402C9.21812 2.6442 9.11416 2.88677 8.90625 3.3719L8.19358 5.03481C8.16334 5.10535 8.14823 5.14063 8.12933 5.1737C8.11255 5.20308 8.09355 5.23114 8.0725 5.25764C8.04881 5.28747 8.02167 5.31461 7.9674 5.36888Z"
                      stroke="#909090"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              )}
              <span className=" cursor-pointer">
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
                >
                  <button
                    onClick={(e) => e.preventDefault()}
                    className="flex justify-center items-center   "
                  >
                    <FeedKababIcon />
                  </button>
                </Dropdown>
              </span>
            </div>
          </div>
        </div>
        <div className="flex w-full mt-2  ">
          <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]">
            {content}
          </p>
        </div>
      </div>
    </div>
  );
};

const useOutsideClick = (ref: any, callback: any) => {
  const handleClick = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [ref, callback]);
};

export default function FeedFooter(props: any) {
  const hashtags = ["#snapchat", "#under25", "#Sol"];
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showAdditionalComment, setShowAdditionalComment] = useState(false);
  const [isLikesModalOpen, setLikesModalOpen] = useState(false);
  const [isUpVotesModalOpen, setUpVotesModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isLike, setIsLiked] = useState(false);
  const [isCommentSort, setIsCommentSort] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Most Relevant");
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const isMac = useIsMac();

  const toggleCommentBox = () => {
    setShowCommentBox(!showCommentBox);
  };

  const toggleAdditionalComment = () => {
    setShowAdditionalComment(!showAdditionalComment);
  };

  const [showEmojiSub, setShowEmojiSub] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);

  const commentData = {
    username: "e/anushaudupi",
    isPinned: true,
    content:
      "Lorem ipsum dolor sit amet consectetur. Turpis consequat blandit eget sollicitudin. Amet posuere magna tortor hendrerit pharetra sapien.",
    upvotes: 4,
    timestamp: "5 mins ago",
  };

  const commentDataPinned = {
    username: "e/anushaudupi",
    isPinned: false,
    content:
      "Lorem ipsum dolor sit amet consectetur. Turpis consequat blandit eget sollicitudin. Amet posuere magna tortor hendrerit pharetra sapien.",
    upvotes: 0,
    timestamp: "5 mins ago",
  };

  const SubcommentDataPinned = {
    username: "e/anushaudupi",
    isPinned: false,
    content: "Aahaha, this response was great.",
    upvotes: 0,
    timestamp: "5 mins ago",
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsCommentSort(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleOptionClick = (option: any) => {
    setSelectedOption(option);
    setIsCommentSort(false);
  };

  const emoji = useMemo(() => ["❤️", "😢", "😂", "😍", "👍", "😡"], []);

  const setEmojiReaction = (emoji: string) => {
    // setReactedEmoji((prev) => [...prev, emoji]);
    // emojiList(emoji);
  };

  useEffect(() => {
    localStorage.setItem("emoji", JSON.stringify(emoji));
  }, [emoji]);

  const emojiPickerRef: any = useRef(null);
  const emojiPickerRef1: any = useRef(null);

  const handleEmojiOutsideClick = () => {
    setShowEmoji(false);
  };

  const handleEmojiSubOutsideClick = () => {
    // Update this logic if needed
    setShowEmoji(false);
  };

  useOutsideClick(emojiPickerRef, handleEmojiOutsideClick);
  useOutsideClick(emojiPickerRef1, handleEmojiSubOutsideClick);

  return (
    <div className="mt-4">
      <div className=" space-x-2">
        {hashtags.map((hashtag, index) => (
          <span
            key={index}
            className={`inline-flex p-2 cursor-pointer rounded-md bg-hashtag_bg text-[17px] not-italic font-normal leading-[120%] ${
              isMac && "pt-2.5"
            } `}
          >
            {hashtag}
          </span>
        ))}
      </div>
      <div className="flex justify-between mt-4">
        <div
          onClick={() => setLikesModalOpen(true)}
          className="flex text-[17px] not-italic font-normal leading-[120%] text-hashtag_text cursor-pointer "
        >
          <span className=" text-primary mr-1">Kani, Anusha</span> and 2 others
          Liked
        </div>
        <span
          onClick={toggleCommentBox}
          className="text-[17px] not-italic font-normal leading-[120%] text-hashtag_text cursor-pointer"
        >
          3 Comments
        </span>
      </div>
      <div className="flex mt-2 pt-3 px-2 border-t-[1.177px] border-solid border-gray_4 ">
        <span
          onClick={(e) => setIsLiked((prevIsLiked) => !prevIsLiked)}
          className="flex  px-3 py-1  cursor-pointer"
        >
          {isLike ? <FeedLoveIconLiked /> : <FeedLoveIcon />}
          <p className={`ml-2 leading-[normal] ${isMac && "pt-1"}`}>1</p>
        </span>
        <span
          className="flex  px-3 py-1 cursor-pointer"
          onClick={toggleCommentBox}
        >
          <FeedCommentIcon />
          <p className={`ml-2 leading-[normal] ${isMac && "pt-1"}`}>1</p>
        </span>
        <span
          onClick={() => setShareModalOpen(true)}
          className="flex  px-3 py-1 cursor-pointer"
        >
          <FeedShareIcon />
          <p className={`ml-2 leading-[normal] ${isMac && "pt-1"}`}>1</p>
        </span>
      </div>

      <LikesModal
        isModalOpen={isLikesModalOpen}
        handleOk={() => setLikesModalOpen(true)}
        handleCancel={() => setLikesModalOpen(false)}
      />

      <UpVotesModal
        isModalOpen={isUpVotesModalOpen}
        handleOk={() => setUpVotesModalOpen(true)}
        handleCancel={() => setUpVotesModalOpen(false)}
      />
      <ShareModal
        isModalOpen={isShareModalOpen}
        handleOk={() => setShareModalOpen(false)}
        handleCancel={() => setShareModalOpen(false)}
      />
      {showCommentBox && (
        <>
          <div className="w-full mt-4 bg-white rounded-lg border border-zinc-300 justify-between items-center inline-flex">
            <input
              placeholder="Add a comment"
              onKeyDown={(e: any) => {
                if (e.key === "Enter") {
                  const newComment = {
                    username: "e/anushaudupi",
                    isPinned: false,
                    content: e.target.value,
                    upvotes: 0,
                    timestamp: "just now",
                  };

                  e.target.value = "";
                }
              }}
              className=" w-full py-3  pl-3 pr-1 rounded-lg text-stone-900 text-opacity-60 text-[17px] font-normal font-['Oskari G2'] leading-tight border-none focus:border-none focus:outline-none"
            ></input>
            <div
              onClick={() => setShowEmoji(!showEmoji)}
              className=" relative justify-end items-center gap-5 flex cursor-pointer mr-3"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 14.4409C8 14.4409 9.5 16.4409 12 16.4409C14.5 16.4409 16 14.4409 16 14.4409M15 9.44092H15.01M9 9.44092H9.01M22 12.4409C22 17.9638 17.5228 22.4409 12 22.4409C6.47715 22.4409 2 17.9638 2 12.4409C2 6.91807 6.47715 2.44092 12 2.44092C17.5228 2.44092 22 6.91807 22 12.4409ZM15.5 9.44092C15.5 9.71706 15.2761 9.94092 15 9.94092C14.7239 9.94092 14.5 9.71706 14.5 9.44092C14.5 9.16478 14.7239 8.94092 15 8.94092C15.2761 8.94092 15.5 9.16478 15.5 9.44092ZM9.5 9.44092C9.5 9.71706 9.27614 9.94092 9 9.94092C8.72386 9.94092 8.5 9.71706 8.5 9.44092C8.5 9.16478 8.72386 8.94092 9 8.94092C9.27614 8.94092 9.5 9.16478 9.5 9.44092Z"
                  stroke="#1F1F1F"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div
                ref={emojiPickerRef}
                className="absolute -right-[50%] -top-8 z-10"
              >
                {showEmoji && (
                  <Picker
                    previewPosition="none"
                    data={data}
                    onEmojiSelect={(e: any) => {
                      setShowEmoji(false);
                      setEmojiReaction(e.native);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div>
            <div
              onClick={() => setIsCommentSort(!isCommentSort)}
              className="relative flex text-left mt-3 cursor-pointer items-center"
            >
              <button
                className="text-[17px] not-italic font-normal leading-[120%] focus:outline-none"
                id="dropdown-button"
              >
                {selectedOption}
              </button>
              <span className="">
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6.44092L8 10.4409L12 6.44092"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>

              {isCommentSort && (
                <div
                  className="origin-top-left absolute left-0 z-10 top-4 mt-2 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
                  id="dropdown-menu"
                >
                  <div className="py-1">
                    <a
                      href="#"
                      className={`block px-4 py-2 text-sm ${
                        selectedOption === "Most Recent"
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      }`}
                      onClick={() => handleOptionClick("Most Recent")}
                    >
                      Most Recent
                    </a>
                    <a
                      href="#"
                      className={`block px-4 py-2 text-sm ${
                        selectedOption === "Most Relevant"
                          ? "bg-gray-100 text-gray-900"
                          : "text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                      }`}
                      onClick={() => handleOptionClick("Most Relevant")}
                    >
                      Most Relevant
                    </a>
                  </div>
                </div>
              )}
            </div>

            <div className="space-y-4 mt-3">
              <Comment
                setUpVotesModalOpen={setUpVotesModalOpen}
                {...commentData}
                toggleAdditionalComment={toggleAdditionalComment}
              />
              {showAdditionalComment && (
                <div className=" flex flex-col relative   justify-center items-end ">
                  <div className="w-11/12 relative  mb-2 bg-white rounded-lg border border-zinc-300 justify-between items-center inline-flex">
                    <input
                      placeholder="Add a comment"
                      className=" w-full  py-3  pl-3 pr-1 rounded-lg text-stone-900 text-opacity-60 text-[17px] font-normal font-['Oskari G2'] leading-tight border-none focus:border-none focus:outline-none"
                    ></input>
                    <div
                      onClick={() => setShowEmojiSub(!showEmojiSub)}
                      className="justify-end  items-center gap-5 flex cursor-pointer mr-3"
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 14.4409C8 14.4409 9.5 16.4409 12 16.4409C14.5 16.4409 16 14.4409 16 14.4409M15 9.44092H15.01M9 9.44092H9.01M22 12.4409C22 17.9638 17.5228 22.4409 12 22.4409C6.47715 22.4409 2 17.9638 2 12.4409C2 6.91807 6.47715 2.44092 12 2.44092C17.5228 2.44092 22 6.91807 22 12.4409ZM15.5 9.44092C15.5 9.71706 15.2761 9.94092 15 9.94092C14.7239 9.94092 14.5 9.71706 14.5 9.44092C14.5 9.16478 14.7239 8.94092 15 8.94092C15.2761 8.94092 15.5 9.16478 15.5 9.44092ZM9.5 9.44092C9.5 9.71706 9.27614 9.94092 9 9.94092C8.72386 9.94092 8.5 9.71706 8.5 9.44092C8.5 9.16478 8.72386 8.94092 9 8.94092C9.27614 8.94092 9.5 9.16478 9.5 9.44092Z"
                          stroke="#1F1F1F"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <span
                      ref={emojiPickerRef1}
                      className="absolute -right-[0%] -top-8 z-10"
                    >
                      {showEmojiSub && (
                        <Picker
                          previewPosition="none"
                          data={data}
                          onEmojiSelect={(e: any) => {
                            setShowEmojiSub(false);
                            setEmojiReaction(e.native);
                          }}
                        />
                      )}
                    </span>
                  </div>

                  <div>
                    <div className="space-y-4 mt-2">
                      <Comment
                        {...commentData}
                        toggleAdditionalComment={toggleAdditionalComment}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="border-b pb-4 border-gray_4">
                <Comment
                  {...commentDataPinned}
                  toggleAdditionalComment={toggleAdditionalComment}
                />
              </div>
              <SubComment
                {...SubcommentDataPinned}
                toggleAdditionalComment={toggleAdditionalComment}
              />
              <SubComment
                {...SubcommentDataPinned}
                toggleAdditionalComment={toggleAdditionalComment}
              />
              <SubComment
                {...SubcommentDataPinned}
                toggleAdditionalComment={toggleAdditionalComment}
              />
              <Comment
                {...commentDataPinned}
                toggleAdditionalComment={toggleAdditionalComment}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
