import { Avatar, Dropdown, MenuProps, Popover, notification } from "antd";
import {
  FeedConnectIcon,
  FeedKababIcon,
  FeedKababSaveIcon,
  FeedKababShareIcon,
  FeedKababCopyIcon,
  FeedKababReportIcon,
  CloseDarkOutlined,
  FeedConnectedIcon,
  CloseIconLight,
} from "../../Icons/Icons";
import { ReactNode, useState } from "react";
import ShareModal from "../Modal/ShareModal";
import "./FeedHeader.scss";
import SaveToDeck from "../Modal/SaveToDeck";
import ReportModal from "../Modal/ReportModal";
import { Link } from "react-router-dom";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import { NotificationPlacement } from "antd/es/notification/interface";

export interface FeedHeaderProps {
  userAvatar: any;
  name: any;
  subName?: any;
  closeIcon?: boolean | ReactNode;
  handleClose?: () => void;
}

const FeedHeader: React.FC<FeedHeaderProps> = ({
  userAvatar,
  name,
  subName,
  closeIcon,
  handleClose,
}) => {
  const [isShareModalOpen, setShareModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [isFeedIconConnected, setFeedIconConnected] = useState(false);
  const [isReportModalOpen, setReportModalOpen] = useState(false);
  const [isPopOverOpen, setIsPopOverOpen] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const handleShareModalOpen = () => {
    setShareModalOpen(true);
  };

  const handleSaveModalOpen = () => {
    setIsSaveModalOpen(true);
  };

  const handleReportModalOpen = () => {
    setReportModalOpen(true);
  };

  const items: MenuProps["items"] = [
    {
      label: (
        <p
          onClick={handleSaveModalOpen}
          className="text-primary text-[17px] not-italic font-normal leading-[120%]"
        >
          Save
        </p>
      ),
      key: "1",
      icon: (
        <span onClick={handleSaveModalOpen} className="mr-3">
          <FeedKababSaveIcon />
        </span>
      ),
    },
    {
      label: (
        <p
          onClick={() => openNotification("topRight")}
          className="text-primary text-[17px] not-italic font-normal leading-[120%]"
        >
          Copy link
        </p>
      ),
      key: "2",
      icon: (
        <span onClick={() => openNotification("topRight")} className="mr-3">
          <FeedKababCopyIcon />
        </span>
      ),
    },
    {
      label: (
        <p
          onClick={handleReportModalOpen}
          className="text-primary text-[17px] not-italic font-normal leading-[120%]"
        >
          Report
        </p>
      ),
      key: "3",
      icon: (
        <span onClick={handleReportModalOpen} className="mr-3">
          <FeedKababReportIcon />
        </span>
      ),
    },
    {
      label: (
        <>
          <p
            onClick={handleShareModalOpen}
            className="text-primary text-[17px] not-italic font-normal leading-[120%]"
          >
            Share
          </p>
        </>
      ),
      key: "4",
      icon: (
        <span className="mr-3" onClick={handleShareModalOpen}>
          <FeedKababShareIcon />
        </span>
      ),
    },
  ];

  const dataItems = (
    <div>
      <button
        onClick={() => {
          setIsPopOverOpen(false);
        }}
        className="w-full text-left text-primary text-[17px] not-italic font-normal leading-[120%] pt-1.5 pb-0.5 px-2 rounded-lg hover:!bg-gray_4"
      >
        Connect
      </button>
      <button
        onClick={() => {
          setIsSaveModalOpen(true);
          setIsPopOverOpen(false);
        }}
        className="w-full text-left text-primary text-[17px] not-italic font-normal leading-[120%] pt-1.5 pb-0.5 px-2 rounded-lg hover:!bg-gray_4"
      >
        Save to decks
      </button>
    </div>
  );

  const openNotification = (placement: NotificationPlacement) => {
    api.open({
      message: "",
      className:
        "copylink_notification auto-close !bg-primary !w-auto !shadow-[0px_12px_8px_0px_rgba(0,0,0,0.08),0px_3px_24px_0px_rgba(0,0,0,0.04)] !py-3 !px-4 !rounded-lg ![&_.ant-notification-notice-message]:m-0",
      description: (
        <div className="flex items-center justify-between">
          <div className="border-r-gray_1 border-r border-solid pr-5">
            <p className="text-white text-[17px] not-italic font-medium leading-[120%]">
              Copied to clipboard
            </p>
          </div>
          <button className="ml-4" onClick={closeNotification}>
            <CloseIconLight />
          </button>
        </div>
      ),
      closeIcon: false,
      duration: 100.1,
      placement,
    });
  };

  const closeNotification = () => {
    api.destroy();
  };

  return (
    <>
      <ShareModal
        isModalOpen={isShareModalOpen}
        handleOk={() => setShareModalOpen(false)}
        handleCancel={() => setShareModalOpen(false)}
      />
      <SaveToDeck
        isModalOpen={isSaveModalOpen}
        handleOk={() => {
          setIsSaveModalOpen(true);
          setIsPopOverOpen(true);
        }}
        handleCancel={() => {
          setIsSaveModalOpen(false);
          setIsPopOverOpen(false);
        }}
      />

      <ReportModal
        isModalOpen={isReportModalOpen}
        setReportModalOpen={setReportModalOpen}
        handleOk={() => setReportModalOpen(false)}
        handleCancel={() => setReportModalOpen(false)}
      />
      <div className="flex justify-between">
        {contextHolder}
        <Link to={"/profile"}>
          <div className="flex">
            <Avatar
              src={userAvatar}
              size={44}
              shape="square"
              alt="user"
              className="rounded-md cursor-pointer max-w-[45px] max-h-[45px] min-w-[45px] min-h-[45px]"
            />
            <div className="ml-2 justify-center items-left text-left flex flex-col">
              <p className="text-primary text-[17px] not-italic font-medium leading-[120%] cursor-pointer">
                {name}
              </p>
              <div className="flex items-center mt-0.5">
                {subName && (
                  <div className=" flex justify-center items-center">
                    <p className="text-hashtag_text text-[15px] not-italic font-medium leading-[120%] cursor-pointer ">
                      {subName}
                    </p>
                    <span className="mx-[7px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="4"
                        height="4"
                        viewBox="0 0 4 4"
                        fill="none"
                      >
                        <circle
                          opacity="0.8"
                          cx="1.82821"
                          cy="1.94101"
                          r="1.76571"
                          fill="#1F1F1F"
                          fillOpacity="0.8"
                        />
                      </svg>
                    </span>
                  </div>
                )}
                <p className="text-[#1F1F1FCC] text-opacity-80 text-[15px] not-italic font-medium leading-[120%]">
                  5m ago
                </p>
              </div>
            </div>
          </div>
        </Link>
        <div className="flex justify-center items-center ">
          {closeIcon ? null : (
            <span
              onClick={() => {
                setFeedIconConnected(!isFeedIconConnected);
              }}
              className={` cursor-pointer w-[35px] h-[35px]  rounded-[8px] p-2 flex justify-center items-center mr-2 ${
                isFeedIconConnected
                  ? "bg-U25_orange hover:bg--U25_orange  "
                  : " hover:bg-gray_20"
              }`}
            >
              {isFeedIconConnected ? (
                <FeedConnectedIcon />
              ) : (
                <FeedConnectIcon />
              )}
            </span>
          )}

          <span className=" cursor-pointer">
            {closeIcon ? (
              <>
                <Popover
                  placement="bottomLeft"
                  arrow={false}
                  content={dataItems}
                  trigger={["click"]}
                  open={isPopOverOpen}
                  onOpenChange={() => {
                    setIsPopOverOpen((prev) => !prev);
                  }}
                  overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
                >
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    className="flex justify-center items-center hover:bg-gray_20 px-2 py-2.5  rounded-lg  w-[35px] h-[35px] "
                  >
                    <FeedKababIcon />
                  </button>
                </Popover>
              </>
            ) : (
              <Dropdown
                menu={{ items }}
                onOpenChange={() => {}}
                trigger={["click"]}
                overlayClassName="header-dropdown pt-2 pb-4 px-2 w-[170px]"
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  className="flex justify-center items-center hover:bg-gray_20 px-2 py-2.5  rounded-lg w-[35px] h-[35px] "
                >
                  <FeedKababIcon />
                </button>
              </Dropdown>
            )}
          </span>
          {closeIcon ? (
            <button
              className="flex justify-center items-center hover:bg-gray_2 ml-2 rounded-lg w-[35px] h-[35px]"
              onClick={handleClose}
            >
              <CloseDarkOutlined />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default FeedHeader;
