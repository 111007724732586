import React from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CustomModal from "../../CustomModal/CustomModal";
import CustomInput from "../../CustomInput/CustomInput";
import { useState } from "react";
import { DatePicker } from "antd";
import "../user_about.scss";
import { DropdownArrow } from "../../Icons/Icons";

interface ExperienceModalProps {
  popover: boolean;
  setPopover: React.Dispatch<React.SetStateAction<boolean>>;
  onBtnClick: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
}
const ExperienceModal: React.FC<ExperienceModalProps> = ({
  popover,
  setPopover,
  title,
  onBtnClick,
}) => {
  const [roleData, setRoleData] = useState("");
  const maxValueCount = 30;
  const [brandData, setBrandData] = useState("");

  const handleRoleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setRoleData(inputValue);
    }
  };

  const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxValueCount) {
      setBrandData(inputValue);
    }
  };

  const handleClosePopover = () => {
    setPopover(false);
  };

  const handleOpenPopover = () => {
    setPopover(true);
  };

  const Header = (
    <div className="pt-6 pb-4 px-6 border-b border-[#F2F2F2]">
      <h6 className="text-[#101828] text-[24px] font-medium leading-[100%] capitalize">
        {title}
      </h6>
    </div>
  );

  const Footer = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_8px_8px]">
      <PrimaryButton
        btnClass="max-h-[42px]"
        onClick={() => {
          handleClosePopover();
          onBtnClick(true);
        }}
        title="Add"
      />
    </div>
  );

  return (
    <CustomModal
      isModalOpen={popover}
      handleCancel={handleClosePopover}
      handleOk={handleOpenPopover}
      title={Header}
      footer={Footer}
      width={615}
    >
      <div className="mt-4 mx-5 flex flex-col gap-5">
        <div className="">
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
            htmlFor="my-modal"
          >
            Role
          </label>
          <div className="relative">
            <CustomInput
              id="my-modal"
              className="mt-[5px] "
              value={roleData}
              onChange={handleRoleChange}
              maxLength={maxValueCount}
            />
            <div className="absolute top-[18px] right-[20px]">
              <p className=" text-[#666666CC] text-[13px] not-italic font-normal leading-[120%]">
                {roleData.length}/{maxValueCount}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
            htmlFor="my-modal"
          >
            Brand/organization
          </label>
          <div className="relative">
            <CustomInput
              id="my-modal"
              className="mt-[5px] "
              value={brandData}
              onChange={handleBrandChange}
              maxLength={maxValueCount}
            />
            <div className="absolute top-[18px] right-[20px]">
              <p className=" text-[#666666CC] text-[13px] not-italic font-normal leading-[120%]">
                {brandData.length}/{maxValueCount}
              </p>
            </div>
          </div>
        </div>
        <div className="">
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-1"
            htmlFor="my-modal"
          >
            Start Date
          </label>
          <div className="flex items-center gap-4">
            <DatePicker
              format="MMM"
              popupClassName="experience-date-picker-popup month-picker"
              picker="month"
              placeholder="Month"
              className="experience-date-picker"
              suffixIcon={<DropdownArrow width={16} height={16} />}
            />
            <DatePicker
              popupClassName="experience-date-picker-popup"
              picker="year"
              placeholder="Year"
              className="experience-date-picker"
              suffixIcon={<DropdownArrow width={16} height={16} />}
            />
          </div>
        </div>
        <div className="">
          <label
            className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-1"
            htmlFor="my-modal"
          >
            End Date
          </label>
          <div className="flex items-center gap-4">
            <DatePicker
              format="MMM"
              popupClassName="experience-date-picker-popup month-picker"
              picker="month"
              placeholder="Month"
              className="experience-date-picker"
              suffixIcon={<DropdownArrow width={16} height={16} />}
            />
            <DatePicker
              popupClassName="experience-date-picker-popup"
              picker="year"
              placeholder="Year"
              className="experience-date-picker"
              suffixIcon={<DropdownArrow width={16} height={16} />}
            />
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ExperienceModal;
