import { ChangeEvent, useEffect, useState } from "react";
import {
  Calender,
  CloseDarkOutlined,
  DropdownArrow,
  FolderPlus,
  KebabMenuSm,
  LinkIcon,
  RadioSelection,
  TypeSquare,
} from "../../Icons/Icons";
import CustomInput from "../../CustomInput/CustomInput";
import { Popover, Select } from "antd";
import { Option } from "antd/es/mentions";
import U25DatePicker from "../../U25DatePicker/U25DatePicker";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";

interface ISubmissionTypesProps {
  selectValue?: string;
  placeholder?: string;
  value?: string;
}

const SubmissionTypes: React.FunctionComponent<ISubmissionTypesProps> = ({
  selectValue,
  value,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState("option1");
  const [showSecondDatePicker, setShowSecondDatePicker] = useState("");
  const [optionsData] = useState<string[]>(["A", "B", "C", "D"]);
  const [options, setOptions] = useState<any>({
    optionA: "",
    optionB: "",
    optionC: "",
    optionD: "",
  });
  const [sliceArr, setSliceArr] = useState<number>(3);
  const [openSubmissionMenu, setOpenSubmissionMenu] = useState<boolean>(false);

  const handleSelectChange = (value: any) => {
    setSelectedValue(value);
  };

  const handleInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setOptions({
      ...options,
      [name]: value,
    });
  };

  const handleOpenChange = (newOpen: boolean) => {
    setOpenSubmissionMenu(newOpen);
  };

  const hide = () => {
    setOpenSubmissionMenu(false);
  };

  useEffect(() => {
    if (selectValue) {
      setSelectedValue(selectValue);
    }
  }, [selectValue]);

  const items = (
    <div>
      <button
        onClick={hide}
        className="flex text-primary text-[17px] not-italic hover:bg-gray_20 rounded-md transition-all duration-200 ease-[ease] font-normal leading-[120%] pt-1.5 pb-0.5 px-2 max-w-[134px] w-full mx-auto cursor-pointer"
      >
        Remove
      </button>
    </div>
  );

  return (
    <>
      <div className="relative border bg-white rounded-xl border-solid border-gray_4 p-4">
        <label className="flex mb-1 text-gray_6" htmlFor="">
          Link Label
        </label>
        <div className="flex items-center gap-4 w-full">
          <Select
            popupClassName={"social-link-dropdown-popup personal-info-popup"}
            className="personal-info-dropdown w-1/4"
            suffixIcon={<DropdownArrow />}
            value={selectedValue}
            onChange={handleSelectChange}
          >
            <Option value="option1" className="align-middle">
              <LinkIcon
                className="inline-flex -mt-1 mr-2"
                height={16}
                width={16}
              />
              Link
            </Option>
            <Option value="option2">
              <FolderPlus
                className="inline-flex -mt-1 mr-2"
                height={16}
                width={16}
              />{" "}
              File
            </Option>
            <Option value="option3">
              <TypeSquare
                className="inline-flex -mt-1 mr-2"
                height={16}
                width={16}
              />{" "}
              Text
            </Option>
            <Option value="option4">
              <Calender
                className="inline-flex -mt-1 mr-2"
                height={16}
                width={16}
              />{" "}
              Date
            </Option>
            <Option value="option5">
              <RadioSelection
                className="inline-flex -mt-1 mr-2"
                height={16}
                width={16}
              />{" "}
              MCQ
            </Option>
          </Select>
          {selectedValue === "option4" ? (
            <U25DatePicker
              setShowSecondDatePicker={setShowSecondDatePicker}
              showSecondDatePicker={showSecondDatePicker}
              containerClass="w-3/4"
            />
          ) : (
            <>
              <CustomInput
                containerStyle="w-3/4"
                placeholder={placeholder}
                value={value}
              />
            </>
          )}
        </div>
        {selectedValue === "option5" && (
          <div className="mt-2 w-full">
            <div className="flex flex-col gap-2">
              {optionsData
                .slice(0, sliceArr)
                ?.map((item: string, index: number) => (
                  <div
                    className={
                      index > 1 && sliceArr - 1 ? "flex items-center gap-2" : ""
                    }
                  >
                    <CustomInput
                      name={`option${item}`}
                      key={index}
                      maxLength={25}
                      displayCount
                      containerStyle="w-full"
                      value={options[`option${item}`]}
                      onChange={handleInputValueChange}
                      placeholder={`Option ${item}`}
                    />

                    {index > 1 && sliceArr - 1 && (
                      <button
                        onClick={() => setSliceArr((prev) => prev - 1)}
                        className="border p-1.5 rounded-lg border-solid border-gray_4"
                      >
                        <CloseDarkOutlined />
                      </button>
                    )}
                  </div>
                ))}
            </div>
            <div className="absolute"></div>
            <PrimaryButton
              title="Add Option"
              size="small"
              theme={"light"}
              fontClass="text-primary text-[13px] not-italic font-normal leading-[normal]"
              onClick={() =>
                setSliceArr((prev) => {
                  if (prev && prev < optionsData.length) {
                    return prev + 1;
                  } else {
                    return optionsData.length;
                  }
                })
              }
              className="mt-2 mx-auto"
            />
          </div>
        )}
        <Popover
          content={items}
          trigger="click"
          open={openSubmissionMenu}
          onOpenChange={handleOpenChange}
          placement="bottomRight"
          arrow={false}
          overlayStyle={{
            width: 150,
          }}
          overlayInnerStyle={{
            padding: "12px 0",
          }}
        >
          <button className="absolute top-2 right-2">
            <KebabMenuSm />
          </button>
        </Popover>
      </div>
    </>
  );
};

export default SubmissionTypes;
