import React, { useState } from "react";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import PhotoUpload from "../../PhotoUpload/PhotoUpload";
import CustomInput from "../../CustomInput/CustomInput";
import CustomTextArea from "../../CustomTextArea/CustomTextArea";
import { HouseModalProps } from "../../../type/types";
import CustomModal from "../../CustomModal/CustomModal";

const EditSpace: React.FC<HouseModalProps> = ({
  isModalOpen,
  handleOk,
  handleCancel,
}) => {
  const [description, setDescription] = useState("");
  const [guidelines, setGuidelines] = useState("");

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setDescription(e.target.value.slice(0, 100));
  };
  const handleGuidelineChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setGuidelines(e.target.value.slice(0, 100));
  };

  const CardHeader = (
    <div className="flex items-center pt-6 pb-4 px-6 border-b-gray_4 border-b border-solid">
      <h4 className="text-dark_85 text-2xl not-italic font-medium leading-[100%]">
        Edit Space
      </h4>
    </div>
  );

  const modalFooter = (
    <div className="flex justify-end items-center gap-4 bg-gray_4 pl-3 pr-6 pt-4 pb-6 rounded-[0_0_12px_12px] ">
      <PrimaryButton title="Save" width="fit-content" />
    </div>
  );
  return (
    <div>
      <CustomModal
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        title={CardHeader}
        footer={modalFooter}
        width={615}
      >
        <div className="mx-0 my-6 px-6 py-0">
          <form action="">
            <p className="text-gray_6 text-[17px] not-italic font-normal leading-[120%] mb-5">
              Fill the below details to get your space approved
            </p>
            <PhotoUpload
              title="Upload Event Thumbnail"
              description="max file size 500kb"
              className="mb-5"
            />
            <div className="flex flex-col gap-5">
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Space Name
                </label>
                <CustomInput
                  name="fullName"
                  //   value={formValues.fullName}
                  //   onChange={handleInputChange}
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="Enter Name"
                />
                {/* {formErrors.fullName && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.fullName}
                </p>
              )} */}
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Space Link
                </label>
                <CustomInput
                  name="fullName"
                  //   value={formValues.fullName}
                  //   onChange={handleInputChange}
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="Enter Space Link"
                />
                {/* {formErrors.fullName && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.fullName}
                </p>
              )} */}
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="dob"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Space Bio
                </label>
                <div className="relative">
                  <CustomTextArea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Edit or create Bio for your space"
                    noBorder={false}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    className="text-primary placeholder:text-gray_3_hover text-[17px] not-italic font-normal leading-[120%] pt-4 pb-2 px-4 mt-1"
                  />
                  <div className="absolute bottom-2 right-4">
                    {description.length}/100
                  </div>
                </div>
                {/* {formErrors.date && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.date}
                </p>
              )} */}
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="dob"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Space Guidelines
                </label>
                <div className="relative">
                  <CustomTextArea
                    value={guidelines}
                    onChange={handleGuidelineChange}
                    placeholder="Edit or create guidelines for your space"
                    noBorder={false}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    className="text-primary placeholder:text-gray_3_hover text-[17px] not-italic font-normal leading-[120%] pt-4 pb-2 px-4 mt-1"
                  />
                  <div className="absolute bottom-2 right-4">
                    {description.length}/100
                  </div>
                </div>
                {/* {formErrors.date && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.date}
                </p>
              )} */}
              </div>
              <div className="flex flex-col">
                <label
                  htmlFor="name"
                  className="text-gray_6 text-[17px] not-italic font-normal leading-[120%]"
                >
                  Hashtags
                </label>
                <CustomInput
                  name="fullName"
                  //   value={formValues.fullName}
                  //   onChange={handleInputChange}
                  containerStyle="mt-2"
                  id="name"
                  type="text"
                  placeholder="Select hashtags filters for your space"
                />
                {/* {formErrors.fullName && (
                <p className="text-danger text-[17px] not-italic font-normal leading-[120%] mt-2">
                  {formErrors.fullName}
                </p>
              )} */}
              </div>
            </div>
          </form>
        </div>
      </CustomModal>
    </div>
  );
};

export default EditSpace;
