import AddItemComp from "./AddItemComp";
import SocialModal from "./SocialModal";
import { useState } from "react";
import "../user_about.scss";
import { EditOutlined, PlusIconLight } from "../../Icons/Icons";
import { useEffect } from "react";

interface SocialLinksProps {
  edit?: boolean;
}
const SocialLinks: React.FC<SocialLinksProps> = ({ edit = false }) => {
  const Data = [
    {
      key: 1,
      name: "Kanishka_Reddy__",
      platform: "twitter",
    },
    {
      key: 2,
      name: "Kanishka_Reddy__",
      platform: "Instagram",
    },
  ];

  const [popover, setPopover] = useState(false);
  const [title, setTitle] = useState("Add Socials");
  const [view, setView] = useState(false);

  useEffect(() => {
    if (!edit) {
      setView(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edit-wrapper">
      <div className="flex justify-between mb-3.5">
        <h6 className="text-[#1F1F1F] text-[17px] font-medium leading-[120%] ">
          Socials
        </h6>
        {edit && view ? (
          <div className="flex">
            <span
              onClick={() => {
                setTitle("Edit Experience");
                setPopover(true);
              }}
              className=" cursor-pointer flex justify-center items-center edit-icon "
            >
              <EditOutlined />
            </span>
            <span
              onClick={() => {
                setTitle("Add Experience");
                setPopover(true);
              }}
              className="cursor-pointer flex justify-center items-center edit-icon"
            >
              <PlusIconLight />
            </span>
          </div>
        ) : null}
      </div>
      {view && (
        <div className="flex flex-col gap-3 ">
          {Data.map((item) => {
            return (
              <div className="p-4 border border-[#DBD9D9] rounded-[12px] flex items-center gap-3 cursor-pointer">
                <span className="max-w-[20px] min-w[20px] min-h-[20px] max-h-[20px] flex justify-center items-center">
                  <img
                    src={`/images/svg/social/${item.platform}.svg`}
                    alt={item.platform}
                  />
                </span>
                <h6 className="text-[#001EFF] text-[15px] font-normal leading-[120%]">
                  {item.name}
                </h6>
              </div>
            );
          })}
        </div>
      )}

      {!view && (
        <AddItemComp
          onBtnClick={() => {
            setPopover(true);
          }}
          title="This looks blank"
          desc="add your social profiles"
        />
      )}
      <SocialModal
        onBtnClick={setView}
        title={title}
        popover={popover}
        setPopover={setPopover}
      />
    </div>
  );
};

export default SocialLinks;
